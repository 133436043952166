import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-place-selection',
  templateUrl: './place-selection.component.html',
  styleUrls: ['./place-selection.component.css']
})
export class PlaceSelectionComponent implements OnInit {
  @ViewChild('f') f;
  constructor(private dialogRef: MatDialogRef<PlaceSelectionComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  model: any = {};
  modelData: any = {};
  map_settings: any;
  show: boolean = false;
  typePassword = "password";
  ngOnInit() {
    this.f.submitted = true;
    this.model = { coordinates: this.data.coordinates, delivery_distance: this.data.delivery_distance }
  }

  close() {
    this.dialogRef.close();
  }


  onSubmit() {

    this.modelData = {
      coordinates: this.model.coordinates,
      delivery_distance: this.model.delivery_distance,
    }
    this.map_settings = JSON.stringify(this.modelData);
    this.apiService.editCompanyMapSettingsInfo(this.data.id, this.map_settings)
      .subscribe((result: any) => {
        this.dialogRef.close('Edited');
        if (result.success) {
          this.notifier.notify('success', 'Company Map Settings Updated Successfully');
        } else {
          this.notifier.notify('error', 'Unable to Update Data');
        }
      }, (result: any) => {
        this.notifier.notify('error', 'Unable to Update Data');
      });
  }

}
