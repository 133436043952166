import { HttpResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from "@angular/router"
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { NotifierService } from 'angular-notifier';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(protected router: Router,
    private notifier: NotifierService,
    private cookieService: CookieService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.cookieService.get('SESSIONID'))
      request = request.clone({
        withCredentials: true
      });
    return next.handle(request.clone()).catch(err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.notifier.notify('error', err.error.message);
        this.router.navigate(['admin']);
      }

      return Observable.throw(err); 
    });

  }
}