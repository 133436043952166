<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <div class="row">
        <h3> Send Email </h3>
        <hr />
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="To:" [(ngModel)]="model.receiver_to" name="receiver_to" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Subject:" [(ngModel)]="model.receiver_subject" name="receiver_subject"
              required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <textarea matInput rows="6" placeholder="Message:" [(ngModel)]="model.receiver_msg" name="receiver_msg"
              required></textarea>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="!data.transDataObj">
        <i class="material-icons">
          picture_as_pdf
        </i> {{model.fileName}}
      </div>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="mat-raised-button" (click)="close()">Close</button>
    <button type="submit" class="mat-raised-button mat-primary" (click)="onSubmit()"
      [disabled]="f.invalid">{{btnSending}}</button>
  </mat-dialog-actions>
</div>