<div class="dialog-title" mat-dialog-title>
  <div class="row">
    <div class="col-md-6 col-9">
      <h3 class="color-h3"> Add Purchase Order </h3>
    </div>
    <div class="col-md-6 col-3 right">
      <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
</div>

<mat-dialog-content>
  <form #f="ngForm" [formGroup]="form">
    <div class="row">
      <div class="col-md-3 d-none">
        <mat-form-field class="contract-full-width">
          <mat-select placeholder="Contract Company:" formControlName="contract_id">
            <mat-option *ngFor="let contract of contractObj" [value]="contract.contract_id">
              {{contract.company_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <fieldset class="border p-2 form-fieldset">
          <legend class="w-auto">Billing Details:</legend>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input type="text" placeholder="Ledger" aria-label="Number" matInput [formControl]="ledgerControl"
                  [matAutocomplete]="auto" (keyup)="search($event)" (focus)="onFocus($event)" required>
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
                  (optionSelected)="ledgerSelection($event)">
                  <mat-option>
                    <div (click)="onAddSupplierClick()" class="text-center">
                      <span>Add New Supplier</span>
                    </div>
                  </mat-option>
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    <span class="item-font"> {{option.ledger_name}}</span><br>
                    <small><span *ngIf="option.address_address1" class="item-rate">{{option.address_address1}},
                      </span></small>
                    <small><span *ngIf="option.address_address2" class="item-rate">{{option.address_address2}},
                      </span></small>
                    <small><span *ngIf="option.address_street" class="item-rate"> {{option.address_street}},
                      </span></small>
                    <small><span *ngIf="option.address_city" class="item-rate">{{option.address_city}} </span></small>
                    <small><span *ngIf="option.address_pin" class="item-rate">-{{option.address_pin}}, </span></small>
                    <small><span *ngIf="option.address_state" class="item-rate">{{option.address_state}},
                      </span></small>
                    <small><span *ngIf="option.address_country" class="item-rate"> {{option.address_country}}
                      </span></small>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Purchase Order No.:" formControlName="trans_no" required readonly>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput [matDatepicker]="trans_date" placeholder="Purchase Order Date:"
                  formControlName="trans_date">
                <mat-datepicker-toggle matSuffix [for]="trans_date"></mat-datepicker-toggle>
                <mat-datepicker #trans_date></mat-datepicker>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Address1:" formControlName="billing_address1">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Address2:" formControlName="billing_address2">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Street:" formControlName="billing_street">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="City:" formControlName="billing_city">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="contract-full-width">
                <mat-select placeholder="State" name="billing_state" formControlName="billing_state" disabled>
                  <mat-option *ngFor="let state of stateName" [value]="state.state_gst_id">
                    {{state.state_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Country:" formControlName="billing_country">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Pin:" formControlName="billing_pin">
              </mat-form-field>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-3">
        <fieldset class="border p-2 form-fieldset">
          <legend class="w-auto">Shipping Details:</legend>
          <div class="row">
            <div class="col-md-12">
              <mat-checkbox type="checkbox" (change)="sameAdd($event.checked)">Same as Billing</mat-checkbox>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Address1:" formControlName="shipping_address1">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Address2:" formControlName="shipping_address2">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Street:" formControlName="shipping_street">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="City:" formControlName="shipping_city">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <mat-select placeholder="State" name="shipping_state" formControlName="shipping_state">
                  <mat-option *ngFor="let state of stateName" [value]="state.state_gst_id">
                    {{state.state_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Country:" formControlName="shipping_country">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Pin:" formControlName="shipping_pin">
              </mat-form-field>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-3">
        <fieldset class="border p-2 form-fieldset">
          <legend class="w-auto">Transport Details:</legend>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Address1:" formControlName="transport_address1">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Address2:" formControlName="transport_address2">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Street:" formControlName="transport_street">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="City:" formControlName="transport_city">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <mat-select placeholder="State" name="transport_state" formControlName="transport_state">
                  <mat-option *ngFor="let state of stateName" [value]="state.state_gst_id">
                    {{state.state_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Country:" formControlName="transport_country">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Pin:" formControlName="transport_pin">
              </mat-form-field>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <br>
    <fieldset class="border p-2 form-fieldset">
      <legend class="w-auto">Items Details:</legend>
      <button class="btn btn-sm" [disabled]="!form.value.ledger_id" (click)="addItemClick()">
        <i class="fa fa-plus"></i>Add Items</button>
      <table class="table contract-item-table">
        <thead class="thead-light">
          <th>#</th>
          <th>Item Id</th>
          <th>Item Name</th>
          <th class="right">Quantity</th>
          <th class="right">Rate</th>
          <th class="right">Amount</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngIf="items.length==0" [class.text-danger]="!isValid && items.length==0">
            <td class="font-italic text-center" colspan="8">
              No item added for this Purchase Order.
            </td>
          </tr>

          <tr *ngFor="let item of items;let i=index;">
            <td data-title='#'>{{i+1}}</td>
            <td data-title='Item Id' class="table-font-arial">{{item.item_id}}</td>
            <td data-title='Item Name' class="table-font-arial">{{item.item_name}}</td>
            <td data-title='Quantity' class="right table-font-arial">{{item.qty}}</td>
            <td data-title='Rate' class="right table-font-arial">{{item.p_rate}}</td>


            <td data-title='Amount' class="right table-font-arial">{{item.amount | number : '1.2-2'}}</td>
            <td data-title='Actions' class="action-column">
              <button mat-icon-button (click)="editItem(i)"><mat-icon>edit</mat-icon></button>
              <button mat-icon-button color="warn"
                (click)="onDeleteItem(i)"><mat-icon>delete_outline</mat-icon></button>
            </td>
          </tr>
        </tbody>
      </table>

    </fieldset>
    <div class="row">
      <div class="col-md-10 col-8 right">
        <label class="form-bold-label big">Total</label>
      </div>
      <div class="col-md-2 col-4">
        <label class="form-bold-label big">{{form.value.net_amount | number : '1.2-2'}}</label>
      </div>
    </div>

  </form>

</mat-dialog-content>
<div class="row">
  <div class="col-md-6 offset-md-6 right">
    <button mat-raised-button class="btn mr-2 form-margin" (click)="onClear()">Clear</button>
    <button mat-raised-button class="btn" type="button" (click)="onSubmit()">Submit</button>
  </div>
</div>