import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { DueReceiptComponent } from '../due-receipt/due-receipt.component';
import { AddLedgerComponent } from '../../ledgers/add-ledger/add-ledger.component';
import { environment } from '../../../environments/environment';
import { MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../date.adapter';
@Component({
  selector: 'app-edit-receipt',
  templateUrl: './edit-receipt.component.html',
  styleUrls: ['./edit-receipt.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class EditReceiptComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private dialogRef: MatDialogRef<EditReceiptComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currency = environment.currency;
  }

  @ViewChild('f') f;
  currency: any;
  disableInput: any = true;
  ledgerControl = new FormControl();
  options: any;
  filteredOptions: Observable<string[]>;
  isInvoiceDetails: boolean = false;
  invoiceData: any;
  l_id1: any;
  closing_balance1: any;
  ledger_id1: any;
  l_id2: any;
  closing_balance2: any;
  ledger_id2: any;
  totalAmtReadonlyFlg: boolean = false;
  ngOnInit() {
    this.model.bills = {};
    this.load();
  }
  filter(value) {
    const filterValue = value.toLowerCase();
    return this.ledgerObj.filter(ledger => ledger.ledger_code.toLowerCase().includes(filterValue) || ledger.ledger_name.toLowerCase().includes(filterValue));
  }

  displayFn(ledger) {
    return ledger ? ledger.ledger_name : "";
  }
  model: any = {
    payment_mode: '', // initialize with the default payment mode value
    cash: 0,
    card: 0,
    upi: 0,
    total_amount: 0
  };
  ledgerObj: any;
  ledgerObj2: any;
  load() {
    if (this.data.payment_mode == "Bank")
      this.disableInput = true;
    this.f.submitted = true;
    this.apiService.getReceipt(this.data.transaction_id)
      .subscribe((result: any) => {
        this.model = result;
        // console.log("modeldata", this.model)
        if (this.model.bills.length > 0) {
          this.isInvoiceDetails = true;
          this.totalAmtReadonlyFlg = true;
          this.calculateTotalAmt();
        } else {
          this.isInvoiceDetails = false;
          this.totalAmtReadonlyFlg = false;
        }

        this.apiService.getLedgerClosingBalance(this.model.ledger_id).subscribe(
          (result: any) => {
            if (result.success) {
              this.closing_balance2 = result.message.closing_balance + ' ' + result.message.cbdrcr;

            }
            else {
              this.notifier.notify('error', result.message);
            }
          },
          (err: any) => {

          }
        )
      }, (result: any) => {
      });

    this.apiService.getLedger(this.data.ledger_id)
      .subscribe((result: any) => {
        this.ledgerObj = result[0];
        this.ledgerControl.setValue(this.ledgerObj);

        this.apiService.getLedgerClosingBalance(this.data.ledger_id).subscribe(
          (result: any) => {
            if (result.success) {
              this.closing_balance1 = result.message.closing_balance + ' ' + result.message.cbdrcr;

            }
            else {
              this.notifier.notify('error', result.message);
            }
          },
          (err: any) => {

          }
        )
      }, (result: any) => {
      });

    this.apiService.getCashBankLedgerList()
      .subscribe((result: any) => {
        this.ledgerObj2 = result;
        // console.log(this.ledgerObj2);
      }, (result: any) => {
      });
  }
  //searching for ledger
  search(event) {
    this.closing_balance1 = null;

    if (event.key != "ArrowDown" && event.key != "ArrowUp" && event.key != "Enter") {
      if (this.ledgerControl.value.length > 1) {
        this.apiService.getLedgerListWithoutCashBank(this.ledgerControl.value)
          .subscribe((result: any) => {
            this.ledgerObj = result;
            this.filteredOptions = this.ledgerControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.ledgerObj = [];
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }
    }
  }
  ledgerSelection(event: MatAutocompleteSelectedEvent) {
    this.model.ledger_id = event.option.value ? event.option.value.ledger_id : undefined;

    this.apiService.getLedgerClosingBalance(event.option.value.ledger_id).subscribe(
      (result: any) => {
        if (result.success) {
          this.closing_balance1 = result.message.closing_balance + ' ' + result.message.cbdrcr;

        }
        else {
          this.notifier.notify('error', result.message);
        }
      },
      (err: any) => {

      }
    )
  }
  enableInp() {
    this.disableInput = false;
  }
  disableInp() {
    this.disableInput = true;
  }

  onSubmit() {
    if (this.f.valid) {
      let paymentSum = 0;
      if (this.model.payment_mode === 'UPI') {
        this.model.upi = this.model.total_amount;
        paymentSum = this.model.upi;
      } else if (this.model.payment_mode === 'Cash') {
        this.model.cash = this.model.total_amount;
        paymentSum = this.model.cash;
      } else if (this.model.payment_mode === 'Bank') {
        this.model.card = this.model.total_amount;
        paymentSum = this.model.card;
      }
      const totalPayment = this.model.upi + this.model.cash + this.model.card;

      if (totalPayment == this.model.total_amount) {
        this.apiService.editReceipt(this.model.transaction_id, this.model)
          .subscribe((result: any) => {
            if (result.success) {
              this.downloadClick();
              this.notifier.notify('success', 'Receipt Details Updated successfully');
              this.dialogRef.close('Edited');
            } else {
              this.notifier.notify('error', result.message);
            }
          }, (result: any) => {
            this.notifier.notify('error', 'Unable to update Receipt Details');
          });
      } else {
        this.notifier.notify('error', 'Please check the payment of total amount');
      }

    }
  }


  downloadClick() {
    this.model.trans_type = "Receipt";
    this.apiService.downloadPdf(this.model)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'receipt' + this.model.trans_no + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
  cancelInvoice() {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Cancel the Receipt Voucher Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteReceipt(this.data.transaction_id)
            .subscribe((result: any) => {
              this.dialogRef.close('Edited');
              this.notifier.notify('success', 'Receipt Canceled successfully');
            }, (result: any) => {
              this.notifier.notify('error', 'unable to cancel Receipt');
            });
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  onBillSearch() {
    // console.log(this.model);
    this.dialog.open(DueReceiptComponent, {
      width: '1100px',
      maxHeight: '600px',
      data: this.model.ledger_id
    })
      .afterClosed().subscribe(result => {
        if (result && result.length > 0) {
          if (this.model && this.model.bills && this.model.bills.length > 0) {
            for (var i = 0; i < result.length; i++) {
              var flagFound = false;
              for (var k = 0; k < this.model.bills.length; k++) {
                if (result[i].transaction_id == this.model.bills[k].transaction_id) {
                  flagFound = true;
                  break;
                }
              }
              if (!flagFound) {
                this.model.bills.push(result[i]);
              }
            }

          } else {
            this.model.bills = result;
          }
          this.isInvoiceDetails = true;
          this.totalAmtReadonlyFlg = true;
          this.calculateTotalAmt();
        } else {
          // this.isInvoiceDetails = false;
          //  this.totalAmtReadonlyFlg=false;
        }
      });
  }
  //to add Ledger
  onAddLedgerClick() {
    this.dialog.open(AddLedgerComponent, {
      width: '800px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var ledgerData = result.data;
          this.ledgerControl.setValue(ledgerData);
          this.model.ledger_id = ledgerData.ledger_id;

          this.apiService.getLedgerClosingBalance(ledgerData.ledger_id).subscribe(
            (result: any) => {
              if (result.success) {
                this.closing_balance1 = result.message.closing_balance + ' ' + result.message.cbdrcr;

              }
              else {
                this.notifier.notify('error', result.message);
              }
            },
            (err: any) => {

            }
          )
        } else {
          this.ledgerControl.setValue(this.ledgerObj);
        }
      });
  }
  onChange() {
    this.apiService.getLedgerClosingBalance(this.model.ledger_id2).subscribe(
      (result: any) => {
        if (result.success) {
          this.closing_balance2 = result.message.closing_balance + ' ' + result.message.cbdrcr;

        }
        else {
          this.notifier.notify('error', result.message);
        }
      },
      (err: any) => {

      }
    )
  }

  calculateTotalAmt() {
    if (this.isInvoiceDetails) {
      var totalAmt = 0;
      this.model.bills.forEach(element => {
        if (!element.bill_amt) {
          element.bill_amt = 0;
        }
        totalAmt += parseFloat(element.bill_amt);
      });
      if (totalAmt == 0)
        this.totalAmtReadonlyFlg = false;
      this.model.total_amount = totalAmt;
    }
  }
}
