<div class="modal-window">
  <div class="border-bottom py-2 m-4">
    <span style="font-size: larger; font-weight: 500">
      <span class="veg">
        <img
          *ngIf="this.itmData.is_veg"
          class="veg-img"
          src="assets/images/veg/veg.png"
          alt=""
        />
        <img
          *ngIf="!this.itmData.is_veg"
          class="veg-img"
          src="assets/images/veg/nonveg.png"
          alt=""
        />
      </span>
      {{ this.itmData.item_name }}</span
    >
    <p class="pl-4">
      <i class="fa fa-{{ currency }}"></i><strong>{{ sRate }}</strong>
    </p>
    <br />
    <hr />
    <span style="font-size: larger; font-weight: 500">{{
      this.itmData.notes
    }}</span>
  </div>
  <div
    class="fixed-window border-bottom"
    style="max-height: 245px; overflow: scroll"
  >
    <div class="scrollable-content">
      <div
        *ngFor="let option of variantsArr; i as index"
        class="addons my-2 m-4"
        style="margin-top: 0px !important"
      >
        <h5 class="title">
          <span>*</span>{{ option.title
          }}<span style="font-size: x-small">(Required)</span>
        </h5>
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="defaultVariantSize"
          (change)="onChangeChildVariant()"
        >
          <div
            class="radio-buttons"
            *ngFor="let child of option.variants"
            style="position: relative"
          >
            <span class="veg">
              <img
                *ngIf="child.is_veg"
                class="veg-img"
                src="assets/images/veg/veg.png"
                alt=""
              />
              <img
                *ngIf="!child.is_veg"
                class="veg-img"
                src="assets/images/veg/nonveg.png"
                alt=""
              />
            </span>
            <mat-radio-button
              [value]="child"
              [disabled]="child.in_stock === false"
            >
              <span style="text-wrap: wrap">{{ child.title }}</span>
              <span *ngIf="child.price > 0 && !child.is_default" class="price"
                ><i class="fa fa-{{ currency }}"></i
                ><strong>{{ child.price }}</strong></span
              >
              <span
                class="pull-right"
                *ngIf="child.in_stock === false"
                style="font-size: x-small; position: absolute; right: 10px"
                >Unavailable</span
              >
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>

      <div *ngFor="let option of optionsArr" class="addons my-2 m-4">
        <h5 class="title">
          {{ option.title }} <span style="font-size: x-small">(Optional)</span>
        </h5>
        <mat-list role="list">
          <mat-list-item
            *ngFor="let child of option.childOptions"
            role="listitem"
            style="margin-top: -15px"
          >
            <span class="veg" style="margin-top: -8px">
              <img
                *ngIf="child.is_veg"
                class="veg-img"
                src="assets/images/veg/veg.png"
                alt=""
              />
              <img
                *ngIf="!child.is_veg"
                class="veg-img"
                src="assets/images/veg/nonveg.png"
                alt=""
              />
            </span>
            <mat-checkbox
              class="bhnjgi"
              [(ngModel)]="child.ischecked"
              [disabled]="child.in_stock === false"
              (ngModelChange)="onChangeAddons($event, child.id)"
              style="width: 100%"
            >
              <div
                style="
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span>{{ child.title }}</span>
                <div
                  style="display: flex; gap: 20px; align-items: center"
                  class=""
                >
                  <span *ngIf="child.amount > 0" class="price"
                    ><i class="fa fa-{{ currency }}"></i
                    ><strong>{{ child.amount }}</strong></span
                  >
                  <span
                    class="pull-right"
                    *ngIf="child.in_stock === false"
                    style="font-size: x-small; position: absolute; right: 10px"
                    >Unavailable</span
                  >
                </div>
              </div>
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>

  <div style="margin: 5px 20px 20px 20px">
    <span></span>
    <button
      (click)="addItems()"
      class="btn btn-primary add-button"
      type="button"
      style="margin-top: 6px"
    >
      <span class="add-item-total"
        >TOTAL <i class="fa fa-{{ currency }}"></i
        ><strong>{{ totalAmt | number : "1.2-2" }}</strong></span
      >
      <span *ngIf="status === 'add'">ADD ITEM</span>
      <span *ngIf="status === 'update'">UPDATE ITEM</span>
    </button>
  </div>
</div>
