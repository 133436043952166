import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/switchMap";
import "rxjs/add/observable/throw";
import { environment } from "../environments/environment";
import { CookieService } from "ngx-cookie-service";
import * as models from "./data.modal";
import { ConfigService } from "./config.service";
import { Timestamp } from "rxjs/internal/operators/timestamp";
import { map } from "rxjs/operators";
@Injectable()
export class DataService {
  basePath: string;
  cookieValue: any;
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private cookieService: CookieService
  ) {
    this.basePath = environment.basePath;
    //this.basePath = 'http://localhost:3002/';
  }

  getWerraVendorHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("X-Wera-Api-Key", "API_KEY");
    return headers;
  }
  getHeader() {
    let headers = new HttpHeaders();
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Accept", "application/json");
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);
    return headers;
  }
  //Backup Database service
  createDBBackup() {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/zip");
    headers = headers.set("Content-Type", "application/zip");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/BackupDB`),
        {
          responseType: "blob",
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  create_google_drive_DBBackup() {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Google_Drive_Db_BackUp`
        ),
        {
          observe: "response",
          headers,
          body: { basePath: this.basePath },
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  get_last_db_backup() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/LastBackup`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // Account Map API service
  getAccountMapList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/AccountMap`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // Account Map API service
  getEBillAcountMapData() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getEBillAcountMapData`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getuserList() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getAllUser`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // Account Map API service
  getBankAccountList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/BankAccountList`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addAccountMap(data: models.AccountMapObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/AccountMap`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getAccountMap(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/AccountMap/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteAccountMap(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/AccountMap/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editAccountMap(id: string | null, data: models.AccountMapObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/AccountMap/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Ledger group API service
  getLedgerGroupList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/LedgerGroup`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addLedgerGroup(data: models.LedgerGroupObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/LedgerGroup`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getLedgerGroup(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LedgerGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteLedgerGroup(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LedgerGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editLedgerGroup(id: string | null, data: models.LedgerGroupObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LedgerGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Ledger API service
  getLedgerList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Ledger`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getBankLedgerList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/BankLedger`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getCashLedgerList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/CashLedger`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSundryLedgerList(filter: string | null, inactive: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CustomerLedger/${encodeURIComponent(
            filter
          )}/${encodeURIComponent(inactive)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getFilterLedgerList(filter: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LedgerList/${encodeURIComponent(filter)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getLedgerListWithInctive(filter: string | null, active: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LedgerListWithInctive/${encodeURIComponent(
            filter
          )}/${encodeURIComponent(active)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSundryCrLedgerList(filter: string | null, inactive: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SundryCreditorLedger/${encodeURIComponent(
            filter
          )}/${encodeURIComponent(inactive)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getCashBankLedgerList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/CashBankLedger`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getExpenseLedgerList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/ExpenseLedger`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getLedgerListWithoutCashBank(filter: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LedgerWithoutCashBank/${encodeURIComponent(filter)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addLedger(data: models.LedgerObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Ledger`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getLedger(id: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Ledger/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteLedger(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Ledger/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editLedger(id: string | null, data: models.LedgerObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Ledger/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Category API service
  getCategoryList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Category`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addCategory(data: models.CategoryObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Category`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getCategory(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Category/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteCategory(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Category/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editCategory(id: string | null, data: models.CategoryObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Category/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Unit API Service
  getUnitList() {
    let headers = this.getHeader();

    return this.http
      .request("get", Location.joinWithSlash(`${this.basePath}`, `/api/Unit`), {
        observe: "response",
        headers,
      })
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addUnit(data: models.UnitObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Unit`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getUnit(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Unit/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteUnit(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Unit/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editUnit(id: string | null, data: models.UnitObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Unit/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Items API service
  getItemList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Items`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Custom Items API service
  getCustomItemList(filter: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CustomItemList/${encodeURIComponent(filter)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addItem(data) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Items`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  addItemImage(data) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ItemImage`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  importItems(data) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ImportItems`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  importImages(data) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ImportImages`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getTaxslabList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Taxslab`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  addCategoryImage(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/UploadCategoryImage`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  addTableGroupImage(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/UploadTableGroupImage`
        ),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getItem(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Items/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getItemImage(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ProductImage/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          responseType: "blob",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getCategoryImage(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CategoryImage/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          responseType: "blob",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getTableGroupImage(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TableGroupImage/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          responseType: "blob",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getItemByBarcode(barcode: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ItemsByBarcode/${encodeURIComponent(barcode)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getFilterItemList(filter: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/FilterItems/${encodeURIComponent(filter)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getCustomFilterItemList(
    filter: string | null,
    filterItem: any,
    table_id: any | null
  ) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CustomFilterItemList/${encodeURIComponent(
            filter
          )}/${encodeURIComponent(filterItem)}/${encodeURIComponent(table_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getItemListWithInactive(filter: string | null, inactive: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ItemListWithInactive/${encodeURIComponent(
            filter
          )}/${encodeURIComponent(inactive)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  deleteItem(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Items/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editItem(id: string | null, data: models.ItemObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Items/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // Featured Item API
  getFeturedItem() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/FeaturedItems`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateFeaturedItem(data) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/FeaturedItems`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Contracts API service
  getContractList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Contracts`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addContract(data: models.ContractObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Contracts`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getContract(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Contracts/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteContract(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Contracts/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editContract(id: string | null, data: models.ContractObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Contracts/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // PurchaseOrder API service
  getPurchaseOrderList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/PurchaseOrder`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addPurchaseOrder(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/PurchaseOrder`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getPurchaseOrder(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/PurchaseOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deletePurchaseOrder(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/PurchaseOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editPurchaseOrder(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/PurchaseOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Sales Order API service
  getSalesOrderList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/SalesOrder`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addSalesOrder(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/SalesOrder`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getSalesOrder(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SalesOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteSalesOrder(id: string | null, reason: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SalesOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(reason),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editSalesOrder(id: string | null, data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SalesOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Purchase API service
  getPurchaseList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Purchase`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addPurchase(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Purchase`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getPurchase(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Purchase/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deletePurchase(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Purchase/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editPurchase(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Purchase/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //PurchaseReturn API service
  getPurchaseReturnList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/PurchaseReturn`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addPurchaseReturn(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/PurchaseReturn`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getPurchaseReturn(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/PurchaseReturn/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deletePurchaseReturn(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/PurchaseReturn/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editPurchaseReturn(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/PurchaseReturn/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //GRN API service
  getGrnList() {
    let headers = this.getHeader();

    return this.http
      .request("get", Location.joinWithSlash(`${this.basePath}`, `/api/GRN`), {
        observe: "response",
        headers,
      })
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addGrn(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request("post", Location.joinWithSlash(`${this.basePath}`, `/api/GRN`), {
        observe: "response",
        headers,
        body: JSON.stringify(data),
      })
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getGrn(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/GRN/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteGrn(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/GRN/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editGrn(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/GRN/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //DeliveryOrder API service
  getDeliveryOrderList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/DeliveryOrder`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addDeliveryOrder(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/DeliveryOrder`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getDeliveryOrder(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DeliveryOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteDeliveryOrder(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DeliveryOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editDeliveryOrder(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DeliveryOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // Invoice API service
  getInvoiceList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Invoice`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getUnpaidInvoice(table_id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getUnpaidInvoice/${encodeURIComponent(table_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addInvoice(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Invoice`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  addInvoiceAndEmail(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/InvoiceAndEmail`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getInvoice(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Invoice/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteInvoice(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Invoice/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editInvoice(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Invoice/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Get Voucher Type List
  getVoucherTypeList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/VoucherType`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getUnpaidInvoiceReport() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getUnpaidreport`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Credit Note API Service
  getCreditNoteList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/CreditNote`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addCreditNote(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/CreditNote`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getCreditNote(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CreditNote/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteCreditNote(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CreditNote/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editCreditNote(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CreditNote/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // InvoiceReturn API service
  getInvoiceReturnList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/InvoiceReturn`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addInvoiceReturn(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/InvoiceReturn`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getInvoiceReturn(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/InvoiceReturn/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteInvoiceReturn(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/InvoiceReturn/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editInvoiceReturn(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/InvoiceReturn/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getVoucherList(
    from_date: any | null,
    to_date: any | null,
    type: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/AccountVoucher/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // getAllVoucherList(from_date: string | null, to_date: string | null, type: string | null) {
  //   let headers = this.getHeader();

  //   return this.http.request('get', Location.joinWithSlash(`${this.basePath}`, `/api/AllVoucher/${encodeURIComponent(from_date)}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`), {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }

  getAllVoucherList(
    from_date: any | null,
    to_date: any | null,
    type: string | null,
    user_id: any
  ) {
    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/AllVoucher/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(
            type
          )}/${encodeURIComponent(user_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getLedgerDetailsList(
    from_date: any | null,
    to_date: any | null,
    ledger: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LedgerDetailReport/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(ledger)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getTrialBalance(from_date: any | null, to_date: any | null) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TrialBalance/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getMonthlyTrialBalance(
    from_date: any | null,
    to_date: any | null,
    ledger: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MonthlyTrialBalance/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(ledger)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getCustomerOutstanding() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/CustomerOutstanding`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSupplierOutstanding() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/SupplierOutstanding`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Get category Stock
  getCategoryStock(
    from_date: any | null,
    to_date: any | null,
    type: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CategoryStock/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Get Item Stock By Category
  getItemStockByCategory(
    from_date: any | null,
    to_date: any | null,
    cat_id,
    loc_id
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ItemStockByCategory/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(
            cat_id
          )}/${encodeURIComponent(loc_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Get Item Stock By Month
  getMonthlyItemStock(
    from_date: any | null,
    to_date: any | null,
    item: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ItemStockByMonth/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(item)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Get no of purchase rate
  noOfPurchaseRate(
    from_date: any | null,
    to_date: any | null,
    item: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getNoOfPurchaseRate/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(item)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Get Purchase Wise Item Stock
  getPurchaseWiseItemStock(
    from_date: any | null,
    to_date: any | null,
    item: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getPurchaseWiseItemStock/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(item)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Get Item Stock
  getItemStockDetails(
    from_date: any | null,
    to_date: any | null,
    item: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ItemStockDetails/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(item)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Get Tax Summary
  getTaxSummary(
    from_date: any | null,
    to_date: any | null,
    type_id: string | null,
    show_for: string | null,
    summaryFlag: any
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TaxSummary/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(
            type_id
          )}/${encodeURIComponent(show_for)}/${encodeURIComponent(summaryFlag)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Balance Sheet and Profit and Loss
  getBalanceSheet(
    from_date: any | null,
    to_date: any | null,
    type: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 59);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/BalanceSheet/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getAccVoucherList(
    from_date: any | null,
    to_date: any | null,
    ledger: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/AccVoucher/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(ledger)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  downloadPdf(data: models.TransactionObj | null) {
    let headers = new HttpHeaders();
    // console.log(data,"data========================================")

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/PdfDownload`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
          responseType: "blob",
        }
      )
      .map((response) => {
        // console.log(response);
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  downloadPdfByTransId(id: any) {
    let headers = new HttpHeaders();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/printApprovedTransaction/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          responseType: "blob",
        }
      )
      .map((response) => {
        console.log(response);
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  printTablesQrCodes() {
    let headers = new HttpHeaders();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/PrintTablesQRcode`),
        {
          observe: "response",
          headers,
          responseType: "blob",
        }
      )
      .map((response) => {
        console.log(response);
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Payment API service
  getPaymentList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Payment`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addPayment(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Payment`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getPayment(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Payment/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deletePayment(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Payment/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editPayment(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Payment/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Receipt API service
  getReceiptList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Receipt`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addReceipt(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Receipt`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getReceipt(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Receipt/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteReceipt(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Receipt/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editReceipt(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Receipt/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Expense API service
  getExpenseList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Expense`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addExpense(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Expense`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getExpense(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Expense/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteExpense(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Expense/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editExpense(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Expense/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // Journal API service
  getJournalList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Journal`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addJournal(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Journal`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getJournal(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Journal/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteJournal(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Journal/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editJournal(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Journal/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Cash Bank API service
  getCashBankList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/CashBank`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addCashBank(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/CashBank`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getCashBank(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CashBank/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteCashBank(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CashBank/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editCashBank(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CashBank/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getErrorLogList(from_date: any | null, to_date: any | null) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ErrorLog/${encodeURIComponent(from_date)}/${encodeURIComponent(
            to_date
          )}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Role API service
  getRoleList() {
    let headers = this.getHeader();

    return this.http
      .request("get", Location.joinWithSlash(`${this.basePath}`, `/api/Role`), {
        observe: "response",
        headers,
      })
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addRole(data: models.RoleObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Role`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getRole(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Role/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteRole(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Role/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editRole(id: string | null, data: models.RoleObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Role/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //User API service
  getUserList() {
    let headers = this.getHeader();

    return this.http
      .request("get", Location.joinWithSlash(`${this.basePath}`, `/api/User`), {
        observe: "response",
        headers,
      })
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addUser(data: models.UserObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/User`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getUser(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/User/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteUser(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/User/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  UserStatusFlag(id: string | null, userFlag) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/User/${encodeURIComponent(id)}/${encodeURIComponent(userFlag)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editUser(id: string | null, data: models.UserObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/User/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  changePass(id: string | null, data: models.UserObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/updatePass/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  changeUserPass(id: string | null, data: models.UserObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/UpdateUserPass/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Change Tenent user Password
  changeTenentPass(id: string | null, data: models.UserObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TenentUserPass/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  login(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/login`),
        {
          headers: headers,
          observe: "response",
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response;
          }
        }
      });
  }

  logout() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/logout`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  checkTenentDB() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/CheckTenent`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Tenent Login
  tenentLogin(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/tenentLogin`),
        {
          headers: headers,
          observe: "response",
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response;
          }
        }
      });
  }

  tenentLogout() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/tenentLogout`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Menu Group API service
  getMenuGroupList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuGroup`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addMenuGroup(data: models.MenuGroupObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuGroup`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getMenuGroup(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteMenuGroup(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editMenuGroup(id: string | null, data: models.MenuGroupObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // Menu Item API service
  getMenuItemList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuItem`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addMenuItem(data: models.MenuItemObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuItem`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getMenuItem(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuItem/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteMenuItem(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuItem/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editMenuItem(id: string | null, data: models.MenuItemObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuItem/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Menu Action API service
  getMenuActionList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuAction`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getMenuActionListByMenuId(menu_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuActionByMenuId/${encodeURIComponent(menu_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  addMenuAction(data: models.MenuActionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuAction`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getMenuAction(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuAction/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteMenuAction(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuAction/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editMenuAction(id: string | null, data: models.MenuActionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuAction/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Menu Permission API service
  getMenuPermissionList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuPermission`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addMenuPermission(data: models.MenuPermissionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuPermission`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getMenuPermission(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuPermission/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteMenuPermission(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuPermission/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editMenuPermission(id: string | null, data: models.MenuPermissionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuPermission/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // Menu Action Permission API service
  getMenuAcnPermissionList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuActionPermission`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addMenuAcnPermission(data: models.MenuPermissionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/MenuActionPermission`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getMenuAcnPermission(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuActionPermission/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Update Rc_date of account voucher
  editRcDate(data: models.MenuPermissionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/RcDate`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Tenent API Service
  getTenentList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Tenent`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addTenent(data: models.TenentObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Tenent`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getTenent(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Tenent/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteTenent(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Tenent/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editTenent(id: string | null, data: models.TenentObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Tenent/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Company Information API service
  getCompanyInfo() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/CompanyInfo`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getCompanyPaymentSetting() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/CompanyInfo`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //reset sms setting
  resetSmsSetting() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/resetSmsSetting`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  editCompanyInfo(id: string | null, data: models.TenentObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyInfo/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // Dashboard
  getTransCount(
    from_date: any | null,
    to_date: any | null,
    type: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/vouchercount/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getSalesInv(
    from_date: string | null,
    to_date: string | null,
    type: string | null
  ) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/salesinv/${encodeURIComponent(from_date)}/${encodeURIComponent(
            to_date
          )}/${encodeURIComponent(type)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getInvoiceCountTrans(from_date: any | null, to_date: any | null) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/invoiceGraph/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Company Terms and Conditions API service
  getTermsConditionsList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyTermsConditions`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addTermCondition(data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyTermsConditions`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getTermCondition(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyTermsConditions/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteTermCondition(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyTermsConditions/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editTermCondition(id: string | null, data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyTermsConditions/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  sgetGstState(filter: string | null, inactive: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/sgstState/${encodeURIComponent(filter)}/${encodeURIComponent(
            inactive
          )}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //get gst state code
  getGstState() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/tntgstState`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //get gst state code
  getTntGstState() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/tntgstState`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Report management(Report Type) API Service
  getReportTypeList() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/ReportType`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addReportType(data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ReportType`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getReportType(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ReportType/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteReportType(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ReportType/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editReportType(id: string | null, data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ReportType/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Report Management(Report) API Service
  getReportList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Report`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addReport(data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Report`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getReport(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Report/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteReport(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Report/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editReport(id: string | null, data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Report/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Report Management(Report data) API service
  getReportDataList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/ReportData`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addReportData(data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ReportData`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getReportData(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ReportData/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteReportData(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ReportData/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editReportData(id: string | null, data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ReportData/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //get update logs list
  getUpdateLogsList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/UpdateLogs`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //send new password to owner
  mailPassToOwner() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/mailPassToOwner`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Due receipt API service
  getDueReceiptList(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DueReceipt/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Due Payment API service
  getDuePaymentList(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DuePayment/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Convert Sales Order to Delivery Order API service
  convertSOtoDO(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SOtoDO/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Tables QR codes
  getTablesQRcodeList() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/TablesQRcode`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Table Group API service
  getTableGroupList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/TableGroup`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addTableGroup(data: models.CategoryObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/TableGroup`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            // console.log(resp)
            return response.body;
          }
        }
      });
  }

  getTableGroup(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TableGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getTableByTransId(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TableByTransId/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteTableGroup(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TableGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editTableGroup(id: string | null, data: models.CategoryObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TableGroup/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Table API service
  getTableList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Table`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getTableListWithKOT() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/TableWithKOT`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addTable(data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Table`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getTable(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Table/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getTableWithKOT(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TableWithKOT/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  deleteTable(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Table/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editTable(id: string | null, data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Table/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //get so list which is not converted to invoice
  getSONotToInvoice(ledger_id: string | null, table_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SOnotInvoiced/${encodeURIComponent(
            ledger_id
          )}/${encodeURIComponent(table_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSONotApproved() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SOnotApprovedWithItems`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSONotFinished() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SOnotFinishedWithItems`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  approveKot(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ApproveKot/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // markProcessingKot(id: any) {
  //   let headers = this.getHeader();
  //   return this.http.request('get', Location.joinWithSlash(`${this.basePath}`, `/api/ProcessingKot/${encodeURIComponent(id)}`), {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }

  markProcessingKot(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ProcessingKot`),
        {
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response: any) => {
        return response;
      });
  }

  markOrderPickedUp(transaction_id: any) {
    const headers = this.getHeader();
    const requestBody = { transactionId: transaction_id };
    return this.http
      .request(
        "POST",
        Location.joinWithSlash(`${this.basePath}`, "/api/weraOrderPickedUp"),
        {
          observe: "response",
          headers,
          body: requestBody,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  weraFoodReady(transaction_id: any) {
    const headers = this.getHeader();
    const requestBody = { transactionId: transaction_id };
    return this.http
      .request(
        "POST",
        Location.joinWithSlash(`${this.basePath}`, "/api/foodReadyWeraOrder"),
        {
          observe: "response",
          headers,
          body: requestBody,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  markFinishedKot(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/FinishedKot/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //get so list which is converted to invoice
  getSOInvoiceList(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SOInvoiced/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //get multiple sales order items list
  getMultipleSOItems(so_id_list: string | null, ledger_id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MultipleSOItems/${encodeURIComponent(
            so_id_list
          )}/${encodeURIComponent(ledger_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //get do list which is not converted to invoice
  getDONotToInvoice(ledger_id: string | null, table_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DOnotInvoiced/${encodeURIComponent(
            ledger_id
          )}/${encodeURIComponent(table_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //get do list which is converted to invoice
  getDOInvoiceList(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DOInvoiced/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //get multiple delivery order items list
  getMultipleDOItems(so_id_list: string | null, ledger_id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MultipleDOItems/${encodeURIComponent(
            so_id_list
          )}/${encodeURIComponent(ledger_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Templates API Service
  getTemplateListName(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/template/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Quotation API Service
  addQuotation(data: any | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `api/quotation`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getQuotationList() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/quotation`),
        {
          observe: "response",
          headers,
        }
      )
      .map((Response) => {
        switch (Response.status) {
          case 200: {
            return Response.body;
          }
        }
      });
  }

  getquoationDetailsList(from_date: any | null, to_date: any | null) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/quotation/${encodeURIComponent(from_date)}/${encodeURIComponent(
            to_date
          )}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // get Quotation
  getQuotation(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/quotation/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // Edit Quotation
  editQuotation(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/quotation/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // delete Quotation
  deleteQuotation(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/quotation/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }
  editQuotationTerms(id: string | null, data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/companyQuotationTerms/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  quotationToSO(data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/quotation_to_so`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  quotatiobTOInvoice(data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/quotation_to_invoice`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  PoToPurchase(data: any | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/po_to_purchase`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Get Enable Resturant Pos
  getEnableResturantPos() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ChechEnableResturantPos`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getpendingDelivery() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/pending_delivery`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getPendingOrders() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/pending_orders`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //emailSendForInvoce
  emailSendForInvoce(emailInfo: any | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/emailInfoSendforTemplate`
        ),
        {
          observe: "response",
          headers,
          body: emailInfo,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Company email settings
  editCompanyEmailSettingsInfo(id: string | null, emailInfo: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyEmailSettings/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: emailInfo,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Company email settings
  editCompanyMapSettingsInfo(id: string | null, mapInfo: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyMapSettingsInfo/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: mapInfo,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Reorder API service
  getReorderList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/reorder`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getCheckedItem(id) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getCheckedItem/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Stock Adjustment API Service
  getStockAdjustmentList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/StockAdjustment`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addStockAdjustment(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/StockAdjustment`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getStockAdjustment(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/StockAdjustment/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteStockAdjustment(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/StockAdjustment/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  editStockAdjustment(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/StockAdjustment/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Customer Items Report API Service
  CustomerItemsReport(
    from_date: any,
    to_date: any,
    ledger_id: any,
    item_id: any
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CustomerItemsReport/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(
            ledger_id
          )}/${encodeURIComponent(item_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Stock Transfer API service
  addStockTransfer(data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/StockTransfer`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getStockTransfer(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/StockTransfer/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editStockTransfer(id: string | null, data: models.TransactionObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/StockTransfer/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteStockTransfer(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/StockTransfer/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
        }
      });
  }

  //LOCATION API service
  getLocationList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Location`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addLocation(data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Location`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  deleteLocation(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Location/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getLocation(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Location/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getLocationByItem(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LocationByItem/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  updateLocation(id: string | null, data: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Location/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Transaction MEMO API service
  addMemo(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Memo`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getTransactionsMemo() {
    let headers = this.getHeader();
    return this.http
      .request("get", Location.joinWithSlash(`${this.basePath}`, `/api/Memo`), {
        observe: "response",
        headers,
      })
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  openMemo(transaction_id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Memo/${encodeURIComponent(transaction_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getMemoItems(transaction_id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getMemoItems/${encodeURIComponent(transaction_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  deleteMemos(transaction_id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Memo/${encodeURIComponent(transaction_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Licence API service
  getLicenceDetails() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Licence`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  uploadLicence(data) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Licence`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // gst1 reports

  getnSale(from_date: any | null, to_date: any | null, type_id: string | null) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getnSale/${encodeURIComponent(from_date)}/${encodeURIComponent(
            to_date
          )}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  gstr1SaleRetuen(
    from_date: any | null,
    to_date: any | null,
    type_id: string | null
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/gstr1SaleRetuen/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addInvoiceTrans(data: models.TransactionObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/addTransFields`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getInvoiceTrans(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getTransFields/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateInvoiceTrans(data: models.TransactionObj | null, id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/updateTransField/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getEnabledInvoiceField(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getEnabledFields/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // Account Map Category API service
  getAppCategorySetting() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/AppCategorysetting`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getDefaultCategory() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getDefaultCategory`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editAppCategory(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "put",
        Location.joinWithSlash(`${this.basePath}`, `/api/updateAppCategory`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Convert GRN to Purchase API service
  convertGRNtoPurchase(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/GRNtoPurchase/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Convert GRN to Purchase API service
  convertDOtoInvoice(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DOtoInvoice/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //get multiple GRN items list
  getMultipleGRNItems(grn_id_list: string | null, ledger_id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MultipleGRNItems/${encodeURIComponent(
            grn_id_list
          )}/${encodeURIComponent(ledger_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getGRNnotToPurchase(ledger_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/GRNnotPurchased/${encodeURIComponent(ledger_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //============================item fields value get=====================================
  getItemFieldsValue(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getAdditionItemFields/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getEnabledItemAdditionField(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getEnabledItemAddtionalFields/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateItemFieldsValue(data: models.TransactionObj | null, id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/updateItemFieldsValue/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getLocationByCb(loc_id: string | null, selected_item_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LocationByCb/${encodeURIComponent(loc_id)}/${encodeURIComponent(
            selected_item_id
          )}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getAllVoucherListByDay(from_date: any | null, to_date: any | null) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `api/getReportByDate/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //------------------GET DUE LIST--------------------------------
  // getDueList(from_date: any | null, to_date: any | null, onlyDue: any) {
  //   let headers = this.getHeader();
  //   return this.http.request('get', Location.joinWithSlash(`${this.basePath}`, `/api/DueList/${encodeURIComponent(from_date)}/${encodeURIComponent(to_date)}/${encodeURIComponent(onlyDue)}`), {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }

  getDueList(
    from_date: any | null,
    to_date: any | null,
    onlyDue: any,
    user_id: any,
    onlync: any
  ) {
    // from_date.setHours(0, 0, 0, 1);
    // to_date.setHours(23, 59, 59, 59);
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/DueList/${encodeURIComponent(from_date)}/${encodeURIComponent(
            to_date
          )}/${encodeURIComponent(onlyDue)}/${encodeURIComponent(
            user_id
          )}/${encodeURIComponent(onlync)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getPurchaseSummary(from_date: any | null, to_date: any | null, onlyDue: any) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/PurchaseSummary/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}/${encodeURIComponent(onlyDue)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getItemSalesReport(from_date: any | null, to_date: any | null) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ItemSalesReport/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getCustomerItemRate(ledger_id: any | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CustomerItemRate/${encodeURIComponent(ledger_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Multicompany API Service
  getCompanyList(tenent_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MultiCompanyList/${encodeURIComponent(tenent_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addCompany(data: any, tenent_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MultiCompanyList/${encodeURIComponent(tenent_id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getCompany(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MultiCompany/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteCompany(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MultiCompany/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editCompany(id: string | null, data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MultiCompany/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getFyList(company_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/companyFY/${encodeURIComponent(company_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getFy(fy_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/FY/${encodeURIComponent(fy_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  closeFy(company_id: string | null, fy_id: string | null, data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CloseFY/${encodeURIComponent(company_id)}/${encodeURIComponent(
            fy_id
          )}`
        ),
        {
          observe: "response",
          body: JSON.stringify(data),
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //Subdomain Details Route
  getSubdomainDetails() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/SubdomainDetails`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  generate_token(code: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/generate_token`),
        {
          observe: "response",
          headers,
          body: { code: code },
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getLocationByObList(item_id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LocationByOb/${encodeURIComponent(item_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getLocationSeviceGet() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/LocationServiceFlag`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getLedgerClosingBalance(ledger_id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/LedgerClosingBalance/${encodeURIComponent(ledger_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getActionPermissionByKey(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/MenuActionPermissionByKey`
        ),
        {
          observe: "response",
          body: JSON.stringify(data),
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  updateSessionAllowLogin(isAllow: any, table_id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SessionAllowLogin/${encodeURIComponent(
            table_id
          )}/${encodeURIComponent(isAllow)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateActiveTable_inactive(active: any, table_id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/clearTable/${encodeURIComponent(table_id)}/${encodeURIComponent(
            active
          )}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getWebActiveTable(table_id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getActiveTable/${encodeURIComponent(table_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  SessionByTableId(table_id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SessionByTableId/${encodeURIComponent(table_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getInvoiceByBillNo(id: any | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/InvoiceByBillNo/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // --------tableTransfer----------------
  transferTable(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/TransferTable/${encodeURIComponent(
            data.table_id_1
          )}/${encodeURIComponent(data.table_id_2)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //------------activity log --------------
  getActivityLogList(from_date: any | null, to_date: any | null) {
    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/ActivityLog/${encodeURIComponent(
            from_date
          )}/${encodeURIComponent(to_date)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // --------------customerDetailsReport----------
  customerDetailsReport() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/customersDetails`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  private theme = new BehaviorSubject<boolean>(false);

  settheme(n: any) {
    this.theme.next(n);
  }
  gettheme() {
    return this.theme.asObservable();
  }

  // consumable

  getConsumableList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Consumeable`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getConsumableItemList(row: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ConsumeableItem`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(row),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addUnitConvert(data: FormData | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Converter`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getUnitConvert() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Converter`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  EditUnitConvert(id: string | null, data: models.UnitObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/AddConverter/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteConverter(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Converter/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getUnitConvertdata(id) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/AddConverter/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addConsumableItem(data) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Items`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  private resultDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private resultData$: Observable<any> = this.resultDataSubject.asObservable();

  setResultData(data: any) {
    this.resultDataSubject.next(data);
  }

  getResultData(): Observable<any> {
    setTimeout(() => {}, 0);
    return this.resultData$;
  }

  getRestaurantCategoryList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/GetRestaurantCategory`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getRestaurantMenuItemList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/GetRestaurantMenuItems`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updatewaterwaiter(table_id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/doneWaterOrWaiter/${encodeURIComponent(table_id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // themes
  importCoverImages(data) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ImportCoverImage`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  importImagesLogo(data) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ImportLogoImages`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getCoverList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getCoverImage`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getLogoList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getLogoImage`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  deletethemecover(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getCoverImage/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  deleteLogo(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getLogoImage/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  //======================Get Enable Website===========================
  getWebsiteEnableStatus() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getWebsiteEnableStatus`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //======================Get Enable Wera===========================
  getWeraEnableStatus() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getWeraEnableStatus`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  // ------------------website---------------------
  getWebsiteInvoice(orderMode: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getWebsiteInvoice/${encodeURIComponent(orderMode)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  addWebsiteImage(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/addWebsiteImage`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getWebsiteImage() {
    let headers = this.getHeader();
    console.log("getWebsiteImageapi---------");
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getWebsiteImage`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // =====================consumable ===============
  importconsumeItems(data) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/ImportConsumeItems`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // =====================customisables import  ===============
  importcustomisables(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/importcustomisables`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Disposal
  getDisposalList() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/DisposalCategorylist`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getDisposalItemList() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/DisposalItemlist`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // -----------------------------getTenentCompanyInfo------------------------------------
  getTenentCompanyInfo(companyName: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getTenentCompanyInfo/${encodeURIComponent(companyName)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  ncValidation() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/sendmail`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  verifyemailotp(data) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/verifyotp`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Company sms settings-----------------------------------
  editCompanySmsSettingsInfo(id: string | null, smsInfo: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanySMSSettingsInfo/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: smsInfo,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //payment Razpaysettings-----------------------------------
  editRazerpaySettingsInfo(id: string | null, payInfo: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanypaySettingsInfo/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: payInfo,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //twilio sms balance -------------
  // curl -G https://api.twilio.com/2010-04-01/Accounts/AC3a9483e1d9d2edd8f55f1a958a2af30c/Balance.json \ -u "AC3a9483e1d9d2edd8f55f1a958a2af30c:97a5a7bd77112ba5bc86c5d83c7c79d5"

  getTwilioBalance(smsCredentials): Observable<any> {
    const twilioAccountSid = smsCredentials.twilio_sid;
    const authToken = smsCredentials.twilio_token;
    // console.log("twilioAccountSid",twilioAccountSid,"and",authToken)
    const url = `https://api.twilio.com/2010-04-01/Accounts/${twilioAccountSid}/Balance.json`;

    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(`${twilioAccountSid}:${authToken}`)}`,
    });

    return this.http.get(url, { headers });
  }

  // =====================smsLowBalanceEmail ===============
  smsLowBalanceEmail(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/smsLowBalanceEmail`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Sub-Category
  getSubCategoryList(id: any | null) {
    console.log("getSubCategoryListidddddd", id);
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SubCategory/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  deleteSubCategory(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SubCategory/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSubCategory(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SubCategoryBy/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  editSubCategory(id: string | null, data: models.RoleObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SubCategory/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  addSubCategory(data: models.RoleObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/SubCategory`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  addSubCategoryImage(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/UploadSubCategoryImage`
        ),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSubCategoryImage(id: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SubCategoryImage/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          responseType: "blob",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSubCategorylist() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/subCategorylist`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSubCategorylistofList() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/SubCategorylistofList`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // getTableInfo(table_id: any,getItems:any,getCategory:any,getSubCategory:any,getDisposalitems:any,getDisposalCategory:any) {
  //   let headers = this.getHeader();
  //   return this.http.request('get', Location.joinWithSlash(`${this.basePath}`, `/api/getTableInfo/${encodeURIComponent(table_id)}/${encodeURIComponent(getItems)}/${encodeURIComponent(getCategory)}/${encodeURIComponent(getSubCategory)}/${encodeURIComponent(getDisposalitems)}/${encodeURIComponent(getDisposalCategory)}`), {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }
  getTableInfo(table_id: any, data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getTableInfo/${encodeURIComponent(table_id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // -------------------get Inetial seting ------------
  getInitialSetting() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/InitialSetting`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // --------------------- Table reservation ------
  getUnApprovedReservedTables() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getUnApprovedReservedTables`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getUnApprovedReservedTableswithDate(date: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getUnApprovedReservedTableswithDate/${encodeURIComponent(date)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getTablesWithGroup() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getTablesWithGroup`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  submitReservationTable(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/submitReservationTable`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateReservationTable(id: string | null, data: models.AccountMapObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/updateReservationTable/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteReservedTablesDetails(code: string | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/deleteReservedTablesDetails/${encodeURIComponent(code)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // wera implementations -----------------------------
  approveWeraKot(id: string | null, data: models.AccountMapObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/approveWeraOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  rejectWeraKot(id: string | null, data: models.AccountMapObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/rejectWeraOrder/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  isDeliveryServiceEnable() {
    let headers = this.getHeader();
    console.log("getWeraDeliveryAgentDetails headers---", headers);
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/isDeliveryServiceEnable`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // wera merchat integration ---------------------------------------------------------------

  // weraMerchantRegistration(data: models.AccountMapObj | null) {
  //   let headers = this.getHeader();
  //   return this.http.post(`${this.microServiceRegistrationUrl}/merchant/merchantRegistration`, data, {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }

  weraMerchantRegistration(data: models.AccountMapObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/merchantRegistration`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateMerchantAdditionalDetails(data: models.AccountMapObj | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/UpdateMerchantSettings`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  postWerraStoreStatus(data: any | null) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/putStoreONOFF`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  weraLiveStoreOnOffStatus() {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/getStoreStatus`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  weraSettingData() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/getWeraSettingData`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //-------------------wera menu integration  uploadMenuToWera

  uploadWeraMenu() {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/uploadMenuToWera`),
        {
          observe: "response",
          headers,
          body: "",
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  weraItemOnOFF(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/weraItemOnOff`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  weraAddOnOnOFF(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/weraAddOnsOnOff`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  weraSizeOnOff(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/weraSizeOnOff`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  downloadSwiggyMenu() {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/downloadSwiggyMenu`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  checkFullWeraUploadedMenu() {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/trackPullMenuStatus`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getWeraDeliveryAgentDetails(transaction_id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/getWeraDeliveryAgentStatus/${encodeURIComponent(
            transaction_id
          )}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Kitchen API Service
  getKitchenList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/kitchen`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addkitchen(data: models.kitchenObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/kitchen`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getkitchen(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/kitchen/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deletekitchen(id: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/kitchen/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editKitchen(id: string | null, data: models.kitchenObj | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/kitchen/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addPriceList(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/pricelist`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Get PriceList
  getAllNewPricList() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/pricelist`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getPricelistData(id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateNewEditPriceList(id: string, data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // deleteNewPriceList(id: string) {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/getAllPriceList/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",

  //         headers,
  //       }
  //     )
  //     .map((response) => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     });
  // }

  deleteNewPriceList(id: string) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  additemPriceList(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/pricelist/item`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateItemPriceList(id: any, data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/item/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getItembyPriceListId(id: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/item/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  DeleteItemPriceListById(id: string, item_id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/item/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(item_id),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getItemDetails() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/restaurant-menuitem`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  priceListCSVImport(id: any, data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelistCSVImport/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addItemVariant(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/item/variant`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getItemVariant(id: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/item/variant/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  importItemsVariant(id: any, data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/item-variant-csv/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getVariantData() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/items-variant-data`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getAllVariant(pricelist: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/variant/${encodeURIComponent(pricelist)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updatepricelistvariant(id: any, data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/variant/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deletevariantId(id: string) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/pricelist/variant/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  saveNotificationsSettings(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Summary/Notification`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  setSummaryNotificationStatus(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/Summary/Notification/Status`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getNotificationSettings() {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/Summary/Notification`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  getSummaryNow(data: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/Summary/Send`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  fetchContacts(page: number, limit: number): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/crm/contacts?page=${page}&limit=${limit}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  fetchContactByPhone(phone_no: string): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/crm/contacts/phone/${phone_no}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getOrderHistoryByPhoneNumber(phone_no: string): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/crm/contacts/order-history/${phone_no}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  deleteContact(customerID: number): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/contacts`),
        {
          observe: "response",
          headers,
          body: JSON.stringify({ CustomerID: customerID }),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  addContact(contact: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/contacts`),
        {
          observe: "response",
          headers,
          body: contact,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  editContact(contact: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .request(
        "put",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/contacts`),
        {
          observe: "response",
          headers,
          body: contact,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  importContact(data) {
    let headers = this.getHeader();

    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/contacts/import`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  importContacts(formData: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Authorization", "bearer " + this.cookieValue);
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/contacts/import`),
        {
          observe: "response",
          headers,
          body: formData,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  searchNumber(contactNo: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/searchnumber`),
        {
          observe: "response",
          headers,
          body: JSON.stringify({ contactNo: contactNo }),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  //Transaction TAKE AWAY MEMO API service
  addTakeAwayMemo(data: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/TakeAway/Memo`),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getTakeAwayMemo() {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/TakeAway/Memo`),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  // deleteMemos(transaction_id: any) {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Memo/${encodeURIComponent(transaction_id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     )
  //     .map((response) => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     });
  // }

  openTakeAwayMemo(contact_no: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/takeaway/memo/${encodeURIComponent(contact_no)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  openTakeAwayCustomer(contact_no: any) {
    let headers = this.getHeader();
    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/customer/${encodeURIComponent(contact_no)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  searchDetails(serchterm: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/details`),
        {
          observe: "response",
          headers,
          body: JSON.stringify({ serchterm: serchterm }),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  getbirthEvent(type: any): Observable<any> {
    const headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/birthday/${type}`),
        {
          observe: "response",
          headers,
        }
      )
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return response.body;
          }
        })
      );
  }

  getAnniEvent(type: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "get",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/crm/annivarsay/${type}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  sendsmsto(phone_no: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/sms`),
        {
          observe: "response",
          headers,
          body: JSON.stringify({ phone_no: phone_no }),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  sendEmailTo(emaildId: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .request(
        "post",
        Location.joinWithSlash(`${this.basePath}`, `/api/crm/email`),
        {
          observe: "response",
          headers,
          body: JSON.stringify({ emaildId: emaildId }),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  editPaymantSetting(id: string | null, data: string | null) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/CompanyPaymentSettings/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: data,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  updateMuteBtn(display_trans_no: any, is_muted: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/websiteMute/${encodeURIComponent(display_trans_no)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(is_muted),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  NotificationSeen(display_trans_no: any, is_muted: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/notificationSeen/${encodeURIComponent(display_trans_no)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(is_muted),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }
  kotApproved(id: any, requestBodyy: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/websiteKotApproved/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
          body: JSON.stringify(requestBodyy),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  OrderRejected(requestBodyy: any) {
    let headers = this.getHeader();

    return this.http
      .request(
        "put",
        Location.joinWithSlash(`${this.basePath}`, `/api/websiteOrderRejected`),
        {
          observe: "response",
          headers,
          body: JSON.stringify(requestBodyy),
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  RejectKot(id: string) {
    let headers = this.getHeader();
    return this.http
      .request(
        "delete",
        Location.joinWithSlash(
          `${this.basePath}`,
          `/api/rejectionKot/${encodeURIComponent(id)}`
        ),
        {
          observe: "response",
          headers,
        }
      )
      .map((response) => {
        switch (response.status) {
          case 200: {
            return response.body;
          }
        }
      });
  }

  private dashboardStatus = new BehaviorSubject<any>(null);
  sharedData = this.dashboardStatus.asObservable();

  setDashboardStatus(data: any) {
    this.dashboardStatus.next(data);
  }

  getDashboardStatus() {
    return this.sharedData;
  }
}
