import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { CookieService } from 'ngx-cookie-service';
import { ElectronService } from 'ngx-electron';
import { CacheServiceService } from '../cache-service.service';

@Component({
  selector: 'app-e-logout',
  templateUrl: './e-logout.component.html',
  styleUrls: ['./e-logout.component.css']
})
export class ElectronLogoutComponent implements OnInit {

  constructor(private router: Router,
    private apiService: DataService,
    private _electronService: ElectronService,
    private cookieService: CookieService,
    private cacheService: CacheServiceService) { }

  ngOnInit() {
    this.load();
  }
  load() {
    console.log("ElectronLogoutComponent------------------")
    this.apiService.logout()
      .subscribe((result: any) => {
        if (this._electronService.isElectronApp) {
          // this._electronService.ipcRenderer.on('pong', (event, arg) => {
          //     console.log(
          //         'RECEIVED RESPONSE FROM ELECTRON TO ANGULAR APP',
          //         event,
          //         arg
          //     );
          // });
          this._electronService.ipcRenderer.send(
            'logout'
          );
        } else {
          this.cookieService.deleteAll();
        }
        console.log("ElectronLogoutComponent------------------")
        this.cacheService.clear()
        
        localStorage.clear();
        sessionStorage.clear();

        localStorage.removeItem('company_name');
        localStorage.removeItem('userName');
        localStorage.removeItem('emailId');
        localStorage.removeItem('userRole');
        localStorage.removeItem('role_id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('ver');
        localStorage.removeItem('licence');
        localStorage.removeItem('tenent_id');
        localStorage.removeItem('company_id');
        localStorage.removeItem('fy_id');
        localStorage.removeItem('isrst');
        localStorage.removeItem('userFullName');
        localStorage.removeItem('currency');

        this.router.navigate(['login']);
      }, (result: any) => {

      });
  }
}
