<h3 mat-dialog-title class="color-h3">Add Featured Items <span><button mat-icon-button class="close-button"
      [mat-dialog-close]="true">
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button></span></h3>
<div class="table-details">
  <mat-dialog-content class="mat-typography">
    <div style="height: 480px;">
      <div class="row">
        <div class="col">Available Items</div>
        <div class="col">Selected Items</div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="contract-full-width">
            <input #searchText matInput placeholder="Search Items" (keyup)="search(searchText.value)" name="search">
          </mat-form-field>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-5 border-box" style="overflow-y: scroll;">
          <div class="row" #clickedDiv style="align-items: center; margin-bottom: 0.3rem;"
            *ngFor="let row of availableItems; let i = index" (click)="onLeftSideItemClicked(row, clickedDiv)">
            <img *ngIf="row.imageurl" class="item-image" [src]="row.imageurl" alt="image">
            <img *ngIf="!row.imageurl" class="item-image" src="../../assets/images/no_image.png" alt="no image">
            <span style="padding-left: 0.3rem;">{{ row.item_name }}</span>
          </div>
        </div>
        <div class="col-1 arrow-box">
          <button mat-icon-button color="primary"
            (click)="shiftrow('right')"><mat-icon>keyboard_arrow_right</mat-icon></button>
          <button mat-icon-button color="primary"
            (click)="shiftrow('left')"><mat-icon>keyboard_arrow_left</mat-icon></button>
        </div>
        <div class="col-5 border-box" style="overflow-y: scroll;">
          <div class="row" #clickedDiv style="align-items: center; margin-bottom: 0.3rem;"
            *ngFor="let row of selectedItems; let i = index" (click)="onRightSideItemClicked(row, clickedDiv)">
            <img *ngIf="row.imageurl" class="item-image" [src]="row.imageurl" alt="image">
            <img *ngIf="!row.imageurl" class="item-image" src="../../assets/images/no_image.png" alt="no image">
            <span style="padding-left: 0.3rem;">{{ row.item_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn-primary mat-raised-button" (click)="save()">Ok</button>
  </mat-dialog-actions>
</div>