<h2 *ngIf="!data" mat-dialog-title class="color-h3">Add New Kitchen</h2>
<h2 *ngIf="data" mat-dialog-title class="color-h3">Edit Kitchen</h2>
<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <mat-form-field class="contract-full-width">
        <input matInput placeholder="Kitchen Code:" [(ngModel)]="model.kitchen_code" name="kitchen_code" required>
        <mat-error>required</mat-error>
      </mat-form-field>
      <mat-form-field class="contract-full-width">
        <input matInput placeholder="Kitchen Name:" [(ngModel)]="model.kitchen_name" name="kitchen_name" required>
        <mat-error>required</mat-error>
      </mat-form-field>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">Close</button>
    <button type="submit" class="btn-primary mat-raised-button" (click)="onSubmit()" [disabled]="f.invalid">Save</button>
  </mat-dialog-actions>
</div>