import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataService } from '../data.service';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css']
})
export class TransactionListComponent implements OnInit {
  constructor(private apiService: DataService,
    private dialogRef: MatDialogRef<TransactionListComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  title: any = "";
  dataObj: any = {};
  DataLists:any;
  dataArr:any = [];
  isAdd:boolean = true;
  type:any;
  ngOnInit() {
    this.load()
  }
  load() {
    // console.log(this.data);
    if (this.data.type == "so-do") {
      this.title = "Sales Orders"
    }
    if (this.data.type == "add-so-invoice") {
      this.title = "Sales Orders"
      this.type = 'so';
      this.isAdd = true;
      this.apiService.getSONotToInvoice(this.data.ledger_id,this.data.table_id)
      .subscribe((result: any) => {
        this.DataLists = result;
        this.addDataToArr(result);
      }, (result: any) => {
      });
    }
    if (this.data.type == "edit-so-invoice") {
      this.title = "Sales Orders"
      this.type = 'so';
      this.isAdd = false;
      this.apiService.getSOInvoiceList(this.data.invoice_id)
      .subscribe((result: any) => {
        this.DataLists = result;
      }, (result: any) => {
      });
    }
    if (this.data.type == "add-do-invoice") {
      this.title = "Delivery Orders"
      this.type = 'do';
      this.isAdd = true;
      this.apiService.getDONotToInvoice(this.data.ledger_id, this.data.table_id)
      .subscribe((result: any) => {
        this.DataLists = result;
        this.addDataToArr(result);
      }, (result: any) => {
      });
    }
    if (this.data.type == "edit-do-invoice") {
      this.title = "Delivery Orders"
      this.type = 'do';
      this.isAdd = false;
      this.apiService.getDOInvoiceList(this.data.invoice_id)
      .subscribe((result: any) => {
        this.DataLists = result;
      }, (result: any) => {
      });
    }
    if (this.data.type == "add-grn-purchase") {
      this.title = "GRN List"
      this.type = 'grn';
      this.isAdd = true;
      this.apiService.getGRNnotToPurchase(this.data.ledger_id)
      .subscribe((result: any) => {
        this.DataLists = result;
        this.addDataToArr(result);
      }, (result: any) => {
      });
    }
    
  }
  addDataToArr(lists){
    lists.forEach(data => {
      this.dataArr.push(data.transaction_id);
    });
  }
  closeDialog(){
    this.dialogRef.close(this.dataObj);
  }
  onCheckboxClick(e, id){
    if(e.target.checked){
      this.dataArr.push(id);
    }else{
      var i = 0;
      if(this.type == 'so')
      i = this.dataArr.findIndex(so_id => so_id === id);
      if(this.type == 'do')
      i = this.dataArr.findIndex(do_id => do_id === id);
      this.dataArr.splice(i, 1);
    }
  }
  onAddClick(){
    this.dataObj = {
      status: "Added",
      data:this.dataArr
    }
    this.dialogRef.close(this.dataObj);
  }
}
