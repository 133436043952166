import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { NotifierService } from "angular-notifier";

import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from "src/app/data.service";
import { ExportService } from "../../../../_export-service/daybook-export.service";

@Component({
  selector: "app-imort-price-list-item",
  templateUrl: "./imort-price-list-item.component.html",
  styleUrls: ["./imort-price-list-item.component.css"],
})
export class ImortPriceListItemComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "itemid",
    "itemname",
    "category",
    "taxslab",
    "defaultprice",
    "srate",
    "msg",
  ];

  displayedColumnsVar: string[] = [
    "selectVar",
    "id",
    "item_id",
    "item_name",
    "parent_id",
    "variant_name",
    "default_rate",
    "s_rate",
    "msg",
  ];
  pricelist_id: any;

  itemVariant: any[] = [];

  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  constructor(
    private dialogRef: MatDialogRef<ImortPriceListItemComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    private exportService: ExportService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pricelist_id = data.pricelist_id;
    console.log("this.priclist_id", this.pricelist_id);
  }
  file: any;
  imgList: any;
  imgfile: any;
  item_data: any = [];
  itemsArr: any;
  itemsVarArr: any;
  isImported: boolean = false;
  imageList: any = [];
  selectedTab: any = 0;
  uploaded: boolean = false;
  ngOnInit() {
    this.load();
  }

  load() {
    console.log("checking");
    this.apiService.getVariantData().subscribe(
      (response: any[]) => {
        if (response) {
          this.itemVariant = response;
        } else {
          this.notifier.notify("error", "Item Data Not Found");
        }
      },
      (response: any) => {}
    );
  }
  imgInputChange(fileInputEvent: any) {
    this.uploaded = false;
    this.imgfile = null;
    // console.log(this.uploaded )
    this.imgList = fileInputEvent.target.files;
    for (var i = 0; i < this.imgList.length; i++) {
      this.imgfile = this.imgfile
        ? this.imgfile + this.imgList[i].name + ","
        : this.imgList[i].name + ", ";
      this.readImgFile(this.imgList[i]).then((url) => {
        this.imageList.push(url);
      });
    }
  }
  readImgFile(file: File) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  }

  csvInputChange(evt) {
    var files = evt.target.files; // FileList object
    this.file = files[0];
    var reader = new FileReader();
    reader.readAsText(this.file);
    reader.onload = () => {
      this.extractData(reader.result);
    };
  }

  extractData(data) {
    // Input csv data to the function
    let csvData = data;

    let allTextLines = csvData.split(/\r\n|\n/);

    let headers = allTextLines[0].split(",");
    let lines = [];

    let obj: any = {};
    for (let i = 0; i < allTextLines.length; i++) {
      // split content based on comma
      let data = allTextLines[i].split(",");
      if (data.length == headers.length) {
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }
        lines.push(tarr);
      }
    }
    for (var m = 1; m < lines.length; m++) {
      obj = {};
      for (var n = 0; n < lines[m].length; n++) {
        obj[lines[0][n]] = lines[m][n];
      }
      this.item_data.push(obj);
    }

    this.itemsArr = new MatTableDataSource(this.item_data);

    this.masterToggle();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.itemsArr.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.itemsArr.data.forEach((row) => {
          this.selection.select(row);
        });
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  onImportDataClick() {
    if (this.selection.selected.length) {
      this.spinner.show();
      this.apiService
        .priceListCSVImport(this.pricelist_id, this.selection.selected)
        .subscribe(
          (result: any) => {
            this.spinner.hide();
            if (result.success) {
              this.isImported = true;
              this.displayedColumns = [
                "select",
                "itemid",
                "itemname",
                "category",
                "taxslab",
                "defaultprice",
                "srate",
                "msg",
              ];
              this.itemsArr.data = result.items;

              this.masterToggle();
              this.notifier.notify(
                "success",
                "Selected Items imported successfully"
              );
            } else {
              this.notifier.notify("error", result.message);
            }
          },
          (result: any) => {
            this.spinner.hide();
            this.notifier.notify("error", "unable to import Items");
          }
        );
    } else {
      this.notifier.notify("error", "Select items to import");
    }
  }

  onImportVariantClick() {
    // console.log("thisccccc", this.pricelist_id);

    if (this.selection.selected.length) {
      console.log("Items selected for import:", this.selection.selected);
      this.spinner.show();
      this.apiService
        .importItemsVariant(this.pricelist_id, this.selection.selected)
        .subscribe(
          (result: any) => {
            console.log("API response received:", result);
            this.spinner.hide();
            if (result.success) {
              this.isImported = true;
              this.displayedColumnsVar = [
                "selectVar",
                "id",
                "item_id",
                "item_name",
                "parent_id",
                "variant_name",
                "default_rate",
                "s_rate",
                "msg",
              ];
              this.itemsArr.data = result.items;
              console.log("Items imported successfully:", this.itemsArr.data);
              this.masterToggle();
              this.notifier.notify(
                "success",
                "Selected Items imported successfully"
              );
            } else {
              console.error("Import failed with message:", result.message);
              this.notifier.notify("error", result.message);
            }
          },
          (error: any) => {
            console.error("API call failed:", error);
            this.spinner.hide();
            this.notifier.notify("error", "Unable to import Items");
          }
        );
    } else {
      console.error("No items selected for import");
      this.notifier.notify("error", "Select items to import");
    }
  }

  close() {
    if (this.isImported) {
      this.dialogRef.close("Imported");
    } else {
      this.dialogRef.close();
    }
  }

  onTabChange(e) {
    this.selectedTab = e;
  }

  downloadTemplate() {
    console.log(" this.itemVariant---", this.itemVariant)
    if (this.itemVariant.length > 0) {
      var dataArr = [];
      this.itemVariant.forEach((d) => {
        var rowValues = [];
        rowValues[0] = d.id;
        rowValues[1] = d.item_id;
        rowValues[2] = d.item_name;
        rowValues[3] = d.parent_id;
        rowValues[4] = d.title;
        rowValues[5] = d.price;
        rowValues[6] = null;

        dataArr.push(rowValues);
      });

      const header = [
        "id",
        "item_id",
        "item_name",
        "parent_id",
        "variant_name",
        "default_rate",
        "s_rate",
      ];

      this.exportService.generateCSVar("Itemvariant", header, dataArr);
    } else {
      this.notifier.notify("error", "Menu Not Found");
    }
  }
}
