<h2 mat-dialog-title class="color-h3">Payment Setting</h2>
<div id="payTab">
  <mat-form-field>
    <mat-label>Select Provider</mat-label>
    <mat-select
      name="paymentSetting"
      [(value)]="selectedPaymentSetting"
      (selectionChange)="onOrderTypeChange($event)"
    >
      <mat-option
        *ngFor="let option of paymentSetting"
        [value]="option.viewValue"
      >
        {{ option.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="table-details" *ngIf="razorPay">
  <mat-dialog-content>
    <form name="PayForm" #PayForm="ngForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="KEYID:"
              [(ngModel)]="modelPayment.keyid"
              name="keyid"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="SECRET KEY:"
              [(ngModel)]="modelPayment.secret_key"
              name="secret_key"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">
      Close
    </button>
    <button
      type="submit"
      class="btn-primary mat-raised-button"
      (click)="onSubmit(PayForm)"
      [disabled]="PayForm.invalid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>

<!-- <div class="table-details" *ngIf="local">
  <mat-dialog-content>
    <form name="localForm" #localForm="ngForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="API KEY:"
              [(ngModel)]="modelLocal.local_api"
              name="local_api"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="SENDER ID:"
              [(ngModel)]="modelLocal.local_apicontent"
              name="local_apicontent"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="BALANCE API:"
              [(ngModel)]="modelLocal.local_balancespi"
              name="local_balancespi"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <p>BALANCE ALERT COUNT:</p>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">
      Close
    </button>
    <button
      type="submit"
      class="btn-primary mat-raised-button"
      (click)="onSubmit(localForm)"
      [disabled]="localForm.invalid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div> -->
