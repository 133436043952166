<!-- <div class="date-picker">
  <div class="header">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <input matInput placeholder="Select date" value="{{currentDate}}">
    <button mat-icon-button (click)="goForward()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>

  </div>
</div> -->
<div class="dialog-title" mat-dialog-title>
  <h3 class="color-h3">
    Table Reservation Dashboard
    <span
      ><mat-icon class="close-icon" style="float: right" (click)="onCancel()"
        >close</mat-icon
      ></span
    >
  </h3>
</div>
<div class="table-details">
  <mat-dialog-content>
    <!-- <div class="row search-items-food"> -->
    <!-- <div class="col-md-5">
        <mat-form-field class="contract-full-width">
          <input type="text" placeholder="Search Item" aria-label="Number" matInput [formControl]="itemControl"
            [matAutocomplete]="auto" (keyup)="search($event)" (focus)="onFocus($event)"
            (keydown.enter)="onEnterKey($event)" #sitm />
          <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
            (optionSelected)="itemSelection($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{ option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div> -->
    <div>
      <div style="margin-bottom: 5px">
        <!-- <button class="btn btn-primary" (click)="selfReservation()"> Reserve Table</button> -->
        <button mat-icon-button class="mat-primary" (click)="selfReservation()">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      <div class="row" style="margin-bottom: 5px">
        <div class="col-10">
          <button mat-icon-button (click)="goToPreviousDay()">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="selectedDate"
            (dateChange)="onDateChange()"
            placeholder="Select a date"
            style="text-align: center; max-width: 200px; margin: 6px"
          />

          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>

          <button mat-icon-button (click)="goToNextDay()">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <div style="text-align: end" class="col-2">
          <a
            class="right-menu-icon mr-4"
            mat-icon-button
            *ngIf="requestCount > 0"
          >
            <i class="badge badge-danger not">{{ requestCount }}</i>
          </a>
        </div>
      </div>

      <div class="catogaries" style="overflow: auto">
        <mat-button-toggle-group
          name="favoriteColor"
          aria-label="Favorite Color"
        >
          <mat-button-toggle
            class="items-catogaries"
            style="margin: 2px; border-radius: 10px"
            *ngFor="let i of UnApprovedReservedTablesArr"
            value="red"
            (click)="reservationForm(i)"
            [ngClass]="{
              'alert-success': i.approved === 'Confirmed',
              'alert-warning': i.approved === 'InProgress',
              'alert-danger': !(
                i.approved === 'Confirmed' || i.approved === 'InProgress'
              )
            }"
          >
            <div
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              <h5>{{ i.code }}</h5>
              <div>
                <h6 class="rCard">Name : {{ i.name }}</h6>
                <h6 class="rCard">Person : {{ i.person }}</h6>
                <h6 class="rCard">Date : {{ i.date | date }}</h6>
                <h6 class="rCard">Time : {{ i.time }}</h6>
              </div>
            </div>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <!-- </div> -->

    <h4 style="margin-top: 10px">Reservation Summary</h4>
    <div style="max-height: 250px; overflow-y: scroll" #TABLE>
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        matSort
      >
        <!-- Display Column -->
        <ng-container matColumnDef="display">
          <th mat-header-cell *matHeaderCellDef>Display</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">{{ element.date | date }}</td>
        </ng-container>

        <!-- Time Column -->
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Time</th>
          <td mat-cell *matCellDef="let element">{{ element.time }}</td>
        </ng-container>

        <!-- Customer Name Column -->
        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef>Customer Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Seat Column -->
        <ng-container matColumnDef="seat">
          <th mat-header-cell *matHeaderCellDef>Seat</th>
          <td mat-cell *matCellDef="let element">
            {{ element.assigned_table }}
          </td>
        </ng-container>

        <!-- Bookings Column -->
        <ng-container matColumnDef="bookings">
          <th mat-header-cell *matHeaderCellDef>Bookings</th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            {{ element.person }}
          </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editRow(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Define the table row -->
        <tr
          mat-header-row
          *matHeaderRowDef="displayReservationSummaryColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayReservationSummaryColumns"
        ></tr>
      </table>
    </div>
    <h4 style="margin-top: 40px">Table Status</h4>
    <div #TABLE>
      <table mat-table [dataSource]="tblStatus" matSort>
        <!-- Display Column -->
        <ng-container matColumnDef="Group">
          <th mat-header-cell *matHeaderCellDef>Group</th>
          <td mat-cell *matCellDef="let element">{{ element.group_name }}</td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="Table">
          <th mat-header-cell *matHeaderCellDef>Table</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Time Column -->
        <ng-container matColumnDef="Capacity">
          <th mat-header-cell *matHeaderCellDef>Capacity</th>
          <td mat-cell *matCellDef="let element">{{ element.capacity }}</td>
        </ng-container>

        <!-- Customer Name Column -->
        <ng-container matColumnDef="IsKOT">
          <th mat-header-cell *matHeaderCellDef>KOT</th>
          <td mat-cell *matCellDef="let element">{{ element.kot_count }}</td>
        </ng-container>

        <!-- Seat Column -->
        <ng-container matColumnDef="IsWebactive">
          <th mat-header-cell *matHeaderCellDef>Is Webactive</th>
          <td mat-cell *matCellDef="let element">{{ element.webactive }}</td>
        </ng-container>

        <!-- Bookings Column -->
        <ng-container matColumnDef="IsAllotted">
          <th mat-header-cell *matHeaderCellDef>Is Allotted</th>
          <td mat-cell *matCellDef="let element">{{ element.is_reserved }}</td>
        </ng-container>

        <!-- Define the table row -->
        <tr mat-header-row *matHeaderRowDef="displayTableStatusColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayTableStatusColumns"
          [ngClass]="{
            'table-danger':
              row.webactive || row.is_reserved || row.kot_count > 0
          }"
        ></tr>
      </table>
    </div>
  </mat-dialog-content>
</div>
