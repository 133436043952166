<h2
  *ngIf="!data || data == 'fromTransaction'"
  class="color-h3"
  mat-dialog-title
>
  Add Item
</h2>
<h2 *ngIf="data && data != 'fromTransaction'" class="color-h3" mat-dialog-title>
  Edit Item
</h2>
<div class="table-details">
  <mat-tab-group
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChanged($event)"
    #itemTabGroup
  >
    <mat-tab label="General">
      <mat-dialog-content>
        <form name="form" #f="ngForm">
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  placeholder="Code:"
                  [(ngModel)]="model.item_code"
                  name="item_code"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <!-- <mat-form-field class="contract-full-width">
              <mat-select placeholder="Category:" [(ngModel)]="model.category_id" name="category_id" required>
                <mat-option *ngFor="let category of catObj" [value]="category.category_id">
                  {{category.category_name}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
              <mat-form-field class="contract-full-width">
                <input
                  type="text"
                  placeholder="Category"
                  aria-label="Number"
                  matInput
                  (focus)="categoryonfocus($event)"
                  (keyup)="searchcategory($event)"
                  [formControl]="category"
                  [matAutocomplete]="autoCategory"
                  required
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #autoCategory="matAutocomplete"
                  [displayWith]="displaycategory"
                  (optionSelected)="categoryselection($event)"
                >
                  <mat-option [value]="0">
                    <div (click)="onAddCategoryClick()" class="text-center">
                      <span>Add New Category</span>
                    </div>
                  </mat-option>
                  <mat-option *ngFor="let cate of categories" [value]="cate">
                    {{ cate.category_name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  type="text"
                  placeholder="Sub Category"
                  aria-label="Number"
                  matInput
                  (focus)="subcategoryonfocus($event)"
                  (keyup)="searchsubcategory($event)"
                  [formControl]="subcategory"
                  [matAutocomplete]="autoSubCategory"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #autoSubCategory="matAutocomplete"
                  [displayWith]="displaysubcategory"
                  (optionSelected)="subcategoryselection($event)"
                >
                  <mat-option [value]="0">
                    <div (click)="onAddSubCategoryClick()" class="text-center">
                      <span>Add New Sub Category</span>
                    </div>
                  </mat-option>
                  <mat-option
                    *ngFor="let subCate of subcategories"
                    [value]="subCate"
                  >
                    {{ subCate.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <!-- <mat-form-field class="contract-full-width">
              <mat-select placeholder="Unit:" [(ngModel)]="model.unit_id" name="unit_id" required>
                <mat-option *ngFor="let unit of unitObj" [value]="unit.unit_id">
                  {{unit.unit_name}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
              <mat-form-field class="contract-full-width">
                <input
                  type="text"
                  placeholder="Unit"
                  aria-label="Number"
                  matInput
                  (focus)="unitonfocus($event)"
                  (keyup)="searchunit($event)"
                  [formControl]="unit"
                  [matAutocomplete]="autoUnit"
                  required
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #autoUnit="matAutocomplete"
                  [displayWith]="displayunit"
                  (optionSelected)="unitselection($event)"
                >
                  <mat-option [value]="0">
                    <div (click)="onAddUnitClick()" class="text-center">
                      <span>Add New Unit</span>
                    </div>
                  </mat-option>
                  <mat-option *ngFor="let unit of units" [value]="unit">
                    {{ unit.unit_name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  placeholder="Name:"
                  [(ngModel)]="model.item_name"
                  name="item_name"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  type="text"
                  placeholder="Kitchen"
                  aria-label="Number"
                  matInput
                  (focus)="kitchenonfocus($event)"
                  (keyup)="searchkitchen($event)"
                  [formControl]="kitchen"
                  [matAutocomplete]="autoKitchen"
                  required
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #autoKitchen="matAutocomplete"
                  [displayWith]="displaykitchen"
                  (optionSelected)="kitchenselection($event)"
                >
                  <mat-option [value]="0">
                    <div (click)="onAddKitchenClick()" class="text-center">
                      <span>Add New Kitchen</span>
                    </div>
                  </mat-option>
                  <mat-option
                    *ngFor="let kitchen of kitchens"
                    [value]="kitchen"
                  >
                    {{ kitchen.kitchen_name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-4">
             
            </div> -->
            <!-- <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="HSN Code:" [(ngModel)]="model.hsn_code" name="hsn_code">
              </mat-form-field>
            </div> -->
          </div>

          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  placeholder="Part No.:"
                  [(ngModel)]="model.part_no"
                  name="part_no"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  type="number"
                  [readonly]="locationServiceFlag"
                  matInput
                  placeholder="Opening Stock:"
                  [(ngModel)]="model.ob"
                  name="ob"
                  min="0"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  placeholder="MFG By:"
                  [(ngModel)]="model.mfg_by"
                  name="mfg_by"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <span matPrefix>
                  <i class="fa fa-{{ currency }}"></i>&nbsp;</span
                >
                <input
                  type="number"
                  class="form-input-number"
                  matInput
                  placeholder="Purchase Rate:"
                  [(ngModel)]="model.p_rate"
                  name="p_rate"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <span matPrefix>
                  <i class="fa fa-{{ currency }}"></i>&nbsp;</span
                >
                <input
                  type="number"
                  class="form-input-number"
                  matInput
                  placeholder="Sell Rate:"
                  [(ngModel)]="model.s_rate"
                  name="s_rate"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <span matPrefix>
                  <i class="fa fa-{{ currency }}"></i>&nbsp;</span
                >
                <input
                  type="number"
                  class="form-input-number"
                  matInput
                  placeholder="MRP:"
                  [(ngModel)]="model.mrp"
                  name="mrp"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  placeholder="ROL:"
                  [(ngModel)]="model.rol"
                  name="rol"
                />
              </mat-form-field>
            </div>
            <!-- <div *ngIf="isLocation" class="col-md-4">
            <mat-form-field class="contract-full-width">
              <input type="text" placeholder="Location" matInput [formControl]="locationControl"
                [matAutocomplete]="autoLocation" required>
              <mat-autocomplete #autoLocation="matAutocomplete" autoActiveFirstOption [displayWith]="displayLocationFn"
                (optionSelected)="locationSelection($event)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option.location_name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div> -->
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  placeholder="ROQ:"
                  [(ngModel)]="model.roq"
                  name="roq"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  placeholder="HSN Code:"
                  [(ngModel)]="model.hsn_code"
                  name="hsn_code"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  placeholder="Barcode:"
                  [(ngModel)]="model.barcode"
                  name="barcode"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <mat-select
                  placeholder="Item Type"
                  [(ngModel)]="model.item_type"
                  name="item_type"
                  required
                >
                  <mat-option
                    *ngFor="let itemType of itemTypes"
                    [value]="itemType.value"
                  >
                    {{ itemType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <!-- <section class="form-section">
                <mat-checkbox class="form-margin" [(ngModel)]="model.active" name="active">
                  Active</mat-checkbox>
              </section> -->
              <mat-form-field class="contract-full-width">
                <span matPrefix>
                  <i class="fa fa-{{ currency }}"></i>&nbsp;</span
                >
                <input
                  type="number"
                  class="form-input-number"
                  matInput
                  placeholder="Packing Charges:"
                  [(ngModel)]="model.packing_charges"
                  name="packing_charges"
                  required
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <mat-select
                  placeholder="Tax Mode"
                  [(ngModel)]="model.tax_mode"
                  name="tax_mode"
                >
                  <mat-option value="GST">GST</mat-option>
                  <mat-option value="VAT">VAT</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  type="number"
                  class="form-input-number"
                  matInput
                  placeholder="{{
                    model.tax_mode === 'GST'
                      ? 'GST Percentage:'
                      : 'VAT Percentage:'
                  }}"
                  [(ngModel)]="model.taxslab"
                  name="taxslab"
                  required
                />
                <span matSuffix>%</span>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field
                class="contract-full-width"
                *ngIf="model.tax_mode === 'GST'"
              >
                <mat-select
                  placeholder="Tax Type:"
                  [(ngModel)]="model.tax_type"
                  name="tax_type"
                  required
                >
                  <mat-option
                    *ngFor="let taxtype of taxTypes"
                    [value]="taxtype.value"
                  >
                    {{ taxtype.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <mat-form-field class="contract-full-width">
                <textarea
                  matInput
                  placeholder="Notes:"
                  [(ngModel)]="model.notes"
                  name="notes"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="data && data != 'fromTransaction'">
            <div class="col-3">
              <section class="form-section">
                <mat-checkbox
                  class="form-margin"
                  [(ngModel)]="model.is_veg"
                  name="Is Veg"
                >
                  Is Veg</mat-checkbox
                >
              </section>
            </div>
            <div class="col-3">
              <section class="form-section">
                <mat-checkbox
                  class="form-margin"
                  [(ngModel)]="model.in_stock"
                  name="In Stock"
                >
                  In Stock</mat-checkbox
                >
              </section>
            </div>
            <div class="col-3">
              <section class="form-section">
                <mat-checkbox
                  class="form-margin"
                  [(ngModel)]="model.recommended"
                  name="Recommended"
                >
                  Recommended</mat-checkbox
                >
              </section>
            </div>
            <div class="col-3">
              <section class="form-section">
                <mat-checkbox
                  class="form-margin"
                  [(ngModel)]="model.active"
                  name="active"
                >
                  Active</mat-checkbox
                >
              </section>
            </div>
          </div>
        </form>
      </mat-dialog-content>
      <!-- <mat-dialog-actions align="end">
      <button class="mat-raised-button" (click)="close()">Close</button>
      <button class="mat-raised-button mat-primary" (click)="onSubmit()" [disabled]="f.invalid">Save</button>
    </mat-dialog-actions> -->
    </mat-tab>
    <mat-tab label="Location" *ngIf="locationServiceFlag">
      <br />
      <div style="width: 97%">
        <div class="row">
          <div class="col-md-9">
            <b>Location</b>
          </div>
          <div class="col-md-2 text-center">
            <b>OB</b>
          </div>
          <div class="col-md-1">&nbsp;</div>
        </div>
        <br />
        <div style="height: 330px; overflow-x: hidden; overflow-y: scroll">
          <div
            style="margin-bottom: -20px !important"
            class="col-md-12"
            *ngFor="let loc of locObj; let i = index"
          >
            <div class="row">
              <div class="col-md-9">
                {{ loc.location_name }}
              </div>
              <div class="col-md-2">
                <mat-form-field
                  style="line-height: unset"
                  class="contract-full-width"
                >
                  <!-- <input class="form-input-number"  style="line-height: unset;"  type="number" matInput (blur)="totalSumOb($event.target.value)"  [(ngModel)]="loc.ob"  name="{{i}}"> -->
                  <input
                    class="form-input-number"
                    style="line-height: unset"
                    type="number"
                    matInput
                    (blur)="totalSumOb()"
                    [(ngModel)]="loc.ob"
                    name="{{ i }}"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-1">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Options" *ngIf="checkEnableResturantFlag">
      <mat-dialog-content style="height: 300px">
        <button
          class="btn mat-raised-button mt-3 mb-3"
          (click)="addOptionModelDataToArray()"
        >
          Add
        </button>
        <ng-container *ngFor="let option of optionArray; let i = index">
          <div class="row row-gap-with-border">
            <div class="col">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Title"
                  [(ngModel)]="option.title"
                  name="title"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col" style="display: none">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Notes"
                  [(ngModel)]="option.notes"
                  name="notes"
                />
              </mat-form-field>
            </div>
            <!-- <div class="col">
              <mat-form-field>
                <mat-label>Input Type</mat-label>
                <mat-select [(ngModel)]="option.s_input_type" name="s_input_type">
                  <mat-option [value]="+'1'">Radio</mat-option>
                  <mat-option [value]="+'2'">Checkbox</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
            <div class="col text-center" style="display: none">
              <mat-checkbox
                [(ngModel)]="option.qty_selection_req"
                name="qty_selection_req"
                >Qty Req</mat-checkbox
              >
            </div>
            <div class="col" style="display: none">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="Default Value"
                  [(ngModel)]="option.default_value"
                  name="default_value"
                />
              </mat-form-field>
            </div>
            <div class="col" style="display: none">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="Amount"
                  [(ngModel)]="option.amount"
                  name="amount"
                />
              </mat-form-field>
            </div>
            <div class="col" *ngIf="checkEnableWeraFlag">
              <span style="margin-right: 10px">Vendors Active</span>
              <mat-slide-toggle
                [ngModel]="option.wera_active"
                color="primary"
                (ngModelChange)="updateOptionWeraActive($event, option)"
              >
              </mat-slide-toggle>
            </div>
            <div class="col text-center">
              <button
                mat-icon-button
                color="warn"
                (click)="deleteOptionClick(i)"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
              <button mat-icon-button class="mat-primary">
                <mat-icon
                  [matTooltip]="'Add Child Option'"
                  (click)="addOptionModelDataToArray(i)"
                  >add_circle</mat-icon
                >
              </button>
            </div>
            <ng-container *ngIf="option.childOptions.length > 0">
              <ng-container
                *ngFor="let childOption of option.childOptions; let j = index"
              >
                <div style="border-top: 2px dotted #999">
                  <div class="row" style="padding-top: 5px">
                    <div class="col">
                      <mat-form-field class="contract-full-width">
                        <input
                          type="text"
                          placeholder="Search Item"
                          aria-label="Number"
                          matInput
                          [formControl]="itemControl"
                          [matAutocomplete]="auto"
                          (keyup)="search($event)"
                          (focus)="onFocus($event)"
                          (keydown.enter)="onEnterKey($event)"
                          #sitm
                        />
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          autoActiveFirstOption
                          [displayWith]="displayFn"
                          (optionSelected)="itemSelection($event, i, j)"
                        >
                          <mat-option
                            *ngFor="let option of filteredOptions | async"
                            [value]="option"
                          >
                            <!-- Your option template -->
                            {{ option.item_name }} ({{ option.item_code }})
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field>
                        <input
                          matInput
                          type="text"
                          placeholder="Title"
                          [(ngModel)]="childOption.title"
                          name="Title"
                          required
                        />
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field>
                        <input
                          matInput
                          placeholder="Notes"
                          [(ngModel)]="childOption.notes"
                          name="notes"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col" style="display: none">
                      <mat-form-field>
                        <mat-label>Input Type</mat-label>
                        <mat-select
                          [(ngModel)]="childOption.s_input_type"
                          name="s_input_type"
                        >
                          <mat-option [value]="+'1'">Radio</mat-option>
                          <mat-option [value]="+'2'">Checkbox</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- <div class="col text-center">
                      <mat-checkbox [(ngModel)]="childOption.qty_selection_req" name="qty_selection_req">Qty Req</mat-checkbox>
                    </div>
                    <div class="col">
                      <mat-form-field>
                        <input matInput type="number" placeholder="Default Value" [(ngModel)]="childOption.default_value" name="default_value">
                      </mat-form-field>
                    </div> -->
                    <div class="col">
                      <mat-form-field>
                        <input
                          matInput
                          type="number"
                          placeholder="Amount"
                          [(ngModel)]="childOption.amount"
                          name="amount"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field>
                        <input
                          matInput
                          type="number"
                          placeholder="Tax Percentage"
                          [(ngModel)]="childOption.taxslab"
                          name="taxslab"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-checkbox
                        [(ngModel)]="childOption.is_veg"
                        name="is_veg"
                        >Is Veg</mat-checkbox
                      >
                    </div>
                    <div class="col">
                      <mat-checkbox
                        [(ngModel)]="childOption.in_stock"
                        name="in_stock"
                        >in Stock</mat-checkbox
                      >
                    </div>
                    <div class="col">
                      <mat-checkbox
                        [(ngModel)]="childOption.is_default"
                        name="is_default"
                        *ngIf="childOption.in_stock == true"
                        >Default</mat-checkbox
                      >
                    </div>
                    <div class="col text-center">
                      <button
                        mat-icon-button
                        color="warn"
                        (click)="deleteOptionClick(i, j)"
                      >
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                      <!-- <button mat-icon-button class="mat-primary">
                        <mat-icon [matTooltip]="'Add Child Option'">add_circle</mat-icon>
                      </button> -->
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </mat-dialog-content>
    </mat-tab>
    <mat-tab label="Variant" *ngIf="checkEnableResturantFlag">
      <mat-dialog-content style="height: 300px">
        <button
          class="btn mat-raised-button mt-3 mb-3"
          (click)="addVariantModelDataToArray()"
          *ngIf="variantArray.length == 0"
        >
          Add
        </button>
        <ng-container *ngFor="let variant of variantArray; let i = index">
          <div class="row row-gap-with-border">
            <div class="col">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Title"
                  [(ngModel)]="variant.title"
                  name="title"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col" style="display: none">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Notes"
                  [(ngModel)]="variant.notes"
                  name="notes"
                />
              </mat-form-field>
            </div>
            <!-- <div class="col">
              <mat-form-field>
                <mat-label>Input Type</mat-label>
                <mat-select [(ngModel)]="variant.s_input_type" name="s_input_type">
                  <mat-option [value]="+'1'">Radio</mat-option>
                  <mat-option [value]="+'2'">Checkbox</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
            <div class="col text-center" style="display: none">
              <mat-checkbox
                [(ngModel)]="variant.qty_selection_req"
                name="qty_selection_req"
                >Qty Req</mat-checkbox
              >
            </div>
            <div class="col" style="display: none">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="Default Value"
                  [(ngModel)]="variant.default_value"
                  name="default_value"
                />
              </mat-form-field>
            </div>
            <div class="col" style="display: none">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="Amount"
                  [(ngModel)]="variant.price"
                  name="price"
                />
              </mat-form-field>
            </div>
            <div class="col" *ngIf="checkEnableWeraFlag">
              <span style="margin-right: 10px">Vendors Active</span>
              <mat-slide-toggle
                [ngModel]="variant.wera_active"
                color="primary"
                (ngModelChange)="updateVeriantsWeraActive($event, variant)"
              >
              </mat-slide-toggle>
            </div>
            <div class="col text-center">
              <button
                mat-icon-button
                color="warn"
                (click)="deleteVariantClick(i)"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
              <button mat-icon-button class="mat-primary">
                <mat-icon
                  [matTooltip]="'Add Child Option'"
                  (click)="addVariantModelDataToArray(i)"
                  >add_circle</mat-icon
                >
              </button>
            </div>
            <ng-container
              *ngIf="variant.variants.length > 0"
              class="childOptions"
            >
              <ng-container
                *ngFor="let childVariant of variant.variants; let j = index"
              >
                <div style="border-top: 2px dotted #999">
                  <div class="row" style="padding-top: 5px">
                    <div class="col">
                      <mat-form-field>
                        <input
                          matInput
                          placeholder="Title"
                          [(ngModel)]="childVariant.title"
                          name="title"
                          required
                        />
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field>
                        <input
                          matInput
                          placeholder="Notes"
                          [(ngModel)]="childVariant.notes"
                          name="notes"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col" style="display: none">
                      <mat-form-field>
                        <mat-label>Input Type</mat-label>
                        <mat-select
                          [(ngModel)]="childVariant.s_input_type"
                          name="s_input_type"
                        >
                          <mat-option [value]="+'1'">Radio</mat-option>
                          <mat-option [value]="+'2'">Checkbox</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field>
                        <input
                          matInput
                          type="number"
                          placeholder="Amount"
                          [(ngModel)]="childVariant.price"
                          name="price"
                          required
                        />
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field>
                        <input
                          matInput
                          type="number"
                          placeholder="Tax Percentage"
                          [(ngModel)]="childVariant.taxslab"
                          name="taxslab"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-checkbox
                        [(ngModel)]="childVariant.is_veg"
                        name="is_veg"
                        >Is Veg</mat-checkbox
                      >
                    </div>
                    <div class="col">
                      <mat-checkbox
                        [(ngModel)]="childVariant.in_stock"
                        name="in_stock"
                        >In Stock</mat-checkbox
                      >
                    </div>
                    <div class="col">
                      <mat-checkbox
                        [(ngModel)]="childVariant.is_default"
                        name="is_default"
                        *ngIf="childVariant.in_stock == true"
                        >Default</mat-checkbox
                      >
                    </div>
                    <div class="col text-center">
                      <button
                        mat-icon-button
                        color="warn"
                        (click)="deleteVariantClick(i, j)"
                      >
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                      <!-- <button mat-icon-button class="mat-primary">
                        <mat-icon [matTooltip]="'Add Child Option'">add_circle</mat-icon>
                      </button> -->
                    </div>
                  </div>
                </div>
                <!-- <button
                  class="btn mat-raised-button mt-3 mb-3"
                  (click)="showConsumable()"
                >
                  consumable
                </button> -->
                <mat-checkbox
                  [(ngModel)]="checkedIndices1[j]"
                  (change)="showConsumable(j)"
                  *ngIf="childVariant.consumableItems.length"
                  >Consumable</mat-checkbox
                >

                <table
                  class="consumable-table"
                  *ngIf="
                    childVariant.consumableItems.length &&
                    checkedIndices[j] == true
                  "
                >
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Consumable Item</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="
                        let option of childVariant.consumableItems;
                        let i = index
                      "
                    >
                      <tr class="row-gap-with-border">
                        <td>{{ i + 1 }}</td>
                        <td>
                          <mat-form-field class="contract-full-width">
                            <input
                              type="text"
                              placeholder="Consumable Item"
                              aria-label="Consumable Item"
                              matInput
                              [(ngModel)]="option.title"
                              required
                            />
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field>
                            <input
                              matInput
                              type="number"
                              placeholder="Quantity"
                              [(ngModel)]="option.qty"
                              name="qty"
                            />
                          </mat-form-field>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </mat-dialog-content>
    </mat-tab>

    <!-- Consumable -->

    <mat-tab label="Consumable">
      <mat-dialog-content style="height: 300px">
        <button
          class="btn mat-raised-button mt-3 mb-3"
          (click)="addConsumableData()"
        >
          Add
        </button>
        <table class="consumable-table">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Consumable Item</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let option of consumableItem; let i = index">
              <tr class="row-gap-with-border">
                <td>{{ i + 1 }}</td>
                <td>
                  <mat-form-field class="contract-full-width">
                    <input
                      type="text"
                      placeholder="Consumable Item"
                      aria-label="Consumable Item"
                      matInput
                      [formControl]="option.consumableControl"
                      [matAutocomplete]="auto"
                      required
                    />
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayConsumable"
                      (optionSelected)="consumableSelection($event, i)"
                    >
                      <mat-option
                        *ngFor="
                          let item of filterConsumableItems(
                            option.consumableControl.value
                          ) | async
                        "
                        [value]="item"
                        >{{ item }}</mat-option
                      >
                    </mat-autocomplete>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field>
                    <input
                      matInput
                      type="number"
                      placeholder="Quantity"
                      [(ngModel)]="option.qty"
                      name="qty"
                    />
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field>
                    <mat-select
                      placeholder="Unit"
                      [(ngModel)]="option.unit_id"
                      name="unit_id"
                      required
                    >
                      <mat-option
                        *ngFor="let unit of Units"
                        [value]="unit.unit_id"
                        >{{ unit.unit_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeConsumableItem(i)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </mat-dialog-content>
    </mat-tab>

    <!-- Consumable -->
  </mat-tab-group>
  <button class="btn mat-raised-button" (click)="close()">Close</button>
  <button
    class="btn-primary mat-raised-button ml-2"
    (click)="onSubmit()"
    [disabled]="f.invalid"
  >
    Save
  </button>
</div>
