<h2 *ngIf="!data" mat-dialog-title class="color-h3">Add New Unit Converter</h2>
<h2 *ngIf="data" mat-dialog-title class="color-h3">Edit Unit Converter</h2>
<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <mat-form-field class="contract-full-width">
        <mat-label>From Unit :</mat-label>
        <mat-select [(ngModel)]="model.from_unit_id" name="from_unit_id" required>
          <mat-option [value]=0>
            <div (click)="onAddUnitClick()" class="text-center">
              <span>Add New Unit</span>
            </div>
          </mat-option>
          <mat-option *ngFor="let unit of unitObj" [value]="unit.unit_id">{{ unit.unit_name }}</mat-option>
        </mat-select>
        <mat-error>required</mat-error>
      </mat-form-field>
      <mat-form-field class="contract-full-width">
        <mat-label>To Unit :</mat-label>
        <mat-select [(ngModel)]="model.to_unit_id" name="to_unit_id" required>
          <mat-option [value]="null">
            <div (click)="onAddUnitClick()" class="text-center">
              <span>Add New Unit</span>
            </div>
          </mat-option>
          <mat-option *ngFor="let unit of unitObj" [value]="unit.unit_id">{{ unit.unit_name }}</mat-option>
        </mat-select>
        <mat-error>required</mat-error>
      </mat-form-field>
      <mat-form-field class="contract-full-width">
        <input matInput placeholder="Conversion Factor :" [(ngModel)]="model.qty" name="qty" required>
        <mat-error>required</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">Close</button>
    <button type="submit" class="btn-primary mat-raised-button" (click)="onSubmit()" [disabled]="f.invalid">Save</button>
  </mat-dialog-actions>
</div>
