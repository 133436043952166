import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../data.service';

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import * as FileSaver from 'file-saver';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { DBbackupComponent } from '../db-backup/db-backup.component';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CompanyManagementComponent } from '../company-management/company-management.component';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent {
 
  params1:any;
  params2:any;
 
  constructor(private breakpointObserver: BreakpointObserver, private route: ActivatedRoute, private notifier: NotifierService,
    private apiService: DataService, public dialog: MatDialog, private router: Router) {
    
  }
  ngOnInit() {
   
    this.redirecturl()
   
   
    
  }
  

 
  
  redirecturl(){

    this.route.queryParams.subscribe(params => {
        console.log(params);
        this.params1 = params['code'];
        this.params2 =params['state']
        console.log(this.params1); // Print the parameter to the console. 
        console.log(this.params1); // Print the parameter to the console. 
       
    }); 
      const observable= new Observable(observer=>{
          observer.next(this.params2);
      });
      observable.subscribe( value=>window.open(this.params2+"/dashboard?code="+this.params1, '_blank', 'toolbar=0,width=400,height=600,left=500,top=50'));
       
      
     
  }
}
