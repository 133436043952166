import { Component, OnInit, ViewChild, Input } from "@angular/core";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MAT_DATE_FORMATS,
  NativeDateAdapter,
  DateAdapter,
} from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from "../date.adapter";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import * as moment from "moment";
import { DataService } from "../data.service";
import { EditInvoiceComponent } from "../invoice/edit-invoice/edit-invoice.component";
import { EditInvoiceReturnComponent } from "../invoice-return/edit-invoice-return/edit-invoice-return.component";
import { EditDeliveryOrderComponent } from "../delivery-order/edit-delivery-order/edit-delivery-order.component";
import { EditPurchaseComponent } from "../purchase/edit-purchase/edit-purchase.component";
import { EditPurchaseReturnComponent } from "../purchase-return/edit-purchase-return/edit-purchase-return.component";
import { EditGrnComponent } from "../grn/edit-grn/edit-grn.component";
import { EditPaymentComponent } from "../payment/edit-payment/edit-payment.component";
import { EditReceiptComponent } from "../receipt/edit-receipt/edit-receipt.component";
import { EditExpenseComponent } from "../expense/edit-expense/edit-expense.component";
import { EditJournalComponent } from "../journal/edit-journal/edit-journal.component";
import { EditCashBankComponent } from "../cash-bank/edit-cash-bank/edit-cash-bank.component";
import { EditCreditNoteComponent } from "../credit-note/edit-credit-note/edit-credit-note.component";
import { ExportService } from "../_export-service/daybook-export.service";
import { EditPurchaseOrderComponent } from "../purchase-order/edit-purchase-order/edit-purchase-order.component";
import { EditSalesOrderComponent } from "../sales-order/edit-sales-order/edit-sales-order.component";
import { ActivatedRoute } from "@angular/router";
import { EditStockAdjustmentComponent } from "../stock-adjustment/edit-stock-adjustment/edit-stock-adjustment.component";
import { EditStockTransferComponent } from "../stock-transfer/edit-stock-transfer/edit-stock-transfer.component";
import { map } from "rxjs/operators";

@Component({
  selector: "app-oustanding-report",
  templateUrl: "./oustanding-report.component.html",
  styleUrls: ["./oustanding-report.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class OustandingReportComponent implements OnInit {
  accountVoucherObj: MatTableDataSource<any>;
  // @Input() counter: any;
  constructor(
    private apiService: DataService,
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private exportService: ExportService
  ) {}

  openDialog: any;
  model: any = {
    from_date: new Date(),
    to_date: new Date(),
    voucherList: [],
    voucher_type: 5,
  };
  voucherObj: any;
  unpaidvoice: any[];
  displayedColumns = ["trans_date", "cutomer_name", "invoice", "net_amount"];
  accDataArr: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit() {
    this.setDetaultType();
    this.load();
  }
  setDetaultType() {
    this._Activatedroute.paramMap.subscribe((params) => {
      if (params.get("id")) {
        this.model.voucher_type = parseInt(params.get("id"));
      } else {
        this.model.voucher_type = 5;
      }
    });
  }

  load() {
    // Adjusting dates to specific times (end of day for toDate and start of day for fromDate)
    const toDate = new Date(this.model.to_date);
    toDate.setHours(23, 59, 59, 999);
    const fromDate = new Date(this.model.from_date);
    fromDate.setHours(0, 0, 0, 0);

    // Fetch unpaid invoice report based on adjusted dates
    this.apiService.getUnpaidInvoiceReport().subscribe(
      (result: any[]) => {
        console.log("Unfiltered unpaid invoices:", result);

        // Filter invoices for the current day
        const today = new Date();
        const filteredResult = result.filter((item) => {
          const transDate = new Date(item.trans_date);
          return (
            transDate.getFullYear() === today.getFullYear() &&
            transDate.getMonth() === today.getMonth() &&
            transDate.getDate() === today.getDate()
          );
        });

        // Map result to include only required fields
        const mappedResult = filteredResult.map((item) => ({
          customer_name: item.customer_name,
          display_trans_no: item.display_trans_no,
          balance: item.balance,
          trans_date: item.trans_date,
        }));

        // Assign filtered result to MatTableDataSource
        this.accountVoucherObj = new MatTableDataSource(mappedResult);
        this.accountVoucherObj.sort = this.sort;
        this.accountVoucherObj.paginator = this.paginator;

        console.log("Filtered unpaid invoices for today:", mappedResult);
      },
      (error: any) => {
        console.error("Error fetching unpaid invoice report:", error);
        // Handle error fetching unpaid invoice report
      }
    );
  }

  serachClick() {
    // Adjust dates to specific times (if needed)
    this.model.to_date.setHours(23, 59, 59, 999); // Set to end of day
    this.model.from_date.setHours(0, 0, 0, 0); // Set to start of day

    // Fetch data from API based on date range and optionally customer name
    this.apiService.getUnpaidInvoiceReport().subscribe(
      (result: any[]) => {
        console.log("Unfiltered unpaid invoices:", result);

        // Apply date range filter
        const filteredByDate = result.filter((item) => {
          const transDate = new Date(item.trans_date);
          return (
            transDate >= this.model.from_date && transDate <= this.model.to_date
          );
        });

        // Optionally, apply customer name filter
        let filteredResult = filteredByDate;
        if (this.model.customer_name) {
          filteredResult = filteredByDate.filter((item) =>
            item.customer_name
              .toLowerCase()
              .includes(this.model.customer_name.toLowerCase())
          );
        }

        // Map result to include only required fields
        const mappedResult = filteredResult.map((item) => ({
          customer_name: item.customer_name,
          display_trans_no: item.display_trans_no,
          balance: item.balance,
          trans_date: item.trans_date,
        }));

        // Assign filtered result to datasource
        this.accountVoucherObj = new MatTableDataSource(mappedResult);
        this.accountVoucherObj.sort = this.sort;
        this.accountVoucherObj.paginator = this.paginator;

        console.log("Filtered invoices:", mappedResult);
      },
      (error: any) => {
        console.error("Error fetching invoice report:", error);
        // Handle error fetching invoice report
      }
    );
  }

  edittransaction(row) {
    if (row.v_type_name == "Invoice") this.openDialog = EditInvoiceComponent;
    if (row.v_type_name == "InvoiceReturn")
      this.openDialog = EditInvoiceReturnComponent;
    if (row.v_type_name == "DeliveryOrder")
      this.openDialog = EditDeliveryOrderComponent;
    if (row.v_type_name == "Purchase") this.openDialog = EditPurchaseComponent;
    if (row.v_type_name == "PurchaseReturn")
      this.openDialog = EditPurchaseReturnComponent;
    if (row.v_type_name == "GRN") this.openDialog = EditGrnComponent;
    if (row.v_type_name == "Payment") this.openDialog = EditPaymentComponent;
    if (row.v_type_name == "Receipt") this.openDialog = EditReceiptComponent;
    if (row.v_type_name == "Expense") this.openDialog = EditExpenseComponent;
    if (row.v_type_name == "Journal") this.openDialog = EditJournalComponent;
    if (row.v_type_name == "CashBank") this.openDialog = EditCashBankComponent;
    if (row.v_type_name == "CreditNote")
      this.openDialog = EditCreditNoteComponent;
    if (row.v_type_name == "PurchaseOrder")
      this.openDialog = EditPurchaseOrderComponent;
    if (row.v_type_name == "SalesOrder")
      this.openDialog = EditSalesOrderComponent;
    if (row.v_type_name == "StockAdjustment")
      this.openDialog = EditStockAdjustmentComponent;
    if (row.v_type_name == "StockTransfer")
      this.openDialog = EditStockTransferComponent;

    this.dialog
      .open(this.openDialog, {
        panelClass: "dialog-class",
        width: "1100px",
        data: row,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == "Edited") {
          this.load();
        }
      });
  }
  getTotalAmt() {
    return this.accDataArr
      ? this.accDataArr
          .filter((filter_data) => {
            return filter_data.active;
          })
          .map((acc_data) => acc_data.net_amount)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }
  //On print click
  printClick() {
    this.model.trans_type = "VoucherDetails";
    this.apiService.downloadPdf(this.model).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "voucher_details" + new Date().toLocaleDateString() + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => {}
    );
  }
  //Export data as csv
  ExportTOExcel() {
    var dataArr = [];
    this.accDataArr.forEach((d) => {
      var rowValues = [];
      rowValues = [];
      rowValues[1] = new Date(d.trans_date);
      rowValues[2] = d.trans_no;
      rowValues[3] = d.customer_name
        ? d.ledger_name + "-" + d.customer_name
        : d.ledger_name;
      rowValues[4] = d.net_amount;
      dataArr.push(rowValues);
    });
    var columnWidth = [
      { column: 1, width: 20 },
      { column: 2, width: 20 },
      { column: 3, width: 30 },
      { column: 4, width: 20 },
    ];
    var numFmt = [{ column: 1, numFmt: "dd/mm/yyyy" }];
    var frm_date = moment(this.model.from_date).format("DD/MM/YYYY");
    var til_date = moment(this.model.to_date).format("DD/MM/YYYY");
    const file_name = "VoucherDetails";
    const title = "Voucher Details Report";
    const subheader =
      this.model.voucher_type +
      " Voucher details From: " +
      frm_date +
      " To: " +
      til_date;
    const header = ["Date", "Voucher No.", "Details", "Amount"];
    this.exportService.generateExcel(
      file_name,
      title,
      header,
      subheader,
      dataArr,
      columnWidth,
      numFmt
    );
  }
}
