<h4 class="color-h3" mat-dialog-title>Purchase Rate Wise Stock of {{data.item_name}} </h4>
<div class="table-details">
  <mat-dialog-content>
    <form>
      <div class="row">
        <div class="col-md-3">
          <mat-form-field class="contract-full-width">
            <input matInput [matDatepicker]="from_date" placeholder="From:" [(ngModel)]="model.from_date"
              name="from_date" disabled>
            <mat-datepicker-toggle matSuffix [for]="from_date"></mat-datepicker-toggle>
            <mat-datepicker #from_date></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="contract-full-width">
            <input matInput [matDatepicker]="to_date" placeholder="To:" [(ngModel)]="model.to_date" name="to_date"
              disabled>
            <mat-datepicker-toggle matSuffix [for]="to_date"></mat-datepicker-toggle>
            <mat-datepicker #to_date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>

    <table *ngIf="itemStockData" mat-table [dataSource]="itemStockData" matSort style="text-align: end;">

      <ng-container matColumnDef="item_name">
        <th mat-header-cell *matHeaderCellDef>Item Name</th>
        <td mat-cell *matCellDef="let row" data-label="item_name" style="text-align: start !important;">
          <a [routerLink]="" (click)="openStockDetails(row)">{{row.item_name}}</a>
        </td>
        <td mat-footer-cell *matFooterCellDef>Total : </td>
      </ng-container>
      <ng-container matColumnDef="p_rate">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;">P Rate</th>
        <td mat-cell *matCellDef="let row" data-label="p_rate">
          <a [routerLink]="" (click)="openStockDetails(row)">{{row.p_rate | number : '1.2-2'}}</a>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="ob">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;">OB</th>
        <td mat-cell *matCellDef="let row" data-label="OB">{{row.ob}}</td>
        <td mat-footer-cell *matFooterCellDef>{{totalObAmt() | number : '1.3-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="in_item">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;">In</th>
        <td mat-cell *matCellDef="let row" data-label="In">{{row.in_item }}</td>
        <td mat-footer-cell *matFooterCellDef>{{totalInAmt() | number : '1.3-3'}}</td>

      </ng-container>

      <ng-container matColumnDef="out_item">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;">Out</th>
        <td mat-cell *matCellDef="let row" data-label="Out">{{row.out_item }}</td>
        <td mat-footer-cell *matFooterCellDef>{{totalOutAmt() | number : '1.3-3'}}</td>

      </ng-container>

      <ng-container matColumnDef="cb">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;">CB</th>
        <td mat-cell *matCellDef="let row" data-label="CB">{{row.cb }}</td>
        <td mat-footer-cell *matFooterCellDef>{{totalCbAmt() | number : '1.3-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef style="text-align: end;">value</th>
        <td mat-cell *matCellDef="let row" data-label="Value">{{row.value | number : '1.3-3'}}</td>
        <td mat-footer-cell *matFooterCellDef>{{totalValueAmt() | number : '1.3-3'}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'group-trail': row.ledger_id == null}">
      </tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns" class="total-row"></tr>
    </table>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="mat-raised-button btn" (click)="printClick()">Print</button>
    <button type="button" class="btn mat-raised-button" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>