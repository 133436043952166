import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AddEditCustomersMasterComponent } from '../../customers-master/add-edit-customers-master/add-edit-customers-master.component';
import { PurchaseOrderItemsComponent } from '../../purchase-order/purchase-order-items/purchase-order-items.component';
import { environment } from '../../../environments/environment';
import {MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter  } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../date.adapter';
@Component({
  selector: 'app-edit-purchase-order',
  templateUrl: './edit-purchase-order.component.html',
  styleUrls: ['./edit-purchase-order.component.css'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class EditPurchaseOrderComponent implements OnInit {
  constructor(private apiService: DataService,
    private dialogRef: MatDialogRef<EditPurchaseOrderComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.currency = environment.currency;
  }

  @ViewChild('f') f;
  currency:any;
  contractObj: any;
  ledgerObj: any;
  transDataObj: any = { items_details: [] };
  items: any = [];
  amount: any;
  ledgerControl = new FormControl();
  options: any;
  filteredOptions: Observable<string[]>;
  optionalFields: any;
  moreField = false;
  GState: any;
  stateName: any;
  statelist: any;
  sbilling_state = new FormControl();
  l_id:any;
  closing_balance:any;
  ledger_id:any;
  
shipping_state = new FormControl();

  ngOnInit() {
    this.apiService.getGstState()
    .subscribe((result: any) => {
      this.GState = result;
      this.stateName = this.GState;
      this.load();
    }, (result: any) => {
      console.log('state not loaded');
      this.notifier.notify('error', 'unable to load data');
    });

    this.getEnabledTransFields();
  }


  getEnabledTransFields() {
    this.apiService.getEnabledInvoiceField(1)
      .subscribe((result: any) => {
        this.optionalFields = result;
        if (this.optionalFields.length > 7) {
          this.moreField = true;
        }
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load enabled fields');
      });
  }

  filter(value) {
    const filterValue = value.toLowerCase();
    return this.ledgerObj.filter(ledger => ledger.ledger_code.toLowerCase().includes(filterValue) || ledger.ledger_name.toLowerCase().includes(filterValue));
  }

  displayFn(ledger) {
    return ledger ? ledger.ledger_name : "";
  }



  // State ************************************
  displayStateFn(state) {
    return state ? state.state_name : '';
  }

  allstates() {
    this.apiService.getGstState()
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }
   // searching for ledger
   searchState(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.sbilling_state.value.length >= 1) {
        this.apiService.sgetGstState(this.sbilling_state.value, 'false')
          .subscribe((result: any) => {
            this.GState = result;
            this.stateName = this.GState;
          });
      }
    }
  }
    // on focus on ledger search
    StateonFocus(e) {
      this.apiService.sgetGstState('a', 'false')
        .subscribe((result: any) => {
          this.GState = result;
          this.stateName = this.GState;
          // console.log(this.stateName);
        });
    }

    StateSelection(event: MatAutocompleteSelectedEvent) {
      this.form.get('billing_state').setValue(event.option.value.state_gst_code);
    }
  // State End************************************


  sameAdd(isChecked: boolean) {
    if (isChecked) {
      this.shipping_state.setValue(this.sbilling_state.value);
      this.form.get('shipping_address1').setValue(this.form.get('billing_address1').value);
      this.form.get('shipping_address2').setValue(this.form.get('billing_address2').value);
      this.form.get('shipping_street').setValue(this.form.get('billing_street').value);
      this.form.get('shipping_city').setValue(this.form.get('billing_city').value);
      this.form.get('shipping_state').setValue(this.form.get('billing_state').value);
      this.form.get('shipping_country').setValue(this.form.get('billing_country').value);
      this.form.get('shipping_pin').setValue(this.form.get('billing_pin').value);
    }
    else {
      this.shipping_state.setValue(null);
      this.form.get('shipping_address1').setValue('');
      this.form.get('shipping_address2').setValue('');
      this.form.get('shipping_street').setValue('');
      this.form.get('shipping_city').setValue('');
      this.form.get('shipping_state').setValue('');
      this.form.get('shipping_country').setValue('');
      this.form.get('shipping_pin').setValue('');
    }

  }
  load() {
    this.f.submitted = true;
    this.apiService.getPurchaseOrder(this.data.transaction_id)
      .subscribe((result: any) => {
        this.items = result.items_details;
        this.form.patchValue(result);
        // console.log(this.stateName);
      let indexofState = this.stateName.findIndex(i => i.state_gst_id === result.billing_state);
      this.sbilling_state.setValue(this.stateName[indexofState]);
      
      let indexofState3 = this.stateName.findIndex(i => i.state_gst_id === result.shipping_state);
      this.shipping_state.setValue(this.stateName[indexofState3]);

      }, (result: any) => {
      });
    this.apiService.getLedger(this.data.ledger_id)
      .subscribe((result: any) => {
        this.ledgerObj = result[0];
        // console.log(this.ledgerObj.ledger_name);
        this.ledgerControl.setValue(this.ledgerObj);
        this.apiService.getLedgerClosingBalance(this.data.ledger_id).subscribe(
          (result:any)=>{
            if(result.success){
              this.closing_balance=result.message.closing_balance+' '+result.message.cbdrcr;
             
            }
            else{
              this.notifier.notify('error', result.message);
            }
          },
          (err:any)=>{
    
          }
        )
      }, (result: any) => {
      });
    this.apiService.getContractList()
      .subscribe((result: any) => {
        this.contractObj = result;
      }, (result: any) => {
      });

  }
  //searching for ledger
  search(event) {
    this.closing_balance=null;
    if (event.key != "ArrowDown" && event.key != "ArrowUp" && event.key != "Enter") {
      if (this.ledgerControl.value.length > 1) {
        this.apiService.getSundryCrLedgerList(this.ledgerControl.value, "false")
          .subscribe((result: any) => {
            this.ledgerObj = result;
            this.filteredOptions = this.ledgerControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.ledgerObj = [];
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }
    }
  }
  // tslint:disable-next-line: member-ordering
  form: FormGroup = new FormGroup({
    contract_id: new FormControl(null),
    transaction_id: new FormControl(0),
    trans_no: new FormControl('Auto Generated', Validators.required),
    trans_date: new FormControl(new Date(), Validators.required),
    ledger_id: new FormControl(null, Validators.required),
    shipping_address1: new FormControl(null),
    shipping_address2: new FormControl(null),
    shipping_street: new FormControl(null),
    shipping_city: new FormControl(null),
    shipping_state: new FormControl(null),
    shipping_country: new FormControl(null),
    shipping_pin: new FormControl(null),
    billing_address1: new FormControl(null),
    billing_address2: new FormControl(null),
    billing_street: new FormControl(null),
    billing_city: new FormControl(null),
    billing_state: new FormControl(null),
    sbilling_state: new FormControl(null),
    billing_country: new FormControl(null),
    billing_pin: new FormControl(null),
    transport_address1: new FormControl(null),
    transport_address2: new FormControl(null),
    transport_street: new FormControl(null),
    transport_city: new FormControl(null),
    transport_state: new FormControl(null),
    transport_country: new FormControl(null),
    transport_pin: new FormControl(null),
    net_amount: new FormControl(0),
    field1: new FormControl(null),
    field2: new FormControl(null),
    field3: new FormControl(null),
    field4: new FormControl(null),
    field5: new FormControl(null),
    dispatch_through: new FormControl(null),
    transport_mode: new FormControl(null),
    container_no: new FormControl(null),
    gr_no: new FormControl(null),
    po_no: new FormControl(null),
    challan_no: new FormControl(null),
    challan_date: new FormControl(null),
    credit_days: new FormControl(null),
    due_date: new FormControl(null),
    po_date: new FormControl(null),
    bill_no: new FormControl(null),
    payment_days: new FormControl(null),
  });
  // Initialise ledger
  initialiseLedger() {
    this.ledgerControl.setValue(null);
    this.ledgerObj = [];
    this.filteredOptions = this.ledgerControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
  }
  initializeFormGroup() {
    this.form.setValue({
      contract_id: null,
      transaction_id: 0,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      shipping_address1: null,
      shipping_address2: null,
      shipping_street: null,
      shipping_city: null,
      shipping_state: null,
      shipping_country: null,
      shipping_pin: null,
      billing_address1: null,
      billing_address2: null,
      billing_street: null,
      billing_city: null,
      sbilling_state: null,
      billing_country: null,
      billing_pin: null,
      transport_address1: null,
      transport_address2: null,
      transport_street: null,
      transport_city: null,
      transport_state: null,
      transport_country: null,
      transport_pin: null,
      net_amount: 0,
      field1: null,
      field2: null,
      field3: null,
      field4: null,
      field5: null,
      dispatch_through: null,
      transport_mode: null,
      container_no: null,
      gr_no: null,
      po_no: null,
      challan_no: null,
      challan_date: null,
      credit_days: null,
      due_date: null,
      po_date: null,
      bill_no: null,
      payment_days: null,
    });
    this.items = [];
    window.scroll(0, 0);
    this.initialiseLedger();
  }
  // addItemClick() {
  //   var itemData = { type: 'po', v_type_id:1 }
  //   this.dialog.open(PurchaseOrderItemsComponent, {
  //     width: '600px',
  //     data: itemData,
  //     autoFocus: false
  //   })
  //     .afterClosed().subscribe(result => {
  //       if (result) {
  //         this.allstates();
  //         this.items.push(result);
  //         this.calculateTotal();
  //       }
  //     });
  // }


  addItemClick() {
    var itemData = {type:'In',v_type_id:1}

    let itemref = this.dialog.open(PurchaseOrderItemsComponent, {
      width: '600px',
      data: itemData,
      autoFocus: false
    });
    itemref.componentInstance.onItemAdd.subscribe((result) => {
      if (result) {
        this.allstates();
        this.items.push(result);
        this.calculateTotal();
      }
    });
  }




  onDeleteItem(i) {
    this.items.splice(i, 1);
    this.calculateTotal();
  }
  onCreditDays() {
    var dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + this.form.value.credit_days);
    this.form.get('due_date').setValue(dueDate);
  }
  editItem(i) {
    var itemData = { type: 'In',v_type_id:1, value: this.items[i] }
    this.dialog.open(PurchaseOrderItemsComponent, {
      width: '600px',
      data: itemData,
      autoFocus: false
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.items.splice(i, 1);
          this.items.push(result);
          this.calculateTotal();
        }
      });
  }
  //On Ledger Selection event
  ledgerSelection(event: MatAutocompleteSelectedEvent) {
    this.ledger_id = event.option.value ? event.option.value.ledger_id : undefined;
    this.form.get('ledger_id').setValue(event.option.value.ledger_id);
    this.form.get('billing_address1').setValue(event.option.value.address_address1);
    this.form.get('billing_address2').setValue(event.option.value.address_address2);
    this.form.get('billing_street').setValue(event.option.value.address_street);
    this.form.get('billing_city').setValue(event.option.value.address_city);
    this.form.get('billing_state').setValue(event.option.value.address_state);
    this.form.get('billing_country').setValue(event.option.value.address_country);
    this.form.get('billing_pin').setValue(event.option.value.address_pin);
    var indexofState = this.stateName.findIndex(i => i.state_gst_id === event.option.value.address_state);
    this.sbilling_state.setValue(this.stateName[indexofState]);
    this.apiService.getLedgerClosingBalance(this.ledger_id).subscribe(
      (result:any)=>{
        if(result.success){
          this.closing_balance=result.message.closing_balance+' '+result.message.cbdrcr;
         
        }
        else{
          this.notifier.notify('error', result.message);
        }
      },
      (err:any)=>{

      }
    )
  }
  calculateTotal() {
    this.amount = 0;
    for (var i = 0; i < this.items.length; i++) {
      this.amount = this.amount + this.items[i].amount;
    }
    this.form.get('net_amount').setValue(this.amount);
  }
  //on Form Submit
  onSubmit() {
 
    if (this.form.valid) {
      this.transDataObj = this.form.value;
      // console.log(this.transDataObj);
      this.transDataObj.items_details = this.items;
      this.apiService.editPurchaseOrder(this.transDataObj.transaction_id, this.transDataObj)
        .subscribe((result: any) => {
          if (result.success) {
            this.downloadClick();
            this.notifier.notify('success', 'Purchase Order Updated successfully');
          } else {
            this.notifier.notify('error', result.message);
          }
          this.dialogRef.close('Edited');
        }, (result: any) => {
          this.notifier.notify('error', 'unable to update Purchase Order');
        });
    } else {
      window.scroll(0, 0);
    }
  }
  //to Reset Form
  onClear() {
    this.closing_balance=null;
    this.initializeFormGroup();
  }
  downloadClick() {
    this.transDataObj = this.form.value;
    this.transDataObj.items_details = this.items;
    this.transDataObj.trans_type = "PurchaseOrder";
    this.apiService.downloadPdf(this.transDataObj)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'po' + this.transDataObj.trans_no + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
  cancelInvoice() {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Cancel the Purchase Order Voucher Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deletePurchaseOrder(this.data.transaction_id)
            .subscribe((result: any) => {
              this.notifier.notify('success', 'Sales Order Canceled successfully');
            }, (result: any) => {
              this.notifier.notify('error', 'unable to cancel Delivery Order');
            });
        }
      });
  }
  //to add customer
  onAddCustomerClick() {
    this.dialog.open(AddEditCustomersMasterComponent, {
      width: '800px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var ledgerData = result.data;
          this.ledgerControl.setValue(ledgerData);
          this.form.get('ledger_id').setValue(ledgerData.ledger_id);
          this.form.get('billing_address1').setValue(ledgerData.address_address1);
          this.form.get('billing_address2').setValue(ledgerData.address_address2);
          this.form.get('billing_street').setValue(ledgerData.address_street);
          this.form.get('billing_city').setValue(ledgerData.address_city);
          this.form.get('billing_state').setValue(ledgerData.address_state);
          this.form.get('billing_country').setValue(ledgerData.address_country);
          this.form.get('billing_pin').setValue(ledgerData.address_pin);
          this.apiService.getLedgerClosingBalance(ledgerData.ledger_id).subscribe(
            (result:any)=>{
              if(result.success){
                this.closing_balance=result.message.closing_balance+' '+result.message.cbdrcr;
               
              }
              else{
                this.notifier.notify('error', result.message);
              }
            },
            (err:any)=>{
      
            }
          )
      
        }
      });
  }
  //on focus on ledger search
  onFocus(e) {
    this.l_id = this.form.value.ledger_id;
    
    if(!this.l_id){
      this.closing_balance=null;
    }
    this.apiService.getSundryCrLedgerList(undefined, "false")
      .subscribe((result: any) => {
        this.ledgerObj = result;
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }, (result: any) => {
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  companyState:any;
  transDataObj2: any = { items_details: [] };


  convertToPurchase(){
    if(this.form.valid){
      this.transDataObj2 = this.form.value;
      this.transDataObj2.items_details = this.items;
      this.apiService.PoToPurchase(this.transDataObj2)
      .subscribe((result:any)=>{
        //console.log(result);
        if(result.success){
          this.notifier.notify('success', result.message);
          this.dialogRef.close('Edited');
        }else{
          this.notifier.notify('error', result.message);
        }
      });
    }

  }
  StateonFocusShipState(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        // console.log(this.stateName);
      });
  }

 searchStateShipState(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.shipping_state.value.length >= 1) {
        this.apiService.sgetGstState(this.shipping_state.value, 'false')
          .subscribe((result: any) => {
            this.GState = result;
            this.stateName = this.GState;
          });
      }
    }
  }

StateSelectionShipState(event: MatAutocompleteSelectedEvent) {
    this.form.get('shipping_state').setValue(event.option.value.state_gst_code);
  }


displayStateFnShipState(state) {
    return state ? state.state_name : '';
  }
}
