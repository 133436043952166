<div class="">
  <div *ngIf="!showOrders" class="panelcard">
    <div class="row" style="margin: 0">
      <div class="col-md-12">
        <h3 class="color-h3">
          Dashboard
          <span
            ><button
              type="button"
              class="btn-primary btn-sm"
              style="float: right; font-weight: 500"
              (click)="changeShowOrders()"
            >
              Orders
            </button></span
          >
        </h3>
      </div>
    </div>
    <div class="table-details">
      <div class="row" *ngFor="let group of tableGroup">
        <div class="col-md-12">
          <span class="grp-name"> {{ group }}</span>
        </div>
        <ng-container *ngFor="let table of tableArr">
          <div class="col-md-2 p-1" *ngIf="table.group_name == group">
            <div
              class="table-sec alert"
              [ngClass]="[
                table.kot_count != 0 ? 'alert-pink pt-2' : 'white-space pt-2',
                !table.display_trans_no ? 'white-space pt-2' : 'alert-success '
              ]"
              (click)="gotoInvoice(table)"
            >
              <div
                style="float: right; color: rgb(69, 8, 209)"
                *ngIf="table.webactive == true && table.webactive != undefind"
              >
                <img
                  src="assets/images/customerapp.png"
                  class="blinking-icon"
                />
              </div>
              <div
                style="float: right; margin-right: 10px"
                *ngIf="table.waiter == true && table.waiter != undefind"
              >
                <img
                  src="assets/images/waiter2.png"
                  alt="Water Icon"
                  class="blinking-icon"
                />
              </div>
              <div
                style="float: right; margin-right: 10px"
                *ngIf="table.water == true && table.water != undefind"
              >
                <img
                  src="assets/images/water.png"
                  alt="Water Icon"
                  class="blinking-icon"
                />
              </div>
              <h6>{{ table.name }}</h6>
              <div *ngIf="table.kot_count > 0">
                <span>KOT : {{ table.kot_count }}</span
                ><br />
                <span
                  ><i class="fa fa-{{ currency }}"></i
                  >{{ table.kot_amount | number : "1.2-2" }}</span
                >
              </div>
              <!-- <div *ngIf="table.display_trans_no">
                <span>{{table.display_trans_no}}</span><br>
                <span><i class="fa fa-{{currency}}"></i>{{table.kot_amount | number : '1.2-2'}}</span>
              </div> -->
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="showOrders" class="panelcard">
    <div class="">
      <div class="col-md-12">
        <h3 class="color-h3">
          Orders
          <span
            ><button
              type="button"
              class="btn-primary btn-sm"
              style="float: right; font-weight: 500"
              (click)="changeShowOrders()"
            >
              Dashboard
            </button></span
          >
        </h3>
      </div>
    </div>
    <div class="table-details">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-25">
            <mat-select
              placeholder="Order Type:"
              [(ngModel)]="typeValue"
              name="typeValue"
              (selectionChange)="onOrderTypeChange($event, orderTypes)"
            >
              <mat-option
                *ngFor="let order of orderTypes"
                [value]="order.value"
              >
                {{ order.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <form [formGroup]='pendingOrders'> -->
        <div class="col-md-3 col-sm-6 col-xs-12" *ngFor="let kot of kotArr">
          <div class="kot-sec">
            <mat-card>
              <mat-card-content>
                <div class="w-100 not-head">
                  <span>#KOT: {{ kot.trans_no }}</span>
                  <span class="pull-right">Table: {{ kot.name }}</span>
                </div>
                <mat-list role="list">
                  <img
                    style="
                      max-width: 30px;
                      border-radius: 12px;
                      margin: 5px 5px;
                    "
                    *ngIf="
                      kot.order_mode.toLowerCase() === 'swiggy' ||
                      kot.order_mode.toLowerCase() === 'zomato'
                    "
                    src="assets/images/{{ kot.order_mode }}.png"
                    alt=""
                    class="pull-right"
                  />
                  <mat-list-item
                    *ngFor="let item of kot.items_details"
                    role="listitem"
                    class="ml-3"
                  >
                    <div class="w-100">
                      <span>{{ item.item_name }}</span>
                      <span class="pull-right pr-4">{{
                        convertFloat(item.qty)
                      }}</span>
                    </div>
                  </mat-list-item>
                </mat-list>
                <!-- <div *ngIf="kot.order_mode && kot.approved" class="d-flex justify-content-between">
                  <button (click)="callRider()" class="btn btn-success btn-sm m-1">Call rider</button>
                  <ul *ngIf="riderStatus" class="rider-list">
                    <li>{{riderStatus.rider_name}}</li>
                    <li>{{riderStatus.rider_number}}</li>
                    <li>{{riderStatus.rider_status}}</li>
                  </ul>
                </div> -->
                <div
                  *ngIf="kot.approved && kot.processing && !kot.finished"
                  class="form-group"
                  style="padding: 5px; text-align: center"
                  [style.background-color]="kot.backgroundColor"
                >
                  <label for="processingTime{{ i }}">Processing Time</label>
                  <input
                    type="text"
                    id="processingTime{{ i }}"
                    [(ngModel)]="
                      kot.processing_time
                        ? kot.processing_time
                        : kot.venders_order_time
                    "
                    class="custom-timer"
                  />
                  <div *ngIf="timer > 0">{{ timer | date : "mm:ss" }}</div>
                </div>

                <div
                  class="form-group"
                  style="padding: 5px; text-align: center"
                  *ngIf="kot.approved && !kot.processing"
                >
                  <label for="processingTimeinput">Cooking Time</label>
                  <div class="custom-time-picker" style="position: relative">
                    <input
                      type="text"
                      id="processingTimeinput"
                      [(ngModel)]="processingTimeinput"
                      class="custom-timer"
                      (click)="toggleTimePicker(kot.transaction_id)"
                      (input)="onTimeInputChange($event)"
                    />
                    <ul
                      *ngIf="timePickers[kot.transaction_id]"
                      class="time-picker-dropdown"
                      style="
                        width: 100%;
                        overflow: hidden;
                        background: white;
                        border: 1px solid #efefef;
                        padding: 0;
                        list-style: none;
                      "
                    >
                      <li
                        *ngFor="let time of times"
                        (click)="selectTime(time, kot.transaction_id)"
                        style="cursor: pointer"
                        class="set-wait-time"
                      >
                        {{ time }}
                      </li>
                    </ul>
                  </div>
                </div>
              </mat-card-content>
              <mat-card-actions>
                <button
                  *ngIf="!kot.approved && !kot.food_cancel && !kot.auto_accept"
                  type="button"
                  class="btn btn-primary btn-sm not-btn"
                  (click)="onApproveClick($event, kot)"
                >
                  Approve
                </button>
                <button
                  *ngIf="!kot.approved && !kot.food_cancel && !kot.auto_accept"
                  type="button"
                  class="btn btn-danger btn-sm not-btn"
                  (click)="onRejectClick($event, kot)"
                >
                  Reject
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm full-btn"
                  (click)="cancelledOrder($event, kot)"
                  *ngIf="kot.food_cancel && !kot.auto_accept"
                >
                  Order Cancelled
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-sm full-btn"
                  (click)="orderAutoAccepted($event, kot)"
                  *ngIf="kot.auto_accept"
                >
                  Auto Accepted
                </button>
                <button
                  *ngIf="kot.approved && !kot.processing"
                  type="button"
                  class="btn btn-info btn-sm full-btn"
                  (click)="onProcessingClick(kot.transaction_id)"
                >
                  Start Processing
                </button>
                <button
                  *ngIf="
                    kot.approved &&
                    kot.processing &&
                    !kot.food_ready &&
                    (kot.order_mode.toLowerCase() === 'swiggy' ||
                      kot.order_mode.toLowerCase() === 'zomato')
                  "
                  type="button"
                  class="btn btn-success btn-sm full-btn"
                  (click)="foodReady(kot)"
                  style="max-width: 200px"
                >
                  Food Ready</button
                ><button
                  *ngIf="
                    kot.approved &&
                    kot.processing &&
                    !kot.food_ready &&
                    (kot.order_mode.toLowerCase() === 'swiggy' ||
                      kot.order_mode.toLowerCase() === 'zomato')
                  "
                  type="button"
                  class="btn btn-success btn-sm full-btn"
                  (click)="callRider(kot)"
                  style="max-width: 68px"
                >
                  Rider
                </button>
                <button
                  *ngIf="
                    kot.approved &&
                    kot.processing &&
                    !kot.finished &&
                    kot.order_mode.toLowerCase() !== 'swiggy' &&
                    kot.order_mode.toLowerCase() !== 'zomato'
                  "
                  type="button"
                  class="btn btn-success btn-sm full-btn"
                  (click)="onDeleveredClick(kot.transaction_id)"
                >
                  Mark Delivered
                </button>
                <button
                  *ngIf="
                    kot.approved &&
                    kot.processing &&
                    kot.food_ready &&
                    !kot.finished &&
                    (kot.order_mode.toLowerCase() === 'swiggy' ||
                      kot.order_mode.toLowerCase() === 'zomato')
                  "
                  type="button"
                  class="btn btn-success btn-sm full-btn"
                  (click)="onMarkPickUp(kot)"
                >
                  Mark Order PickedUp
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
  <!-- <button (click)="TenentNavComponent()">Book Table</button> -->
</div>
