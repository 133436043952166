import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AddLedgerComponent } from '../../ledgers/add-ledger/add-ledger.component';
import { environment } from '../../../environments/environment';
import {MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter  } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../date.adapter';
@Component({
  selector: 'app-edit-journal',
  templateUrl: './edit-journal.component.html',
  styleUrls: ['./edit-journal.component.css'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class EditJournalComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private dialogRef: MatDialogRef<EditJournalComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currency = environment.currency;
  }

  @ViewChild('f') f;
  currency: any;
  disableInput: any = true;
  ledgerControl = new FormControl();
  ledger2Control = new FormControl();

  options: any;
  filteredOptions: Observable<string[]>;
  l_id1: any;
  closing_balance1: any;
  ledger_id1: any;
  l_id2: any;
  closing_balance2: any;
  ledger_id2: any;

  ngOnInit() {
    this.load();
  }
  filter(value) {
    const filterValue = value.toLowerCase();
    return this.ledgerObj.filter(ledger => ledger.ledger_code.toLowerCase().includes(filterValue) || ledger.ledger_name.toLowerCase().includes(filterValue));
  }
  filter2(value) {
    const filterValue = value.toLowerCase();
    return this.ledgerObj2.filter(ledger => ledger.ledger_code.toLowerCase().includes(filterValue) || ledger.ledger_name.toLowerCase().includes(filterValue));
  }
  displayFn(ledger) {
    return ledger ? ledger.ledger_name : "";
  }
  model: any = {};
  ledgerObj: any;
  ledgerObj2: any;
  load() {
    if (this.data.payment_mode == "Bank")
      this.disableInput = false;
    this.f.submitted = true;
    this.apiService.getJournal(this.data.transaction_id)
      .subscribe((result: any) => {
        this.model = result;
        this.apiService.getLedger(this.model.ledger_id)
          .subscribe((result: any) => {
            this.ledgerObj = result[0];

            this.ledgerControl.setValue(this.ledgerObj);
            this.apiService.getLedgerClosingBalance(this.model.ledger_id).subscribe(
              (result:any)=>{
                if(result.success){
                  this.closing_balance1=result.message.closing_balance+' '+result.message.cbdrcr;
                 
                }
                else{
                  this.notifier.notify('error', result.message);
                }
              },
              (err:any)=>{
        
              }
            )
          }, (result: any) => {
          });

        this.apiService.getLedger(this.model.ledger_id2)
          .subscribe((result: any) => {
            this.ledgerObj2 = result[0];
            this.ledger2Control.setValue(this.ledgerObj2);
            this.apiService.getLedgerClosingBalance(this.model.ledger_id2).subscribe(
              (result:any)=>{
                if(result.success){
                  this.closing_balance2=result.message.closing_balance+' '+result.message.cbdrcr;
                 
                }
                else{
                  this.notifier.notify('error', result.message);
                }
              },
              (err:any)=>{
        
              }
            )
      
          }, (result: any) => {
          });

      }, (result: any) => {
      });
     

    
  }
  //searching for ledger
  search(event) {
    this.closing_balance1=null;

    if (event.key != "ArrowDown" && event.key != "ArrowUp" && event.key != "Enter") {
      if (this.ledgerControl.value.length > 1) {
        this.apiService.getFilterLedgerList(this.ledgerControl.value)
          .subscribe((result: any) => {
            this.ledgerObj = result;
            this.filteredOptions = this.ledgerControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.ledgerObj = [];
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }
    }
  }
  search2(event) {
    this.closing_balance2=null;

    if (event.key != "ArrowDown" && event.key != "ArrowUp" && event.key != "Enter") {
      if (this.ledger2Control.value.length > 1) {
        this.apiService.getFilterLedgerList(this.ledger2Control.value)
          .subscribe((result: any) => {
            this.ledgerObj2 = result;
            this.filteredOptions = this.ledger2Control.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filter2(value))
              );
          }, (result: any) => {
          });
      } else {
        this.ledgerObj2 = [];
        this.filteredOptions = this.ledger2Control.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter2(value))
          );
      }
    }
  }
  ledgerSelection(event: MatAutocompleteSelectedEvent) {
    this.model.ledger_id = event.option.value ? event.option.value.ledger_id : undefined;
    this.ledgerObj = [];
    this.filteredOptions = this.ledgerControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
      this.apiService.getLedgerClosingBalance(event.option.value.ledger_id ).subscribe(
        (result:any)=>{
          if(result.success){
            this.closing_balance1=result.message.closing_balance+' '+result.message.cbdrcr;
           
          }
          else{
            this.notifier.notify('error', result.message);
          }
        },
        (err:any)=>{
  
        }
      )
  }
  ledger2Selection(event: MatAutocompleteSelectedEvent) {
    this.model.ledger_id2 = event.option.value ? event.option.value.ledger_id : undefined;
    this.ledgerObj2 = [];
    this.filteredOptions = this.ledger2Control.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
      this.apiService.getLedgerClosingBalance(event.option.value.ledger_id).subscribe(
        (result:any)=>{
          if(result.success){
            this.closing_balance2=result.message.closing_balance+' '+result.message.cbdrcr;
           
          }
          else{
            this.notifier.notify('error', result.message);
          }
        },
        (err:any)=>{
  
        }
      )
  }
  enableInp() {
    this.disableInput = false;
  }
  disableInp() {
    this.disableInput = true;
  }

  onSubmit() {
    
    if (this.f.valid) {
      this.model.upi=this.model.cash=this.model.card=0;
      this.model.payment_mode == 'UPI' ? this.model.upi = this.model.total_amount : this.model.upi = 0;
      this.model.payment_mode == 'Cash' ? this.model.cash = this.model.total_amount : this.model.cash = 0;
      this.model.payment_mode == 'Bank' ? this.model.card = this.model.total_amount : this.model.card = 0;
      this.apiService.editJournal(this.model.transaction_id, this.model)
        .subscribe((result: any) => {
          if (result.success) {
            this.downloadClick();
            this.notifier.notify('success', 'Journal Details Updated successfully');
          } else {
            this.notifier.notify('error', result.message);
          }
          this.dialogRef.close('Edited');
        }, (result: any) => {
          this.notifier.notify('error', 'unable to update Journal Details');
        });
    }
  }

  downloadClick() {
    this.model.trans_type = "Journal";
    this.apiService.downloadPdf(this.model)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'journal' + this.model.trans_no + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
  cancelInvoice() {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Cancel the Journal Voucher Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteJournal(this.data.transaction_id)
            .subscribe((result: any) => {
              this.dialogRef.close('Edited');
              this.notifier.notify('success', 'Journal Canceled successfully');
            }, (result: any) => {
              this.notifier.notify('error', 'unable to cancel Journal');
            });
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  //to add Ledger
  onAddLedger1Click() {
    this.dialog.open(AddLedgerComponent, {
      width: '800px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var ledgerData = result.data;
          this.ledgerControl.setValue(ledgerData);
          this.model.ledger_id = ledgerData.ledger_id;
          this.apiService.getLedgerClosingBalance(ledgerData.ledger_id).subscribe(
            (result:any)=>{
              if(result.success){
                this.closing_balance1=result.message.closing_balance+' '+result.message.cbdrcr;
               
              }
              else{
                this.notifier.notify('error', result.message);
              }
            },
            (err:any)=>{
      
            }
          )
        } else {
          this.ledgerControl.setValue(this.ledgerObj);
        }
      });
  }
  onAddLedger2Click() {
    this.dialog.open(AddLedgerComponent, {
      width: '800px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var ledgerData = result.data;
          this.ledger2Control.setValue(ledgerData);
          this.model.ledger_id2 = ledgerData.ledger_id;
          this.apiService.getLedgerClosingBalance(ledgerData.ledger_id).subscribe(
            (result:any)=>{
              if(result.success){
                this.closing_balance2=result.message.closing_balance+' '+result.message.cbdrcr;
               
              }
              else{
                this.notifier.notify('error', result.message);
              }
            },
            (err:any)=>{
      
            }
          )
        } else {
          this.ledger2Control.setValue(this.ledgerObj2);
        }
      });
  }
}
