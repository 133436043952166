import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { NotifierService } from 'angular-notifier';
import { CookieService } from 'ngx-cookie-service';
import { ElectronService } from 'ngx-electron';
import { DBbackupComponent } from '../db-backup/db-backup.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AddEditTenentComponent } from '../tenent-management/add-edit-tenent/add-edit-tenent.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-e-login',
  templateUrl: './e-login.component.html',
  styleUrls: ['./e-login.component.css']
})
export class ElectronLoginComponent implements OnInit {

  constructor(private router: Router,
    private apiService: DataService,
    private route: ActivatedRoute,
    private _electronService: ElectronService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    private cookieService: CookieService) { }
  model: any = {};
  loading: any = false;
  returnUrl: any;
  windowReference: any;
  companyArr: any
  fyArr: any;
  subdomain: any;

  ngOnInit() {
    this.getSubdomain();
  }
  load() {
    this.apiService.checkTenentDB()
      .subscribe((result: any) => {
        // console.log(result)
        if (result.success) {
          if (!result.data.length) {
            this.dialog.open(AddEditTenentComponent, {
              width: '650px',
            });
          }
        }
      }, (result: any) => {
        this.loading = false;
      });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.apiService.logout();

    this.apiService.getSubdomainDetails()
      .subscribe((result: any) => {
        this.companyArr = result
        if (this.companyArr.length == 1) {
          this.model.company_id = this.companyArr[0].company_id
          this.getFYlist(this.companyArr[0].company_id);
        }
      }, (result: any) => {
        this.loading = false;
      });
  }
  getSubdomain() {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      this.subdomain = '';
    } else {
      this.subdomain = domain.split('.')[0];
    }
    if (this.subdomain == 'accounts') {
      this.router.navigate(['/admin']);
    } else {
      this.load()
    }
  }

  onCompanySelection(e) {
    const company_id = e.value;
    this.getFYlist(company_id);
  }
  getFYlist(company_id) {
    this.apiService.getFyList(company_id)
      .subscribe((result: any) => {
        this.fyArr = result

        this.model.fy_id = this.fyArr[this.fyArr.length - 1].fy_id;

      }, (result: any) => {
        this.loading = false;
      });
  }
  login() {
    this.loading = true;
    const body = {
      'username': this.model.username, 'password': this.model.password, 'electron': true, 'company_id': this.model.company_id, 'fy_id': this.model.fy_id
    };
    this.apiService.login(body)
      .subscribe(async (result: any) => {
        this.loading = false;
        const parsedresult = (result.body.dataObj);
        if (!result.body.success) {
          this.notifier.notify('error', result.body.message);
        } else {
          if (this._electronService.isElectronApp) {

            this._electronService.ipcRenderer.send(
              'login',
              parsedresult.token
            );
          } else {
            await this.cookieService.set('SESSIONID', parsedresult.token, 10, '/', null, false, "Lax");
          }
          localStorage.setItem('is_trial', result.body.is_trial);
          localStorage.setItem('allow_multicompany', result.body.allow_multicompany);
          localStorage.setItem('userName', parsedresult.userName);
          localStorage.setItem('userFullName', parsedresult.userFullName);
          localStorage.setItem('emailId', parsedresult.emailId);
          localStorage.setItem('userRole', parsedresult.userRole);
          localStorage.setItem('role_id', parsedresult.role_id);
          localStorage.setItem('company_name', parsedresult.company_name);
          localStorage.setItem('user_id', parsedresult.user_id);
          localStorage.setItem('ver', result.body.app_version);
          localStorage.setItem('fy_id', parsedresult.fy_id);
          localStorage.setItem('company_id', parsedresult.company_id);
          localStorage.setItem('tenent_id', parsedresult.tenent_id);
          localStorage.setItem('isrst', result.body.isRST);
          localStorage.setItem('last_Backup', JSON.stringify(parsedresult.lastBackup));
          localStorage.setItem('help_Desk_Url', result.body.helpDeskUrl);

          // this.notifier.notify('success', this.cookieService.get('SESSIONID'));

          const observable = new Observable(observer => {
            console.log(this.cookieService.get('SESSIONID'))
            observer.next(this.cookieService.get('SESSIONID'));
          });
          observable.subscribe(value => this.get_last_db_backup());
          // if (!result.body.licence.SUCCESS && result.body.licence.LICENSEDATA) {
          //   this.router.navigate(['/update-licence']);
          // } else 

          if (result.body.isRST) {
            this.router.navigate(['/rst-dashboard']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      }, (result: any) => {
        this.loading = false;
        this.notifier.notify('error', 'Unable to connect with server');
      });
  }

  get_last_db_backup() {

    this.apiService.get_last_db_backup().subscribe((result: any) => {
      if (result.message) {
        let backup_date = result.message.split('T')[0].split('-');
        backup_date = backup_date[2] + '-' + backup_date[1] + '-' + backup_date[0];
        this.dialog.open(ConfirmDialogComponent, {
          data: "Your last database backup was on " + backup_date + ", do you want to take back up now ?"

        })
          .afterClosed().subscribe(result => {
            if (result == true) {
              this.onBackupClick();
            }
          });
      }
      else {
        if (!result.success) {
          if (result.message) {
            this.notifier.notify('error', result.message);
          }
        }
      }
    },
      (err: any) => {

      });
  }


  onBackupClick() {

    this.dialog.open(DBbackupComponent, {
      width: 'auto',
      height: 'auto'
    }).afterClosed().subscribe(result => {

      if (result) {
        this.windowReference = window.open(result, '_blank', 'toolbar=0,width=400,height=600,left=500,top=50');

      }
    });
  }
}
