<div class="dialog-title" mat-dialog-title>
  <h3 class="color-h3"><span>{{title}} <span><mat-icon class="close-icon" style="float: right;color: black;
        padding: 5px;" (click)="closeDialog()">close</mat-icon></span></span></h3>
</div>
<div class="table-details">
  <mat-dialog-content>
    <table class="table">
      <tr>
        <th *ngIf="isAdd">#</th>
        <th>Date</th>
        <th>Voucher No</th>
        <th>Total Items</th>
        <th>Amount</th>
      </tr>
      <tr *ngFor="let data of DataLists;let i=index;">
        <td *ngIf="isAdd"><input type="checkbox" checked (change)="onCheckboxClick($event, data.transaction_id)">
        </td>
        <td>{{data.trans_date | date: 'dd/MMM/yyyy'}}</td>
        <td>{{data.trans_no}}</td>
        <td>{{data.item_count}}</td>
        <td>{{data.net_amount | number : '1.2-2'}}</td>
      </tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="isAdd" align="end">
    <!-- <button type="button" class="mat-raised-button" (click)="closeDialog()">Close</button> -->
    <button mat-raised-button color="primary" type="button" (click)="onAddClick()">Add</button>
  </mat-dialog-actions>
</div>