import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { DataService } from "./data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "UI";
  mapArr: any;
  loading: boolean = false;
  constructor(private router: Router, private DataService: DataService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }
  theme: any = "light";
  ngOnInit(): void {
    let p = localStorage.getItem("theme");
    this.DataService.gettheme().subscribe((k: any) => {
      // console.log(k, "kkkkk");
      if (k == true) {
        this.theme = "dark";
      } else {
        this.theme = "light";
      }
    });
  }
}
