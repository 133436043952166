import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from "@angular/material";
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../data.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AddEditCompanyComponent } from './add-edit-company/add-edit-company.component';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.css']
})
export class CompanyManagementComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<CompanyManagementComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  companyArr: any;
  tenent_id: any;
  displayedColumns = ['company_name', 'actions'];

  ngOnInit() {
    this.tenent_id = localStorage.getItem('tenent_id');
    this.load();
  }

  load() {
    this.apiService.getCompanyList(this.tenent_id)
      .subscribe((result: any) => {
        this.companyArr = result;
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }

  deleteClick(row) {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Delete Selected Company Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteCompany(row.company_id)
            .subscribe((result: any) => {
              if (!result.success)
                this.notifier.notify('error', result.message);
              else
                this.notifier.notify('warning', 'category deleted successfully');
              this.load();
            }, (result: any) => {
              this.notifier.notify('error', 'unable to delete data');
            });
        }
      });
  }
  addClick() {
    this.dialog.open(AddEditCompanyComponent, {
      width: '650px',
    })
      .afterClosed().subscribe(result => {
        if (result == 'Added') {
          this.load();
        }
      });

  }
  editClick(row) {
    this.dialog.open(AddEditCompanyComponent, {
      width: '500px',
      data: row
    })
      .afterClosed().subscribe(result => {
        if (result == 'Edited') {
          this.load();
        }
      });
  }
  close() {
    this.dialogRef.close();
  }
}
