import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer} from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';

@Injectable()

export class CustomPreloadingStrategyService  implements PreloadingStrategy {
preload(route: Route, load: () => Observable<any>): Observable<any> {

       if(route.data && route.data['preload'])
         {
          console.log('Preload Path: ' + route.path);
           if(route.data['delay']){
               return timer(7000).mergeMap(()=>load())
           }
         return load();
         }else{
          //return of(null);
         return Observable.of(null);
          
        }
   }
}
