// import { Component, OnInit } from "@angular/core";
// import { DataService } from "../data.service";
// import { ExportService } from "../_export-service/daybook-export.service";
// import * as moment from "moment";
// import { MatTableDataSource } from "@angular/material/table";

// @Component({
//   selector: "app-category-sales-report",
//   templateUrl: "./category-sales-report.component.html",
//   styleUrls: ["./category-sales-report.component.css"],
// })
// export class CategorySalesReportComponent implements OnInit {
//   itemSalesArr: any = [];
//   selectedCategoryItems: any[] = [];
//   export_data: any;
//   selectedItemsDataSource: MatTableDataSource<any>;
//   selectedItemsColumns: string[] = ["quantity", "item_name", "amount"];
//   model: any = { from_date: new Date(), to_date: new Date() };

//   constructor(
//     private apiService: DataService,
//     private exportService: ExportService
//   ) {}

//   ngOnInit() {}

//   serachClick() {
//     this.apiService
//       .getItemSalesReport(this.model.from_date, this.model.to_date)
//       .subscribe(
//         (result: any) => {
//           // Group items by category and calculate total amount
//           const groupedItems = {};
//           result.forEach((item) => {
//             if (!groupedItems[item.category_name]) {
//               groupedItems[item.category_name] = {
//                 category_name: item.category_name,
//                 amount: 0,
//                 items: [],
//               };
//             }
//             groupedItems[item.category_name].amount += item.amount;
//             groupedItems[item.category_name].items.push(item);
//           });

//           this.export_data = JSON.parse(JSON.stringify(result));
//           // Convert grouped items to an array
//           this.itemSalesArr = Object.values(groupedItems);
//           console.log("result1---", this.itemSalesArr);
//         },
//         (error: any) => {
//           console.error("Error fetching item sales report:", error);
//         }
//       );
//   }

//   ExportTOExcel() {
//     var dataArr = [];
//     this.export_data.forEach((d) => {
//       var rowValues = [];
//       rowValues = [];
//       rowValues[1] = d.qty;
//       rowValues[2] = d.item_name;
//       rowValues[3] = d.amount;
//       dataArr.push(rowValues);
//     });
//     var columnWidth = [
//       { column: 1, width: 16 },
//       { column: 2, width: 30 },
//       { column: 3, width: 16 },
//     ];
//     var numFmt = [{ column: 1, numFmt: "dd/mm/yyyy" }];
//     var frm_date = moment(this.model.from_date).format("DD/MM/YYYY");
//     var til_date = moment(this.model.to_date).format("DD/MM/YYYY");
//     const file_name = "Items Sales Report";
//     const title = "Items Sales Report";
//     const subheader = " Items Sales Report: " + frm_date + " To: " + til_date;
//     const header = ["Quantity", "Name", "Amount"];
//     this.exportService.generateExcel(
//       file_name,
//       title,
//       header,
//       subheader,
//       dataArr,
//       columnWidth,
//       numFmt
//     );
//   }

//   calculateTotal() {
//     return this.itemSalesArr.reduce(
//       (total, category) => total + category.amount,
//       0
//     );
//   }

//   onShow(category: any) {
//     this.selectedCategoryItems = category.items;
//     console.log("this.selectedCategoryItems", this.selectedCategoryItems);
//     this.selectedItemsDataSource = new MatTableDataSource(
//       this.selectedCategoryItems
//     );
//     console.log("this.selectedItemsDataSource", this.selectedItemsDataSource);
//   }
// }

import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { ExportService } from "../_export-service/daybook-export.service";
import * as moment from "moment";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-category-sales-report",
  templateUrl: "./category-sales-report.component.html",
  styleUrls: ["./category-sales-report.component.css"],
})
export class CategorySalesReportComponent implements OnInit {
  itemSalesArr: any = [];
  selectedCategoryItems: any[] = [];
  export_data: any;
  selectedItemsDataSource: MatTableDataSource<any>;
  selectedItemsColumns: string[] = ["quantity", "item_name", "amount"];
  model: any = { from_date: new Date(), to_date: new Date() };

  constructor(
    private apiService: DataService,
    private exportService: ExportService
  ) {
    this.selectedItemsDataSource = new MatTableDataSource([]);
  }

  ngOnInit() {}

  serachClick() {
    this.apiService
      .getItemSalesReport(this.model.from_date, this.model.to_date)
      .subscribe(
        (result: any) => {
          // Group items by category and calculate total amount
          const groupedItems = {};
          result.forEach((item) => {
            if (!groupedItems[item.category_name]) {
              groupedItems[item.category_name] = {
                category_name: item.category_name,
                amount: 0,
                items: [],
              };
            }
            groupedItems[item.category_name].amount += item.amount;
            groupedItems[item.category_name].items.push(item);
          });

          this.export_data = JSON.parse(JSON.stringify(result));
          // Convert grouped items to an array
          this.itemSalesArr = Object.values(groupedItems);
          console.log("result1---", this.itemSalesArr);
        },
        (error: any) => {
          console.error("Error fetching item sales report:", error);
        }
      );
  }

  ExportTOExcel() {
    // Export functionality as before
  }

  calculateTotal() {
    return this.itemSalesArr.reduce(
      (total, category) => total + category.amount,
      0
    );
  }

  onShow(category: any) {
    this.selectedCategoryItems = category.items;
    console.log("this.selectedCategoryItems", this.selectedCategoryItems);
    this.selectedItemsDataSource.data = this.selectedCategoryItems;
    console.log("this.selectedItemsDataSource", this.selectedItemsDataSource);
  }
}
