import { AddEditItemsComponent } from "./items/items-price-list/add-edit-items/add-edit-items.component";
import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppNavComponent } from "./app-nav/app-nav.component";

import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";

import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { DashboardComponent } from "./dashboard/dashboard.component";

import { TenentManagementComponent } from "./tenent-management/tenent-management.component";
import { TenentNavComponent } from "./tenent-nav/tenent-nav.component";
import { TenentLoginComponent } from "./tenent-login/tenent-login.component";
import { TenentLogoutComponent } from "./tenent-logout/tenent-logout.component";
import { ElectronLoginComponent } from "./electron-login/e-login.component";
import { ElectronLogoutComponent } from "./electron-logout/e-logout.component";
import { LicenceInfoComponent } from "./licence-info/licence-info.component";
// import { ElectronService } from 'ngx-electron';
import { UpdateLicenceComponent } from "./update-licence/update-licence.component";
import { CustomPreloadingStrategyService } from "./custom-preloading-strategy.service";
import { RedirectComponent } from "./redirect/redirect.component";
import { RstDashboardComponent } from "./rst-dashboard/rst-dashboard.component";
import { RstInvoiceComponent } from "./rst-dashboard/rst-invoice/rst-invoice.component";
// let eService: ElectronService = new ElectronService();
import { AuthGuard } from "./guards/auth.guard";
import { AddEditPriceListComponent } from "./items/items-price-list/add-edit-price-list/add-edit-price-list.component";
import { ItemsVariantComponent } from "./items/items-price-list/add-edit-items/items-variant/items-variant.component";
import { ExpenseComponent } from "./expense/expense.component";
import { DiscountReportComponent } from "./discount-report/discount-report.component";
import { KotCancellationsComponent } from "./kot-cancellations/kot-cancellations.component";
import { BilCancellationComponent } from "./bil-cancellation/bil-cancellation.component";
import { OustandingReportComponent } from "./oustanding-report/oustanding-report.component";
const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "admin", redirectTo: "/tenent-login", pathMatch: "full" },

  {
    path: "login",
    component: ElectronLoginComponent,
    // component: eService.isElectronApp?ElectronLoginComponent:LoginComponent
  },
  {
    path: "logout",
    component: ElectronLogoutComponent,
    // component: eService.isElectronApp?ElectronLogoutComponent:LogoutComponent
  },
  {
    path: "tenent-login",
    component: TenentLoginComponent,
  },
  {
    path: "tenent-logout",
    component: TenentLogoutComponent,
  },
  {
    path: "update-licence",
    component: UpdateLicenceComponent,
  },
  {
    path: "redirect",
    component: AppNavComponent,
  },
  {
    path: "redirecturl",
    component: RedirectComponent,
  },
  {
    path: "admin",
    component: TenentNavComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "tenent-management",
        component: TenentManagementComponent,
      },
    ],
  },
  {
    path: "",
    component: AppNavComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "Entry",
        loadChildren:
          "./_modules/entry-modules/entry-modules.module#EntryModulesModule",
        data: { preload: true, delay: true },
      },
      {
        path: "Transaction",
        loadChildren:
          "./_modules/transaction-modules/transaction-modules.module#TransactionModulesModule",
        data: { preload: false, delay: false },
      },
      {
        path: "Master",
        loadChildren:
          "./_modules/master-modules/master-modules.module#MasterModulesModule",
        data: { preload: false, delay: false },
      },
      {
        path: "Report",
        loadChildren:
          "./_modules/reports-modules/reports-modules.module#ReportsModulesModule",
        data: { preload: true, delay: true },
      },
      {
        path: "Setting",
        loadChildren:
          "./_modules/setting-modules/setting-modules.module#SettingModulesModule",
        data: { preload: false, delay: false },
      },
      {
        path: "access-denied",
        component: AccessDeniedComponent,
      },
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "rst-dashboard",
        component: RstDashboardComponent,
      },
      {
        path: "rst-invoice/:id",
        component: RstInvoiceComponent,
      },
      {
        path: "Master/add-item-editPrice",
        component: AddEditPriceListComponent,
      },
      {
        path: "Master/add-item-editPrice/:id",
        component: AddEditPriceListComponent,
      },
      {
        path: "Report/expense-report",
        component: ExpenseComponent,
      },
      {
        path: "Report/discount-report",
        component: DiscountReportComponent,
      },
      {
        path: "Report/kot-cancel-report",
        component: KotCancellationsComponent,
      },
      {
        path: "Report/bill-cancel-report",
        component: BilCancellationComponent,
      },

      {
        path: "Report/outstanding-report",
        component: OustandingReportComponent,
      },
    ],
  },
  { path: "**", redirectTo: "access-denied" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategyService,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
