import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { ReceiptDialogComponent } from '../receipt-dialog/receipt-dialog.component';
import { DataService } from 'src/app/data.service';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.css']
})
export class BookingFormComponent implements OnInit {

  reservationForm: FormGroup;
  reservtionData: any;
  tables: any = [];

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ReceiptDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any = [], private notifier: NotifierService, private apiService: DataService, private datePipe: DatePipe, public dialog: MatDialog) {
    this.createForm();
    this.reservtionData = data
  }

  ngOnInit() {
    this.load()
    this.patchData()
  }
  load() {
    this.apiService.getTablesWithGroup().subscribe((result) => {
      this.tables = result
      // console.log("tablesresult-----", this.tables)
    }, (err: any) => {
      console.log("err getting table-", err)
    })
  }
  patchData() {
    // console.log("data--------", this.reservtionData)
    if (this.reservtionData) {
      const rDate = this.datePipe.transform(this.reservtionData.date, 'yyyy-MM-dd')
      var reservationData = {
        date: rDate, // Replace with your actual data
        time: this.reservtionData.time,
        name: this.reservtionData.name,
        numberOfPersons: this.reservtionData.person,
        mobileNumber: this.reservtionData.contact_no,
        email: this.reservtionData.email_id,
        waiterAssigned: this.reservtionData.waiter_assigned,
        comments: this.reservtionData.comment,
        table: this.reservtionData.assigned_table,
        sendEmail: false,
        sendSMS: false,
        status: this.reservtionData.approved,
        isAllotted: this.reservtionData.is_allotted
      }

      // Patch the data to the form
      this.reservationForm.patchValue(reservationData);
    };
  }
  createForm() {
    this.reservationForm = this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      name: ['', Validators.required],
      numberOfPersons: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      waiterAssigned: [''],
      comments: [''],
      table: [''],
      sendEmail: false,
      sendSMS: false,
      status: ['Confirmed'],
      isAllotted: false,
    });
  }
  initializeFormGroup() {
    this.reservationForm.setValue({
      date: [''],
      time: [''],
      name: [''],
      numberOfPersons: [''],
      mobileNumber: [''],
      email: [''],
      waiterAssigned: [''],
      comments: [''],
      sendEmail: false,
      sendSMS: false,
      status: ['Confirmed']
    });
  }

  async onSubmit() {
    // console.log('Form submitted:', this.reservationForm.value);
    const table_id = this.tables.filter(e => {
      // console.log("e.name == this.reservationForm.value.table", e.name, this.reservationForm.value.table)
      if (e.name == this.reservationForm.value.table) {
        return e
      }
    })
    // console.log("table_id----", table_id, "-------", table_id[0].id)
    this.reservationForm.value.table_id = table_id[0].id
    // console.log('Form submitted:', this.reservationForm.value);
    if (this.reservationForm.value.table) {
      // Add your logic for handling the form data here
      if (this.reservationForm.value.isAllotted == true) {
        const data = {
          title: 'Allotted Confirmation',
          message: 'Are you sure you want to perform this action?',
          action: 'allotted'
        }
        this.openConfirmationDialog(data);

      } else {
        if (this.reservtionData) {
          this.apiService.updateReservationTable(this.reservtionData.id, this.reservationForm.value).subscribe((result: any) => {
            if (result.success) {
              // console.log("result", result);
              this.notifier.notify('success', 'Reservation Form Submitted Successfully');
              this.reservationForm.reset();
              this.dialogRef.close({ status: 'OK', message: 'Reservation Form Submitted Successfully' });
            } else {
              this.notifier.notify('error', result.message);
            }

          })
        } else {
          this.apiService.submitReservationTable(this.reservationForm.value).subscribe((result: any) => {
            // console.log("result", result);
            if (result.success) {
              this.notifier.notify('success', 'Reservation Form Submitted Successfully');
              this.reservationForm.reset();
              this.dialogRef.close({ status: 'OK', message: 'Reservation Form Submitted Successfully' });
            } else {
              this.notifier.notify('error', result.message);
            }
          })
        }
      }
    } else {
      this.notifier.notify('error', "Please Assign Table");
    }
  }

  onReset() {
    this.reservationForm.reset();
  }

  onCancel() {
    this.dialogRef.close();
  }

  openConfirmationDialog(data) {
    var confirmationResult;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: data.title,
        message: data.message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      confirmationResult = result
      // console.log("cdiresult-------", result)
      if (result) {
        // User clicked "OK"
        console.log('User clicked OK');
        if (data.action == 'allotted') {
          this.apiService.updateReservationTable(this.data.id, this.reservationForm.value).subscribe((result: any) => {
            if (result.success) {
              // console.log("result", result);
              this.notifier.notify('success', 'Table Allotted');
              this.reservationForm.reset();
              this.dialogRef.close({ status: 'OK', message: 'Table Allotted' });
            } else {
              this.notifier.notify('error', result.message);
            }

          })
        } if (data.action == 'rejected') {
          const code = this.reservtionData.code
          this.apiService.deleteReservedTablesDetails(code).subscribe(
            (result: any) => {
              if (result.success) {
                this.dialogRef.close({ status: 'OK', message: 'Table Rejected' });
              }
            },
            (result: any) => { }
          );

        } else {

        }
        // return result
      } else {
        // User clicked "Cancel" or closed the dialog
        this.notifier.notify('error', 'Action Denied');
        console.log('User clicked Cancel or closed the dialog');
        // return result
      }
    });
    return confirmationResult
  }

  onRejectClick() {
    const data = {
      title: 'Rejection Confirmation',
      message: 'Are you sure you want to reject this form?',
      action: 'rejected'
    }
    this.openConfirmationDialog(data);
    // console.log("data ----------", this.reservtionData)

  }
}
