import {
  ViewChild,
  Inject,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { FormControl } from "@angular/forms";
import { take } from "rxjs/operators";
import { of } from "rxjs";
import { MatTabsModule } from "@angular/material/tabs";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataService } from "../../../data.service";
import { environment } from "../../../../environments/environment";

import { NotifierService } from "angular-notifier";
import { map, startWith, switchMap } from "rxjs/operators";
import { ImortPriceListItemComponent } from "src/app/items/items-price-list/add-edit-price-list/imort-price-list-item/imort-price-list-item.component";
import { Router, ActivatedRoute } from "@angular/router";
import { element } from "protractor";
import { AddEditItemsComponent } from "../add-edit-items/add-edit-items.component";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { SocketServiceService } from "src/app/socket-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { EditItemVariantComponent } from "../add-edit-items/items-variant-list/edit-item-variant/edit-item-variant.component";
@Component({
  selector: "app-add-edit-price-list",
  templateUrl: "./add-edit-price-list.component.html",
  styleUrls: ["./add-edit-price-list.component.css"],
})
export class AddEditPriceListComponent implements OnInit {
  item_id: any[] = [];
  itemdataArr: any[] = [];
  filteredItems: any[] = [];
  applyFilter: boolean = false;
  selectedTab: any = 0;
  isImported: boolean = false;

  model: any = {
    table_groupId: [],
    name: [],
    Dine_in: false,
    Web_App: false,
    Web_site: false,
    active: false,
  }; // Object to hold form data
  Dine_in: boolean = false; // Dine-in checkbox value
  Web_App: boolean = false; // Web App checkbox value
  Web_site: boolean = false; // Web Site checkbox value
  tableGroupList: any[] = []; // Table group list data
  itemObj: MatTableDataSource<any>;
  searchText: any;
  activeType: any = false;
  width: any = window.innerWidth;
  file: any;
  resultData: any;
  prceListId: any;
  filteredDataArr: any[] = [];
  filteredVarAr: any[] = [];
  tablegroupObj: any = [];
  filterdataId: any = [];
  checkEnableResturantFlag: boolean;
  displayedColumns: any[] = [
    "id",
    "item_id",
    "category_name",
    "item_name",
    "default_rate",
    "s_rate",
    "gst",
    "actions",
  ];
  variant_id: any;
  itemIdVariant: any[] = [];
  itemVarArr: any[] = [];
  itemVarAr: any[] = [];
  displayedColumnsV: any[] = [
    "id",
    "item_id",
    "itemanme",
    "variant_name",
    "default_rate",
    "s_rate",
    "actions",
  ];

  tableGroupForm: FormGroup;
  connection: any;
  constructor(
    public dialog: MatDialog,
    private apiService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private socketService: SocketServiceService
  ) {
    this.route.params.subscribe((params) => {
      this.prceListId = params.id;
      // Access route parameters here
      // const id = params['id'];
      // console.log("ID:", params.id);
    });
  }

  ngOnInit() {
    this.connectSocket();
    this.load();
  }
  connectSocket() {
    this.connection = this.socketService
      .on("priceList")
      .subscribe((result: any) => {
        console.log("socket called on pricelist");
        this.load();
      });
    this.socketService.on("reconnect").subscribe((result: any) => {
      this.load();
    });
  }
  ngOnDestroy() {
    this.connection.unsubscribe();
  }
  importPricelistClick() {
    this.dialog.open(
      ImortPriceListItemComponent,
      {
        width: "800px",
        data: { pricelist_id: this.prceListId },
      }
      // .afterClosed()
      // .subscribe((result) => {
      //   if (result == "Imported") {
      //     this.load();
      //   }
      // })
    );
  }

  // addClick() {
  //   this.dialog.open(AddEditItemsComponent, {
  //     width: "500px",
  //     data: { priceList_id: this.prceListId },
  //   });
  // }

  addClick() {
    this.dialog
      .open(AddEditItemsComponent, {
        width: "500px",
        data: {
          status: "add",
          priceList_id: this.prceListId,
        }, // Pass data to the dialog
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.status == "add" || result.status == "update") {
          this.load();
        }
      });
  }



  onSubmit() {
    const selectedTableGrp = this.tableGroupForm.get("tableGroupNames").value;
    this.model.table_groups = selectedTableGrp;
    if (
      (this.model.table_groups === undefined || this.model.table_groups === null || this.model.table_groups.length === 0) ||
      (this.model.name === undefined || this.model.name === null || this.model.name.trim() === "")
    ) {
      this.notifier.notify("error", "Table groups or name cannot be empty");
    } else {
      this.model.Dine_in = this.model.Dine_in || false;
      this.model.Web_App = this.model.Web_App || false;
      this.model.Web_site = this.model.Web_site || false;
  
      if (this.prceListId) {
        this.apiService.updateNewEditPriceList(this.prceListId, this.model)
          .subscribe(
            (response: any) => {
              if (response.success) {
                this.notifier.notify("success", "Pricelist Updated Successfully");
                this.load();
              } else {
                this.notifier.notify("error", response.message);
              }
            },
            (error) => {
              console.log(error);
              this.notifier.notify("error", "An error occurred while updating the price list");
            }
          );
      } else {
        this.apiService.addPriceList(this.model)
          .subscribe(
            (response: any) => {
              if (response.success) {
                this.notifier.notify("success", "Pricelist Added Successfully");
                this.prceListId = response.priceList_id;
                this.load();
              } else {
                this.notifier.notify("error", response.message);
              }
            },
            (error) => {
              console.log(error);
              this.notifier.notify("error", "An error occurred while adding the price list");
            }
          );
      }
    }
    
    // this.router.navigate(["Master/add-item-editPrice", row.id]);
  }
  

  load() {
    this.tableGroupForm = this.fb.group({
      tableGroupNames: new FormControl([]),
    });

    this.model = {};
    this.apiService.getTableGroupList().subscribe((response: any) => {
      // Check if response is an array

      this.tableGroupList = response;
    });
    if (this.prceListId) {
      this.apiService
        .getPricelistData(this.prceListId)
        .subscribe((response: any) => {
          if (response) {
            this.tablegroupObj = response.table_groups;

            // this.tableGroupForm.patchValue({
            //   tableGroupNames: this.tableGroupList.map(option => option)
            // });
            this.tableGroupList.forEach((item) => {
              this.tablegroupObj.forEach((e) => {
                if (item.id == e.table_group_id) {
                  const control = this.tableGroupForm.get(
                    "tableGroupNames"
                  ) as FormControl;

                  const selectedValues = control.value.concat(item);
                  control.setValue(selectedValues);
                }
              });
            });

            this.model.name = response.name;
            this.model.Dine_in = response.dine_in;
            this.model.Web_App = response.webapp;
            this.model.Web_site = response.website;
            this.model.active = response.active;
          }
        });
    }

    this.getAllPriceListItems();
    this.apiService
      .getAllVariant(this.prceListId)
      .subscribe((response: any[]) => {
        this.itemVarAr = response;
        // console.log("resssseeee", this.itemVarAr);
        // this.searchItem();
        this.updateFilteredItems();
      });
  }
  getAllPriceListItems() {
    this.apiService
      .getItembyPriceListId(this.prceListId)
      .subscribe((response: any) => {
        this.itemdataArr = response;

        this.filteredItems = this.itemdataArr;
        // this.searchItem();
        this.updateFilteredItems();
      });
    console.log("thissssss", this.itemdataArr);
  }

  updateFilteredItems() {
    this.filteredDataArr = this.itemdataArr.slice();
    this.filteredVarAr = this.itemVarAr.slice();
    this.searchItem();
  }

  updatePrice() {
    // Assuming you have an API service method for updating price list data
    this.apiService
      .updateNewEditPriceList(this.prceListId, this.model)

      .subscribe(
        (response: any) => {
          if (response.message) {
            this.notifier.notify("success", response.message);
          } else {
            this.notifier.notify("error", response.message);
          }
          // console.log("chinckinggggg");
          // Handle response, maybe show a notification
          // console.log("Price list updated successfully:", response);
          // Optionally, you can reload the data after successful update
          this.load();
        },
        (error) => {
          this.notifier.notify("error", error);
          // Handle error, show error notification or log it
          // console.error("Error updating price list:", error);
        }
      );
  }

  DeleteNewPriceList() {
    // console.log("Deleting Price List with ID:", this.prceListId);

    // Assuming you have an API service method for deleting price list data
    this.apiService.deleteNewPriceList(this.prceListId).subscribe(
      (response: any) => {
        // console.log("Price list deleted successfully:", response);
        if (response.success) {
          this.notifier.notify("success", response.message);

          this.router.navigate(["Master/item-price-list"]);
        } else {
          this.notifier.notify("error", response.message);
        }
        // Handle response, maybe show a notification
        // Optionally, you can reload the data after successful deletion
      },
      (error) => {
        this.notifier.notify("error", error);
        // Handle error, show error notification or log it
        // console.error("Error deleting price list:", error);
      }
    );
  }

  DeleteItemPriceListId(data: any) {
    const itemId = data.item_id;
    let id = { item_id: itemId };
    // console.log("Deleting Price List with ID:", this.prceListId);
    // Assuming you have an API service method for deleting price list data
    this.apiService.DeleteItemPriceListById(this.prceListId, id).subscribe(
      (response: any) => {
        if (response.success) {
          this.notifier.notify("success", response.message);
        } else {
          this.notifier.notify("error", response.message);
        }
      },
      (error) => {
        this.notifier.notify("error", error);
        // Handle error, show error notification or log it
        console.error("Error deleting price list:", error);
      }
    );
    this.load();
  }

  search() {
    // console.log("serch");
    this.searchItem();
  }

  enterClick(key) {
    if (key.key == "Enter") this.search();
  }

  editClick(row) {
    console.log("row", row);
    // Extract item_id from the row object

    const item_id = row.item_id;
    const s_rate = row.s_rate;
    const taxslab = row.taxslab;

    // Pass data to the dialog including item_id
    this.dialog
      .open(AddEditItemsComponent, {
        width: "500px",
        data: {
          status: "update",
          priceList_id: this.prceListId,
          item_id: item_id,
          s_rate: s_rate,
          taxslab: taxslab,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.status == "add" || result.status == "update") {
          this.getAllPriceListItems();
        }
      });
  }

  editvariant(row) {
    const variant_id = row.variant_id;
    const s_rate = row.s_rate;
    const id = row.id;

    this.dialog
      .open(EditItemVariantComponent, {
        width: "500px",
        data: {
          variant_id: variant_id,
          s_rate: s_rate,
          id: id,
        },
      })
      .afterClosed();
  }

  cancel() {
    this.router.navigate(["Master/item-price-list"]);
  }

  // searchItem() {
  //   if (this.searchText == undefined || this.searchText == "") {
  //     // If the search input is empty, reset filteredItems to display all items
  //     this.filteredItems = this.itemdataArr;

  //     return;
  //   }

  //   // Filter items based on item name containing the search input
  //   this.filteredItems = this.itemdataArr.filter((item) =>
  //     item.item_name
  //       .toLowerCase()
  //       .includes(this.searchText.trim().toLowerCase())
  //   );
  // }

  searchItem() {
    if (!this.searchText == undefined || this.searchText.trim() === "") {
      this.filteredDataArr = this.itemdataArr.slice();
      console.log("this.filteredDataArr", this.filteredDataArr);

      this.filteredVarAr = this.itemVarAr.slice();
      console.log("this.filteredVarAr", this.filteredVarAr);

      return;
    }

    const searchTextLower = this.searchText.trim().toLowerCase();

    this.filteredDataArr = this.itemdataArr.filter(
      (item) =>
        item.item_name.toLowerCase().includes(searchTextLower) ||
        item.item_id.toString().includes(searchTextLower)
    );
    console.log("this.filteredDataArr", this.filteredDataArr);

    this.filteredVarAr = this.itemVarAr.filter(
      (item) =>
        item.item_name.toLowerCase().includes(searchTextLower) ||
        item.item_id.toString().includes(searchTextLower)
    );
    console.log("this.filteredVarAr", this.filteredVarAr);
  }

  onTabChange(e) {
    this.selectedTab = e;
  }

  Deletevariant(row) {
    const id = row.id;

    this.apiService.deletevariantId(id).subscribe(
      (response: any) => {
        if (response.success) {
          this.notifier.notify("success", response.message);
        } else {
          this.notifier.notify("error", response.message);
        }
      },
      (error) => {
        this.notifier.notify("error", error);
        // Handle error, show error notification or log it
        console.error("Error deleting price list:", error);
      }
    );
    this.load();
  }
}
