<div class="panelcard">
  <h3 class="color-h3">Kot Cancellation</h3>
  <div class="table-details">
    <form>
      <div class="row">
        <div class="col-md-2">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              [matDatepicker]="from_date"
              placeholder="From:"
              [(ngModel)]="model.from_date"
              name="from_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="from_date"
            ></mat-datepicker-toggle>
            <mat-datepicker #from_date></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              [matDatepicker]="to_date"
              placeholder="To:"
              [(ngModel)]="model.to_date"
              name="to_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="to_date"
            ></mat-datepicker-toggle>
            <mat-datepicker #to_date></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- <div class="col-md-3">
          <mat-form-field class="contract-full-width">
            <mat-select
              placeholder="Voucher Type:"
              multiple
              [(ngModel)]="model.voucher_type"
              name="voucher_type"
              required
            >
              <mat-option
                *ngFor="let voucher of voucherObj"
                [value]="voucher.v_type_id"
              >
                {{ voucher.v_type_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="col-md-2">
          <mat-form-field class="contract-full-width">
            <mat-select
              class="form-margin"
              placeholder="Select User"
              (selectionChange)="UserFilter($event.value)"
              [(ngModel)]="model.filter_user_id"
              name="filter_user"
            >
              <mat-option value="-1"> All </mat-option>
              <mat-option *ngFor="let obj of userList" [value]="obj.user_id">
                {{ obj.full_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="col-md-1 col-4">
          <!-- Correct: searchClick() -->
          <button mat-raised-button color="primary" (click)="searchClick()">
            Search
          </button>
        </div>
        <div class="col-md-2 col-8">
          <div class="btn-group">
            <button
              type="button"
              #btnGrp
              class="btn ml-2"
              (click)="printClick()"
            >
              Print
            </button>
            <button
              type="button"
              class="btn ml-1 dropdown-toggle"
              [matMenuTriggerFor]="btnmenu"
            >
              <span class="caret"></span>
            </button>
            <mat-menu #btnmenu="matMenu" class="btn-grp">
              <a mat-menu-item (click)="ExportTOExcel()"> Export </a>
            </mat-menu>
          </div>
        </div>
      </div>
    </form>
    <div #TABLE>
      <table
        *ngIf="accountVoucherObj"
        mat-table
        [dataSource]="accountVoucherObj"
        matSort
      >
        <!--voucher_date column-->
        <ng-container matColumnDef="trans_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let row" data-label="Date">
            {{ row.trans_date | date : "dd/MMM/yyyy" }}
          </td>
        </ng-container>

        <!--voucher_time column-->
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
          <td mat-cell *matCellDef="let row" data-label="Time">
            {{ row.trans_date | date : "h:mm:ss a" }}
          </td>
        </ng-container>

        <!-- voucher_no Column -->
        <ng-container matColumnDef="trans_no">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Voucher No</th>
          <td
            mat-cell
            *matCellDef="let row"
            data-label="Voucher No"
            style="text-align: center"
          >
            {{ row.trans_no }}
            <span
              mat-button
              [matMenuTriggerFor]="reason"
              #menuTrigger
              *ngIf="!row.active"
              class="badge badge-danger"
              >Canceled</span
            >
            <mat-menu #reason="matMenu">
              <button mat-menu-item>{{ row.cancel_reason }}</button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="bill_no">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Bill No.</th>
          <td mat-cell *matCellDef="let row" data-label="Bill No.">
            <span *ngIf="row.v_type_name == 'Purchase' && row.bill_no">{{
              row.bill_no
            }}</span>
            <span
              *ngIf="row.v_type_name == 'Invoice' && row.display_trans_no"
              >{{ row.display_trans_no }}</span
            >
          </td>
        </ng-container>
        <!--voucher_type column-->
        <ng-container matColumnDef="v_type_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let row" data-label="Type">
            {{ row.v_type_name }}
          </td>
        </ng-container>

        <!--ledger_name column-->
        <ng-container matColumnDef="ledger_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
          <td mat-cell *matCellDef="let row" data-label="Details">
            <a *ngIf="row.active" (click)="edittransaction(row)"
              >{{ row.ledger_name }}
              <span *ngIf="row.customer_name">- {{ row.customer_name }}</span>
            </a>
            <span *ngIf="!row.active"
              >{{ row.ledger_name }}
              <span *ngIf="row.customer_name">- {{ row.customer_name }}</span>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="full_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
          <td mat-cell *matCellDef="let row" data-label="User Name">
            {{ row.full_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tablename" *ngIf="checkEnableResturantFlag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Table</th>
          <td mat-cell *matCellDef="let row" data-label="Table">
            {{ row.tablename }}
          </td>
        </ng-container>
        <!--amount column-->
        <ng-container matColumnDef="net_amount">
          <th class="right" mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let row" data-label="Amount">
            {{ row.net_amount | number : "1.2-2" }}
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="cash">
          <th class="right" mat-header-cell *matHeaderCellDef>Cash</th>
          <td mat-cell *matCellDef="let row" data-label="Cash">{{getCashAmt(row) | number : '1.2-2' }}</td>
        </ng-container>
  
        <ng-container matColumnDef="card">
          <th class="right" mat-header-cell *matHeaderCellDef>Bank</th>
          <td mat-cell *matCellDef="let row" data-label="Bank">{{getCardAmt(row) | number : '1.2-2' }}</td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            'table-danger': row.active === false,
            'table-info': row.non_receivable === true
          }"
        ></tr>
      </table>
    </div>
  </div>
</div>
