import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-add-edit-sub-category',
  templateUrl: './add-edit-sub-category.component.html',
  styleUrls: ['./add-edit-sub-category.component.css']
})
export class AddEditSubCategoryComponent implements OnInit {
  @ViewChild('f') f;
  maincat_id: any
  constructor(private apiService: DataService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddEditSubCategoryComponent>) {
  }
  isRestaurantCategory: boolean = false;
  ngOnInit() {
    this.f.submitted = true;
    this.load();
  }
  model: any = {};

  load() {

    console.log('Sub Category ID:', this.data.sub_category_id);

    if (this.data.sub_category_id) {
      this.apiService.getSubCategory(this.data.sub_category_id)
        .subscribe((result: any) => {
          this.model = result[0];
        }, (result: any) => {
          this.notifier.notify('error', 'unable to load data');
        });
    }
  }

  onSubmit() {
    if (this.data && this.data.sub_category_id) {
      this.model.sub_category_id = this.data.sub_category_id;
      this.apiService.editSubCategory(this.model.sub_category_id, this.model)
        .subscribe((result: any) => {
          this.dialogRef.close('Edited');
          this.notifier.notify('success', 'Sub Category updated successfully');
        }, (result: any) => {
          this.notifier.notify('error', 'unable to update data');
        });
    } else {
      this.model.cat_id = this.data.category_id;
      this.apiService.addSubCategory(this.model)
        .subscribe((result: any) => {
          this.dialogRef.close('Added');
          this.notifier.notify('success', 'New Sub Category added successfully');
        }, (result: any) => {
          this.notifier.notify('error', 'unable to add New Sub Category');
        });
    }
  }

  // onSubmit() {
  //   console.log("--------------------------subcat----------------",this.data);
  //   if(this.data && this.data.sub_category_id){
  //     console.log("update----",this.data)
  //     this.apiService.editSubCategory(this.model.sub_category_id,this.model)
  //     .subscribe((result: any) => {
  //       console.log('result----------',result)
  //     this.dialogRef.close('Edited');
  //     this.notifier.notify( 'success', 'Sub Category updated successfully' );
  //     }, (result: any) => {
  //       this.notifier.notify( 'error', 'unable to update data' );
  //     });
  //   }else if(this.data.cat_id){
  //     this.data.cat_id = this.maincat_id
  //     console.log("submit----", this.model)

  //     this.apiService.addSubCategory(this.model)
  //     .subscribe((result: any) => {
  //     this.dialogRef.close('Added');
  //     this.notifier.notify( 'success', 'New Sub Category added successfully' );
  //     }, (result: any) => {
  //       this.notifier.notify( 'error', 'unable to add New Sub Category' );
  //     });
  //   }

  // }



  // load(){
  //   if(this.data){
  //     this.apiService.getSubCategory(this.data.sub_category_id)
  //     .subscribe((result: any) => {
  //       this.model=result[0];
  //     }, (result: any) => {
  //       this.notifier.notify( 'error', 'unable to load data' );
  //     });
  //   }
  // }
  // onSubmit() {
  //   if (this.model.sub_category_id) {
  //     // Editing an existing subcategory
  //     this.apiService.editSubCategory(this.model.sub_category_id, this.model)
  //       .subscribe(
  //         (result: any) => {
  //           console.log('result----------', result);
  //           this.dialogRef.close('Edited');
  //           this.notifier.notify('success', 'Sub Category updated successfully');
  //         },
  //         (error: any) => {
  //           this.notifier.notify('error', 'Unable to update data');
  //         }
  //       );
  //   } else if (this.model.cat_id) {
  //     // Adding a new subcategory
  //     this.model.cat_id = this.maincat_id; // Set the cat_id
  //     this.apiService.addSubCategory(this.model)
  //       .subscribe(
  //         (result: any) => {
  //           this.dialogRef.close('Added');
  //           this.notifier.notify('success', 'New Sub Category added successfully');
  //         },
  //         (error: any) => {
  //           this.notifier.notify('error', 'Unable to add New Sub Category');
  //         }
  //       );
  //   }
  // }




  close() {
    this.dialogRef.close();
  }
}
