import { Component, OnInit,ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {DataService} from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { environment } from '../../../environments/environment';

import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import {MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter  } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../date.adapter';
@Component({
  selector: 'app-edit-expense',
  templateUrl: './edit-expense.component.html',
  styleUrls: ['./edit-expense.component.css'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class EditExpenseComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private dialogRef: MatDialogRef<EditExpenseComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.currency=environment.currency;
    }
    
  @ViewChild('f') f;
  currency:any;
  disableInput:any =  true;
  ngOnInit() {
    this.load();
  }
  model: any = {};
  ledgerObj:any;
  ledgerObj2:any;
  l_id1:any;
  closing_balance1:any;
ledger_id1:any; 
l_id2:any;
  closing_balance2:any;
ledger_id2:any; 
  load(){
    if(this.data.payment_mode == "Bank")
      this.disableInput = false;
    this.f.submitted = true;
    this.apiService.getExpense(this.data.transaction_id)
    .subscribe((result: any) => {
      this.model = result;
      this.apiService.getLedgerClosingBalance(this.model.ledger_id).subscribe(
        (result:any)=>{
          if(result.success){
            this.closing_balance1=result.message.closing_balance+' '+result.message.cbdrcr;
           
          }
          else{
            this.notifier.notify('error', result.message);
          }
        },
        (err:any)=>{
  
        }
      )
      this.apiService.getLedgerClosingBalance(this.model.ledger_id2).subscribe(
        (result:any)=>{
          if(result.success){
            this.closing_balance2=result.message.closing_balance+' '+result.message.cbdrcr;
           
          }
          else{
            this.notifier.notify('error', result.message);
          }
        },
        (err:any)=>{
  
        }
      )
  
  
    }, (result: any) => {
    });

    this.apiService.getExpenseLedgerList()
    .subscribe((result: any) => {
      this.ledgerObj = result;
    }, (result: any) => {
    });

    this.apiService.getCashBankLedgerList()
    .subscribe((result: any) => {
      this.ledgerObj2 = result;
      // console.log(this.ledgerObj2);
    }, (result: any) => {
    });
  }

  enableInp() {
    this.disableInput =  false;
  }
  disableInp(){
    this.disableInput =  true;
  }
  
  onSubmit(){
   
    if(this.f.valid){
      this.model.upi=this.model.cash=this.model.card=0;
      this.model.payment_mode == 'UPI' ? this.model.upi = this.model.total_amount : this.model.upi = 0;
      this.model.payment_mode == 'Cash' ? this.model.cash = this.model.total_amount : this.model.cash = 0;
      this.model.payment_mode == 'Bank' ? this.model.card = this.model.total_amount : this.model.card = 0;
      this.apiService.editExpense(this.model.transaction_id, this.model)
      .subscribe((result: any) => {
        if(result.success){
          this.downloadClick();
          this.notifier.notify( 'success', 'Expense Details Updated successfully' );
        }else{
          this.notifier.notify( 'error', result.message );
        }
        this.dialogRef.close('Edited');
        }, (result: any) => {
        this.notifier.notify( 'error', 'unable to update Expense Details' );
      });
    }
  }

  downloadClick(){
    this.model.trans_type = "Expense";
    this.apiService.downloadPdf(this.model)
    .subscribe((result: any) => {
      let blob = new Blob([result], {
        type: 'application/pdf'
     });
     var link = document.createElement('a');
     link.href = window.URL.createObjectURL(blob);
     link.download = 'expense'+this.model.trans_no+'.pdf';
     link.click();
     window.URL.revokeObjectURL(link.href);
    },(result: any) => {
    });
  }
  cancelInvoice(){
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Cancel the Expense Voucher Details.?"
    })
    .afterClosed().subscribe(result =>{
      if(result == true){
        this.apiService.deleteExpense(this.data.transaction_id)
        .subscribe((result: any) => {
          this.dialogRef.close('Edited');
          this.notifier.notify( 'success', 'Expense Canceled successfully' );
        }, (result: any) => {
          this.notifier.notify( 'error', 'unable to cancel Expense' );
        });
      }
  });
  }

  closeDialog(){
    this.dialogRef.close();
  } 

  onChange1(){
    this.apiService.getLedgerClosingBalance(this.model.ledger_id).subscribe(
      (result:any)=>{
        if(result.success){
          this.closing_balance1=result.message.closing_balance+' '+result.message.cbdrcr;
         
        }
        else{
          this.notifier.notify('error', result.message);
        }
      },
      (err:any)=>{

      }
    )
  }
  onChange2(){
    this.apiService.getLedgerClosingBalance(this.model.ledger_id2).subscribe(
      (result:any)=>{
        if(result.success){
          this.closing_balance2=result.message.closing_balance+' '+result.message.cbdrcr;
         
        }
        else{
          this.notifier.notify('error', result.message);
        }
      },
      (err:any)=>{

      }
    )
  }
}
