import { Component, OnInit, ViewChild, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent, MatTabChangeEvent, MatDialog, MatTableDataSource, MatSort } from "@angular/material";
import { NotifierService } from 'angular-notifier';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../../../data.service';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-featured-item',
  templateUrl: './add-featured-item.component.html',
  styleUrls: ['./add-featured-item.component.css']
})
export class AddFeaturedItemComponent implements OnInit {

  availableItems: any = [];
  selectedItems:any = [];
  suggestionArray: any = [];
  itemsToMoveLeft: any = [];
  itemsToMoveRight: any = [];

  constructor(private dialogRef: MatDialogRef<AddFeaturedItemComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  @Output() onItemAdd: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.load();
  }

  async load() {
    this.selectedItems = await this.apiService.getFeturedItem().toPromise();
    this.selectedItems.forEach(item => {
      this.apiService.getItemImage(item.item_id)
        .subscribe((resultObj: any) => {
          this.createImageFromBlob(resultObj, (url) => {
            item.imageurl = url;
          });
        }, (err: any) => {
          console.log('unable to load image');
        });
    });
    this.availableItems = await this.apiService.getItemListWithInactive('undefined', 'false').toPromise();
    this.availableItems = this.availableItems.filter(item => !this.selectedItems.filter(_item => _item.item_id === item.item_id).length);
    this.suggestionArray = this.availableItems;
    this.availableItems.forEach(item => {
      this.apiService.getItemImage(item.item_id)
        .subscribe((resultObj: any) => {
          this.createImageFromBlob(resultObj, (url) => {
            item.imageurl = url;
          });
        }, (err: any) => {
          console.log('unable to load image');
        });
    });
  }

  createImageFromBlob(image: Blob, fn) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      var reader_result = reader.result;
      fn(reader_result);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  search(searchText) {
    if (searchText) {
      this.availableItems = this.suggestionArray.filter(item => item.item_name.toLowerCase().includes(searchText.toLowerCase()));
    } else {
      this.availableItems = this.suggestionArray;
    }
  }

  onLeftSideItemClicked(item, div) {
    if (this.itemsToMoveRight.filter(_item => _item.item_id === item.item_id).length) {
      this.itemsToMoveRight = this.itemsToMoveRight.filter(_item => _item.item_id !== item.item_id);
      div.classList.remove('selectedItem');
    } else {
      this.itemsToMoveRight.push(item);
      div.classList.add('selectedItem');
    }
    // console.log(this.itemsToMoveRight);
  }
  
  onRightSideItemClicked(item, div) {
    if (this.itemsToMoveLeft.filter(_item => _item.item_id === item.item_id).length) {
      this.itemsToMoveLeft = this.itemsToMoveLeft.filter(_item => _item.item_id !== item.item_id);
      div.classList.remove('selectedItem');
    } else {
      this.itemsToMoveLeft.push(item);
      div.classList.add('selectedItem');
    }
    // console.log(this.itemsToMoveLeft);
  }

  shiftrow(action) {
    if (action === 'right' && this.itemsToMoveRight.length) {
      Array.prototype.push.apply(this.selectedItems, this.itemsToMoveRight);
      this.availableItems = this.availableItems.filter(item => !this.itemsToMoveRight.filter(_item => item.item_id === _item.item_id).length);
      this.suggestionArray = this.suggestionArray.filter(item => !this.itemsToMoveRight.filter(_item => item.item_id === _item.item_id).length);
      this.itemsToMoveRight = [];
    }
    if (action === 'left' && this.itemsToMoveLeft.length) {
      Array.prototype.push.apply(this.availableItems, this.itemsToMoveLeft);
      this.selectedItems = this.selectedItems.filter(item => !this.itemsToMoveLeft.filter(_item => item.item_id === _item.item_id).length);
      this.suggestionArray = this.suggestionArray.filter(item => !this.itemsToMoveRight.filter(_item => item.item_id === _item.item_id).length);
      // Array.prototype.push.apply(this.suggestionArray, this.itemsToMoveLeft);
      this.itemsToMoveLeft = [];
    }
  }

  save() {
    let data = this.selectedItems;
    let item_ids = data.map((item) =>  {
      return item.item_id;
    });
    this.apiService.updateFeaturedItem({item_ids})
    .subscribe((result: any) => {
      this.close();
      this.onItemAdd.emit(result);
      this.notifier.notify('success', 'Items updated successfully');
      // this.load()
    },(result: any) => {
      this.notifier.notify('error', result.message);
    })
  }

  close() {
    this.dialogRef.close();
  }
}
