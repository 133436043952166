import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { MonthlyItemStockComponent } from '../monthly-item-stock/monthly-item-stock.component';
import { DataService } from 'src/app/data.service';
import { ItemStockDetailsComponent } from '../item-stock-details/item-stock-details.component';

@Component({
  selector: 'app-purchase-rate-wise-stock',
  templateUrl: './purchase-rate-wise-stock.component.html',
  styleUrls: ['./purchase-rate-wise-stock.component.css']
})
export class PurchaseRateWiseStockComponent implements OnInit {


  accountVoucherObj: MatTableDataSource<any>;
  constructor(private dialogRef: MatDialogRef<MonthlyItemStockComponent>,
    private apiService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {

  }
  dataObj: any = {};
  itemStockData: any;
  model: any = { from_date: this.data.from_date, to_date: this.data.to_date };
  displayedColumns = ['item_name', 'p_rate', 'ob', 'in_item', 'out_item', 'cb', 'value'];

  ngOnInit() {
    this.load();
  }
  load() {
    this.apiService.getPurchaseWiseItemStock(this.data.from_date, this.data.to_date, this.data.item_id)
      .subscribe((result: any) => {
        // console.log("===============vvvvvvvvvvvvvvvvvvvvvvvvvv=============");
        // console.log(result);

        // console.log("================vvvvvvvvv==================================");
        this.itemStockData = result;
      }, (result: any) => {
      });
  }

  openStockDetails(row) {
    this.dataObj.item_id = this.data.item_id;
    this.dataObj.item_name = this.data.item_name;
    this.dataObj.from_date = this.data.from_date;
    this.dataObj.to_date = this.data.to_date;

    this.dialog.open(ItemStockDetailsComponent, {
      width: '1000px',
      data: this.dataObj
    })
      .afterClosed().subscribe(result => {
        this.load();
      });
  }
  close() {
    this.dialogRef.close();
  }
  printClick() {
    this.model.to_date.setHours(23, 30, 60, 60);
    this.model.from_date.setHours(6, 0, 0, 0);
    this.model.trans_type = "MonthlyItemStock";
    this.model.voucherList = this.itemStockData;
    this.model.item_name = this.data.item_name;
    this.model.item_id = this.data.item_id;
    this.apiService.downloadPdf(this.model)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'stock' + new Date().toLocaleDateString() + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }




  
  //get total value
  totalValueAmt() {
    return this.itemStockData ? this.itemStockData.map(data => data.value).reduce((amt, value) => amt + value, 0) : 0;
  }


  totalObAmt() {
    return this.itemStockData ? this.itemStockData.map(data => data.ob).reduce((amt, ob) => parseFloat(amt) + parseFloat(ob), 0) : 0;
  }
  totalCbAmt() {
    return this.itemStockData ? this.itemStockData.map(data => data.cb).reduce((amt, cb) => parseFloat(amt) + parseFloat(cb), 0) : 0;
  }

  totalInAmt() {
    return this.itemStockData ? this.itemStockData.map(data => data.in_item).reduce((amt, in_item) => parseFloat(amt) + parseFloat(in_item), 0) : 0;
  }

  totalOutAmt() {
    return this.itemStockData ? this.itemStockData.map(data => data.out_item).reduce((amt, out_item) => parseFloat(amt) + parseFloat(out_item), 0) : 0;
  }
}
