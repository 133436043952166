
import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { EditAccountGroupComponent } from '../../account-groups/edit-account-group/edit-account-group.component';
import { PurchaseOrderItemsComponent } from '../../purchase-order/purchase-order-items/purchase-order-items.component';
import { AddEditSuppliersMasterComponent } from '../../suppliers-master/add-edit-suppliers-master/add-edit-suppliers-master.component';


@Component({
  selector: 'app-add-po',
  templateUrl: './add-po.component.html',
  styleUrls: ['./add-po.component.css']
})
export class AddPoComponent implements OnInit {

  constructor(private apiService: DataService, 
    public dialog: MatDialog, 
    private dialogRef: MatDialogRef<AddPoComponent>,
    private notifier: NotifierService,
     private cd: ChangeDetectorRef,
     @Inject(MAT_DIALOG_DATA) public row: any
    ) {
  }

  @ViewChild('f') f;
  contractObj: any;
  ledgerObj: any[];
  transDataObj: any = { items_details: [] };
  items: any = [];
  amount: any;
  ledgerControl = new FormControl();
  options: any;
 
  filteredOptions: Observable<string[]>;
  ngOnInit() {
    this.load();
    this.allstates(); 
  }
  filter(value) {
    const filterValue = value.toLowerCase();
    return this.ledgerObj.filter(ledger => ledger.ledger_code.toLowerCase().includes(filterValue) || ledger.ledger_name.toLowerCase().includes(filterValue));
  }

  displayFn(ledger) {
    return ledger ? ledger.ledger_name : "";
  }

  
  GState: any;
  stateName: any;
  statelist:any;
  allstates() {
    this.apiService.getGstState()
      .subscribe((result: any) => {
        this.GState = result;
          this.stateName = this.GState;
          // console.log(this.stateName);
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }
  
  sameAdd(isChecked: boolean){
    if(isChecked) {
    this.form.get('shipping_address1').setValue(this.form.get('billing_address1').value);
    this.form.get('shipping_address2').setValue(this.form.get('billing_address2').value);
    this.form.get('shipping_street').setValue(this.form.get('billing_street').value);
    this.form.get('shipping_city').setValue(this.form.get('billing_city').value);
    this.form.get('shipping_state').setValue(this.form.get('billing_state').value);
    this.form.get('shipping_country').setValue(this.form.get('billing_country').value);
    this.form.get('shipping_pin').setValue(this.form.get('billing_pin').value);
  }
    else{
      this.form.get('shipping_address1').setValue("");
      this.form.get('shipping_address2').setValue("");
      this.form.get('shipping_street').setValue("");
      this.form.get('shipping_city').setValue("");
      this.form.get('shipping_state').setValue("");
      this.form.get('shipping_country').setValue("");
      this.form.get('shipping_pin').setValue("");
    }

  }
  

  load() {
    this.f.submitted = true;
    this.apiService.getContractList()
      .subscribe((result: any) => {
        this.contractObj = result;
      }, (result: any) => {
      });

      this.apiService.getCheckedItem(this.row)
      .subscribe((res) =>{
        //  console.log(res);
      });
    this.items = this.row;
    this.calculateTotal();
     
  }
  
  //searching for ledger
  search(event) {
    // console.log(event.key);
    if (event.key != "ArrowDown" && event.key != "ArrowUp" && event.key != "Enter") {
      if (this.ledgerControl.value.length > 1) {
        this.apiService.getSundryCrLedgerList(this.ledgerControl.value, "false")
          .subscribe((result: any) => {
            this.ledgerObj = result;
            this.filteredOptions = this.ledgerControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.ledgerObj = [];
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }
    }
  }
  form: FormGroup = new FormGroup({
    contract_id: new FormControl(null),
    transaction_id: new FormControl(0),
    trans_no: new FormControl("Auto Generated", Validators.required),
    trans_date: new FormControl(new Date(), Validators.required),
    ledger_id: new FormControl(null, Validators.required),
    shipping_address1: new FormControl(null),
    shipping_address2: new FormControl(null),
    shipping_street: new FormControl(null),
    shipping_city: new FormControl(null),
    shipping_state: new FormControl(null),
    shipping_country: new FormControl(null),
    shipping_pin: new FormControl(null),
    billing_address1: new FormControl(null),
    billing_address2: new FormControl(null),
    billing_street: new FormControl(null),
    billing_city: new FormControl(null),
    billing_state: new FormControl(null),
    billing_country: new FormControl(null),
    billing_pin: new FormControl(null),
    transport_address1: new FormControl(null),
    transport_address2: new FormControl(null),
    transport_street: new FormControl(null),
    transport_city: new FormControl(null),
    transport_state: new FormControl(null),
    transport_country: new FormControl(null),
    transport_pin: new FormControl(null),
    net_amount: new FormControl(0)
  });
  //Initialise ledger
  initialiseLedger() {
    this.ledgerControl.setValue(null);
    this.ledgerObj = [];
    this.filteredOptions = this.ledgerControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
  }
  initializeFormGroup() {
    this.form.setValue({
      contract_id: null,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      shipping_address1: null,
      shipping_address2: null,
      shipping_street: null,
      shipping_city: null,
      shipping_state: null,
      shipping_country: null,
      shipping_pin: null,
      billing_address1: null,
      billing_address2: null,
      billing_street: null,
      billing_city: null,
      billing_state: null,
      billing_country: null,
      billing_pin: null,
      transport_address1: null,
      transport_address2: null,
      transport_street: null,
      transport_city: null,
      transport_state: null,
      transport_country: null,
      transport_pin: null,
      net_amount: 0
    });
    this.items = [];
    window.scroll(0, 0);
    this.initialiseLedger();
  }
  addItemClick() {
    var itemData = {type:'po'}
    this.dialog.open(PurchaseOrderItemsComponent, {
      width: '600px',
      data: itemData,
      autoFocus: false
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.items.push(result);
          this.calculateTotal();
        }
      });
  }
  onDeleteItem(i) {
    this.items.splice(i, 1);
    this.calculateTotal();
  }
  editItem(i) {
    var itemData = {type:'po', value:this.items[i]}
    this.dialog.open(PurchaseOrderItemsComponent, {
      width: '600px',
      data: itemData,
      autoFocus: false
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.items.splice(i, 1);
          this.items.push(result);
          this.calculateTotal();
        }
      });
  }

  closeDialog() {
    this.dialogRef.close()
  }
  //On Ledger Selection event
  ledgerSelection(event: MatAutocompleteSelectedEvent) {
    this.items = [];
    this.items = this.row;
    this.amount = 0;
    this.form.get('ledger_id').setValue(event.option.value.ledger_id);
    this.form.get('billing_address1').setValue(event.option.value.address_address1);
    this.form.get('billing_address2').setValue(event.option.value.address_address2);
    this.form.get('billing_street').setValue(event.option.value.address_street);
    this.form.get('billing_city').setValue(event.option.value.address_city);
    this.form.get('billing_state').setValue(event.option.value.address_state);
    this.form.get('billing_country').setValue(event.option.value.address_country);
    this.form.get('billing_pin').setValue(event.option.value.address_pin);
  }
  calculateTotal() {
    this.amount = 0;
    for (var i = 0; i < this.items.length; i++) {
      this.amount = this.amount + this.items[i].amount;
    }
    this.form.get('net_amount').setValue(this.amount);
  }
  onSubmit() {
    if (this.form.valid) {
      this.transDataObj.trans_type = "PurchaseOrder";
      this.transDataObj = this.form.value;
      this.transDataObj.items_details = this.items;
      this.transDataObj.trans_no = null;
      this.apiService.addPurchaseOrder(this.transDataObj)
        .subscribe((result: any) => {
          if (result.success) {
            this.transDataObj.trans_no = result.max_trans_value;
            this.transDataObj.transaction_id = result.transaction_id;
            this.transDataObj.trans_type = "PurchaseOrder";
            this.apiService.downloadPdf(this.transDataObj)
              .subscribe((result: any) => {
                let blob = new Blob([result], {
                  type: 'application/pdf'
                });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'po' + this.transDataObj.trans_no + '.pdf';
                link.click();
                window.URL.revokeObjectURL(link.href);
              }, (result: any) => {
              });
            this.notifier.notify('success', 'New Purchase Order added successfully');
          } else {
            this.notifier.notify('error', result.message);
          }
          this.dialogRef.close();
        }, (result: any) => {
          this.notifier.notify('error', 'Unable to add new Purchase Order');
        });
    } else {
      window.scroll(0, 0);
    }
  }

  onClear() {
    this.initializeFormGroup();
  }
  //to add supplier
  onAddSupplierClick() {
    this.dialog.open(AddEditSuppliersMasterComponent, {
      width: '800px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var ledgerData = result.data;
          this.ledgerControl.setValue(ledgerData);
          this.form.get('ledger_id').setValue(ledgerData.ledger_id);
          this.form.get('billing_address1').setValue(ledgerData.address_address1);
          this.form.get('billing_address2').setValue(ledgerData.address_address2);
          this.form.get('billing_street').setValue(ledgerData.address_street);
          this.form.get('billing_city').setValue(ledgerData.address_city);
          this.form.get('billing_state').setValue(ledgerData.address_state);
          this.form.get('billing_country').setValue(ledgerData.address_country);
          this.form.get('billing_pin').setValue(ledgerData.address_pin);
        }
      });
  }
  //on focus on ledger search
  onFocus(e) {
    this.apiService.getSundryCrLedgerList(undefined, "false")
      .subscribe((result: any) => {
        this.ledgerObj = result;
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }, (result: any) => {
      });
  }
}
