<mat-toolbar class="fixed-header" color="primary">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="onToolbarMenuToggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div class="row top-section">
    <div class="col-md-6">
      <span>Tenent Management </span>
    </div>
    <div class="col-md-6 right">
      <a class="right-menu-icon" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </a>
      <mat-menu #menu="matMenu">
          <a mat-menu-item (click)="changePass()">
              <mat-icon>fingerprint</mat-icon>
              <span>Change Password</span>
            </a>
        <a mat-menu-item routerLink="/tenent-logout">
          <mat-icon>logout</mat-icon>
          <span>LogOut</span>
        </a>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav fixedInViewport="true" mode="side" #sidenav id="sidenav" [class.menu-open]="isMenuOpen" [class.menu-close]="!isMenuOpen"
    opened>
    <mat-nav-list>
      <a mat-list-item routerLink="/tenent-management" routerLinkActive="active-list-item">
        <mat-icon>settings</mat-icon>Tenent Management</a>

    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentMargin }">
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
    <notifier-container></notifier-container>
  </mat-sidenav-content>

</mat-sidenav-container>