import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as io from 'socket.io-client';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SocketServiceService {
  private socket: SocketIOClient.Socket;
  constructor() {

    this.socket = io(environment.basePath);
    // console.log("SocketServiceService------------------",this.socket)
    this.socket.emit('join', { company_id: localStorage.getItem('company_id') });
    this.socket.on('readyToJoin', () => {
      this.socket.emit('join', { company_id: localStorage.getItem('company_id') });
    });
  }

  emit(event: string, data: any) {
    this.socket.emit(event, data);
  }
  on(event: string) {
    return Observable.create(observer => {
      this.socket.on(event, data => {
        observer.next(data);
      });
    })
  }
  checkConnectionStatus(){
    return this.socket.connected
  }
}
