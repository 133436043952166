import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { NotifierService } from "angular-notifier";
import {
  MAT_DATE_FORMATS,
  NativeDateAdapter,
  DateAdapter,
} from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from "../date.adapter";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { ExportService } from "../_export-service/daybook-export.service";

@Component({
  selector: "app-discount-report",
  templateUrl: "./discount-report.component.html",
  styleUrls: ["./discount-report.component.css"],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class DiscountReportComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private notifier: NotifierService,
    private exportService: ExportService
  ) {}

  f_date: Date = new Date();
  t_date: Date = new Date();
  date: Date = new Date();

  settings = {
    bigBanner: true,
    timePicker: true,
    format: "dd-MM-yyyy",
    defaultOpen: false,
  };

  dueReceiptObj: any = [];
  model: any = {
    from_date: new Date(),
    to_date: new Date(),
    onlyDue: false,
    duesFilterTypeId: 1,
    filter_user_id: -1,
    onlync: false,
  };

  displayedColumns = [
    "trans_date",
    "trans_no",
    "customer_name",
    "full_name",
    "display_trans_no",
    "net_amount",
    // Uncomment "discount" when necessary
    // "discount",
    "advance",
    // "paid",
    // "card",
    // "cash",
    // "upi",
    // "due",
  ];

  searchFilterName = [
    { id: 1, name: "All" },
    { id: 7, name: "Due > 7" },
    { id: 30, name: "Due > 30" },
  ];

  isPOS: boolean = false;
  mapArr: any;
  itemObj: any[];
  filteredOptions: Observable<string[]>;
  isTable: boolean = false;
  toDateFlag: boolean = false;
  fromDateFlag: boolean = false;
  duesOnlyFlag: boolean = false;
  ncOnlyFlag: boolean = false;
  menuGroupObj: any = [];
  userList: any = [];
  isLoading = false;

  ngOnInit() {
    this.getUser();
    this.load();
  }

  filteredUserList: any[] = []; // Data after filtering

  itemControl = new FormControl();
  searchName: any;
  userDetails: any;
  newresultedvalue: any = "";

  UserFilter(value) {
    this.model.filter_user_id = value;
  }

  getUser() {
    this.apiService.getuserList().subscribe(
      (result: any) => {
        if (result.success) {
          this.userList = result.data;
        } else {
          this.notifier.notify("error", result.message);
        }
      },
      (err: any) => {
        this.notifier.notify(
          "error",
          "An error occurred while fetching the user list."
        );
      }
    );
  }

  routingdata: any;

  focus(n: any) {
    this.newresultedvalue = null;
  }

  load() {
    this.apiService.getAccountMapList().subscribe(
      (result: any) => {
        console.log("resulitttttttttt", result);
        this.mapArr = result;
        this.findAccSetting();
      },
      (result: any) => {}
    );
    this.initialSearch();
  }

  findAccSetting() {
    var indexofPOS = this.mapArr.findIndex((i) => i.key === "Enable_POS");
    var indexofTABLE = this.mapArr.findIndex(
      (i) => i.key === "Enable_Restaurant_POS"
    );

    if (
      this.mapArr[indexofPOS].checkbox_value &&
      !this.mapArr[indexofTABLE].checkbox_value
    ) {
      this.isPOS = true;
      this.displayedColumns = [
        "trans_date",
        "trans_no",
        "display_trans_no",
        "customer_name",
        "full_name",
        "discount",
        "net_amount",
        // "advance",
        // "paid",
        // "card",
        // "cash",
        // "upi",
        // "due",
      ];
    }

    if (
      this.mapArr[indexofTABLE].checkbox_value &&
      !this.mapArr[indexofPOS].checkbox_value
    ) {
      this.isTable = true;
      this.displayedColumns = [
        "trans_date",
        "trans_no",
        "display_trans_no",
        "customer_name",
        "table",
        "full_name",
        "discount",
        "net_amount",
        // "advance",
        // "paid",
        // "card",
        // "cash",
        // "upi",
        // "due",
      ];
    }

    if (
      this.mapArr[indexofTABLE].checkbox_value &&
      this.mapArr[indexofPOS].checkbox_value
    ) {
      this.isTable = true;
      this.isPOS = true;
      this.displayedColumns = [
        "trans_date",
        "trans_no",
        "display_trans_no",
        "table",
        "customer_name",
        "full_name",
        "discount",
        "net_amount",
        // "advance",
        // "paid",
        // "card",
        // "cash",
        // "upi",
        // "due",
      ];
    }
  }

  searchClick() {
    var toDate = new Date();
    var fromDate = new Date();
    var duesFlag = false;
    var ncFlag = false;
    var user_id = -1;
    if (this.model.duesFilterTypeId > 1) {
      fromDate = new Date("1990-01-01");
      toDate.setDate(toDate.getDate() - parseInt(this.model.duesFilterTypeId));
      duesFlag = true;
      ncFlag = true;
      user_id = parseInt(this.model.filter_user_id || -1);
    } else {
      // console.log("this.model--", this.model)
      fromDate = this.f_date;
      toDate = this.t_date;
      duesFlag = this.model.onlyDue;
      ncFlag = this.model.onlync;
      user_id = parseInt(this.model.filter_user_id || -1);
    }
    this.apiService
      .getDueList(fromDate, toDate, duesFlag, user_id, ncFlag)
      .subscribe(
        (result: any) => {
          if (result.success) {
            this.dueReceiptObj = result.data.filter(
              (item) => item.discount_amt > 0
            );
          } else {
            this.notifier.notify("error", result.message);
          }
        },
        (result: any) => {}
      );
  }

  initialSearch() {
    this.getData();
  }

  getData() {
    var toDate = new Date();
    var fromDate = new Date();
    var duesFlag = false;
    var ncFlag = false;
    var user_id = -1;

    if (this.model.duesFilterTypeId > 1) {
      fromDate = new Date("1990-01-01");
      toDate.setDate(toDate.getDate() - parseInt(this.model.duesFilterTypeId));
      duesFlag = true;
      ncFlag = true;
      user_id = parseInt(this.model.filter_user_id || -1);
    } else {
      fromDate = this.f_date;
      toDate = this.t_date;
      duesFlag = this.model.onlyDue;
      ncFlag = this.model.onlync;
      user_id = parseInt(this.model.filter_user_id || -1);
      fromDate.setHours(0, 0, 0, 1);
      toDate.setHours(23, 59, 59, 59);
    }

    this.apiService
      .getDueList(fromDate, toDate, duesFlag, user_id, ncFlag)
      .subscribe(
        (result: any) => {
          if (result.success) {
            this.dueReceiptObj = result.data.filter(
              (item) => item.discount_amt > 0
            ); // Filter data here
            this.checkDiscountColumnVisibility(); // Check visibility after data is loaded
          } else {
            this.notifier.notify("error", result.message);
          }
        },
        (result: any) => {}
      );
  }

  checkDiscountColumnVisibility() {
    // Check if any item in dueReceiptObj has discount_amt greater than 0
    const hasDiscountAmt = this.dueReceiptObj.some(
      (item) => item.discount_amt !== null && item.discount_amt > 0
    );

    // Update displayedColumns based on the flag
    if (hasDiscountAmt) {
      // Ensure "discount" column is included only if it's not already in displayedColumns
      if (!this.displayedColumns.includes("discount")) {
        this.displayedColumns.splice(6, 0, "discount"); // Insert "discount" at index 6
      }
    } else {
      // Remove "discount" column from displayedColumns if present
      const index = this.displayedColumns.indexOf("discount");
      if (index !== -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  searchByFilterKey(value) {
    this.model.duesFilterTypeId = value;
    if (value == 1) {
      this.toDateFlag = false;
      this.fromDateFlag = false;
      this.duesOnlyFlag = false;
      this.ncOnlyFlag = false;
    } else {
      this.toDateFlag = true;
      this.fromDateFlag = true;
      this.duesOnlyFlag = true;
      this.ncOnlyFlag = true;
    }
  }

  setPrintOption(option: string) {
    this.model.trans_type = option;
    this.printClick();
  }

  printClick() {
    this.isLoading = true;
    var toDate = new Date();
    var fromDate = new Date();
    this.model.salesListObj = this.dueReceiptObj;

    var modelTemp = JSON.parse(JSON.stringify(this.model));
    if (this.model.duesFilterTypeId > 1) {
      fromDate = new Date("1990-01-01");
      toDate.setDate(toDate.getDate() - parseInt(this.model.duesFilterTypeId));
      modelTemp.from_date = fromDate;
      modelTemp.to_date = toDate;
      modelTemp.onlyDue = true;
      modelTemp.onlync = true;
    }

    this.apiService.downloadPdf(modelTemp).subscribe(
      (result: any) => {
        this.isLoading = false;
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        if (this.model.trans_type == "Total Sale Data") {
          link.download =
            "all_sale_summary" + new Date().toLocaleDateString() + ".pdf";
          link.click();
          window.URL.revokeObjectURL(link.href);
        } else {
          link.download =
            "sales_summary" + new Date().toLocaleDateString() + ".pdf";
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      },
      (result: any) => {
        this.isLoading = false;
      }
    );
  }

  totalAmt() {
    return this.dueReceiptObj
      ? this.dueReceiptObj.reduce(
          (amt, f) => parseInt(amt) + parseInt(f.net_amount),
          0
        )
      : 0;
  }

  totalCard() {
    return this.dueReceiptObj
      ? this.dueReceiptObj
          .map((data) => data.card)
          .reduce((amt, card) => parseInt(amt) + parseInt(card), 0)
      : 0;
  }

  totalCash() {
    return this.dueReceiptObj
      ? this.dueReceiptObj
          .map((data) => data.cash)
          .reduce((amt, cash) => parseInt(amt) + parseInt(cash), 0)
      : 0;
  }

  totalUPI() {
    return this.dueReceiptObj
      ? this.dueReceiptObj
          .map((data) => data.upi)
          .reduce((amt, upi) => parseInt(amt) + parseInt(upi), 0)
      : 0;
  }

  totalDiscountAmt() {
    return this.dueReceiptObj
      ? this.dueReceiptObj
          .map((data) => data.discount_amt)
          .reduce(
            (amt, discount_amt) => parseInt(amt) + parseInt(discount_amt),
            0
          )
      : 0;
  }

  totalAdvance() {
    return this.dueReceiptObj
      ? this.dueReceiptObj
          .map((data) => data.advance)
          .reduce((amt, advance) => parseInt(amt) + parseInt(advance), 0)
      : 0;
  }

  totalPaid() {
    return this.dueReceiptObj
      ? this.dueReceiptObj
          .map((data) => data.paid)
          .reduce((amt, paid) => parseInt(amt) + parseInt(paid), 0)
      : 0;
  }

  totalDue() {
    return this.dueReceiptObj
      ? this.dueReceiptObj
          .map((data) => data.due)
          .reduce((amt, due) => parseInt(amt) + parseInt(due), 0)
      : 0;
  }

  onDateSelect($event) {
    // console.log("$event--", $event)
    // console.log("this.f_date--", this.f_date)
    // console.log("this.t_date--", this.t_date)
  }

  exportSalesData() {
    var dataArr = [];
    this.dueReceiptObj.forEach((d) => {
      var rowValues = [];
      rowValues = [];
      rowValues[1] = new Date(d.trans_date);
      rowValues[2] = d.trans_no;
      rowValues[3] = d.display_trans_no;
      rowValues[4] = d.table_name;
      rowValues[5] = d.customer_name;
      rowValues[6] = d.full_name;
      rowValues[7] = d.discount_amt;
      rowValues[8] = d.net_amount;
      dataArr.push(rowValues);
    });

    var columnWidth = [
      { column: 1, width: 10, alignment: "center" },
      { column: 2, width: 10, alignment: "center" },
      { column: 3, width: 20, alignment: "center" },
      { column: 4, width: 10, alignment: "center" },
      { column: 5, width: 20, alignment: "center" },
      { column: 6, width: 20, alignment: "center" },
      { column: 7, width: 10, alignment: "right" },
      { column: 8, width: 10, alignment: "right" },
    ];

    var numFmt = [{ column: 1, numFmt: "dd/mm/yyyy" }];

    var frm_date = moment(this.model.from_date).format("DD/MM/YYYY");
    var til_date = moment(this.model.to_date).format("DD/MM/YYYY");
    const file_name = "SalesSummaryReport";
    const title = "Sales Summary Report";
    const subheader = " Sales Summary Report: " + frm_date + " To: " + til_date;
    const header = [
      "Date",
      "Voucher No",
      "Invoice No",
      "Table",
      "Customer Name",
      "User Name",
      "Discount",
      "Amount",
    ];

    this.exportService.generateExcel(
      file_name,
      title,
      header,
      subheader,
      dataArr,
      columnWidth,
      numFmt
    );
  }
}
