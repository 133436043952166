<a  style="text-decoration: none;">
<mat-card class="d-card c-so c-purchase">
  <mat-card-content>
      <i class="material-icons wtico">notification_important</i>
      <mat-card-title *ngIf="ReorderdoCount">
          {{ReorderdoCount.length}} 
          </mat-card-title> 
          
      <mat-card-subtitle> 
          Reorder Stock 
       </mat-card-subtitle>
</mat-card-content>
</mat-card>
</a>