import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/rst-dashboard/booking-form/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-auto-accept-dialoge',
  templateUrl: './auto-accept-dialoge.component.html',
  styleUrls: ['./auto-accept-dialoge.component.css']
})
export class AutoAcceptDialogeComponent implements OnInit {
  preparationTime: any

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.preparationTime = data.preparationTime

  }

  ngOnInit() {
  }


  onConfirm(data: any): void {
    const approveData = {
      preparationTime: this.preparationTime
    }
    const message = approveData
    this.dialogRef.close(message);
  }
}
