import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ChangeUserPassComponent } from '../app-nav/change-user-pass/change-user-pass.component';

@Component({
  selector: 'app-tenent-nav',
  templateUrl: './tenent-nav.component.html',
  styleUrls: ['./tenent-nav.component.css']
})
export class TenentNavComponent {
  isMenuOpen = true;
  contentMargin = 250;
  userName: any;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, 
    private apiService: DataService, public dialog: MatDialog) {
    
   }
  ngOnInit() {
  }
    
  onToolbarMenuToggle() {
    // console.log('On toolbar toggled', this.isMenuOpen);
    this.isMenuOpen = !this.isMenuOpen;

    if (!this.isMenuOpen) {
      this.contentMargin = 90;
    } else {
      this.contentMargin = 250;
    }
  }
  logout() {
    console.log("logout");
  }
  changePass(){
    this.dialog.open(ChangeUserPassComponent,{
      height:'auto',
      width:'auto',
      data: 'Tenent'
    })
  
  }
}
