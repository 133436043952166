<div class='login-backgound'></div>
<form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
    <div class='login-container'>
        <div class="login-header">
            <h3>Tenent Management</h3>

        </div>
        <div class="login-body form-group">

            <h4>Login</h4>
            <hr/>
            <input type='text' class='form-control' name="username" [(ngModel)]="model.username" #username="ngModel" placeholder="User Name" required/>
            <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
            <br/>
            <input type='password' class='form-control' name="password" [(ngModel)]="model.password" #password="ngModel" placeholder='Password' required/>
            <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
            <a class="pull-right" [routerLink]="" (click)="forgetPassClick()">Forget password</a>
            <br/>
            <button [disabled]="loading" class="btn btn-primary">Submit</button>
            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
        </div>
    </div>