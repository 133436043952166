import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-customer-app-cover-image',
  templateUrl: './customer-app-cover-image.component.html',
  styleUrls: ['./customer-app-cover-image.component.css']
})
export class CustomerAppCoverImageComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CustomerAppCoverImageComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // console.log("data-----------",data)
     }
  file: any;
  imageUrl: any;
  ngOnInit() {
    this.load();
  }

  load() {
    if (this.data && this.data.imageurl) {
      this.imageUrl = this.data.imageurl;
    }
  }

  imgInputChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    this.readFile(this.file);
  }
  onSave() {
    if (this.file) {
      const formData: any = new FormData();
      formData.append("upload", this.file, this.file.name);

      this.spinner.show();

      this.apiService.importCoverImages(formData)
        .subscribe((result: any) => {
          this.spinner.hide();
          if (result.success) {
            this.notifier.notify('success', 'Image updated successfully');
            this.file = null;
            this.imageUrl = null
          } else {
            this.notifier.notify('error', result.message);
          }
          this.dialogRef.close("Save");
        }, (result: any) => {
          this.spinner.hide();
          this.notifier.notify('error', 'Unable to update image...try after some time.');
        });
    } else {
      this.notifier.notify('error', 'Please select an image to upload');
    }
  }
  close() {
    this.dialogRef.close();
  }

  readFile(file: File) {
    var reader = new FileReader();
    reader.onload = () => {
      this.imageUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }
}
