import { Component, Inject, OnInit,ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { NotifierService } from 'angular-notifier';
import { MatSort,MatPaginator, MatDialogRef, MatDialog,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { AddEditSubCategoryComponent } from './add-edit-sub-category/add-edit-sub-category.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AddSubCategoryImageComponent } from './add-sub-category-image/add-sub-category-image.component';
@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.css']
})
export class SubCategoryComponent implements OnInit {
   categoryObj: MatTableDataSource<any>;
  roleObj: any;
  constructor(private apiService: DataService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<SubCategoryComponent>, @Inject(MAT_DIALOG_DATA) public data: any = []) { 
   
    }
    checkEnableResturantFlag: boolean;
    height:any;
    displayedColumns:any;
    roleColumns = ['code', 'name', 'seq_no','actions'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit() {
    this.load();
  }
  load() {
    this.apiService.getEnableResturantPos().
    subscribe((result: any) => {
      this.checkEnableResturantFlag = result;
      if (this.checkEnableResturantFlag) {
        this.displayedColumns = ['sub_category_id','code', 'name', 'seq_no','actions'];
      } else {
        this.displayedColumns = ['code', 'name', 'actions'];
      }
      // console.log(this.checkEnableResturantFlag);
    }, (result: any) => {
    });
    this.apiService.getSubCategoryList(this.data.category_id)
      .subscribe((result: any) => {
        this.roleObj = result;      
        this.categoryObj =  new MatTableDataSource(this.roleObj);
        this.categoryObj.sort = this.sort;
        this.categoryObj.paginator = this.paginator;
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });

  }
  addSubCatecoryClick() {
    this.dialog.open( AddEditSubCategoryComponent, {
      width: '500px',
     data : this.data

    })
      .afterClosed().subscribe(result => {
        if (result == 'Added') {
          // this.apiService.getSubCategoryList(this.data.category_id)
          //   .subscribe((result: any) => {
          //     this.roleObj = result[0];
          //   }, (result: any) => {
          //   });
          this.load();
        }
      });
  }
  editSubCatecoryClick(row) {
    this.dialog.open(AddEditSubCategoryComponent, {
      width: '700px',
      data: row
    })
      .afterClosed().subscribe(result => {
        if (result == 'Edited') {
         this.load();
        }
      });
  }
  deleteSubCategory(row) {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Delete Selected Sub Category?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteSubCategory(row.sub_category_id)
            .subscribe((result: any) => {
              if (!result.success)
                this.notifier.notify('error', result.message);
              else
                this.notifier.notify('warning', 'Sub Category deleted successfully');
             this.load()
            }, (result: any) => {
              this.notifier.notify('error', 'unable to delete data');
            });
        }
      });
  }
  close() {
    this.dialogRef.close();
  }
  createImageFromBlob(image: Blob, fn) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      var reader_result = reader.result;
      fn(reader_result);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
  onImagePopup(row) {
    this.dialog.open(AddSubCategoryImageComponent, {
      width: '750px',
      data: row
    })
      .afterClosed().subscribe(result => {
        if (result == 'Save') {
          this.load();
        }
      });

  }
  handelerror(event) {
    event.target.src = '/assets/images/NoImage.png';
  }
}
