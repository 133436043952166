import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";

import { NotifierModule, NotifierOptions } from "angular-notifier";
import { ChartsModule } from "ng2-charts";
import { MatButtonToggleModule } from "@angular/material/button-toggle";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppNavComponent } from "./app-nav/app-nav.component";
import { DataService } from "./data.service";

import { TransactionItemsComponent } from "./transaction-items/transaction-items.component";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { LoginLayoutComponent } from "./login-layout/login-layout.component";

import { RoleGuard } from "./user.guard";
import { AuthInterceptor } from "./auth.interceptor";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ConfigService } from "./config.service";
import { TenentManagementComponent } from "./tenent-management/tenent-management.component";
import { AddEditTenentComponent } from "./tenent-management/add-edit-tenent/add-edit-tenent.component";
import { TenentLoginComponent } from "./tenent-login/tenent-login.component";
import { TenentNavComponent } from "./tenent-nav/tenent-nav.component";
import { TenentLogoutComponent } from "./tenent-logout/tenent-logout.component";
import { CookieService } from "ngx-cookie-service";
import { DateFormat } from "./date-format";
import {
  DateAdapter,
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSidenavModule,
  MatTableModule,
} from "@angular/material";
import { DOComponent } from "./dashboard/do/do.component";
import { SOComponent } from "./dashboard/so/so.component";
import { DPurchaseComponent } from "./dashboard/d-purchase/d-purchase.component";
import { DInvoiceComponent } from "./dashboard/d-invoice/d-invoice.component";
import { SalesInvoiceComponent } from "./dashboard/sales-invoice/sales-invoice.component";
import { ChangeUserPassComponent } from "./app-nav/change-user-pass/change-user-pass.component";
import { SendMailComponent } from "./send-mail/send-mail.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { PurchaseOrderItemsComponent } from "./purchase-order/purchase-order-items/purchase-order-items.component";
import { ExportService } from "./_export-service/daybook-export.service";
import { DueReceiptComponent } from "./receipt/due-receipt/due-receipt.component";
import { DuePaymentComponent } from "./payment/due-payment/due-payment.component";
import { AddEditCustomersMasterComponent } from "./customers-master/add-edit-customers-master/add-edit-customers-master.component";
import { AddEditSuppliersMasterComponent } from "./suppliers-master/add-edit-suppliers-master/add-edit-suppliers-master.component";
import { AddLedgerComponent } from "./ledgers/add-ledger/add-ledger.component";
import { AddItemComponent } from "./items/add-item/add-item.component";
import { TransactionListComponent } from "./transaction-list/transaction-list.component";
import { TaxSummaryService } from "./tax-summary.service";
import { NgxElectronModule } from "ngx-electron";
import { PendingDlvryComponent } from "./dashboard/pending-dlvry/pending-dlvry.component";
import { PendingOrdrsComponent } from "./dashboard/pending-ordrs/pending-ordrs.component";
import { ElectronLoginComponent } from "./electron-login/e-login.component";
import { ElectronLogoutComponent } from "./electron-logout/e-logout.component";
import { AddPoComponent } from "./reorder/add-po/add-po.component";
import { ReorderStockComponent } from "./dashboard/reorder-stock/reorder-stock.component";
import { SharedModule } from "./common.module";
import { UpdateLicenceComponent } from "./update-licence/update-licence.component";
import { DBbackupComponent } from "./db-backup/db-backup.component";
import { CompanyManagementComponent } from "./company-management/company-management.component";
import { AddEditCompanyComponent } from "./company-management/add-edit-company/add-edit-company.component";
import { CustomPreloadingStrategyService } from "./custom-preloading-strategy.service";
import { RedirectComponent } from "./redirect/redirect.component";
import { AddCategoryComponent } from "./category/add-category/add-category.component";
import { AddUnitComponent } from "./units/add-unit/add-unit.component";
import { RstDashboardComponent } from "./rst-dashboard/rst-dashboard.component";
import { RstInvoiceComponent } from "./rst-dashboard/rst-invoice/rst-invoice.component";
import { RstKotComponent } from "./rst-dashboard/rst-kot/rst-kot.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FeaturedItemComponent } from "./items/featured-item/featured-item.component";
import { AddFeaturedItemComponent } from "./items/featured-item/add-featured-item/add-featured-item.component";
import { ItemOptionsCustomComponent } from "./commom-components/item-options-custom/item-options-custom.component";
import { CacheServiceService } from "./cache-service.service";
import { CacheInterceptor } from "./cache-interceptor";
import { ItemsDialogComponent } from "./rst-dashboard/items-dialog/items-dialog.component";
import { ReceiptDialogComponent } from "./rst-dashboard/receipt-dialog/receipt-dialog.component";
import { TabletransferDialogComponent } from "./rst-dashboard/tabletransfer-dialog/tabletransfer-dialog.component";
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AddUnitConverterComponent } from "./unit-converter/add-unit-converter/add-unit-converter.component";
import { CustomerAppCoverImageComponent } from "./theme/customer-app-cover-image/customer-app-cover-image.component";
import { CustomerAppLogoImageComponent } from "./theme/customer-app-logo-image/customer-app-logo-image.component";
import { AddWebsiteImageComponent } from "./theme/add-website-image/add-website-image.component";
import { SmsInfoComponent } from "./company-info/sms-info/sms-info.component";
import { BooktableComponent } from "./rst-dashboard/booktable/booktable.component";
import { BookingFormComponent } from "./rst-dashboard/booking-form/booking-form.component";
import { ConfirmationDialogComponent } from "./rst-dashboard/booking-form/confirmation-dialog/confirmation-dialog.component";
import { AngularDateTimePickerModule } from "angular2-datetimepicker";
import { OrderConfirmationComponent } from "./app-nav/order-confirmation/order-confirmation.component";
import { AutoAcceptDialogeComponent } from "./app-nav/auto-accept-dialoge/auto-accept-dialoge.component";
import { CallRiderComponent } from "./app-nav/call-rider/call-rider.component";
import { PurchaseRateWiseStockComponent } from "./stock-register/purchase-rate-wise-stock/purchase-rate-wise-stock.component";
import { KitchenComponent } from "./kitchen/kitchen.component";
import { AddKitchenComponent } from "./kitchen/add-kitchen/add-kitchen.component";
import { PlaceSelectionComponent } from "./company-info/place-selection/place-selection.component";
import { AddEditPriceListComponent } from "./items/items-price-list/add-edit-price-list/add-edit-price-list.component";
import { AddEditItemsComponent } from "./items/items-price-list/add-edit-items/add-edit-items.component";
import { ImortPriceListItemComponent } from "./items/items-price-list/add-edit-price-list/imort-price-list-item/imort-price-list-item.component";
import { KotCancelDialogComponent } from "./rst-dashboard/rst-kot/kot-cancel-dialog/kot-cancel-dialog.component";
import { ItemsVariantComponent } from "./items/items-price-list/add-edit-items/items-variant/items-variant.component";
import { ItemsVariantListComponent } from "./items/items-price-list/add-edit-items/items-variant-list/items-variant-list.component";
import { EditItemVariantComponent } from "./items/items-price-list/add-edit-items/items-variant-list/edit-item-variant/edit-item-variant.component";
import { ImportCustomerComponent } from "./crm/import-customer/import-customer.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CategorySalesReportComponent } from "./category-sales-report/category-sales-report.component";
import { ExpenseComponent } from "./expense/expense.component";
import { EditExpenseComponent } from "./expense/edit-expense/edit-expense.component";
import { DiscountReportComponent } from "./discount-report/discount-report.component";
import { KotCancellationsComponent } from "./kot-cancellations/kot-cancellations.component";
import { BilCancellationComponent } from "./bil-cancellation/bil-cancellation.component";
import { OustandingReportComponent } from "./oustanding-report/oustanding-report.component";
import { TakeawayMemoComponent } from "./takeaway-memo/takeaway-memo.component";
import { PaymentSettingsComponent } from "./company-info/payment-settings/payment-settings.component";

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: "right",
      distance: 12,
    },
    vertical: {
      position: "top",
      distance: 82,
      gap: 10,
    },
  },
  theme: "material",
  behaviour: {
    autoHide: 5000,
    onClick: "hide",
    onMouseover: "pauseAutoHide",
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: "slide",
      speed: 300,
      easing: "ease",
    },
    hide: {
      preset: "fade",
      speed: 300,
      easing: "ease",
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: "ease",
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    CategorySalesReportComponent,
    AppComponent,
    AppNavComponent,
    TenentNavComponent,
    TransactionItemsComponent,
    PurchaseOrderItemsComponent,
    ConfirmDialogComponent,
    LoginComponent,
    ElectronLoginComponent,
    LogoutComponent,
    ElectronLogoutComponent,
    LoginLayoutComponent,
    TenentLoginComponent,
    TenentLogoutComponent,
    AccessDeniedComponent,
    DashboardComponent,
    TenentManagementComponent,
    AddEditTenentComponent,
    DOComponent,
    SOComponent,
    DPurchaseComponent,
    DInvoiceComponent,
    SalesInvoiceComponent,
    ChangeUserPassComponent,
    SendMailComponent,
    DueReceiptComponent,
    DuePaymentComponent,
    AddEditCustomersMasterComponent,
    AddEditSuppliersMasterComponent,
    AddLedgerComponent,
    AddItemComponent,
    AddCategoryComponent,
    AddUnitComponent,
    TransactionListComponent,
    PendingDlvryComponent,
    PendingOrdrsComponent,
    AddPoComponent,
    ReorderStockComponent,
    UpdateLicenceComponent,
    DBbackupComponent,
    CompanyManagementComponent,
    AddEditCompanyComponent,
    RedirectComponent,
    RstDashboardComponent,
    RstInvoiceComponent,
    RstKotComponent,
    FeaturedItemComponent,
    AddFeaturedItemComponent,
    ItemOptionsCustomComponent,
    ItemsDialogComponent,
    ReceiptDialogComponent,
    TabletransferDialogComponent,
    AddUnitConverterComponent,
    CustomerAppCoverImageComponent,
    CustomerAppLogoImageComponent,
    AddWebsiteImageComponent,
    SmsInfoComponent,
    BooktableComponent,
    BookingFormComponent,
    ConfirmationDialogComponent,
    OrderConfirmationComponent,
    AutoAcceptDialogeComponent,
    CallRiderComponent,
    PurchaseRateWiseStockComponent,
    AddKitchenComponent,
    PlaceSelectionComponent,
    AddEditPriceListComponent,
    AddEditItemsComponent,
    ImortPriceListItemComponent,
    ItemsVariantComponent,
    ItemsVariantListComponent,
    EditItemVariantComponent,
    KotCancelDialogComponent,
    ImportCustomerComponent,
    ExpenseComponent,
    TakeawayMemoComponent,
    EditExpenseComponent,
    DiscountReportComponent,
    KotCancellationsComponent,
    BilCancellationComponent,
    OustandingReportComponent,
    PaymentSettingsComponent,
  ],
  imports: [
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonToggleModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ChartsModule,
    NgxElectronModule,
    NotifierModule.withConfig(customNotifierOptions),
    MatTooltipModule,
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    AngularDateTimePickerModule, // AngularFontAwesomeModule
    MatMenuModule,
  ],
  providers: [
    CacheServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    { provide: DateAdapter, useClass: DateFormat },
    DataService,
    TaxSummaryService,
    ConfigService,
    RoleGuard,
    AppNavComponent,
    TenentNavComponent,
    CookieService,
    ExportService,
    CustomPreloadingStrategyService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TakeawayMemoComponent,
    CategorySalesReportComponent,
    TransactionItemsComponent,
    PurchaseOrderItemsComponent,
    ConfirmDialogComponent,
    AddEditTenentComponent,
    ChangeUserPassComponent,
    SendMailComponent,
    DueReceiptComponent,
    DuePaymentComponent,
    AddEditCustomersMasterComponent,
    AddEditSuppliersMasterComponent,
    AddLedgerComponent,
    AddItemComponent,
    AddCategoryComponent,
    AddUnitComponent,
    TransactionListComponent,
    AddPoComponent,
    DBbackupComponent,
    AddEditCompanyComponent,
    CompanyManagementComponent,
    RstKotComponent,
    FeaturedItemComponent,
    AddFeaturedItemComponent,
    ItemOptionsCustomComponent,
    ItemsDialogComponent,
    ReceiptDialogComponent,
    TabletransferDialogComponent,
    AddUnitConverterComponent,
    CustomerAppCoverImageComponent,
    CustomerAppLogoImageComponent,
    AddWebsiteImageComponent,
    SmsInfoComponent,
    BooktableComponent,
    BookingFormComponent,
    ConfirmationDialogComponent,
    OrderConfirmationComponent,
    AutoAcceptDialogeComponent,
    CallRiderComponent,
    PurchaseRateWiseStockComponent,
    AddKitchenComponent,
    PlaceSelectionComponent,
    AddEditItemsComponent,
    ImortPriceListItemComponent,
    AddEditPriceListComponent,
    TenentNavComponent,
    ItemsVariantComponent,
    ItemsVariantListComponent,
    EditItemVariantComponent,
    KotCancelDialogComponent,
    ImportCustomerComponent,
    EditExpenseComponent,
    PaymentSettingsComponent,
  ],
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale("en-in"); // DD/MM/YYYY
  }
}
