<div class="dialog-title" mat-dialog-title>
  <h3 *ngIf="!item_id" mat-dialog-title class="color-h3">
    <span>Add Price List</span>
  </h3>
  <h3 *ngIf="item_id" mat-dialog-title class="color-h3">
    <span>Edit Price List</span>
  </h3>
</div>
<div class="table-details">
  <mat-dialog-content>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="contract-full-width">
          <input
            matInput
            type="number"
            placeholder="Item ID"
            [(ngModel)]="model.item_id"
            name="item_id"
            [readonly]="status == 'update'"
            required
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="contract-full-width">
          <input
            matInput
            type="number"
            placeholder="Sale Rate"
            [(ngModel)]="model.s_rate"
            name="sale_rate"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="contract-full-width">
          <input
            matInput
            type="number"
            placeholder="Taxslab"
            [(ngModel)]="model.taxslab"
            name="gst"
          />
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button class="btn" type="button" (click)="onClose()">
      Close
    </button>

    <!-- <button
      *ngIf="item_id"
      mat-raised-button
      class="btn"
      type="button"
      (click)="addvariant()"
    >
      variant
    </button> -->
    <button
      mat-raised-button
      class="btn-primary"
      type="button"
      (click)="onSubmit()"
    >
      {{ status == "add" ? "Save" : "Update" }}
    </button>
  </mat-dialog-actions>
</div>
