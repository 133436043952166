import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-item-options-custom',
  templateUrl: './item-options-custom.component.html',
  styleUrls: ['./item-options-custom.component.css']
})
export class ItemOptionsCustomComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ItemOptionsCustomComponent>, 
              private apiService: DataService, 
              private notifier: NotifierService, 
              public dialog: MatDialog, 
              @Inject(MAT_DIALOG_DATA) public _options: any
            ) { 
              this.currencyCode = environment.currency.toUpperCase();
             }
  @Output() onItemOptionSelected: EventEmitter<any> = new EventEmitter<any>();
  options = [];
  selectedItemOptions = [];
  currencyCode: any;
  totalAmount: Number = 0;
  rate: Number = 0;
  quantity: Number = 0;
            
  ngOnInit() {
    this.options = _.cloneDeep(this._options);
    if (this.options.filter(item => item.checked ? true : false).length === 0) {
      this.markAllUnchecked(this.options);
      if (this.options.length > 1) {
        this.options[0].checked = true;
      }
    }
    this.totalAmount = this.calculateTotalAmount(this.options);
    this.rate = this.calculateRate(this.options);
    this.quantity = this.calculateQuantity(this.options);
  }

  markAllUnchecked(options) {
    options.map(item => {
      item.checked = false;
      if (item.childOptions && item.childOptions.length > 0) {
        this.markAllUnchecked(item.childOptions);
      }
    });
  }

  onClick(inputType, parentIndex, childIndex = null) {
    /* if (inputType === 1) {
      if (parentIndex !== null && childIndex === null) {
        this.options[parentIndex].checked = true;
        this.options.map((item, _index) => {
          if (_index !== parentIndex) {
            item.checked = false;
          }
        });
      } else {
        this.options[parentIndex].childOptions[childIndex].checked = true;
        this.options[parentIndex].childOptions.map((item, _index) => {
          if (_index !== childIndex) {
            item.checked = false;
          }
        });
      }
    } else {
      if (parentIndex !== null && childIndex === null) {
        this.options[parentIndex].checked = !this.options[parentIndex].checked;
        if (!this.options[parentIndex].checked) {
          this.options[parentIndex].childOptions.map((item, _index) => item.checked = false);
        }
      } else {
        this.options[parentIndex].childOptions[childIndex].checked = !this.options[parentIndex].childOptions[childIndex].checked;
      }
    } */
    if (parentIndex !== null && childIndex === null) {
      if (this.options.length > 1) {
        this.options[parentIndex].checked = true;
        this.options.map((item, index) => {
          if (index !== parentIndex)
          item.checked = false;
          this.markAllUnchecked(item.childOptions);
        });
        this.markAllUnchecked(this.options[parentIndex].childOptions);
      } else {
        this.options[parentIndex].checked = !this.options[parentIndex].checked;
        this.markAllUnchecked(this.options[parentIndex].childOptions);
      }
      this.options[parentIndex].default_value = 1;
    } else {
      if (inputType === 1) {
        this.options[parentIndex].childOptions[childIndex].checked = true;
        this.options[parentIndex].childOptions.map((item, index) => {
          if (index !== childIndex && inputType === 1) {
            this.options[parentIndex].childOptions[index].checked = false;
          }
        });
      } else {
        this.options[parentIndex].childOptions[childIndex].checked = !this.options[parentIndex].childOptions[childIndex].checked;
      }
    }
    this.totalAmount = this.calculateTotalAmount(this.options)
    this.rate = this.calculateRate(this.options);
    this.quantity = this.calculateQuantity(this.options);
  }

  onButtonClick(action, parentIndex, childIndex = null) {
    if (action === 'plus') {
      if (parentIndex !== null && childIndex === null) {
        this.options[parentIndex].default_value = parseFloat(this.options[parentIndex].default_value) + 1;
      } else {
        this.options[parentIndex].childOptions[childIndex].default_value = parseFloat(this.options[parentIndex].childOptions[childIndex].default_value) + 1;
      }
      this.totalAmount = this.calculateTotalAmount(this.options);
      this.quantity = this.calculateQuantity(this.options);
    } else {
      if (parentIndex !== null && childIndex === null) {
        if (this.options[parentIndex].default_value > 1)
        this.options[parentIndex].default_value = parseFloat(this.options[parentIndex].default_value) - 1;
      } else {
        if (this.options[parentIndex].childOptions[childIndex].default_value > 1)
        this.options[parentIndex].childOptions[childIndex].default_value = parseFloat(this.options[parentIndex].childOptions[childIndex].default_value) - 1;
      }
      this.totalAmount = this.calculateTotalAmount(this.options);
      this.quantity = this.calculateQuantity(this.options);
    }
  }

  onOkClick() {
    /* this.selectedItemOptions = [];
    this.options.forEach(item => {
      if (item.checked) {
        let obj:any = { id: item.id, default_value:item.default_value, amount: item.amount , title: item.title, notes: item.notes, s_input_type: item.s_input_type, checked: item.checked, qty_selection_req: item.qty_selection_req };
        if (item.childOptions && item.childOptions.length > 0) {
          let childObj = item.childOptions.filter(item => item.checked);
          obj.childOptions = item.childOptions.map(item => ({ id:item.id, default_value:item.default_value, amount: item.amount, title: item.title, notes: item.notes, s_input_type: item.s_input_type, checked: item.checked, qty_selection_req: item.qty_selection_req })) 
        } else {
          obj.childOptions = []
        }
        this.selectedItemOptions.push(obj);
      }
    }); */
    this.onItemOptionSelected.emit({ selectedItemOptions: this.options, rate: this.rate, quantity: this.quantity })
    this.close();
  }

  calculateTotalAmount(options) {
    let amt = 0;
    for (let item of options) {
      if (item.checked) {
        amt += parseFloat(item.amount) + this.calculateTotalAmount(item.childOptions);
        if (item.qty_selection_req) {
          amt = item.default_value * amt; 
        }
      }
    }
    return amt; 
  }

  calculateRate(options) {
    let amt = 0;
    for (let item of options) {
      if (item.checked) {
        amt += parseFloat(item.amount) + this.calculateTotalAmount(item.childOptions);
      }
    }
    return amt; 
  }

  calculateQuantity(options) {
    let quantity = 0;
    for (let item of options) {
      if (item.checked) {
        quantity = +item.default_value;
      }
    }
    return quantity;
  }

  close() {
    // this.options = [];
    this.dialogRef.close();
  }

}
