import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { DataService } from "../../../../../data.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-edit-item-variant",
  templateUrl: "./edit-item-variant.component.html",
  styleUrls: ["./edit-item-variant.component.css"],
})
export class EditItemVariantComponent implements OnInit {
  model: any = {};
  id: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditItemVariantComponent>,
    private apiService: DataService,
    private notifier: NotifierService
  ) {
    this.model.variant_id = data.variant_id;
    this.model.s_rate = data.s_rate;
    this.id = data.id;
    console.log("thisid", this.id);
  }

  ngOnInit() {
    this.load();
  }

  onClose() {
    this.dialogRef.close();
  }

  load() {}

  updatevar() {
    this.apiService.updatepricelistvariant(this.id, this.model).subscribe(
      (response: any) => {
        if (response.success) {
          this.notifier.notify("success", response.message);
          // Pass the updated model back to the parent component
          this.dialogRef.close(this.model);
        } else {
          this.notifier.notify("error", response.message);
        }
      },
      (err) => {
        this.notifier.notify("error", err);
      }
    );
  }
}
