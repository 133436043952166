<h2 *ngIf="data" class="color-h3" mat-dialog-title>Customer App Logo Upload</h2>
<div class="table-details">
  <mat-dialog-content>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <input #imgInput hidden="true" type="file" (change)="imgInputChange($event)" accept=".jpg,.jpeg,.png">
          <button mat-flat-button class="btn mr-1" (click)="imgInput.click()">Choose Image</button>
          <span *ngIf="file">{{file.name}}</span>
          <span *ngIf="!file">No File Choosen</span>
        </div>
        <img *ngIf="imageUrl" class="item-image" [src]="imageUrl" alt="">
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn mat-raised-button" (click)="close()">Close</button>
    <button class="btn-primary mat-raised-button" (click)="onSave()">Save</button>
  </mat-dialog-actions>
</div>
