import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import { DataService } from "src/app/data.service";
import { DatePipe } from "@angular/common";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-receipt-dialog",
  templateUrl: "./receipt-dialog.component.html",
  styleUrls: ["./receipt-dialog.component.css"],
})
export class ReceiptDialogComponent implements OnInit {
  paymentMode: any;
  receipt: any = [];
  billsdata: any = [];
  refund_amt: any = 0;
  formattedDate: any;
  dateStr: any = this.data.trans_date;
  currency: any;
  model: any = { trans_data: [], trans_type: [] };
  constructor(
    private dialogRef: MatDialogRef<ReceiptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = [],
    private notifier: NotifierService,
    private apiService: DataService,
    private datePipe: DatePipe
  ) {
    // console.log("MAT_DIALOG_DATAreceipt-------", data)
    this.currency = environment.currency;
    this.load();
  }
  load() {
    this.form.patchValue({
      cash: this.data.cash > 0 ? this.data.cash : null,
      card: this.data.card > 0 ? this.data.card : null,
      upi: this.data.upi > 0 ? this.data.upi : null,
      card_ref_no: this.data.card_ref_no > 0 ? this.data.card_ref_no : null,
      advance: this.data.advance > 0 ? this.data.advance : null,
      net_amount: this.data.net_amount > 0 ? this.data.net_amount : null,
      cash_receive: this.data.cash_receive > 0 ? this.data.cash_receive : null,
    });
  }
  ngOnInit() {
    this.formattedDate = this.datePipe.transform(
      this.dateStr,
      "dd-MM-yyyy HH:mm:ss"
    );
  }

  form: FormGroup = new FormGroup({
    cash: new FormControl(0, Validators.required),
    card: new FormControl(0, Validators.required),
    upi: new FormControl(0, Validators.required),
    card_ref_no: new FormControl(null),
    advance: new FormControl(0),
    net_amount: new FormControl(0),
    cash_receive: new FormControl(0),
    // upi_receive: new FormControl(0)
  });

  initializeFormGroup() {
    this.form.setValue({
      cash: 0,
      card: 0,
      upi: 0,
      card_ref_no: null,
      advance: 0,
      net_amount: 0,
      cash_receive: 0,
    });
    // this.itemsArr = [];
    // this.calculateAmtWithTransTax()
    // this.soIdArr = [];
    // this.kotArr.filter(x => x.added === true).forEach(x => this.kotArr.splice(this.kotArr.indexOf(x), 1));
    // this.allow_login = false;
    window.scroll(0, 0);
  }

  onCashOrCard() {
    //   var total_advance = this.form.value.cash || 0 + this.form.value.card || 0 + this.form.value.upi || 0;
    //   var net_amount_amt = this.data.net_amount - total_advance;
    //   this.form.get('advance').setValue(total_advance);
    //   this.form.get('net_amount').setValue(net_amount_amt);
    //   if (this.form.value.cash_receive >= this.form.value.cash) {
    //     this.refund_amt = this.form.value.cash_receive - this.form.value.cash
    //   }
    // }

    const cashValue = this.form.value.cash || 0;
    const cardValue = this.form.value.card || 0;
    const upiValue = this.form.value.upi || 0;

    const total_advance = cashValue + cardValue + upiValue;
    const net_amount_amt = (this.data ? this.data.net_amount : 0) - total_advance;

    this.form.get("advance").setValue(total_advance);
    this.form.get("net_amount").setValue(net_amount_amt);

    if (this.form.value.cash_receive >= cashValue) {
      this.refund_amt = this.form.value.cash_receive - cashValue;
    }
  }

  refreshPayment() {
    if (this.form.value.cash_receive >= this.form.value.net_amount) {
      this.form.get("cash").setValue(this.form.value.net_amount);
    }
    this.onCashOrCard();
  }
  onCashReceive() {
    if (this.form.value.cash_receive >= this.form.value.net_amount) {
      this.form.get("cash").setValue(this.form.value.net_amount);
      this.onCashOrCard();
    }
  }

  wait: boolean = false;
  onReceipt() {
    this.wait = true;
    if (this.form.controls.upi.value > 0) {
      this.paymentMode = "upi";
    } else if (this.form.controls.cash.value) {
      this.paymentMode = "cash";
    } else {
      this.paymentMode = "card";
    }
    if (this.data) {
      if (this.form.controls.advance.value) {
        if (this.form.controls.net_amount.value == 0) {
          this.billsdata.push({
            transaction_id: this.data.transaction_id,
            trans_date: this.data.trans_date,
            trans_no: this.data.trans_no,
            display_trans_no: this.data.display_trans_no,
            customer_name: this.data.customer_name,
            table_id: this.data.table_id,
            table_name: "T2",
            net_amount: this.data.net_amount,
            discount_amt: this.data.discount_amt,
            advance: this.form.controls.advance.value,
            paid: "0",
            due: this.data.net_amount,
            bill_amt: this.data.net_amount,
          });
          this.receipt.push({
            bills: this.billsdata,
            ledger_id: 17,
            ledger_id2: 1,
            payment_mode: this.paymentMode,
            total_amount: this.form.controls.advance.value,
            trans_date: this.data.trans_date,
            trans_no: null,
            trans_type: "Receipt",
            transaction_id: this.data.transaction_id,
            cash: this.form.controls.cash.value,
            card: this.form.controls.card.value,
            upi: this.form.controls.upi.value,
          });
          // console.log("this.receipt[0]", this.receipt[0])
          // console.log("this.form.controls--", this.receipt[0])

          this.apiService.addReceipt(this.receipt[0]).subscribe(
            (result: any) => {
              if (result.success) {
                this.receipt.trans_no = result.max_trans_value;
                this.receipt.transaction_id = result.transaction_id;
                this.receipt.trans_type = "Receipt";
                // this.totalAmtReadonlyFlg = false;
                this.receipt.bills = null;
                // this.apiService.downloadPdf(this.receipt)
                //   .subscribe((result: any) => {
                //     let blob = new Blob([result], {
                //       type: 'application/pdf'
                //     });
                //     var link = document.createElement('a');
                //     link.href = window.URL.createObjectURL(blob);
                //     link.download = 'receipt' + this.model.trans_no + '.pdf';
                //     link.click();
                //     window.URL.revokeObjectURL(link.href);
                //     // this.clearOnSubmit();
                //   }, (result: any) => {
                //   });
                this.printClick();
                this.notifier.notify("success", "Receipt created successfully");
                this.data = undefined;
                this.billsdata = [];
                this.receipt = [];
                this.dialogRef.close({
                  status: "OK",
                  message: "Receipt created successfully",
                });
                // this.panel4.close()
                this.initializeFormGroup();
                this.wait = false;
                this.receipt = [];

                // this.load()
              } else {
                this.notifier.notify("error", result.message);
                this.wait = false;
                this.load();
                this.receipt = [];
              }
            },
            (result: any) => {
              this.notifier.notify("error", "unable to create Receipt");
              this.wait = false;
              this.load();
              this.receipt = [];
            }
          );
        } else {
          this.notifier.notify(
            "error",
            "Paid Amount Is Not Equal To Due Amount"
          );
          this.wait = false;
          this.load();
          this.receipt = [];
        }
      } else {
        this.notifier.notify("error", "Amount Not Found");
        this.wait = false;
        this.load();
        this.receipt = [];
      }
    } else {
      this.notifier.notify("error", "No invoice Found");
      this.wait = false;
    }
  }
  printClick() {
    this.model.trans_data = this.receipt;
    this.model.trans_type = "Receipts";
    this.apiService.downloadPdf(this.model).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "recepit_data" + new Date().toLocaleDateString() + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => { }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }
}
