import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatAutocompleteSelectedEvent,
  MatSelect,
} from "@angular/material";

import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DataService } from "src/app/data.service";
import { NotifierService } from "angular-notifier";
import { RstKotComponent } from "../rst-kot.component";

@Component({
  selector: "app-kot-cancel-dialog",
  templateUrl: "./kot-cancel-dialog.component.html",
  styleUrls: ["./kot-cancel-dialog.component.css"],
})
export class KotCancelDialogComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<KotCancelDialogComponent>,
    private dialogReff: MatDialogRef<RstKotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  myControl = new FormControl("");
  options: string[] = ["Change of Mind", "Incorrect Order", "Price Concerns", "Service Issues"];
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    console.log("THIS.DATA", this.data);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  cancelKOT() {
    const Reason = { cancel_reason: this.myControl.value };
    console.log("reason",Reason.cancel_reason)
    if (Reason.cancel_reason) {
      this.apiService
        .deleteSalesOrder(this.data.transaction_id, Reason)
        .subscribe(
          (result: any) => {
            if (result.success) {
              this.notifier.notify("success", "KOT Canceled successfully");
              this.dialogReff.close({ success: true });
            } else {
              this.notifier.notify("error", result.message);
            }
          },
          (error: any) => {
            this.notifier.notify("error", "unable to cancel KOT");
          }
        );
    }else{
      this.notifier.notify("error", "Please Select Or Write Reason");

    }
  }
}
