import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-add-kitchen',
  templateUrl: './add-kitchen.component.html',
  styleUrls: ['./add-kitchen.component.css']
})
export class AddKitchenComponent implements OnInit {

  @ViewChild('f') f;
  constructor(private dialogRef: MatDialogRef<AddKitchenComponent>,
    private notifier: NotifierService,
    private apiService: DataService, 
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.f.submitted = true;
    this.load();
  }
  model: any = {};
  load(){
    if(this.data){
      this.apiService.getkitchen(this.data.kitchen_id)
      .subscribe((result: any) => {
        this.model=result[0];
      }, (result: any) => {
        this.notifier.notify( 'error', 'unable to load data' );
      });
    }
  }
  onSubmit() {
    if(this.data){
      this.apiService.editKitchen(this.model.kitchen_id,this.model)
      .subscribe((result: any) => {
      this.dialogRef.close('Edited');
      }, (result: any) => {
        this.notifier.notify( 'error', 'unable to update kitchen details' );
      });
    }else{
      this.apiService.addkitchen(this.model)
      .subscribe((result: any) => {
        if(result.success)
        this.dialogRef.close({status:'Added',kitchen_code:this.model.kitchen_code});
      else
      this.notifier.notify( 'error', result.message );      

      }, (result: any) => {
        this.notifier.notify( 'error', 'unable to add new kitchen' );
      });
    }
    
  }
  
  close(){
    this.dialogRef.close();
  }

}
