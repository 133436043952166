import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { NotifierService } from 'angular-notifier';
import { SubCategoryComponent } from 'src/app/sub-category/sub-category.component';

import { DataService } from '../../data.service';
import { FormControl } from '@angular/forms';
import { AddKitchenComponent } from 'src/app/kitchen/add-kitchen/add-kitchen.component';
@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  @ViewChild('f') f;
  constructor(private dialogRef: MatDialogRef<AddCategoryComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  model: any = {};
  isRestaurantCategory: boolean = false;
  condition: boolean = false;
  disposalcondition: boolean = false;
  kitchen = new FormControl();
  kitchens: any;
  kitchenObj: any;
  ngOnInit() {
    this.load();
  }
  subCategory: any;
  load() {
    this.apiService.getEnableResturantPos().
      subscribe((result: any) => {
        if (result) {
          this.isRestaurantCategory = true;
        }
      }, (result: any) => {
      });

    this.f.submitted = true;
    this.model.maintain_stock = this.data ? this.data.maintain_stock : true;
    this.model.restaurant_menu_items = this.data ? this.data.restaurant_menu_items : false;
    this.model.consumable = this.data ? this.data.consumable : true;
    this.model.disposal = this.data ? this.data.disposal : true;
    if (this.data) {
      if (this.data.consumable == true) {

        this.condition = this.data.consumable;
      }
      if (this.data.disposal == true) {

        this.disposalcondition = this.data.disposal;
      }

      this.apiService.getCategory(this.data.category_id)
        .subscribe((result: any) => {
          this.model = result[0];

          //set kitchen 
          var indexofKitchen = this.kitchenObj.findIndex(
            (i) => i.kitchen_id === result[0].kitchen_id
          );
          console.log("this.kitchenObj[indexofKitchen]--", this.kitchenObj, indexofKitchen, this.kitchenObj[indexofKitchen])
          this.kitchen.setValue(this.kitchenObj[indexofKitchen]);
        }, (result: any) => {
          this.notifier.notify('error', 'unable to load data');
        });
    }
    // Get kitchen list
    this.apiService.getKitchenList().subscribe(
      (result: any) => {
        this.kitchenObj = result;
      },
      (result: any) => {
        this.notifier.notify("error", "unable to load Kitchen list");
      }
    );
  }
  checkStatus(event) {
    this.model.maintain_stock = event.checked;
  }
  consumableStatus(e) {
    this.model.consumable = e.checked
  }
  disposiableStatus(e) {
    this.model.disposal = e.checked
  }
  restaurantmenu(e) {
    this.model.restaurant_menu_items = e.checked;
  }
  onSubmit() {
    if (this.data) {
      // console.log("this.model onedit---",this.model)

      this.apiService.editCategory(this.model.category_id, this.model)
        .subscribe((result: any) => {
          this.dialogRef.close('Edited');
          this.notifier.notify('success', 'category updated successfully');
        }, (result: any) => {
          this.notifier.notify('error', 'unable to update data');
        });
    } else {
      // console.log("this.model onsubmit---",this.model)
      this.apiService.addCategory(this.model)
        .subscribe((result: any) => {
          if (result.success) {
            this.dialogRef.close({ status: 'Added', category_code: this.model.category_code });
            this.notifier.notify('success', 'new category added successfully');
          } else {
            this.notifier.notify('error', result.message);
          }


        }, (result: any) => {
          this.notifier.notify('error', 'unable to add new category');
        });
    }

  }

  close() {
    this.dialogRef.close();
  }

  subCategoryClick() {
    this.dialog.open(SubCategoryComponent, {
      width: '600px',
      data: this.data
    })
      .afterClosed().subscribe(result => {
        if (result == 'Added') {
          this.apiService.getSubCategoryList(this.data.cat_id)
            .subscribe((result: any) => {
              this.subCategory = result;
            }, (result: any) => {
            });
        }
      });
  }

  // On focus on kitchen input
  kitchenonfocus(e) {
    this.kitchens = this.kitchenObj;
  }

  //kitchen tab 
  searchkitchen(event) {
    if (
      event.key != "ArrowDown" &&
      event.key != "ArrowUp" &&
      event.key != "Enter"
    ) {
      if (this.kitchen.value.length >= 1) {
        this.kitchens = this.kitchenObj.filter((kitchen) =>
          kitchen.kitchen_name.toLowerCase().includes(this.kitchen.value)
        );
      } else {
        this.kitchens = this.kitchenObj;
      }
    }
  }

  //when select any kitchen from dropdown
  kitchenselection(e) {
    if (e.option.value != 0) {
      console.log("this.model.kitchen_id = e.option.value.kitchen_id--", this.model.kitchen_id, e.option.value.kitchen_id)
      this.model.kitchen_id = e.option.value.kitchen_id;
    }
  }

  // after selection it will show in the tab
  displaykitchen(kitchen) {
    return kitchen ? kitchen.kitchen_name : "";
  }

  //add new kitchen
  onAddKitchenClick() {
    this.dialog
      .open(AddKitchenComponent, {
        width: "500px",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.status == "Added") {
          this.apiService.getKitchenList().subscribe(
            (result: any) => {
              this.kitchenObj = result;
              var kitchenIndex = this.kitchenObj.findIndex(
                (kitchen) => kitchen.kitchen_code == res.kitchen_code
              );
              this.kitchen.setValue(this.kitchenObj[kitchenIndex]);
              this.model.kitchen_id = this.kitchenObj[kitchenIndex].kitchen_id;
            },
            (result: any) => {
              this.notifier.notify("error", "unable to load kitchen list");
            }
          );
        }
      });
  }
}