<div class="panelcard">
  <h3 class="color-h3">Outstanding Report</h3>
  <div class="table-details">
    <form>
      <div class="row">
        <div class="col-md-3">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              [matDatepicker]="from_date"
              placeholder="From:"
              [(ngModel)]="model.from_date"
              name="from_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="from_date"
            ></mat-datepicker-toggle>
            <mat-datepicker #from_date></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              [matDatepicker]="to_date"
              placeholder="To:"
              [(ngModel)]="model.to_date"
              name="to_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="to_date"
            ></mat-datepicker-toggle>
            <mat-datepicker #to_date></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- <div class="col-md-3">
          <mat-form-field class="contract-full-width">
            <mat-select
              placeholder="Voucher Type:"
              [(ngModel)]="model.voucher_type"
              name="voucher_type"
              required
            >
              <mat-option
                *ngFor="let voucher of voucherObj"
                [value]="voucher.v_type_id"
              >
                {{ voucher.v_type_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="col-md-1">
            <button class="mat-raised-button mat-primary" (click)="serachClick()">Search</button>
        </div>
        <div class="col-md-1">
            <div class="btn-group" style="margin-left:8px;">
                <button type="button" #btnGrp class="btn btn-primary" (click)="printClick()">Print</button>
                <button type="button" class="btn btn-primary dropdown-toggle" [matMenuTriggerFor]="btnmenu">
                  <span class="caret"></span>
                </button>
                <mat-menu #btnmenu="matMenu" class="btn-grp">
                  <a mat-menu-item (click)="ExportTOExcel()">
                      Export
                    </a>
              </mat-menu>
              </div>
        </div> -->
        <div class="col-md-3 col-sm-6">
          <button class="btn mat-raised-button" (click)="serachClick()">
            Search
          </button>
          <div class="btn-group" style="margin-left: 8px">
            <button type="button" #btnGrp class="btn" (click)="printClick()">
              Print
            </button>
            <button
              type="button"
              class="btn ml-1 dropdown-toggle"
              [matMenuTriggerFor]="btnmenu"
            >
              <span class="caret"></span>
            </button>
            <mat-menu #btnmenu="matMenu" class="btn-grp">
              <a mat-menu-item (click)="ExportTOExcel()"> Export </a>
            </mat-menu>
          </div>
        </div>
      </div>
    </form>
    <table mat-table [dataSource]="accountVoucherObj" matSort>
      <!-- voucher_no Column -->
      <ng-container matColumnDef="invoice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice No</th>
        <td mat-cell *matCellDef="let row" data-label="Voucher No">
          {{ row.display_trans_no }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <!--voucher_date column-->
      <ng-container matColumnDef="trans_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Voucher Date</th>
        <td mat-cell *matCellDef="let row" data-label="Voucher Date">
          {{ row.trans_date | date : "dd/MMM/yyyy" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <!--ledger_name column-->
      <ng-container matColumnDef="cutomer_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
        <td mat-cell *matCellDef="let row" data-label="Customer Name">
          <a *ngIf="row.active" [routerLink]="" (click)="edittransaction(row)"
            >{{ row.cutomer_name }}
            <span *ngIf="row.v_type_name == 'Purchase' && row.bill_no"
              >- {{ row.bill_no }}</span
            >
            <span *ngIf="row.customer_name">- ({{ row.customer_name }})</span>
          </a>
          <span *ngIf="!row.active"
            >{{ row.ledger_name }}
            <span *ngIf="row.v_type_name == 'Purchase' && row.bill_no"
              >- {{ row.bill_no }}</span
            >
            <span *ngIf="row.customer_name">- ({{ row.customer_name }})</span>
          </span>
        </td>
        <td mat-footer-cell *matFooterCellDef>Total:</td>
      </ng-container>
      <!--amount column-->
      <ng-container matColumnDef="net_amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let row" data-label="Amount">
          {{ row.balance | number : "1.2-2" }}
        </td>
        <!-- <td mat-footer-cell *matFooterCellDef>
          {{ getTotalAmt() | number : "1.2-2" }}
        </td> -->
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          'table-danger': row.active === false,
          'table-info': row.non_receivable === true
        }"
      ></tr>
      <!-- <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns"
        [class]="total - row"
      ></tr> -->
    </table>
  </div>
</div>
