<h3 mat-dialog-title class="color-h3">Choose Options</h3>
<mat-dialog-content class="mat-typography">
  <div style="height: 400px;">
    <!-- <ng-container *ngIf="options.length > 1"> -->
      <ng-container *ngFor="let option of options; let i = index">
        <div class="parent-container">
          <div class="row justify-content-between">
            <div class="col-4 column-box">
              <span class="title">{{ option.title }}</span>
              <span class="notes">{{ option.notes }}</span>
            </div>
            <div class="col-4 quantity-column" *ngIf="option.checked">
              <div *ngIf="option.qty_selection_req">
                <button class="btn btn-primary" (click)="onButtonClick('minus', i)">-</button>
                <input type="number" [(ngModel)]="option.default_value" name="quantity" required>
                <button class="btn btn-primary" (click)="onButtonClick('plus', i)">+</button>
              </div>
            </div>
            <div class="col-4 column-box">
              <div class="row justify-content-end align-items-center">
                <span style="margin-right: 10px;" *ngIf="+option.amount">{{ option.amount | currency: currencyCode }}</span>
                <input type="radio" name="parent" *ngIf="options.length > 1 && option.s_input_type === 1" (click)="onClick(option.s_input_type, i)" [checked]="option.checked">
                <input type="checkbox" *ngIf="options.length > 1 && option.s_input_type === 2" (click)="onClick(option.s_input_type, i)" [checked]="option.checked">
                <input type="checkbox" *ngIf="options.length === 1" (click)="onClick(2, i)" [checked]="option.checked">
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="option.checked">
          <div *ngFor="let childOption of option.childOptions; let j = index">
            <div class="parent-container">
              <div class="row justify-content-between">
                <div class="col-4 child-column-box">
                  <span class="title">{{ childOption.title }}</span>
                  <span class="notes">{{ childOption.notes }}</span>
                </div>
                <div class="col-4 quantity-column" *ngIf="childOption.checked">
                  <div *ngIf="childOption.qty_selection_req">
                    <button class="btn btn-primary" (click)="onButtonClick('minus', i, j)">-</button>
                    <input type="number" [(ngModel)]="childOption.default_value" name="quantity" required>
                    <button class="btn btn-primary" (click)="onButtonClick('plus', i, j)">+</button>
                  </div>
                </div>
                <div class="col-4 column-box">
                  <div class="row justify-content-end align-items-center">
                    <span style="margin-right: 10px;" *ngIf="+childOption.amount">+ {{ childOption.amount | currency: currencyCode }}</span>
                    <input type="radio" [name]="'child'+i" *ngIf="childOption.s_input_type === 1" (click)="onClick(childOption.s_input_type, i, j)" [checked]="childOption.checked">
                    <input type="checkbox" *ngIf="childOption.s_input_type === 2" (click)="onClick(childOption.s_input_type, i, j)" [checked]="childOption.checked">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    <!-- </ng-container> -->
    <!-- <ng-container *ngIf="options.length === 1">

    </ng-container> -->
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <label class="form-bold-label" style="margin-right: auto;">Total: {{ totalAmount | currency: currencyCode }}</label>
  <button class="mat-raised-button mat-primary" (click)="close()">Close</button>
  <button class="mat-raised-button mat-primary" (click)="onOkClick()">Ok</button>
</mat-dialog-actions>
