import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { DataService } from "../../../../data.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-items-variant",
  templateUrl: "./items-variant.component.html",
  styleUrls: ["./items-variant.component.css"],
})
export class ItemsVariantComponent implements OnInit {
  model: any = {};
  varId: any;
  id: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ItemsVariantComponent>,
    private apiService: DataService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.load();
    this.model.id = this.varId;
    console.log("thismodel", this.model);
  }

  onClose() {
    this.dialogRef.close();
  }

  load() {
    this.varId = this.data.modelData;
    console.log("this.modelIrre", this.varId);
  }
  onSubmit() {
    // console.log("this.model", this.model);
    // this.apiService.addItemVariant(this.model).subscribe(
    //   (response: any) => {
    //     console.log("thismodel", this.model);
    //     // if (response.success) {
    //     //   this.model = {};
    //     //   this.notifier.notify("success", response.message);
    //     // } else {
    //     //   this.notifier.notify("error", response.message);
    //     // }
    //   }
    //   // (err) => {
    //   //   this.notifier.notify("error", err);
    //   // }
    // );
  }
}
