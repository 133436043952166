import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
@Component({
  selector: 'app-d-invoice',
  templateUrl: './d-invoice.component.html',
  styleUrls: ['./d-invoice.component.css']
})
export class DInvoiceComponent implements OnInit {

 invoice:any;
  constructor(private apiService:DataService) { }
  model: any = { from_date: new Date(), to_date: new Date, type: "Invoice" };


  ngOnInit() {
    this.getTotalVoucherAmount();
  }


 getTotalVoucherAmount(){
  //********* Get Current Month **********/
  var date = new Date();
 this.model.from_date = new Date(date.getFullYear(), date.getMonth(), 1);
  this.model.to_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  this.model.to_date.setHours(23, 30, 60, 60);
  this.model.from_date.setHours(6, 0, 0, 0); 
  this.apiService.getTransCount(this.model.from_date, this.model.to_date, this.model.type).subscribe((result:any)=>{
  //  console.log(result);
    // this.invoice = result.count;
    this.invoice = result;
    // console.log(this.invoice);

  },(result:any)=>{

  });
}


}
