import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { NotifierService } from 'angular-notifier';
import { DataService } from '../data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.css']
})
export class SendMailComponent implements OnInit {

  @ViewChild('f') f;
  constructor(private dialogRef: MatDialogRef<SendMailComponent>, 
    private apiService: DataService,
    private spinner: NgxSpinnerService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    receiver_msg:any = "Find Invoice Attachment";
    receiver_subject:any = "Invoice";
    model: any = {};
     btnSending:any="Send";
  ngOnInit() {
    this.f.submitted = true;
    // console.log(this.data);
    //this.load();
    this.model = {
      legger_id :  this.data.ledger_id,
      receiver_to : this.data.email_id,
      receiver_subject:this.receiver_subject,
      receiver_msg :this.receiver_msg,
      fileName:'invoice'+this.data.display_trans_no+'.pdf',
      transaction_id: this.data.transaction_id,
      trans_type: this.data.trans_type
    }
  }

  
  onSubmit() {
    if(!this.data.transDataObj){
      this.btnSending = "Sending...";
      this.spinner.show();
      this.apiService.emailSendForInvoce(this.model)
      .subscribe((result: any) => {
        this.spinner.hide();
        if(result.success){
          this.btnSending = "Send";
          this.dialogRef.close('Edited');
          this.notifier.notify( 'success', result.message );
        }else{
          this.btnSending = "failed try again";
          this.notifier.notify( 'error', result.message );
        }
      }, (result: any) => {
        this.spinner.hide();
        this.notifier.notify( 'error', 'Email not send' );
      });
    }else{
      this.data.transDataObj.action = "SaveAndEmail"
      this.data.transDataObj.receiver_to = this.model.receiver_to,
      this.data.transDataObj.receiver_subject = this.model.receiver_subject,
      this.data.transDataObj.receiver_msg = this.model.receiver_msg,
      this.spinner.show();
      this.apiService.addInvoiceAndEmail(this.data.transDataObj)
        .subscribe((result: any) => {
          this.spinner.hide();
          if(result.success){
            this.btnSending = "Send";
            this.dialogRef.close('Mailed');
            this.notifier.notify( 'success', result.message );
          }else{
            this.dialogRef.close(result);
          }
        }, (result: any) => {
          this.spinner.hide();
          this.notifier.notify('error', 'unable to add invoice or send mail');
        });
    }
   
}

  close() {
    this.dialogRef.close();
  }
}






















// import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
// import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
// import { NotifierService } from 'angular-notifier';
// import { Router, ActivatedRoute } from '@angular/router';
// import { environment } from '../../environments/environment';
// import { DataService } from '../data.service';
// import { SocketServiceService } from '../socket-service.service';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { TimerObservable } from 'rxjs/observable/TimerObservable';
// import { Subject } from 'rxjs';
// @Component({
//   selector: 'app-rst-dashboard',
//   templateUrl: './rst-dashboard.component.html',
//   styleUrls: ['./rst-dashboard.component.css']
// })
// export class RstDashboardComponent implements OnInit {
//   kotBackgroundColor: string = '';
//   tableArr: any = []
//   currency: any;
//   kotArr: any;
//   tableGroup: any = [];
//   showOrders: boolean = false;
//   webappActiveTable: boolean = false;
//   private timerSubject: Subject<number> = new Subject<number>();
//   timerValue: number = 0;
//   private timerSubscription: any;
//   timer:any;
//   processingTimeinput: string = '00:15'; 
//   showTimePicker: boolean = false;
//   times: string[] = [
//     '00:00','00:05','00:10','00:15','00:30','00:45',
//     '01:00', '01:15', '01:30', '01:45',
//     // Add more time options as needed
//   ];
  
//   private socket: SocketIOClient.Socket;
//   constructor(private apiService: DataService,
//     private router: Router,
//     private spinner: NgxSpinnerService,
//     private socketService: SocketServiceService,
//     private notifier: NotifierService) {
//     this.currency = environment.currency;
//   }
//   orderTypes: any = [{ name: "Pending Approved", value: 1 }, { name: "Pending", value: 2 }]
//   typeValue: any = 1;
//   connection: any;
//   receipt: any
//   ngOnInit() {
//     this.connectSocket()
//     this.load();
//     this.loadTimerState(); 
//     this.startTimer();
//   }
//   connectSocket() {
//     this.connection = this.socketService.on('refresh')
//       .subscribe((result: any) => {
//         console.log('socket called on dashboard')
//         this.onRefresh();
//       });
//     this.socketService.on('reconnect')
//       .subscribe((result: any) => {
//         this.onRefresh();
//       });
//       this.socketService.on('webService')
//       .subscribe((result: any) => {
//         this.load();
//       });
//       this.socketService.on('tableTransfer')
//       .subscribe((result: any) => {
//         this.load();
//       });
//   }
//   ngOnDestroy() {
//     this.connection.unsubscribe();
//     this.saveTimerState(); 
//     this.stopTimer();
//   }
//   toggleTimePicker(trans_no:any) {
//     this.kotArr.filter(e=>{
      
//       if(e.transaction_id === trans_no){

//         e.time === true
//       }
//     })
    
//     this.kotArr.filter(e=>{

//       if(e.transaction_id === trans_no && e.time === true){

//         this.showTimePicker = !this.showTimePicker;
//       }
//     })
//   }

//   selectTime(time: string) {
//     this.processingTimeinput = time;
//     this.showTimePicker = false;
//   }

//   onTimeInputChange(event: any) {
//     const inputValue = event.target.value;
//     const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

//     if (!timePattern.test(inputValue)) {
//       // Reset the input if it doesn't match the format
//       this.processingTimeinput = '00:00';
//     }
//   }
//   load() {

//     this.apiService.getAccountMapList()
//       .subscribe((result: any) => {
//         let indexofCurrency = result.findIndex(i => i.key === "Currency");
//         if (result[indexofCurrency].text_value) {
//           environment.currency = result[indexofCurrency].text_value;
//           localStorage.setItem('currency', result[indexofCurrency].text_value);
//         }
//       }, (result: any) => {
//       });

//     this.apiService.getTableListWithKOT()
//       .subscribe((result: any) => {
//         console.log("getTableListWithKOT-----", result)
//         this.tableArr = result.sort(this.customCompare);
//         this.getUniqueTableGroup()
//       }, (result: any) => {
//         this.notifier.notify('error', 'unable to load data');
//       });
//   }
  
//   customCompare(a: any, b: any): number {
//     const nameA = a.group_name.toLowerCase();
//     const nameB = b.group_name.toLowerCase();
//     if (nameA === nameB) {
//       return 0;
//     }
//     const numericPartA = parseInt(nameA, 10);
//     const numericPartB = parseInt(nameB, 10);
//     if (!isNaN(numericPartA) && !isNaN(numericPartB)) {
//       return numericPartA - numericPartB;
//     }
//     return nameA.localeCompare(nameB);
//   }

//   onRefresh() {
//     if (this.showOrders) {
//       this.refreshOrderList()
//     } else {
//       this.load()
//     }
//   }
//   getUniqueTableGroup() {
//     this.tableGroup = this.tableArr.map(table => table.group_name)
//       .filter((value, index, self) => self.indexOf(value) === index);
//   }

//   gotoInvoice(table) {
//     this.router.navigate(['/rst-invoice/' + table.id]);
//   }
//   changeShowOrders() {
//     this.showOrders = !this.showOrders;
//     if (this.showOrders) {
//       this.refreshOrderList()
//     } else {
//       this.load()
//     }
//   }
//   getNotApprovedKot() {
//     this.spinner.show();
//     this.apiService.getSONotApproved()
//       .subscribe((result: any) => {
       
//         this.kotArr = result;

//         // this.startTimer();
//         this.spinner.hide();
//       }, (result: any) => {
//         this.spinner.hide();
//       });
//   }
//   getNotFinishedKot() {
//     this.spinner.show();
//     this.apiService.getSONotFinished()
//       .subscribe((result: any) => {
//         result.filter(e=>{
//           e.time = false
//         })
//         console.log("WWWWWWWWWWWWW",result)
//         this.spinner.hide();
//         this.kotArr = result;
//       }, (result: any) => {
//         this.spinner.hide();
//       });
//   }

//   convertFloat(val) {
//     return parseFloat(val)
//   }
//   onOrderTypeChange(e) {
//     this.refreshOrderList()
//   }
//   onApproveClick(kot) {
//     this.spinner.show();
//     this.apiService.approveKot(kot.transaction_id)
//       .subscribe((result: any) => {
//         if (result.success) {
//           this.spinner.hide();
//           this.refreshOrderList()
//           this.printKot(kot)
//         }
//       }, (result: any) => {
//         this.spinner.hide();
//       });
//   }
//   onRejectClick(kot_id) {
//     this.spinner.show();
//     this.apiService.deleteSalesOrder(kot_id)
//       .subscribe((result: any) => {
//         if (result.success) {
//           this.spinner.hide();
//           this.refreshOrderList()
//         }
//       }, (result: any) => {
//         this.spinner.hide();
//       });
//   }
//   // onProcessingClick(kot_id) {
//   //   this.spinner.show();
//   //   this.apiService.markProcessingKot(kot_id)
//   //     .subscribe((result: any) => {
//   //       if (result.success) {
//   //         this.spinner.hide();
//   //         this.refreshOrderList()
//   //       }
//   //     }, (result: any) => {
//   //       this.spinner.hide();
//   //     });
//   // }

//   onProcessingClick(transactionId: number) {
//     if (!this.processingTimeinput) {
//       alert('Please enter processing time.');
//       return;
//     }

//     this.spinner.show();
  
//     const requestData = {
//       transaction_id: transactionId,
//       processingTime: this.processingTimeinput,
//     };
  
//     this.apiService.markProcessingKot(requestData)
//       .subscribe(
//         (result: any) => {
//           if (result.success) {
//             this.spinner.hide();
//             this.refreshOrderList();
//           } else {
//             console.error(result.message);
//             this.spinner.hide();
//           }
//         },
//         (error: any) => {
//           console.error(error);
//           this.spinner.hide();
//         }
//       );
//   }


//   onDeleveredClick(kot_id) {
//     this.spinner.show();
//     this.apiService.markFinishedKot(kot_id)
//       .subscribe((result: any) => {
//         if (result.success) {
//           this.spinner.hide();
//           this.refreshOrderList()
//         }
//       }, (result: any) => {
//         this.spinner.hide();
//       });
//   }
//   refreshOrderList() {
//     if (this.typeValue == 1) {
//       this.getNotApprovedKot()
//     }
//     if (this.typeValue == 2) {
//       this.getNotFinishedKot()
//     }
//   }

//   printKot(kot) {
//     this.apiService.downloadPdfByTransId(kot.transaction_id)
//       .subscribe((result: any) => {
//         console.log(result)
//         let blob = new Blob([result], {
//           type: 'application/pdf'
//         });
//         var link = document.createElement('a');
//         link.href = window.URL.createObjectURL(blob);
//         link.download = 'kot' + kot.trans_no + '.pdf';
//         link.click();
//         window.URL.revokeObjectURL(link.href);
//       }, (result: any) => {
//       });
//   }

//   startTimer() {
//     this.timer = TimerObservable.create(0, 1000);
  
//     this.timer.subscribe(() => {
//       // Check if the conditions are met for starting the timer
//       if (
//         this.kotArr &&
//         Array.isArray(this.kotArr) &&
//         this.kotArr.length > 0 &&
//         this.kotArr.some(
//           (kot) => kot.approved && kot.processing && !kot.finished
//         )
//       ) {
//         // Update processing_time for each KOT and store in localStorage
//         this.kotArr.forEach((kot) => {
//           if (kot.approved && kot.processing && !kot.finished) {
//             const processingTime = this.calculateNewProcessingTime(
//               kot.trans_no,
//               kot.processing_time
//             );
//             kot.processing_time = processingTime;
  
//             // Check if processing time has reached "00:00:00"
//             if (processingTime === '00:00') {
//               // Set the background color to red when time reaches "00:00:00"
//               kot.backgroundColor = '#FFCCCB';
//             } else {
//               // Reset the background color if the condition is not met
//               kot.backgroundColor = '';
//             }
//           }
//         });
  
//         // Save the updated KOT data in localStorage
//         this.saveTimerState();
//       }
//     });
//   }
 
//   calculateNewProcessingTime(trans_no: string, currentTime: string): string {
  
//     const previousTime = localStorage.getItem(`kot_${trans_no}_processing_time`) || currentTime;

   
//     const [hours, minutes, seconds] = previousTime.split(':').map(Number);

   
//     const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  
//     if (totalSeconds <= 0) {
     
//       return '00:00';
//     }

   
//     const newTotalSeconds = totalSeconds - 1;

   
//     const newHours = Math.floor(newTotalSeconds / 3600);
//     const remainingSecondsAfterHours = newTotalSeconds % 3600;
//     const newMinutes = Math.floor(remainingSecondsAfterHours / 60);
//     const newSeconds = remainingSecondsAfterHours % 60;

    
//     const formattedNewHours = newHours.toString().padStart(2, '0');
//     const formattedNewMinutes = newMinutes.toString().padStart(2, '0');
//     const formattedNewSeconds = newSeconds.toString().padStart(2, '0');

//     // Store the updated processing time in localStorage
//     localStorage.setItem(
//       `kot_${trans_no}_processing_time`,
//       `${formattedNewHours}:${formattedNewMinutes}:${formattedNewSeconds}`
//     );
//     return `${formattedNewMinutes}:${formattedNewSeconds}`;
//   }

//   // Add a function to save the timer state in localStorage
//   saveTimerState() {
//     localStorage.setItem('kotArr', JSON.stringify(this.kotArr));
//   }

//   // Add a function to load the timer state from localStorage
//   loadTimerState() {
//     const savedKotArr = localStorage.getItem('kotArr');
//     if (savedKotArr) {
//       this.kotArr = JSON.parse(savedKotArr);
//     }
//   }
//   stopTimer() {
//     if (this.timerSubscription) {
//       this.timerSubscription.unsubscribe();
//     }
//   }


 
// }







