import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { DataService } from "../../../data.service";
import { NotifierService } from "angular-notifier";
import { ItemsVariantComponent } from "./items-variant/items-variant.component";
import { ItemsVariantListComponent } from "./items-variant-list/items-variant-list.component";

@Component({
  selector: "app-add-edit-items",
  templateUrl: "./add-edit-items.component.html",
  styleUrls: ["./add-edit-items.component.css"],
})
export class AddEditItemsComponent implements OnInit {
  model: any = {};
  item_id: any;
  itemVariant: any;
  status: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditItemsComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.status = data.status;
    this.model.priceList_id = parseInt(data.priceList_id);
    this.model.item_id = data.item_id;
    this.model.s_rate = data.s_rate;
    this.model.taxslab = data.taxslab;
    // console.log("this.model.priceList_id", this.model.priceList_id);
  }

  ngOnInit() {
    this.load();
    this.item_id = this.data.item_id; // Access item_id from the data passed
  }

  // loadItemDetails() {
  //   //Call your data service method to fetch item details by item_id
  //   this.apiService.getItemDetails(this.item_id).subscribe((response: any) => {
  //     this.itemDetails = response;
  //     // Optionally, you can handle errors here
  //   });
  // }

  onClose() {
    this.dialogRef.close();
  }

  load() {}

  onSubmit() {
    if (this.status == "add") {
      console.log("this.model", this.model);
      // Here you can perform actions like sending data to a server
      this.apiService.additemPriceList(this.model).subscribe(
        (response: any) => {
          if (response.success) {
            this.model = {};
            this.notifier.notify("success", response.message);
          } else {
            this.notifier.notify("error", response.message);
          }
        },
        (err) => {
          this.notifier.notify("error", err);
        }
      );
    } else {
      console.log("this.model--", this.model);
      this.apiService
        .updateItemPriceList(this.model.priceList_id, this.model)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.model = {};
              this.notifier.notify("success", response.message);
            } else {
              this.notifier.notify("error", response.message);
            }
          },
          (err) => {
            this.notifier.notify("error", err);
          }
        );
    }
    this.dialogRef.close({ status: this.status });
  }

  addvariant() {
    console.log("this.dataltem", this.data.item_id);

    this.dialog.open(ItemsVariantListComponent, {
      data: {
        modelData: this.data.item_id, // Pass this.model data to ItemsVariantListComponent
      },
      width: "900px",
    });
  }
}
