import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { TaxSummaryService } from '../../tax-summary.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TransactionItemsComponent } from '../../transaction-items/transaction-items.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AddEditCustomersMasterComponent } from '../../customers-master/add-edit-customers-master/add-edit-customers-master.component';
import { environment } from '../../../environments/environment';
import { MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../date.adapter';
import { PurchaseOrderItemsComponent } from 'src/app/purchase-order/purchase-order-items/purchase-order-items.component';
@Component({
  selector: 'app-edit-invoice-return',
  templateUrl: './edit-invoice-return.component.html',
  styleUrls: ['./edit-invoice-return.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class EditInvoiceReturnComponent implements OnInit {
  constructor(private apiService: DataService,
    private dialogRef: MatDialogRef<EditInvoiceReturnComponent>,
    public dialog: MatDialog,
    private taxService: TaxSummaryService,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currency = environment.currency;
  }
  @ViewChild('f') f;
  currency: any;
  invoice_no: any;
  contractObj: any;
  ledgerObj: any;
  transDataObj: any = { items_details: [] };
  items: any = [];
  sgst: any = [];
  cgst: any = [];
  igst: any = [];
  tax: any = [];
  totalAmt: any;
  amount: any;
  totalTax: any;
  totalNetAmt: any;
  roundOffAmt: any;
  totalDis: any;
  totalSGST: any;
  totalCGST: any;
  totalIGST: any;
  ledger_id: any;
  sgstUnqPer: any = [];
  igstUnqPer: any = [];
  sign: any;
  dataObj: any = {};
  ledgerControl = new FormControl();
  options: any;
  filteredOptions: Observable<string[]>;
  moreField: boolean = false;
  optionalFields: any;
  sbilling_state = new FormControl();
  place_of_supply = new FormControl();
  shipping_state = new FormControl();
  l_id: any;
  closing_balance: any;
  isTransactionGSTslab: boolean = false;
  taxSlabList:any;


  ngOnInit() {
    this.apiService.getGstState()
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        this.load();
      }, (result: any) => {
        console.log('state not loaded');
        this.notifier.notify('error', 'unable to load data');
      });
    this.getEnabledTransFields();
  }


  getEnabledTransFields() {
    this.apiService.getEnabledInvoiceField(6)
      .subscribe((result: any) => {
        this.optionalFields = result;
        if (this.optionalFields.length > 7) {
          this.moreField = true;
        }
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load enabled fields');
      });
  }

  filter(value) {
    const filterValue = value.toLowerCase();
    return this.ledgerObj.filter(ledger => ledger.ledger_code.toLowerCase().includes(filterValue) || ledger.ledger_name.toLowerCase().includes(filterValue));
  }

  displayFn(ledger) {
    return ledger ? ledger.ledger_name : "";
  }

  company: any;
  GState: any;
  stateName: any;
  statelist: any;


  // State ************************************
  displayStateFn(state) {
    return state ? state.state_name : '';
  }

  allstates() {
    this.apiService.getGstState()
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }
  // searching for ledger
  searchState(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.sbilling_state.value.length >= 1) {
        this.apiService.sgetGstState(this.sbilling_state.value, 'false')
          .subscribe((result: any) => {
            this.GState = result;
            this.stateName = this.GState;
          });
      }
    }
  }
  // on focus on ledger search
  StateonFocus(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        // console.log(this.stateName);
      });
  }

  StateSelection(event: MatAutocompleteSelectedEvent) {
    this.form.get('billing_state').setValue(event.option.value.state_gst_code);
  }
  // State End************************************


  load() {
    this.f.submitted = true;
    this.apiService.getInvoiceReturn(this.data.transaction_id)
      .subscribe((result: any) => {
        this.items = result.items_details;
        this.form.patchValue(result);
        if(result.taxslab){
          this.isTransactionGSTslab = true;
        }
        let indexofState = this.stateName.findIndex(i => i.state_gst_id === result.billing_state);
        this.sbilling_state.setValue(this.stateName[indexofState]);
        let indexofState1 = this.stateName.findIndex(i => i.state_gst_id === result.place_of_supply);
        this.place_of_supply.setValue(this.stateName[indexofState1]);
        let indexofState3 = this.stateName.findIndex(i => i.state_gst_id === result.shipping_state);
        this.shipping_state.setValue(this.stateName[indexofState3]);
      }, (result: any) => {
      });
    this.apiService.getLedger(this.data.ledger_id)
      .subscribe((result: any) => {
        this.ledgerObj = result[0];
        // console.log(this.ledgerObj.ledger_name);
        this.ledgerControl.setValue(this.ledgerObj);
        this.apiService.getLedgerClosingBalance(this.data.ledger_id).subscribe(
          (result: any) => {
            if (result.success) {
              this.closing_balance = result.message.closing_balance + ' ' + result.message.cbdrcr;

            }
            else {
              this.notifier.notify('error', result.message);
            }
          },
          (err: any) => {

          }
        )
      }, (result: any) => {
      });
    this.apiService.getContractList()
      .subscribe((result: any) => {
        this.contractObj = result;
      }, (result: any) => {
      });
    this.apiService.getCompanyInfo()
      .subscribe((result: any) => {
        this.company = result;
        if (this.isTransactionGSTslab) {
          this.calculateAmtWithTransTax();
        }else{
          this.calculateAmt()
        }
      }, (result: any) => {
      });
      this.apiService.getTaxslabList().subscribe((result: any) => {
        this.taxSlabList = result;
      },
        (result: any) => {
          this.notifier.notify('error', 'unable to load Table list');
        });
  }
  //searching for ledger
  search(event) {
    this.closing_balance = null;
    if (event.key != "ArrowDown" && event.key != "ArrowUp" && event.key != "Enter") {
      if (this.ledgerControl.value.length > 1) {
        this.apiService.getSundryLedgerList(this.ledgerControl.value, "false")
          .subscribe((result: any) => {
            this.ledgerObj = result;
            this.filteredOptions = this.ledgerControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.ledgerObj = [];
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }
    }
  }
  form: FormGroup = new FormGroup({
    transaction_id: new FormControl(0),
    contract_id: new FormControl(null),
    trans_no: new FormControl("Auto Generated", Validators.required),
    trans_date: new FormControl(new Date(), Validators.required),
    ledger_id: new FormControl(null, Validators.required),
    dispatch_through: new FormControl(null),
    transport_mode: new FormControl(null),
    container_no: new FormControl(null),
    gr_no: new FormControl(null),
    place_of_supply: new FormControl(null),
    billing_address1: new FormControl(null),
    billing_address2: new FormControl(null),
    billing_street: new FormControl(null),
    billing_city: new FormControl(null),
    billing_state: new FormControl(null),
    billing_country: new FormControl(null),
    billing_pin: new FormControl(null),
    gstin: new FormControl(null),
    shipping_address1: new FormControl(null),
    shipping_address2: new FormControl(null),
    shipping_street: new FormControl(null),
    shipping_city: new FormControl(null),
    shipping_state: new FormControl(null),
    shipping_country: new FormControl(null),
    shipping_pin: new FormControl(null),
    challan_no: new FormControl(null),
    po_no: new FormControl(null),
    po_date: new FormControl(null),
    challan_date: new FormControl(null),
    lc_no: new FormControl(null),
    total_amount: new FormControl(0, Validators.required),
    total: new FormControl(0),
    total_tax: new FormControl(0),
    discount_amt: new FormControl(0),
    roundoff_amt: new FormControl(0),
    other1_label: new FormControl(null),
    other2_label: new FormControl(null),
    other1_amt: new FormControl(0),
    other2_amt: new FormControl(0),
    net_amount: new FormControl(0, Validators.required),
    display_trans_no: new FormControl(null),
    contact_no: new FormControl(null),
    field1: new FormControl(null),
    field2: new FormControl(null),
    field3: new FormControl(null),
    field4: new FormControl(null),
    field5: new FormControl(null),
    credit_days: new FormControl(null),
    due_date: new FormControl(null),
    payment_days: new FormControl(null),
    taxslab: new FormControl(0),
    dis_per: new FormControl(0),
    taxable_amt: new FormControl(0),
    sgst_per: new FormControl(0),
    sgst_amt: new FormControl(0),
    cgst_per: new FormControl(0),
    cgst_amt: new FormControl(0),
    igst_per: new FormControl(0),
    igst_amt: new FormControl(0),
    gross_amount: new FormControl(0),
  });
  //Initialise ledger
  initialiseLedger() {
    this.ledgerControl.setValue(null);
    this.ledgerObj = [];
    this.filteredOptions = this.ledgerControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
  }
  // Initialise or Reset Form Group
  initializeFormGroup() {
    this.form.setValue({
      contract_id: null,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      dispatch_through: null,
      transport_mode: null,
      container_no: null,
      gr_no: null,
      place_of_supply: null,
      billing_address1: null,
      billing_address2: null,
      billing_street: null,
      billing_city: null,
      billing_state: null,
      billing_country: null,
      billing_pin: null,
      gstin: null,
      shipping_address1: null,
      shipping_address2: null,
      shipping_street: null,
      shipping_city: null,
      shipping_state: null,
      shipping_country: null,
      shipping_pin: null,
      challan_no: null,
      po_no: null,
      po_date: null,
      challan_date: null,
      lc_no: null,
      total_amount: 0,
      discount_amt: 0,
      roundoff_amt: 0,
      other1_label: null,
      other2_label: null,
      other1_amt: 0,
      other2_amt: 0,
      net_amount: 0,
      total: 0,
      total_tax: 0,
      display_trans_no: null,
      contact_no: null,
      field1: null,
      field2: null,
      field3: null,
      field4: null,
      field5: null,
      credit_days: null,
      due_date: null,
      payment_days: null,
      dis_per: 0,
      taxable_amt: 0,
      sgst_per: 0,
      sgst_amt: 0,
      cgst_per: 0,
      cgst_amt: 0,
      igst_per: 0,
      igst_amt: 0,
      gross_amount: 0,
    });
    this.items = [];
    if (this.isTransactionGSTslab) {
      this.calculateAmtWithTransTax();
    }else{
      this.calculateAmt()
    }
    window.scroll(0, 0);
    this.initialiseLedger();
  }

  //On Ledger Selection event
  ledgerSelection(event: MatAutocompleteSelectedEvent) {
    this.ledger_id = event.option.value ? event.option.value.ledger_id : undefined;
    this.form.get('ledger_id').setValue(event.option.value.ledger_id);
    this.form.get('billing_address1').setValue(event.option.value.address_address1);
    this.form.get('billing_address2').setValue(event.option.value.address_address2);
    this.form.get('billing_street').setValue(event.option.value.address_street);
    this.form.get('billing_city').setValue(event.option.value.address_city);
    this.form.get('billing_state').setValue(event.option.value.address_state);
    this.form.get('billing_country').setValue(event.option.value.address_country);
    this.form.get('billing_pin').setValue(event.option.value.address_pin);
    this.form.get('gstin').setValue(event.option.value.gstin);
    this.form.get('contact_no').setValue(event.option.value.contact_no);
    var indexofState = this.stateName.findIndex(i => i.state_gst_id === event.option.value.address_state);
    this.sbilling_state.setValue(this.stateName[indexofState]);
    this.apiService.getLedgerClosingBalance(this.ledger_id).subscribe(
      (result: any) => {
        if (result.success) {
          this.closing_balance = result.message.closing_balance + ' ' + result.message.cbdrcr;

        }
        else {
          this.notifier.notify('error', result.message);
        }
      },
      (err: any) => {

      }
    )
  }

  onDiscountPer() {
    var discount = 0;
    discount = this.form.value.total_amount * (this.form.value.dis_per / 100);
    discount = Math.round(discount * 100) / 100;
    this.form.get('discount_amt').setValue(discount);
    this.calculateAmtWithTransTax();
  }
  onDiscountAmt() {
    this.form.get('dis_per').setValue(0);
    this.calculateAmtWithTransTax();
  }
  onTaxChange(e) {
    this.calculateAmtWithTransTax()
  }
  calculateAmtWithTransTax() {
    this.amount = 0;
    var tax_slab = this.form.value.taxslab;
    if (this.company.is_composite) {
      tax_slab = 0;
    }
    var taxable_amt = 0, tax_amt = 0, sgst_per = 0, sgst_amt = 0, cgst_per = 0, cgst_amt = 0, igst_per = 0, igst_amt = 0
    this.sign = null;
    this.totalAmt = 0;
    this.totalDis = 0;
    this.amount = 0;
    for (var i = 0; i < this.items.length; i++) {
      this.amount = this.amount + this.items[i].amount;
    }
    taxable_amt = this.amount - this.form.value.discount_amt;
    if (this.form.value.billing_state == this.company.address_state) {
      sgst_per = tax_slab / 2;
      sgst_amt = taxable_amt * (sgst_per / 100);
      sgst_amt = Math.round(sgst_amt * 100) / 100;
      cgst_per = tax_slab / 2;
      cgst_amt = taxable_amt * (cgst_per / 100);
      cgst_amt = Math.round(cgst_amt * 100) / 100;

      tax_amt = sgst_amt + cgst_amt;
    } else {
      igst_per = tax_slab;
      igst_amt = taxable_amt * (igst_per / 100);
      igst_amt = Math.round(igst_amt * 100) / 100;

      tax_amt = igst_amt;
    }

    this.totalAmt = taxable_amt + tax_amt + this.form.value.other1_amt + this.form.value.other2_amt;
    this.totalAmt = Math.round(this.totalAmt * 100) / 100;
    this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
    this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
    if (this.roundOffAmt < .5) {
      this.totalNetAmt = this.totalAmt - this.roundOffAmt;
      this.totalNetAmt = Math.round(this.totalNetAmt);
      this.roundOffAmt = -this.roundOffAmt;
      this.sign = "fa-minus";
    } else {
      this.roundOffAmt = 1 - this.roundOffAmt;
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      this.totalNetAmt = this.totalAmt + this.roundOffAmt;
      this.totalNetAmt = Math.round(this.totalNetAmt);
      this.sign = "fa-plus";
    }

    this.form.get('total_amount').setValue(this.amount);
    this.form.get('net_amount').setValue(this.totalNetAmt);
    this.form.get('roundoff_amt').setValue(this.roundOffAmt);
    this.form.get('gross_amount').setValue(this.totalAmt);
    this.form.get('total_tax').setValue(this.totalTax);
    this.form.get('sgst_per').setValue(sgst_per);
    this.form.get('sgst_amt').setValue(sgst_amt);
    this.form.get('cgst_per').setValue(cgst_per);
    this.form.get('cgst_amt').setValue(cgst_amt);
    this.form.get('igst_per').setValue(igst_per);
    this.form.get('igst_amt').setValue(igst_amt);
    this.form.get('taxable_amt').setValue(taxable_amt);
  }

  //To Open Add Item Dialog
  addItemClick() {
    if (this.isTransactionGSTslab) {
      var itemData = { type: 'Out', v_type_id: 5 }

      let itemref = this.dialog.open(PurchaseOrderItemsComponent, {
        width: '600px',
        data: itemData,
        autoFocus: false
      });
      itemref.componentInstance.onItemAdd.subscribe((result) => {
        if (result) {
          this.items.push(result);
          this.calculateAmtWithTransTax();
        }
      });
    } else {
    this.dataObj.v_type_id = 6;
    this.dataObj.trans_type = "Out";
    this.dataObj.ledger_id = this.form.value.ledger_id;
    this.dataObj.item = null;
    let itemref = this.dialog.open(TransactionItemsComponent, {
      width: '800px',
      data: this.dataObj,
      autoFocus: false
    });
    itemref.componentInstance.onItemAdd.subscribe((result) => {
      if (result) {
        this.items.push(result);
        this.calculateAmt();
      }
    });
  }
  }
  // To delete Item from Item Details
  onDeleteItem(i) {
    this.items.splice(i, 1);
    if (this.isTransactionGSTslab) {
      this.calculateAmtWithTransTax();
    }else{
      this.calculateAmt()
    }
  }

  //Open edit-Item Dialog to edit item data
  editItem(i) {
    if (this.isTransactionGSTslab) {
      var itemData = { type: 'Out', v_type_id: 5, value: this.items[i] }
      this.dialog.open(PurchaseOrderItemsComponent, {
        width: '600px',
        data: itemData,
        autoFocus: false
      })
        .afterClosed().subscribe(result => {
          if (result) {
            this.items.splice(i, 1);
            this.items.push(result);
            this.calculateAmtWithTransTax();
          }
        });
    } else {
    this.dataObj.v_type_id = 6;
    this.dataObj.trans_type = "Out";
    this.dataObj.item = this.items[i];
    this.dialog.open(TransactionItemsComponent, {
      width: '800px',
      data: this.dataObj,
      autoFocus: false
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.items.splice(i, 1);
          this.items.push(result);
          this.calculateAmt();
        }
      });
  }
  }
  // Function to calculate all amount related values
  calculateAmt() {
    this.sign = null;
    this.totalAmt = 0;
    this.totalDis = 0;
    this.totalTax = 0;
    this.amount = 0;


    for (var i = 0; i < this.items.length; i++) {
      this.amount = this.amount + this.items[i].amount;
      this.totalDis = this.totalDis + this.items[i].dis_amt;
      this.totalDis = Math.round(this.totalDis * 100) / 100;
    }
    if (this.company.is_composite) {
      this.tax = [];
    } else {
      this.tax = this.taxService.calculateTax(this.items);
    }

    for (var i = 0; i < (this.tax.length - 1); i++) {
      this.totalTax = this.totalTax + this.tax[i].sgst_amt + this.tax[i].cgst_amt + this.tax[i].igst_amt;
      this.totalTax = Math.round(this.totalTax * 100) / 100;
    }
    this.totalAmt = (this.amount - this.totalDis) + this.totalTax + this.form.value.other1_amt + this.form.value.other2_amt;
    this.totalAmt = Math.round(this.totalAmt * 100) / 100;
    this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
    this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
    if (this.roundOffAmt < .5) {
      this.totalNetAmt = this.totalAmt - this.roundOffAmt;
      this.totalNetAmt = Math.round(this.totalNetAmt);
      this.roundOffAmt = -this.roundOffAmt;
      this.sign = "fa-minus";
    } else {
      this.roundOffAmt = 1 - this.roundOffAmt;
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      this.totalNetAmt = this.totalAmt + this.roundOffAmt;
      this.totalNetAmt = Math.round(this.totalNetAmt);
      this.sign = "fa-plus";
    }

    this.form.get('total_amount').setValue(this.amount);
    this.form.get('discount_amt').setValue(this.totalDis);
    this.form.get('net_amount').setValue(this.totalNetAmt);
    this.form.get('roundoff_amt').setValue(this.roundOffAmt);
    this.form.get('total').setValue(this.totalAmt);
    this.form.get('total_tax').setValue(this.totalTax);

  }

  //on other price input
  onOtherPrice() {
    if (this.isTransactionGSTslab) {
      this.calculateAmtWithTransTax();
    }else{
      this.calculateAmt()
    }
  }
  onCreditDays() {
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + this.form.value.credit_days);
    this.form.get('due_date').setValue(dueDate);
  }
  //on Form Submit
  onSubmit() {

    if (this.form.valid) {
      this.transDataObj = this.form.value;
      this.transDataObj.items_details = this.items;
      this.transDataObj.sgst = this.sgst;
      this.transDataObj.cgst = this.cgst;
      this.transDataObj.igst = this.igst;
      this.apiService.editInvoiceReturn(this.transDataObj.transaction_id, this.transDataObj)
        .subscribe((result: any) => {
          if (result.success) {
            this.downloadClick();
            this.notifier.notify('success', 'Invoice Return Updated successfully');
          } else {
            this.notifier.notify('error', result.message);
          }
          this.dialogRef.close('Edited');
        }, (result: any) => {
          this.initializeFormGroup();
          this.notifier.notify('error', 'unable to add invoice');
        });

    } else {
      window.scroll(0, 0);
    }
  }
  //to Reset Form
  onClear() {
    this.closing_balance = null;

    this.initializeFormGroup();
  }
  downloadClick() {
    this.transDataObj = this.form.value;
    this.transDataObj.items_details = this.items;
    this.transDataObj.sgst = this.sgst;
    this.transDataObj.cgst = this.cgst;
    this.transDataObj.igst = this.igst;
    this.transDataObj.trans_type = "InvoiceReturn";
    this.apiService.downloadPdf(this.transDataObj)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'invoiceReturn' + this.transDataObj.trans_no + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
  cancelInvoiceReturn() {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Cancel the Invoice Return Voucher Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteInvoiceReturn(this.data.transaction_id)
            .subscribe((result: any) => {
              this.dialogRef.close('Edited');
              this.notifier.notify('success', 'Invoice Return Canceled successfully');
            }, (result: any) => {
              this.notifier.notify('error', 'unable to cancel invoice Return');
            });
        }
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  //to add new customer
  onAddCustomerClick() {
    this.dialog.open(AddEditCustomersMasterComponent, {
      width: '800px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var ledgerData = result.data;
          this.ledger_id = ledgerData.ledger_id;
          this.ledgerControl.setValue(ledgerData);
          this.form.get('ledger_id').setValue(ledgerData.ledger_id);
          this.form.get('billing_address1').setValue(ledgerData.address_address1);
          this.form.get('billing_address2').setValue(ledgerData.address_address2);
          this.form.get('billing_street').setValue(ledgerData.address_street);
          this.form.get('billing_city').setValue(ledgerData.address_city);
          this.form.get('billing_state').setValue(ledgerData.address_state);
          this.form.get('billing_country').setValue(ledgerData.address_country);
          this.form.get('billing_pin').setValue(ledgerData.address_pin);
          this.apiService.getLedgerClosingBalance(ledgerData.ledger_id).subscribe(
            (result: any) => {
              if (result.success) {
                this.closing_balance = result.message.closing_balance + ' ' + result.message.cbdrcr;

              }
              else {
                this.notifier.notify('error', result.message);
              }
            },
            (err: any) => {

            }
          )
        } else {
          this.ledgerControl.setValue(this.ledgerObj);
        }
      });
  }
  displayStateSuplay(state) {
    return state ? state.state_name : '';
  }

  searchStateSuplay(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.place_of_supply.value.length >= 1) {
        this.apiService.sgetGstState(this.place_of_supply.value, 'false')
          .subscribe((result: any) => {
            this.GState = result;
            this.stateName = this.GState;
          });
      }
    }
  }


  StateonFocusSuplay(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        // console.log(this.stateName);
      });



  }
  StateSelectionSuplay(event: MatAutocompleteSelectedEvent) {
    this.form.get('place_of_supply').setValue(event.option.value.state_gst_code);
  }
  StateonFocusShipState(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        // console.log(this.stateName);
      });
  }

  searchStateShipState(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.shipping_state.value.length >= 1) {
        this.apiService.sgetGstState(this.shipping_state.value, 'false')
          .subscribe((result: any) => {
            this.GState = result;
            this.stateName = this.GState;
          });
      }
    }
  }

  StateSelectionShipState(event: MatAutocompleteSelectedEvent) {
    this.form.get('shipping_state').setValue(event.option.value.state_gst_code);
  }


  displayStateFnShipState(state) {
    return state ? state.state_name : '';
  }
  sameAdd(isChecked: boolean) {
    if (isChecked) {
      this.shipping_state.setValue(this.sbilling_state.value);
      this.form.get('shipping_address1').setValue(this.form.get('billing_address1').value);
      this.form.get('shipping_address2').setValue(this.form.get('billing_address2').value);
      this.form.get('shipping_street').setValue(this.form.get('billing_street').value);
      this.form.get('shipping_city').setValue(this.form.get('billing_city').value);
      this.form.get('shipping_state').setValue(this.form.get('billing_state').value);
      this.form.get('shipping_country').setValue(this.form.get('billing_country').value);
      this.form.get('shipping_pin').setValue(this.form.get('billing_pin').value);
    } else {
      this.shipping_state.setValue(null);
      this.form.get('shipping_address1').setValue("");
      this.form.get('shipping_address2').setValue("");
      this.form.get('shipping_street').setValue("");
      this.form.get('shipping_city').setValue("");
      this.form.get('shipping_state').setValue("");
      this.form.get('shipping_country').setValue("");
      this.form.get('shipping_pin').setValue("");
    }
  }

}
