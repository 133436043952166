<h4 *ngIf="!data.item" class="color-h3">Add Items...</h4>
<h4 *ngIf="data.item" class="color-h3">Edit Items</h4>
<div class="table-details">
    <mat-dialog-content>
        <form #transForm="ngForm">
            <fieldset class="border p-2 form-fieldset">
                <legend class="w-auto">Item Details:</legend>
                <div class="row">
                    <div [ngClass]="{'col-md-8': isLocation, 'col-md-12': !isLocation}">
                        <mat-form-field class="contract-full-width">
                            <input type="text" placeholder="Item" aria-label="Number" matInput
                                [formControl]="itemControl" [matAutocomplete]="auto" (keyup)="search($event)"
                                (focus)="onFocus($event)" required>
                            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
                                (optionSelected)="itemSelection($event)">
                                <mat-option [value]=0>
                                    <div (click)="onAddItemClick()" class="text-center">
                                        <span>Add New Item</span>
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    <span class="item-font">({{option.item_code}})</span>
                                    <span class="item-font"> {{option.item_name}}</span>
                                    <br>
                                    <small>
                                        <span class="item-subtitle">PRate: </span>
                                        <span *ngIf="option.p_rate" class="item-rate">{{option.p_rate | number :
                                            '1.2-2'}}</span>
                                    </small>
                                    <small>
                                        <span class="item-rate-font">SRate: </span>
                                        <span *ngIf="option.s_rate" class="item-rate">{{option.s_rate | number :
                                            '1.2-2'}}</span>
                                    </small>
                                    <small>
                                        <span class="item-rate-font">MRP: </span>
                                        <span *ngIf="option.mrp" class="item-rate"> {{option.mrp | number :
                                            '1.2-2'}}</span>
                                    </small>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div *ngIf="isLocation" class=col-md-4>
                        <mat-form-field class="contract-full-width">
                            <mat-select placeholder="Location:" (selectionChange)="onLocationSelect($event.value)"
                                [(ngModel)]="model.location_id" name="location_id" required>
                                <mat-option *ngFor="let location of locObj" [value]="location.location_id">
                                    {{location.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="optionsArray && optionsArray.length > 0" class="col-md-12"
                        style="font-size: 11px;text-decoration: none;font-weight: 600;">
                        <a href="javascript:void(0)" (click)="openCustomDialog()">Customize</a>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="contract-full-width">
                            <textarea matInput placeholder="Description:" [(ngModel)]="model.description"
                                name="description"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="contract-full-width">
                            <input matInput placeholder="Serial/IMEI No:" [(ngModel)]="model.serial_no"
                                name="serial_no">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div *ngFor="let optional of optionalFields; let i=index"
                        [ngClass]="{'col-md-6': moreField, 'col-md-12': !moreField}">

                        <mat-form-field class="contract-full-width">
                            <input matInput placeholder={{optional.assignedname}} [(ngModel)]="model[optional.code]"
                                name="{{optional.code}}Input">
                        </mat-form-field>






                    </div>
                </div>



                <div class="row">

                    <div class="col-md-4">
                        <mat-form-field class="contract-full-width">
                            <input matInput placeholder="HSN Code:" [(ngModel)]="model.hsn_code" name="hsn_code"
                                readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="contract-full-width">
                            <input type="number" matInput placeholder="Current Stock:" [(ngModel)]="model.cb" name="cb"
                                readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="contract-full-width">
                            <span matPrefix>
                                <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                            <input type="number" matInput placeholder="MRP:" [(ngModel)]="model.mrp" name="mrp"
                                class="form-input-number" readonly>
                        </mat-form-field>
                    </div>

                </div>
            </fieldset>
            <div class="row">
                <div class="col-md-7">
                    <fieldset class="border p-2 form-fieldset">
                        <legend class="w-auto">Amount Details:</legend>
                        <div class="row">
                            <div *ngIf="model.tax_type =='i' && data.trans_type =='Out'" class="col-md-6">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input type="number" matInput placeholder="Sale Rate:"
                                        [(ngModel)]="model.inclusive_rate" name="inclusive_rate" required
                                        (keyup)="onKeyUp()">
                                </mat-form-field>
                            </div>

                            <div class="col-md-6">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input *ngIf="data.trans_type =='In'" type="number" matInput placeholder="Rate:"
                                        [(ngModel)]="model.p_rate" name="p_rate" class="form-input-number" required
                                        (keyup)="onKeyUp()" #p_rate1>
                                    <input *ngIf="data.trans_type =='Out'" type="number" matInput placeholder="Rate:"
                                        [(ngModel)]="model.s_rate" name="s_rate" class="form-input-number" required
                                        (keyup)="onKeyUp()" #s_rate1
                                        [readonly]="model.options && model.options.length > 0">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix># &nbsp;</span>
                                    <input type="number" matInput placeholder="Quantity:" [(ngModel)]="model.qty"
                                        name="qty" required (keyup)="onKeyUp()" (click)="onKeyClickSpiner()" #qty1
                                        (keyUp.Enter)="onEnterFromQuantity()"
                                        [readonly]="model.options && model.options.length > 0">
                                </mat-form-field>
                            </div>
                            <div
                                [ngClass]="[model.tax_type =='i' && data.trans_type =='Out'? 'col-md-6' : 'col-md-12']">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input type="number" matInput placeholder="Amount:" [(ngModel)]="model.amount"
                                        name="amount" class="form-input-number" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="contract-full-width">
                                    <input type="number" matInput placeholder="Discount:" [(ngModel)]="model.dis_per"
                                        name="dis_per" class="form-input-number" (keyup)="onPerAmtChange()">
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input type="number" matInput placeholder="Discount Amount:"
                                        [(ngModel)]="model.dis_amt" name="dis_amt" class="form-input-number"
                                        (keyup)="onDisAmtChange()">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input type="number" matInput placeholder="Taxable Amount:"
                                        [(ngModel)]="model.taxable_amt" name="taxable_amt" class="form-input-number"
                                        required>
                                </mat-form-field>
                            </div>


                        </div>
                    </fieldset>

                    <div class="col-md-12">
                        <label class="mt-3 form-bold-label big">Total: </label>
                        <label class="form-bold-label big"><i class="fa fa-{{currency}}"></i>
                            {{model.total | number : '1.2-2'}}</label>

                    </div>
                </div>
                <div class="col-md-5">
                    <fieldset class="border p-2 form-fieldset">
                        <legend class="w-auto">Tax Details:</legend>
                        <div class="row">
                            <div class="col-md-6" *ngIf="model.tax_mode !== 'VAT'">
                                <mat-form-field class="contract-full-width">
                                    <input matInput placeholder="SGST:" [(ngModel)]="model.sgst_per" name="sgst_per"
                                        class="form-input-number" readonly>
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="model.tax_mode !== 'VAT'">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input type="number" matInput placeholder="SGST Amount:"
                                        [(ngModel)]="model.sgst_amt" name="sgst_amt" class="form-input-number" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="model.tax_mode !== 'VAT'">
                                <mat-form-field class="contract-full-width">
                                    <input matInput placeholder="CGST:" [(ngModel)]="model.cgst_per" name="cgst_per"
                                        class="form-input-number" readonly>
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="model.tax_mode !== 'VAT'">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input type="number" matInput placeholder="CGST Amount:"
                                        [(ngModel)]="model.cgst_amt" name="cgst_amt" class="form-input-number" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="model.tax_mode !== 'VAT'">
                                <mat-form-field class="contract-full-width">
                                    <input matInput placeholder="IGST:" [(ngModel)]="model.igst_per" name="igst_per"
                                        class="form-input-number" readonly>
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="model.tax_mode !== 'VAT'">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input type="number" matInput placeholder="IGST Amount:"
                                        [(ngModel)]="model.igst_amt" name="igst_amt" class="form-input-number" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="model.tax_mode === 'VAT'">
                                <mat-form-field class="contract-full-width">
                                    <input matInput placeholder="VAT:" [(ngModel)]="model.vat_per" name="vat_per"
                                        class="form-input-number" readonly>
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="model.tax_mode === 'VAT'">
                                <mat-form-field class="contract-full-width">
                                    <span matPrefix>
                                        <i class="fa fa-{{currency}}"></i>&nbsp;</span>
                                    <input type="number" matInput placeholder="VAT Amount:" [(ngModel)]="model.vat_amt"
                                        name="vat_amt" class="form-input-number" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <label class="form-bold-label">Total Tax: </label>
                                <label class="form-bold-label"><i class="fa fa-{{currency}}"></i>
                                    {{model.total_tax | number : '1.2-2'}}</label>

                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" class="btn mat-raised-button" (click)="close()">Close</button>
        <button type="button" class="btn-primary mat-raised-button" (click)="transForm.valid && onSubmit()" #sub1>
            <span *ngIf="!data.item">Add</span>
            <span *ngIf="data.item">Save</span>
        </button>
    </mat-dialog-actions>
</div>