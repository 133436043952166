import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/data.service';
import { AddUnitComponent } from 'src/app/units/add-unit/add-unit.component';

@Component({
  selector: 'app-add-unit-converter',
  templateUrl: './add-unit-converter.component.html',
  styleUrls: ['./add-unit-converter.component.css']
})
export class AddUnitConverterComponent implements OnInit {
  unit: any = []
  unitObj:any=[]

  @ViewChild('f') f;
  constructor(private dialogRef: MatDialogRef<AddUnitConverterComponent>,
    private notifier: NotifierService,
    private apiService: DataService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.f.submitted = true;
    this.load();
  }
  model: any = {};
  load() {
    if (this.data) {
      this.apiService.getUnitConvertdata(this.data.id)
        .subscribe((result: any) => {
          this.model = result[0];
        }, (result: any) => {
          this.notifier.notify('error', 'unable to load data');
        });
    }
    this.apiService.getUnitList().subscribe((result: any) => {
      this.unitObj = result;
    });
  }
  onSubmit() {
    if (this.data) {
      this.apiService.EditUnitConvert(this.data.id,this.model)
        .subscribe((result: any) => {
          this.dialogRef.close('Edited');
        }, (result: any) => {
          this.notifier.notify('error', 'unable to update unit details');
        });
    } else {
      this.apiService.addUnitConvert(this.model)
        .subscribe((result: any) => {
          if (result.success)
            this.dialogRef.close({ status: 'Added' });
          else
            this.notifier.notify('error', result.message);

        }, (result: any) => {
          this.notifier.notify('error', 'unable to add new unit');
        });
    }

  }

  close() {
    this.dialogRef.close();
  }

  onAddUnitClick() {
    this.dialog.open(AddUnitComponent, {
      width: '500px',
    })
      .afterClosed().subscribe(res => {
        if (res.status == 'Added') {
          this.apiService.getUnitList()
            .subscribe((result: any) => {
              this.unitObj = result;
              var unitIndex = this.unitObj.findIndex(unit => unit.unit_code == res.unit_name)
              this.unit.setValue(this.unitObj[unitIndex]);
              this.model.unit_id = this.unitObj[unitIndex].unit_id;
            }, (result: any) => {
              this.notifier.notify('error', 'unable to load unit list');
            });


        }
      });
  }

}
