import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgModule,
} from "@angular/core";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MAT_DATE_FORMATS,
  NativeDateAdapter,
  DateAdapter,
} from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from "../date.adapter";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { DataService } from "../data.service";
import { EditExpenseComponent } from "../expense/edit-expense/edit-expense.component";
import { ExportService } from "../_export-service/daybook-export.service";
import * as moment from "moment";
import { NotifierService } from "angular-notifier";
@Component({
  selector: "app-bil-cancellation",
  templateUrl: "./bil-cancellation.component.html",
  styleUrls: ["./bil-cancellation.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class BilCancellationComponent implements OnInit {
  accountVoucherObj: MatTableDataSource<any>;
  constructor(
    private apiService: DataService,
    public dialog: MatDialog,
    private exportService: ExportService,
    private notifier: NotifierService
  ) {}

  openDialog: any;
  export_data: any;
  userList: any = [];
  selectedUserId: any = [];
  checkEnableResturantFlag: boolean;
  displayedColumns: any;
  model: any = {
    from_date: new Date(),
    to_date: new Date(),
    voucherList: [],
    voucher_type: [],
    filter_user_id: -1,
  };

  voucherObj: any;
  voucherTypeArr: any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("TABLE") table: ElementRef;
  @ViewChild("btnGrp") btnGrp: any;
  ngOnInit() {
    this.getUser();
    this.load();
  }
  getVoucherTypeList;
  UserFilter(value) {
    this.model.filter_user_id = value;
  }

  getUser() {
    this.apiService.getuserList().subscribe(
      (result: any) => {
        if (result.success) {
          this.userList = result.data;
        } else {
          this.notifier.notify("error", result.message);
        }
      },
      (err: any) => {
        this.notifier.notify(
          "error",
          "An error occurred while fetching the user list."
        );
      }
    );
  }
  load() {
    this.apiService.getEnableResturantPos().subscribe(
      (result: any) => {
        this.checkEnableResturantFlag = result;
        this.displayedColumns = this.checkEnableResturantFlag
          ? [
              "trans_date",
              "time",
              "trans_no",
              "bill_no",
              "v_type_name",
              "ledger_name",
              "full_name",
              "tablename",
              "net_amount",
            ]
          : [
              "trans_date",
              "time",
              "trans_no",
              "bill_no",
              "v_type_name",
              "ledger_name",
              "full_name",
              "net_amount",
            ];
      },
      (error: any) => {
        // Handle error
      }
    );

    this.apiService.getVoucherTypeList().subscribe(
      (result: any) => {
        this.voucherObj = result;
        console.log("voucherObj-----", this.voucherObj);
        this.voucherTypeArr = [];
        this.voucherObj.forEach((element) => {
          this.voucherTypeArr.push(element.v_type_id);
        });
        console.log("this vouchertypeaar", this.voucherTypeArr);
        this.model.voucher_type = this.voucherTypeArr;

        console.log("thissssss", this.model.voucher_type);
        this.apiService
          .getAllVoucherList(
            this.model.from_date,
            this.model.to_date,
            this.model.voucher_type,
            this.model.filter_user_id
          )
          .subscribe(
            (result: any) => {
              console.log("with user information in Day books", result);
              console.log("reeeeeeesult", result);
              // Filter the results to display only non-canceled rows with v_type_name "Invoice"
              const filteredResult = result.filter(
                (item) => item.active === false
              );

              this.export_data = JSON.parse(JSON.stringify(filteredResult));
              this.model.voucherList = filteredResult;
              console.log("this.model.voucherList", this.model.voucherList);
              this.accountVoucherObj = new MatTableDataSource(filteredResult);
              this.accountVoucherObj.sort = this.sort;
              this.accountVoucherObj.paginator = this.paginator;
              console.log("WWWWWWWWWWWWWWWWWWWWWWWWWW", this.accountVoucherObj);
            },
            (error: any) => {
              // Handle error
            }
          );
      },
      (error: any) => {
        // Handle error
      }
    );
  }

  searchClick() {
    // console.log("");
    this.apiService
      .getAllVoucherList(
        this.model.from_date,
        this.model.to_date,
        this.model.voucher_type,
        this.model.filter_user_id
      )
      .subscribe(
        (result: any) => {
          console.log("crrrrrrrrrresult", result);
          // Filter the results to display only rows with v_type_name "Expense"
          const filteredResult = result.filter((item) => item.active === false);
          this.export_data = JSON.parse(JSON.stringify(filteredResult));
          console.log("this.export_data", this.export_data);
          this.model.voucherList = filteredResult;
          console.log("this.model.voucherList", this.model.voucherList);

          // Access the selected user_id from the model
          this.selectedUserId = parseInt(this.model.filter_user_id || -1);
          // console.log('Selected user_id:', this.selectedUserId);
          if (this.selectedUserId === -1) {
            this.accountVoucherObj = new MatTableDataSource(filteredResult);
          } else {
            const filteredData = filteredResult.filter(
              (row: any) => row.user_id === this.selectedUserId
            );
            this.accountVoucherObj = new MatTableDataSource(filteredData);
          }
          this.accountVoucherObj.sort = this.sort;
          this.accountVoucherObj.paginator = this.paginator;
        },
        (result: any) => {
          this.selectedUserId = result.data;
        }
      );
  }

  printClick() {
    this.model.trans_type = "DayBook";
    this.apiService.downloadPdf(this.model).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "daybook" + new Date().toLocaleDateString() + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => {}
    );
  }

  edittransaction(row) {
    const component = EditExpenseComponent;
    this.dialog
      .open(component, {
        panelClass: "dialog-class",
        width: "1100px",
        data: row,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == "Edited") {
          this.load();
        }
      });
  }
  getCashAmt(row) {
    if (row.cash) return row.cash;
    else return 0;
  }
  getCardAmt(row) {
    if (row.cash) return row.card;
    else return 0;
  }
  //to export data to csv file
  ExportTOExcel() {
    var dataArr = [];
    this.export_data.forEach((d) => {
      var rowValues = [];
      rowValues = [];
      rowValues[1] = new Date(d.trans_date);
      rowValues[2] = d.trans_no;
      rowValues[3] = d.v_type_name;
      rowValues[4] = d.customer_name
        ? d.ledger_name + "-" + d.customer_name
        : d.ledger_name;
      rowValues[5] = d.net_amount;
      dataArr.push(rowValues);
    });
    var columnWidth = [
      { column: 1, width: 20 },
      { column: 2, width: 20 },
      { column: 3, width: 20 },
      { column: 4, width: 30 },
      { column: 5, width: 20 },
    ];
    var numFmt = [{ column: 1, numFmt: "dd/mm/yyyy" }];
    var frm_date = moment(this.model.from_date).format("DD/MM/YYYY");
    var til_date = moment(this.model.to_date).format("DD/MM/YYYY");
    const file_name = "DayBook";
    const title = "Day Book Report";
    const subheader = "From: " + frm_date + " To: " + til_date;
    const header = ["Date", "Voucher No.", "Type", "Details", "Amount"];
    this.exportService.generateExcel(
      file_name,
      title,
      header,
      subheader,
      dataArr,
      columnWidth,
      numFmt
    );
  }
}
