<div class='login-backgound'>
  <div class="container-fluid loghead" style="display: flex;">
    <div class="col-sm-6"><a target="_blank" href="https://intellibooks.io/">
        <img src="../../assets/images/logoNew.png" alt="" class="logodb"></a>
    </div>
    <div class="learn-more col-sm-6">
      <a href="https://intellibooks.io/" target="_blank">Learn more</a>
    </div>
  </div>
  <div class="main-div mt-5">
    <div class="col-md-1"></div>
    <div class="col-md-3 login-block">
      <mat-card>
        <div class="example-container">
          <div class="row m-b-20">
            <div class="col-md-12">
              <h3 class="text-center">Sign In</h3>
              <p></p> <br>
            </div>
          </div>
          <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
            <mat-form-field class="contract-full-width">
              <mat-select placeholder="Company" [(ngModel)]="model.company_id" name="company_id"
                (selectionChange)="onCompanySelection($event)" required>
                <mat-option *ngFor="let company of companyArr" [value]="company.company_id">
                  {{company.company_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="contract-full-width">
              <mat-select placeholder="Financial Year" [(ngModel)]="model.fy_id" name="fy_id" required>
                <mat-option *ngFor="let fy of fyArr" [value]="fy.fy_id"> {{fy.fy_year}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span>
              <mat-form-field appearance="outline">
                <mat-label>Enter Username</mat-label>
                <input matInput placeholder="Username" [(ngModel)]="model.username" name="username" #username="ngModel"
                  required>
              </mat-form-field>
            </span>
            <div *ngIf="f.submitted && !username.valid" class="help-block"> </div>

            <span>
              <mat-form-field appearance="outline">
                <mat-label>Enter Pasword</mat-label>
                <input matInput placeholder="Password" name="password" [(ngModel)]="model.password" #password="ngModel"
                  type="password" required>
              </mat-form-field>
            </span>
            <div *ngIf="f.submitted && !password.valid" class="help-block"> </div>

            <button [disabled]="loading" class="btn-primary btn-md btn-block text-center m-b-20 ripple light submt">Sign
              in</button>
            <img *ngIf="loading"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </form>
          <br>
        </div>
      </mat-card>
    </div>
    <div class="col-md-1"></div>
    <div class="slide-tab col-md-6">
      <mat-tab-group headerPosition>
        <mat-tab label="Restaurant POS"> <img src="../../assets/images/Untitled design 90.png" alt=""> </mat-tab>
        <mat-tab label="Mobile POS"> <img src="../../assets/images/Untitled design 91.png" alt=""> </mat-tab>
        <mat-tab label="Online Ordering"> <img src="../../assets/images/Untitled design 92.png" alt=""> </mat-tab>
        <mat-tab label="Kitchen Display System"><img src="../../assets/images/kds.png" alt=""></mat-tab>
      </mat-tab-group>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>




<!-- 
<form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
  <div class='login-container'>
    <div class="login-header">
      <h3>Contract Management</h3>

    </div>
    <div class="login-body form-group">

      <h4>Login</h4>
      <hr />
      <input type='text' class='form-control' name="username"
       [(ngModel)]="model.username" #username="ngModel"
        placeholder="User Name" required />
      <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
      <br />

      <input type='password' class='form-control' name="password" 
      [(ngModel)]="model.password" #password="ngModel"
        placeholder='Password' required />
      <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
      <br />

      <button [disabled]="loading" class="btn btn-primary">Submit</button>
      <img *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>
  </div>
-->