import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/rst-dashboard/booking-form/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})

export class OrderConfirmationComponent implements OnInit {


  rejectionMessages: any = [
    { key: 1, message: "Items out of stock" },
    { key: 2, message: "No delivery boys available" },
    { key: 3, message: "Nearing closing time" },
    { key: 4, message: "Out of Subzone/Area" },
    { key: 5, message: "Kitchen is Full" },
  ]
  swiggyRejectionMessages: any = [
    { key: 1, message: "Item is out of stock" },
    { key: 2, message: "Where is Delivery Executive?" },
    { key: 3, message: "Price(s) is incorrect" },
    { key: 4, message: "Special instructions confirmation" },
    { key: 5, message: "Delay in order preparation" },
    { key: 6, message: "Add-on(s)/Variant(s) is not specified" },
    { key: 7, message: "Report Delivery Executive behavior" },
    { key: 8, message: "Some other reason" },
  ]
  rejectionMessage: any;
  selectedTime: any;
  preparationTime: any;
  order_mode: any;
  trans_no: any;

  onTimeSet(newTime: any) {
    // console.log('Selected time:', newTime);
    // Handle the selected time as needed
  }
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.preparationTime = data.preparationTime;
    this.order_mode = data.order_mode;
    this.trans_no = data.trans_no
  }

  isDataSelected(): boolean {
    if (this.data.title === 'Approve') {
      return this.preparationTime !== undefined && this.preparationTime !== null && this.preparationTime !== '';
    } else if (this.data.title === 'Reject') {
      return this.rejectionMessage !== undefined && this.rejectionMessage !== null && this.rejectionMessage !== '';
    }
    return false;
  }

  onConfirm(data: any): void {
    // console.log(this.rejectionMessage)
    const approveData = {
      approved: true,
      preparationTime: this.preparationTime
    }
    const rejectData = {
      approved: true,
      rejection_id: this.rejectionMessage
    }
    const message = data.title === 'Approve' ? approveData : rejectData
    this.dialogRef.close(message);
  }
  onCancel(): void {
    this.dialogRef.close({ approved: false });
  }

  ngOnInit() {
  }

}
