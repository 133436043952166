<div class="my-container">
  <h3 class="color-h3">Memos</h3>
  <div class="table-details">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="contact_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Contact Number
        </th>
        <td mat-cell *matCellDef="let row">{{ row.contact_no }}</td>
      </ng-container>
      <ng-container matColumnDef="customer_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
        <td mat-cell *matCellDef="let row">
          <a
            [routerLink]=""
            (click)="openTakeAwayCustomer($event, row)"
            class="myClass"
            >{{ row.customer_name }}</a
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="gross_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gross Amount</th>
        <td mat-cell *matCellDef="let row">{{ row.gross_amount }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
      </ng-container>
      <ng-container matColumnDef="gstin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>GstIn</th>
        <td mat-cell *matCellDef="let row">{{ row.gstin }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            color="warn"
            (click)="deleteMemo($event, row)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'table-danger': row.active == false }"
      ></tr>
    </table>
    <div class="button-group">
      <mat-dialog-actions>
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="add(true)"
        >
          ADD
        </button>
        <button mat-raised-button mat-dialog-close color="warn" type="button">
          CLOSE
        </button>
      </mat-dialog-actions>
    </div>
    <mat-paginator
      [pageSizeOptions]="[20, 30, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
