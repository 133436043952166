import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/data.service';
import { AddItemImageComponent } from 'src/app/items/add-item-image/add-item-image.component';

@Component({
  selector: 'app-add-website-image',
  templateUrl: './add-website-image.component.html',
  styleUrls: ['./add-website-image.component.css']
})
export class AddWebsiteImageComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AddItemImageComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // console.log("data-----------",data)
     }
  file: any;
  imageUrl: any;
  ngOnInit() {
    this.load();
  }

  load() {
    this.imageUrl = this.data.imageurl;
  }

  imgInputChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    this.readFile(this.file);
  }
  onSave() {
    const fd = new FormData();
    // fd.image_name = this.data
    fd.append('itemImg', this.file, this.file.name);
    // console.log("fd------",fd, this.file, this.file.name)
    this.apiService.addWebsiteImage(fd)
      .subscribe((result: any) => {
        if (result.success) {
          this.notifier.notify('success', 'Image updated successfully');
        } else {
          this.notifier.notify('error', result.message);
        }
        this.dialogRef.close("Save");
      }, (result: any) => {
        this.notifier.notify('error', 'unable to update image...try after some time.');
      });
  }
  close() {
    this.dialogRef.close();
  }

  readFile(file: File) {
    var reader = new FileReader();
    reader.onload = () => {
      this.imageUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }
}
