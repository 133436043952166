import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-do',
  templateUrl: './do.component.html',
  styleUrls: ['./do.component.css']
})
export class DOComponent implements OnInit {

  do:any;
  constructor(private apiService:DataService) { }
  model: any = { from_date: new Date(), to_date: new Date, type: "DeliveryOrder" };
  ngOnInit() {
    this.getTotalDO();
  }
 getTotalDO(){
  //********* Get Current Month **********/
  var date = new Date();
 this.model.from_date = new Date(date.getFullYear(), date.getMonth(), 1);
  this.model.to_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  this.model.to_date.setHours(23, 30, 60, 60);
  this.model.from_date.setHours(6, 0, 0, 0); 
  this.apiService.getTransCount(this.model.from_date, this.model.to_date, this.model.type).subscribe((result:any)=>{
  //  console.log(result);
    // this.invoice = result.count;
    this.do = result;
    // console.log(this.do);

  },(result:any)=>{

  });
}

}
