import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import {MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter  } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../date.adapter';

@Component({
  selector: 'app-due-payment',
  templateUrl: './due-payment.component.html',
  styleUrls: ['./due-payment.component.css'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class DuePaymentComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private dialogRef: MatDialogRef<DuePaymentComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    duePaymentObj:any;
    invoicePaymentObj = [];
    displayedColumns = ['check','trans_date', 'trans_no','bill_no', 'net_amount','advance','paid','due'];

  ngOnInit() {
    this.load();
  }

  load() {
    this.apiService.getDuePaymentList(this.data)
      .subscribe((result: any) => {
        this.duePaymentObj = result;
        this.duePaymentObj.forEach(element => {
          element.isChecked = false;
          element.bill_amt = element.due; 
          });
      }, (result: any) => {
      });
  }
 
  closeDialog() {
    this.dialogRef.close();
  }


  onSelection(data) {
    if(data.isChecked){
      this.invoicePaymentObj.push(data);
    }else{
      const index = this.invoicePaymentObj.indexOf(data.transaction_id);
      if (index)
      this.invoicePaymentObj.splice(index, 1);
    }
}

cancelPaymentPopup(){
  this.dialogRef.close();
}

addAllTransactionData(){
  this.dialogRef.close(this.invoicePaymentObj);
}




}
