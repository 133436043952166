<h2 class="color-h3" mat-dialog-title>Change Password</h2>
<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <div class="row">

        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Current Password" type="password" [(ngModel)]="model.old_password"
              name="old_password" required>
          </mat-form-field>
        </div>

        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="New Password" type="password" [(ngModel)]="model.password" name="password"
              required>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Confirm Password" type="password" [(ngModel)]="model.confirm_password"
              name="confirm_password" required>
          </mat-form-field>
        </div>

      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">Close</button>
    <button type="submit" class="mat-raised-button mat-primary" (click)="onSubmit()"
      [disabled]="f.invalid">Change</button>
  </mat-dialog-actions>
</div>