import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/data.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tabletransfer-dialog',
  templateUrl: './tabletransfer-dialog.component.html',
  styleUrls: ['./tabletransfer-dialog.component.css']
})
export class TabletransferDialogComponent implements OnInit {
  selectedTable: any;
  tableDetails: any
  table_id: any
  data: any = {};
  owntable: any
  trans_type: any = {};
  model: any = { trans_data: [], trans_type: [], time: [], date: [] };
  constructor(private dialogRef: MatDialogRef<TabletransferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public details: any = [], private notifier: NotifierService, private apiService: DataService, private datePipe: DatePipe) {
    // console.log()
  }
  ngOnInit() {
    this.load()
  }
  load() {
    // -----------------------getTableListWithKOT-----
    this.apiService.getTableListWithKOT()
      .subscribe((result: any) => {
        this.tableDetails = result.filter((table: any) => table.id !== this.details && table.kot_count == 0);
        // this.tableDetails = result.filter((table: any) => table.kot_count === 0);
        this.owntable = result.filter((table: any) => table.id == this.details);
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }
  // ---------------------transfer table -------------------
  processTransfer() {
    if (!this.data.selectedTable) {
      console.log('Please select a table before transferring.');
      return;
    }
    const data = {
      table_id_1: this.details,
      table_id_2: this.data.selectedTable
    };
    // console.log("processTransfer*************", data);
    this.apiService.transferTable(data).subscribe(
      (result: any) => {
        // console.log("table transfer completed ---------", result)
        if (result.success == true) {
          console.log('Data transfer successful');
          this.notifier.notify('success', 'Table Transfer successfully');
          this.data.selectedTable = null;
          this.dialogRef.close('true');
          this.printClick(data);
        } else {
          console.log('Data transfer failed');
          this.notifier.notify('error', 'Table Transfer failed');
          this.dialogRef.close();
        }
      },
      (error: any) => {
        console.log('table transfer error occurred:', error);
      }
    );
  }
  printClick(data: any) {
    this.model.trans_type = "transfer table";
    this.model.trans_data = data
    const currentTime = new Date();
    const currentDateString = currentTime.toLocaleDateString();
    const currentTimeString = currentTime.toLocaleTimeString();
    this.model.time = currentTimeString;
    this.model.date = currentDateString;
    this.apiService.downloadPdf(this.model)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'transfer_table' + new Date().toLocaleDateString() + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
}




