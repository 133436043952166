<div class="panelcard">
  <h3 class="color-h3">Category Sales Report</h3>
  <div class="table-details">
    <form>
      <div class="row">
        <div class="col-md-3">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              [matDatepicker]="from_date"
              placeholder="Date:"
              [(ngModel)]="model.from_date"
              name="from_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="from_date"
            ></mat-datepicker-toggle>
            <mat-datepicker #from_date></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-2">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              [matDatepicker]="to_date"
              placeholder="To:"
              [(ngModel)]="model.to_date"
              name="to_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="to_date"
            ></mat-datepicker-toggle>
            <mat-datepicker #to_date></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-1 col-2">
          <button class="btn mat-raised-button" (click)="serachClick()">
            Search
          </button>
        </div>
        <div *ngIf="itemSalesArr" class="col-md-1 col-4">
          <button
            class="btn mat-raised-button ml-2 marginLeft"
            (click)="ExportTOExcel()"
          >
            Export
          </button>
        </div>
        <!-- <div class="col-md-1 col-2">
        <button type="button" class="btn" (click)="printClick()">Print</button>

      </div> -->
      </div>
    </form>
    <!-- Your existing HTML code -->
    <table
      mat-table
      [dataSource]="itemSalesArr"
      style="border-collapse: collapse"
    >
      <ng-container matColumnDef="category_name">
        <th mat-header-cell *matHeaderCellDef>Category Name</th>
        <td mat-cell *matCellDef="let row" data-label="Name">
          {{ row.category_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Total Amount</th>
        <td mat-cell class="right" *matCellDef="let row" data-label="Amount">
          {{ row.amount | number : "1.2-2" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          class="right"
          *matCellDef="let row"
          data-label="button"
          style="width: 140px"
        >
          <a mat-button (click)="onShow(row)">Show Items</a>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['category_name', 'amount', 'action']"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['category_name', 'amount', 'action']"
      ></tr>
    </table>
    <br />
    <br />
    <!-- Selected items table -->

    <div *ngIf="selectedCategoryItems.length > 0" class="selected-items-table">
      <h3>{{ selectedCategoryItems[0]?.category_name }}</h3>

      <table
        mat-table
        [dataSource]="selectedItemsDataSource"
        style="border-collapse: collapse"
      >
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let item" data-label="Quantity">
            {{ item.qty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="item_name">
          <th mat-header-cell *matHeaderCellDef>Item Name</th>
          <td mat-cell *matCellDef="let item" data-label="Name">
            {{ item.item_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Total Amount</th>
          <td mat-cell class="right" *matCellDef="let item" data-label="Amount">
            {{ item.amount | number : "1.2-2" }}
          </td>
        </ng-container>

        <!-- Footer row -->
        <tr mat-header-row *matHeaderRowDef="selectedItemsColumns"></tr>
        <tr mat-row *matRowDef="let item; columns: selectedItemsColumns"></tr>
      </table>

      <div
        *ngIf="!itemSalesArr || !itemSalesArr.length"
        class="row.width center"
      >
        No Record Found
      </div>
    </div>
  </div>
</div>
