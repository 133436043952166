import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { DataService } from '../../data.service';
import { TaxSummaryService } from '../../tax-summary.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TransactionItemsComponent } from '../../transaction-items/transaction-items.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AddEditCustomersMasterComponent } from '../../customers-master/add-edit-customers-master/add-edit-customers-master.component';
import { TransactionListComponent } from 'src/app/transaction-list/transaction-list.component';
import { SendMailComponent } from '../../send-mail/send-mail.component';
import { environment } from '../../../environments/environment';
import { MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../date.adapter';
import { PurchaseOrderItemsComponent } from 'src/app/purchase-order/purchase-order-items/purchase-order-items.component';


@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class EditInvoiceComponent implements OnInit {
  constructor(private apiService: DataService,
    private taxService: TaxSummaryService,
    private dialogRef: MatDialogRef<EditInvoiceComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
    this.currency = environment.currency;
  }
  @ViewChild('f') f;
  customerRates: any;
  customer_item_rate_applicable: boolean = false;
  use_qty_from_sale_history: boolean = false;
  currency: any;
  invoice_no: any;
  contractObj: any;
  ledgerObj: any;
  transDataObj: any = { items_details: [] };
  items: any = [];
  sgst: any = [];
  cgst: any = [];
  igst: any = [];
  tax: any = [];
  totalAmt: any;
  amount: any;
  totalTax: any;
  totalNetAmt: any;
  roundOffAmt: any;
  totalDis: any;
  totalSGST: any;
  totalCGST: any;
  totalIGST: any;
  ledger_id: any;
  sgstUnqPer: any = [];
  igstUnqPer: any = [];
  sign: any;
  dataObj: any = {};
  ledgerControl = new FormControl();
  place_of_supply = new FormControl();
  options: any;
  filteredOptions: Observable<string[]>;
  mapArr: any;
  isShipping: boolean = true;
  isTransaction: boolean = true;
  isPOS: boolean = false;
  isCredit: boolean = false;
  isCash: boolean = true;
  isTable: boolean = false;
  tables: any;
  ledger_state: any;
  company: any;
  ledgerObjForEmail: any;
  optionalFields: any;
  moreField = false;
  GState: any;
  stateName: any;
  statelist: any;
  sbilling_state = new FormControl();
  shipping_state = new FormControl();
  l_id: any;
  closing_balance: any
  isTransactionGSTslab: boolean = false;
  taxSlabList: any;
  validationForm: FormGroup;
  otpInvalid = false;
  otpVerified = false;
  showValidationInput = false;
  formSubmitted = false;
  // non_receivableData = { non_receivable: false };
  ngOnInit() {
    this.apiService.getGstState()
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        this.load();
      }, (result: any) => {
        console.log('state not loaded');
        this.notifier.notify('error', 'unable to load data');
      });
    this.tableList();
    this.getEnabledTransFields();
    this.validationForm = this.fb.group({
      validationCode: ['', Validators.required]
    });

    this.validationForm.get('validationCode').valueChanges.subscribe((value: string) => {
      if (value && value.length === 5) {
        this.otpVerified = false;
        this.otpInvalid = false;
      } else if (value && value.length === 6) {
        this.verifyEmailOtp(value);
      }
    });
  }


  onCheckboxChange() {

    if (!this.transaction_details.non_receivable) {
      if (!this.showValidationInput) {
        this.apiService.ncValidation().subscribe(
          (response) => {
            // console.log('API response:', response);
          },
          (error) => {
            console.error('API error:', error);
          }
        );
        this.showValidationInput = !this.showValidationInput;
      }

    }
  }

  verifyEmailOtp(validationCode: string) {
    // console.log("1111111111111111111", this.otpInvalid);
    const requestBody = { code: validationCode };
    this.apiService.verifyemailotp(requestBody).subscribe(
      (result: any) => {
        if (result.success) {
          this.otpVerified = true;
          this.otpInvalid = false;
        }
        if (!result.success) {
          this.otpInvalid = true;
          this.otpVerified = false;

        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }

  getEnabledTransFields() {
    this.apiService.getEnabledInvoiceField(5)
      .subscribe((result: any) => {
        this.optionalFields = result;
        if (this.optionalFields.length > 7) {
          this.moreField = true;
        }
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load enabled fields');
      });
  }

  tableList() {
    this.apiService.getTableList()
      .subscribe((result: any) => {
        this.tables = result;
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load table data');
      });
  }
  filter(value) {
    const filterValue = value.toLowerCase();
    return this.ledgerObj.filter(ledger => ledger.ledger_code.toLowerCase().includes(filterValue) || ledger.ledger_name.toLowerCase().includes(filterValue));
  }

  displayFn(ledger) {
    return ledger ? ledger.ledger_name : "";
  }

  // State ************************************
  displayStateFn(state) {
    return state ? state.state_name : '';
  }
  displayStateSuplay(state) {
    return state ? state.state_name : '';
  }

  displayStateFnShipState(state) {
    return state ? state.state_name : '';
  }


  allstates() {
    this.apiService.getGstState()
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }
  // searching for ledger
  searchState(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.sbilling_state.value.length >= 1) {
        this.apiService.sgetGstState(this.sbilling_state.value, 'false')
          .subscribe((result: any) => {
            this.GState = result;
            this.stateName = this.GState;
          });
      }
    }
  }
  searchStateSuplay(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.place_of_supply.value.length >= 1) {
        this.apiService.sgetGstState(this.place_of_supply.value, 'false')
          .subscribe((result: any) => {
            this.GState = result;
            this.stateName = this.GState;
          });
      }
    }
  }




  searchStateShipState(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.shipping_state.value.length >= 1) {
        this.apiService.sgetGstState(this.shipping_state.value, 'false')
          .subscribe((result: any) => {
            this.GState = result;
            this.stateName = this.GState;
          });
      }
    }
  }

  StateonFocusSuplay(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        // console.log(this.stateName);
      });
  }
  // on focus on ledger search
  StateonFocus(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        // console.log(this.stateName);
      });
  }


  StateonFocusShipState(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.GState = result;
        this.stateName = this.GState;
        // console.log(this.stateName);
      });
  }

  StateSelection(event: MatAutocompleteSelectedEvent) {
    this.form.get('billing_state').setValue(event.option.value.state_gst_id);
  }


  StateSelectionSuplay(event: MatAutocompleteSelectedEvent) {
    this.form.get('place_of_supply').setValue(event.option.value.state_gst_id);
  }
  // State End************************************
  StateSelectionShipState(event: MatAutocompleteSelectedEvent) {
    this.form.get('shipping_state').setValue(event.option.value.state_gst_id);
  }


  sameAdd(isChecked: boolean) {
    if (isChecked) {
      this.shipping_state.setValue(this.sbilling_state.value);
      this.form.get('shipping_address1').setValue(this.form.get('billing_address1').value);
      this.form.get('shipping_address2').setValue(this.form.get('billing_address2').value);
      this.form.get('shipping_street').setValue(this.form.get('billing_street').value);
      this.form.get('shipping_city').setValue(this.form.get('billing_city').value);
      this.form.get('shipping_state').setValue(this.form.get('billing_state').value);
      this.form.get('shipping_country').setValue(this.form.get('billing_country').value);
      this.form.get('shipping_pin').setValue(this.form.get('billing_pin').value);
    }
    else {
      this.shipping_state.setValue(null);
      this.form.get('shipping_address1').setValue("");
      this.form.get('shipping_address2').setValue("");
      this.form.get('shipping_street').setValue("");
      this.form.get('shipping_city').setValue("");
      this.form.get('shipping_state').setValue("");
      this.form.get('shipping_country').setValue("");
      this.form.get('shipping_pin').setValue("");
    }

  }
  isSOcount: boolean = false;
  so_count: any;
  isDOcount: boolean = false;
  do_count: any;
  transaction_details: any = {};
  load() {
    console.log("data---------", this.data)
    // Get the value from localStorage
    var isTransactionWiseGST = localStorage.getItem("isTransactionGSTslab");

    // Check if the value is "true" or "false" and assign a boolean accordingly
    if (isTransactionWiseGST === "true")
      this.isTransactionGSTslab = true;

    this.f.submitted = true;
    this.apiService.getAccountMapList()
      .subscribe((result: any) => {
        this.mapArr = result;
        this.findAccSetting();
      }, (result: any) => {
      });

    this.apiService.getInvoice(this.data.transaction_id)
      .subscribe((result: any) => {

        this.items = result.items_details;
        // console.log("IIIIIIIIIIIIIIIIIIIIIIIIIIII", this.items);

        this.transaction_details = result;
        // this.non_receivableData =result
        console.log("TTTTTTTTTTTTTTTTTTTTTTTTTTT", this.transaction_details);

        this.form.patchValue(result);
        this.getCustomerRate();
        // if (result.taxslab) {
        //   this.isTransactionGSTslab = true;
        // }
        let indexofState = this.stateName.findIndex(i => i.state_gst_id === result.billing_state);
        this.sbilling_state.setValue(this.stateName[indexofState]);

        let indexofState1 = this.stateName.findIndex(i => i.state_gst_id === result.place_of_supply);
        this.place_of_supply.setValue(this.stateName[indexofState1]);

        let indexofState3 = this.stateName.findIndex(i => i.state_gst_id === result.shipping_state);
        this.shipping_state.setValue(this.stateName[indexofState3]);


        if (this.isPOS) {
          this.getPOSleserId();
        }
        if (parseInt(result.so_count)) {
          this.isSOcount = true;
          this.so_count = result.so_count;
        }
        if (parseInt(result.do_count)) {
          this.isDOcount = true;
          this.do_count = result.do_count;
        }

        this.apiService.getCompanyInfo()
          .subscribe((result: any) => {
            this.company = result;
            if (this.isTransactionGSTslab) {
              this.calculateAmtWithTransTax()
            } else {
              this.calculateAmt();
            }
          }, (result: any) => {
          });
        this.apiService.getTaxslabList().subscribe((result: any) => {
          this.taxSlabList = result;
        },
          (result: any) => {
            this.notifier.notify('error', 'unable to load Table list');
          });
      }, (result: any) => {
      });
    this.apiService.getLedger(this.data.ledger_id)
      .subscribe((result: any) => {
        this.ledgerObj = result[0];
        this.customer_item_rate_applicable = this.ledgerObj.customer_item_rate_applicable;
        this.use_qty_from_sale_history = this.ledgerObj.use_qty_from_sale_history;
        this.ledgerControl.setValue(this.ledgerObj);
        this.apiService.getLedgerClosingBalance(this.data.ledger_id).subscribe(
          (result: any) => {
            if (result.success) {
              this.closing_balance = result.message.closing_balance + ' ' + result.message.cbdrcr;

            }
            else {
              this.notifier.notify('error', result.message);
            }
          },
          (err: any) => {

          }
        )
      }, (result: any) => {
      });
    this.apiService.getContractList()
      .subscribe((result: any) => {
        this.contractObj = result;
      }, (result: any) => {
      });

  }
  //find map setting
  findAccSetting() {
    var indexOfShippingData = this.mapArr.findIndex(i => i.key === "Invoice_Shipping_Details");
    var indexOfTransData = this.mapArr.findIndex(i => i.key === "Invoice_Transaction_Details");
    var indexofPOS = this.mapArr.findIndex(i => i.key === "Enable_POS");
    var indexofTABLE = this.mapArr.findIndex(i => i.key === "Enable_Restaurant_POS");

    if (!this.mapArr[indexOfShippingData].checkbox_value)
      this.isShipping = false;
    if (!this.mapArr[indexOfTransData].checkbox_value)
      this.isTransaction = false;
    if (this.mapArr[indexofPOS].checkbox_value) {
      this.isPOS = true;
      this.form.get('sale_type').setValue('cash');
    } else {
      this.form.get('sale_type').setValue('credit');
    }
    if (this.mapArr[indexofTABLE].checkbox_value) {
      this.isTable = true;
      this.form.controls["table_id"].setValidators(Validators.required);
    }

  }
  //searching for ledger
  search(event) {
    this.closing_balance = null;
    if (event.key != "ArrowDown" && event.key != "ArrowUp" && event.key != "Enter") {
      if (this.ledgerControl.value.length > 1) {
        this.apiService.getSundryLedgerList(this.ledgerControl.value, "false")
          .subscribe((result: any) => {
            this.ledgerObj = result;
            this.filteredOptions = this.ledgerControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this.filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.ledgerObj = [];
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }
    }
  }


  onFocus(e) {
    this.l_id = this.form.value.ledger_id;

    if (!this.l_id) {
      this.closing_balance = null;
    }
    this.apiService.getSundryLedgerList(undefined, 'false')
      .subscribe((result: any) => {
        this.ledgerObj = result;
        this.filteredOptions = this.ledgerControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filter(value))
          );
      }, (result: any) => {
      });
  }
  form: FormGroup = new FormGroup({
    transaction_id: new FormControl(0),
    contract_id: new FormControl(null),
    trans_no: new FormControl("Auto Generated"),
    trans_date: new FormControl(new Date(), Validators.required),
    ledger_id: new FormControl(null, Validators.required),
    dispatch_through: new FormControl(null),
    transport_mode: new FormControl(null),
    container_no: new FormControl(null),
    gr_no: new FormControl(null),
    barcode: new FormControl(null),
    sale_type: new FormControl(null),
    table_id: new FormControl(null),
    gstin: new FormControl(null),
    customer_name: new FormControl(null),
    billing_address1: new FormControl(null),
    billing_address2: new FormControl(null),
    billing_street: new FormControl(null),
    billing_city: new FormControl(null),
    billing_state: new FormControl(null),
    billing_country: new FormControl(null),
    billing_pin: new FormControl(null),
    invoice_description: new FormControl(null),
    shipping_address1: new FormControl(null),
    shipping_address2: new FormControl(null),
    shipping_street: new FormControl(null),
    shipping_city: new FormControl(null),
    shipping_state: new FormControl(null),
    shipping_country: new FormControl(null),
    shipping_pin: new FormControl(null),
    challan_no: new FormControl(null),
    due_date: new FormControl(null),
    credit_days: new FormControl(null),
    place_of_supply: new FormControl(null),
    po_no: new FormControl(null),
    po_date: new FormControl(null),
    challan_date: new FormControl(null),
    lc_no: new FormControl(null),
    total_amount: new FormControl(0, Validators.required),
    total: new FormControl(0),
    total_tax: new FormControl(0),
    discount_amt: new FormControl(0),
    roundoff_amt: new FormControl(0),
    other1_label: new FormControl(null),
    other2_label: new FormControl(null),
    other1_amt: new FormControl(0),
    other2_amt: new FormControl(0),
    net_amount: new FormControl(0, Validators.required),
    display_trans_no: new FormControl(null),
    cash: new FormControl(0),
    card: new FormControl(0),
    upi: new FormControl(0),
    card_ref_no: new FormControl(null),
    advance: new FormControl(0),
    balance: new FormControl(0),
    contact_no: new FormControl(null),
    payment_days: new FormControl(null),
    bill_no: new FormControl(null),
    field1: new FormControl(null),
    field2: new FormControl(null),
    field3: new FormControl(null),
    field4: new FormControl(null),
    field5: new FormControl(null),
    taxslab: new FormControl(0),
    dis_per: new FormControl(0),
    taxable_amt: new FormControl(0),
    sgst_per: new FormControl(0),
    sgst_amt: new FormControl(0),
    cgst_per: new FormControl(0),
    cgst_amt: new FormControl(0),
    igst_per: new FormControl(0),
    igst_amt: new FormControl(0),
    gross_amount: new FormControl(0),
    non_receivable: new FormControl(0),
  });
  //Initialise ledger
  initialiseLedger() {
    this.ledgerControl.setValue(null);
    this.ledgerObj = [];
    this.filteredOptions = this.ledgerControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
  }
  // Initialise or Reset Form Group
  initializeFormGroup() {
    this.form.setValue({
      contract_id: null,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      customer_name: null,
      sale_type: null,
      barcode: null,
      dispatch_through: null,
      transport_mode: null,
      container_no: null,
      gr_no: null,
      table_id: null,
      gstin: null,
      billing_address1: null,
      billing_address2: null,
      billing_street: null,
      billing_city: null,
      billing_state: null,
      billing_country: null,
      billing_pin: null,
      invoice_description: null,
      shipping_address1: null,
      shipping_address2: null,
      shipping_street: null,
      shipping_city: null,
      shipping_state: null,
      shipping_country: null,
      shipping_pin: null,
      challan_no: null,
      due_date: null,
      credit_days: null,
      po_no: null,
      po_date: null,
      challan_date: null,
      place_of_supply: null,
      lc_no: null,
      total_amount: 0,
      discount_amt: 0,
      roundoff_amt: 0,
      other1_label: null,
      other2_label: null,
      other1_amt: 0,
      other2_amt: 0,
      net_amount: 0,
      total: 0,
      total_tax: 0,
      display_trans_no: null,
      cash: 0,
      card: 0,
      upi: 0,
      card_ref_no: null,
      advance: 0,
      balance: 0,
      contact_no: null,
      payment_days: null,
      bill_no: null,
      field1: null,
      field2: null,
      field3: null,
      field4: null,
      field5: null,
      dis_per: 0,
      taxable_amt: 0,
      sgst_per: 0,
      sgst_amt: 0,
      cgst_per: 0,
      cgst_amt: 0,
      igst_per: 0,
      igst_amt: 0,
      gross_amount: 0,
      taxslab: 0,
      non_receivable: null,

    });
    if (this.isCash) {
      this.form.get('sale_type').setValue('cash');
    } else {
      this.form.get('sale_type').setValue('credit');
    }
    this.items = [];
    if (this.isTransactionGSTslab) {
      this.findAccSetting();
      this.calculateAmtWithTransTax()
    } else {
      this.calculateAmt();
    }
    window.scroll(0, 0);
    this.initialiseLedger();
  }

  //On Ledger Selection event
  ledgerSelection(event: MatAutocompleteSelectedEvent) {
    this.ledger_id = event.option.value ? event.option.value.ledger_id : undefined;
    this.ledger_state = event.option.value ? event.option.value.address_state : undefined;
    this.customer_item_rate_applicable = event.option.value ? event.option.value.customer_item_rate_applicable : undefined;
    this.use_qty_from_sale_history = event.option.value ? event.option.value.customer_item_rate_applicable : undefined;
    this.form.get('ledger_id').setValue(event.option.value.ledger_id);
    this.form.get('billing_address1').setValue(event.option.value.address_address1);
    this.form.get('billing_address2').setValue(event.option.value.address_address2);
    this.form.get('billing_street').setValue(event.option.value.address_street);
    this.form.get('billing_city').setValue(event.option.value.address_city);
    this.form.get('billing_state').setValue(event.option.value.address_state);
    this.form.get('billing_country').setValue(event.option.value.address_country);
    this.form.get('billing_pin').setValue(event.option.value.address_pin);
    this.form.get('gstin').setValue(event.option.value.gstin);
    this.form.get('contact_no').setValue(event.option.value.contact_no);
    var indexofState = this.stateName.findIndex(i => i.state_gst_id === event.option.value.address_state);
    this.sbilling_state.setValue(this.stateName[indexofState]);
    this.form.get('customer_name').setValue(null);
    this.getCustomerRate();
    this.apiService.getLedgerClosingBalance(this.ledger_id).subscribe(
      (result: any) => {
        if (result.success) {
          this.closing_balance = result.message.closing_balance + ' ' + result.message.cbdrcr;

        }
        else {
          this.notifier.notify('error', result.message);
        }
      },
      (err: any) => {

      }
    )
  }
  onDiscountPer() {
    var discount = 0;
    discount = this.form.value.total_amount * (this.form.value.dis_per / 100);
    discount = Math.round(discount * 100) / 100;
    this.form.get('discount_amt').setValue(discount);
    this.calculateAmtWithTransTax();
  }
  onDiscountAmt() {
    this.form.get('dis_per').setValue(0);
    this.calculateAmtWithTransTax();
  }
  onTaxChange(e) {
    this.calculateAmtWithTransTax()
  }
  calculateAmtWithTransTax() {
    console.log("calculateAmtWithTransTax---", this.isTransactionGSTslab)
    if (this.isTransactionGSTslab === false) {
      this.amount = 0;

      var taxable_amt = 0,
        tax_amt = 0,
        sgst_per = 0,
        sgst_amt = 0,
        cgst_per = 0,
        cgst_amt = 0,
        igst_per = 0,
        igst_amt = 0,
        vat_per = 0,
        vat_amt = 0;
      this.sign = null;
      this.totalAmt = 0;
      this.totalDis = 0;
      this.amount = 0;
      for (var i = 0; i < this.items.length; i++) {
        this.amount += this.items[i].amount;
        if (this.items[i].tax_mode === "GST") {
          sgst_amt +=
            (this.items[i].sgst_amt || 0) *
            (this.items[i].qty ? this.items[i].qty : 1);
          cgst_amt +=
            (this.items[i].cgst_amt || 0) *
            (this.items[i].qty ? this.items[i].qty : 1);
          igst_amt +=
            (this.items[i].igst_amt || 0) *
            (this.items[i].qty ? this.items[i].qty : 1);
        } else {
          vat_amt +=
            (this.items[i].vat_amt || 0) *
            (this.items[i].qty ? this.items[i].qty : 1);
        }
      }
      taxable_amt = this.amount - this.form.value.discount_amt;

      this.totalTax = sgst_amt + cgst_amt + igst_amt + vat_amt;

      this.totalAmt =
        taxable_amt +
        this.totalTax +
        this.form.value.other1_amt +
        this.form.value.other2_amt;
      // console.log("this.totalAmt---", this.totalAmt)

      this.totalAmt = Math.round(this.totalAmt * 100) / 100;
      console.log("totalAmt---", this.totalAmt)
      this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      if (this.roundOffAmt < 0.5) {
        this.totalNetAmt = this.totalAmt - this.roundOffAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.roundOffAmt = -this.roundOffAmt;
        this.sign = "fa-minus";
      } else {
        this.roundOffAmt = 1 - this.roundOffAmt;
        this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
        this.totalNetAmt = this.totalAmt + this.roundOffAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.sign = "fa-plus";
      }

      this.form.get("total_amount").setValue(this.amount);
      this.form.get("net_amount").setValue(this.totalNetAmt);
      this.form.get("roundoff_amt").setValue(this.roundOffAmt);
      this.form.get("gross_amount").setValue(this.totalAmt);
      this.form.get("total_tax").setValue(this.totalTax);
      this.form.get("sgst_per").setValue(sgst_per);
      this.form.get("sgst_amt").setValue(sgst_amt);
      this.form.get("cgst_per").setValue(cgst_per);
      this.form.get("cgst_amt").setValue(cgst_amt);
      this.form.get("igst_per").setValue(igst_per);
      this.form.get("igst_amt").setValue(igst_amt);
      this.form.get("vat_per").setValue(vat_per);
      this.form.get("vat_amt").setValue(vat_amt);
      this.form.get("taxable_amt").setValue(taxable_amt);

      console.log("this.form11---", this.form.value)
    } else {
      this.amount = 0;
      var tax_slab = this.form.value.taxslab;

      var taxable_amt = 0,
        tax_amt = 0,
        sgst_per = 0,
        sgst_amt = 0,
        cgst_per = 0,
        cgst_amt = 0,
        igst_per = 0,
        igst_amt = 0;
      this.sign = null;
      this.totalAmt = 0;
      this.totalDis = 0;
      this.amount = 0;
      let vatAmount = 0;
      let vatTaxAmount = 0;
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].tax_mode === "GST") {
          this.amount = this.amount + this.items[i].amount;
        } else {
          this.items[i].vat_per = this.items[i].taxslab;
          this.items[i].vat_amt =
            this.items[i].amount * (this.items[i].vat_per / 100) || 0;
          this.items[i].vat_amt =
            Math.round(this.items[i].vat_amt * 100) / 100;
          this.items[i].total_tax = this.items[i].vat_amt || 0;

          this.items[i].total =
            this.items[i].amount + this.items[i].total_tax;
          vatTaxAmount += this.items[i].total_tax;
          vatAmount += this.items[i].amount;
        }
      }
      taxable_amt = this.amount - this.form.value.discount_amt;
      sgst_per = tax_slab / 2;
      sgst_amt = taxable_amt * (sgst_per / 100);
      sgst_amt = Math.round(sgst_amt * 100) / 100;
      cgst_per = tax_slab / 2;
      cgst_amt = taxable_amt * (cgst_per / 100);
      cgst_amt = Math.round(cgst_amt * 100) / 100;

      tax_amt = sgst_amt + cgst_amt;
      this.totalTax = sgst_amt + cgst_amt + igst_amt + vatTaxAmount;

      this.totalAmt =
        taxable_amt +
        vatAmount +
        tax_amt +
        vatTaxAmount +
        this.form.value.other1_amt +
        this.form.value.other2_amt;
      this.totalAmt = Math.round(this.totalAmt * 100) / 100;
      this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      if (this.roundOffAmt < 0.5) {
        this.totalNetAmt = this.totalAmt - this.roundOffAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.roundOffAmt = -this.roundOffAmt;
        this.sign = "fa-minus";
      } else {
        this.roundOffAmt = 1 - this.roundOffAmt;
        this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
        this.totalNetAmt = this.totalAmt + this.roundOffAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.sign = "fa-plus";
      }

      this.form.get("total_amount").setValue(this.amount);
      this.form.get("net_amount").setValue(this.totalNetAmt);
      this.form.get("roundoff_amt").setValue(this.roundOffAmt);
      this.form.get("gross_amount").setValue(this.totalAmt);
      this.form.get("total_tax").setValue(this.totalTax);
      this.form.get("sgst_per").setValue(sgst_per);
      this.form.get("sgst_amt").setValue(sgst_amt);
      this.form.get("cgst_per").setValue(cgst_per);
      this.form.get("cgst_amt").setValue(cgst_amt);
      this.form.get("igst_per").setValue(igst_per);
      this.form.get("igst_amt").setValue(igst_amt);
      this.form.get("taxable_amt").setValue(taxable_amt);
    }
  }
  //To Open Add Item Dialog
  addItemClick() {
    if (this.isTransactionGSTslab) {
      var itemData = { type: 'Out', v_type_id: 5 }

      let itemref = this.dialog.open(PurchaseOrderItemsComponent, {
        width: '600px',
        data: itemData,
        autoFocus: false
      });
      itemref.componentInstance.onItemAdd.subscribe((result) => {
        if (result) {
          this.items.push(result);
          this.calculateAmtWithTransTax();
        }
      });
    } else {
      if (this.isPOS) {
        this.getPOSleserId();
        this.ledger_id = this.form.value.ledger_id;
      }
      this.dataObj.v_type_id = 5;
      this.dataObj.trans_type = "Out";
      this.dataObj.ledger_id = this.form.value.ledger_id;
      this.dataObj.item = null;
      this.dataObj.address_state = this.form.value.billing_state;
      this.dataObj.isCredit = this.isCredit;
      this.dataObj.customer_item_rate_applicable = this.customer_item_rate_applicable;
      this.dataObj.use_qty_from_sale_history = this.use_qty_from_sale_history;
      let itemref = this.dialog.open(TransactionItemsComponent, {
        width: '800px',
        data: this.dataObj,
        autoFocus: false
      });
      itemref.componentInstance.onItemAdd.subscribe((result) => {
        if (result) {
          var pos = this.items.map(function (e) { return e.item_id; }).indexOf(result.item_id);
          if (pos == -1) {
            this.items.push(result);
          } else {
            this.items[pos].qty = parseInt(this.items[pos].qty) + parseInt(result.qty);
            this.calculatePOS(pos);
          }
          this.calculateAmt();
        }
      });
    }
  }

  // To delete Item from Item Details
  onDeleteItem(i) {
    this.items.splice(i, 1);
    if (this.isTransactionGSTslab) {
      this.calculateAmtWithTransTax();
    } else {
      this.calculateAmt()
    }
  }

  //Open edit-Item Dialog to edit item data
  editItem(i) {
    if (this.isTransactionGSTslab) {
      var itemData = { type: 'Out', v_type_id: 5, value: this.items[i] }
      this.dialog.open(PurchaseOrderItemsComponent, {
        width: '600px',
        data: itemData,
        autoFocus: false
      })
        .afterClosed().subscribe(result => {
          if (result) {
            this.items.splice(i, 1);
            this.items.push(result);
            this.calculateAmtWithTransTax();
          }
        });
    } else {
      this.dataObj.v_type_id = 5;
      this.dataObj.trans_type = "Out";
      this.dataObj.item = this.items[i];
      this.dataObj.address_state = this.form.value.billing_state;
      this.dataObj.isCredit = this.isCredit;
      this.dataObj.ledger_id = this.form.value.ledger_id;
      this.dataObj.customer_item_rate_applicable = this.customer_item_rate_applicable;
      this.dataObj.use_qty_from_sale_history = this.use_qty_from_sale_history;
      this.dialog.open(TransactionItemsComponent, {
        width: '800px',
        data: this.dataObj,
        autoFocus: false
      })
        .afterClosed().subscribe(result => {
          if (result) {
            this.items.splice(i, 1);
            this.items.push(result);
            this.calculateAmt();
          }
        });
    }
  }

  // Function to calculate all amount related values
  calculateAmt() {
    this.sign = null;
    this.totalAmt = 0;
    this.totalDis = 0;
    this.totalTax = 0;
    this.amount = 0;
    for (var i = 0; i < this.items.length; i++) {
      this.amount = this.amount + this.items[i].amount;
      this.totalDis = this.totalDis + this.items[i].dis_amt;
      this.totalDis = Math.round(this.totalDis * 100) / 100;
    }
    if (this.company.is_composite) {
      this.tax = [];
    } else {
      this.tax = this.taxService.calculateTax(this.items);
    }
    console.log("this.tax---", this.tax)

    for (var i = 0; i < (this.tax.length - 1); i++) {
      console.log("this.tax[i]---", this.tax[i])
      this.totalTax = this.totalTax + this.tax[i].sgst_amt + this.tax[i].cgst_amt + this.tax[i].igst_amt + this.tax[i].vat_amt;
      this.totalTax = Math.round(this.totalTax * 100) / 100;
    }
    this.totalAmt = (this.amount - this.totalDis) + this.totalTax + this.form.value.other1_amt + this.form.value.other2_amt;
    this.totalAmt = Math.round(this.totalAmt * 100) / 100;
    this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
    this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
    if (this.roundOffAmt < .5) {
      this.totalNetAmt = this.totalAmt - this.roundOffAmt;
      this.totalNetAmt = Math.round(this.totalNetAmt);
      this.roundOffAmt = -this.roundOffAmt;
      this.sign = "fa-minus";
    } else {
      this.roundOffAmt = 1 - this.roundOffAmt;
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      this.totalNetAmt = this.totalAmt + this.roundOffAmt;
      this.totalNetAmt = Math.round(this.totalNetAmt);
      this.sign = "fa-plus";
    }
    this.form.get('total_amount').setValue(this.amount);
    this.form.get('discount_amt').setValue(this.totalDis);
    this.form.get('net_amount').setValue(this.totalNetAmt);
    this.form.get('roundoff_amt').setValue(this.roundOffAmt);
    this.form.get('total').setValue(this.totalAmt);
    this.form.get('total_tax').setValue(this.totalTax);
  }
  //on other price input
  onOtherPrice() {
    if (!this.isTransactionGSTslab) {
      this.calculateAmt();
    } else {
      this.calculateAmtWithTransTax()
    }
  }
  onSaleTypeSelection(e) {
    this.form.get('sale_type').setValue(e.value);
    this.getPOSleserId();
  }
  getPOSleserId() {
    // console.log(this.mapArr)
    var indexofPOSCash = this.mapArr.findIndex(i => i.key === "POS_Cash_Ledger");

    if (this.form.value.sale_type == 'cash') {
      this.isCredit = false;
      this.isCash = true;
      this.form.get('ledger_id').setValue(this.mapArr[indexofPOSCash].ledger_id);
    }
    if (this.form.value.sale_type == 'credit') {
      this.isCredit = true;
      this.isCash = false;

      // this.form.get('ledger_id').setValue(this.mapArr[indexofPOSCredit].ledger_id);
    }
  }

  /* PREPARE COUSTOM ITEM OPTIONS */
  prepareCustomItemOptions(items) {
    console.log("prepareCustomItemOptions--", items)
    for (let item of items) {
      let selectedItemOptions = [];
      if (this.options) {
        item.options.forEach(item => {
          if (item.checked) {
            let obj: any = { id: item.id, default_value: item.default_value, amount: item.amount, notes: item.notes };
            if (item.childOptions && item.childOptions.length > 0) {
              let childObj = item.childOptions.filter(item => item.checked);
              obj.childOptions = childObj.map(item => ({ id: item.id, default_value: item.default_value, amount: item.amount, notes: item.notes }));
            } else {
              obj.childOptions = []
            }
            selectedItemOptions.push(obj);
          }
        });
      }
      item.options = selectedItemOptions;
    }
    return items;
  }
  onSubmit() {
    if (this.showValidationInput) {
      if (this.validationForm.valid) {
        this.validationForm.reset();
        this.otpVerified = false;
        this.otpInvalid = false;
        this.formSubmitted = true;
        this.showValidationInput = false;

        this.saveInvoice();
      } else {
        console.log("Validation code is not valid");
      }
    } else {
      this.saveInvoice();
    }
  }
  //on Form Submit
  private saveInvoice() {
    if (this.isPOS) {
      this.getPOSleserId();
    }
    this.transaction_details.non_receivable = (this.form.value.non_receivable);
    // console.log("PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP", this.transaction_details.non_receivable);

    if (this.items) {
      for (let item of this.items) {

        if (this.isCredit && this.customerRates) {


          var indexof_s_rate = this.customerRates.findIndex(i => i.item_id == item.item_id);

          if (indexof_s_rate >= 0) {
            item.cust_rate_id = this.customerRates[indexof_s_rate].cus_item_rates;
          }
          else {
            item.cust_rate_id = null;
          }
        }
      }
    }

    if (this.form.valid) {
      this.transDataObj = this.form.value;
      this.transDataObj.items_details = this.prepareCustomItemOptions(this.items);
      this.transDataObj.sgst = this.sgst;
      this.transDataObj.cgst = this.cgst;
      this.transDataObj.igst = this.igst;
      this.transDataObj.non_receivable = this.transaction_details.non_receivable
      this.transDataObj.customer_item_rate_applicable = this.customer_item_rate_applicable;
      this.transDataObj.use_qty_from_sale_history = this.use_qty_from_sale_history;
      // console.log("PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP",this.transDataObj.items_details);

      this.apiService.editInvoice(this.transDataObj.transaction_id, this.transDataObj)
        .subscribe((result: any) => {
          // console.log(result);
          if (result.success) {
            this.downloadClick();
            this.notifier.notify('success', 'Invoice Updated successfully');
          } else {
            this.notifier.notify('error', result.message);
          }
          this.dialogRef.close('Edited');
        }, (result: any) => {
          this.initializeFormGroup();
          this.notifier.notify('error', 'unable to add invoice');
        });

    } else {
      window.scroll(0, 0);
    }
  }
  //to Reset Form
  onClear() {
    this.closing_balance = null;
    this.initializeFormGroup();
  }
  downloadClick() {
    console.log("transaction_details--", this.transaction_details)
    this.transDataObj = this.form.value;
    this.transDataObj.email = this.transaction_details.email ? this.transaction_details.email : null;

    this.transDataObj.items_details = this.items;
    this.transDataObj.sgst = this.sgst;
    this.transDataObj.cgst = this.cgst;
    this.transDataObj.igst = this.igst;
    this.transDataObj.trans_type = "Invoice";
    this.apiService.downloadPdf(this.transDataObj)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'invoice' + this.transDataObj.trans_no + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
  cancelInvoice() {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Cancel the Invoice Voucher Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteInvoice(this.data.transaction_id)
            .subscribe((result: any) => {
              this.dialogRef.close('Edited');
              this.notifier.notify('success', 'Invoice Canceled successfully');
            }, (result: any) => {
              this.notifier.notify('error', 'unable to cancel invoice');
            });
        }
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  onCreditDays() {
    var dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + this.form.value.credit_days);
    this.form.get('due_date').setValue(dueDate);
  }
  onBarcodeEnter() {
    this.apiService.getItemByBarcode(this.form.value.barcode)
      .subscribe((result: any) => {
        if (result.length) {
          var pos = this.items.map(function (e) { return e.barcode; }).indexOf(this.form.value.barcode);
          result[0].dis_amt = 0;
          if (pos == -1) {
            result[0].qty = 1;
            this.items.push(result[0]);
            var itm_index = this.items.map(function (e) { return e.barcode; }).indexOf(this.form.value.barcode);
            this.calculatePOS(itm_index);
          } else {
            this.items[pos].qty = this.items[pos].qty + 1;
            this.calculatePOS(pos);
          }
          this.calculateAmt();
        } else {
          this.notifier.notify('error', 'incorrect barcode');
        }
        this.form.get('barcode').setValue(null);
      }, (result: any) => {
      });
  }
  calculatePOS(pos) {
    this.items[pos].amount = this.items[pos].qty * (this.items[pos].s_rate + (this.items[pos].optionAmount && this.items[pos].optionAmount > 0 ? this.items[pos].optionAmount : 0));
    if (this.items[pos].dis_per) {
      this.items[pos].dis_amt = this.items[pos].amount * (this.items[pos].dis_per / 100);
      this.items[pos].dis_amt = Math.round(this.items[pos].dis_amt * 100) / 100;
    }
    this.items[pos].taxable_amt = this.items[pos].amount - this.items[pos].dis_amt;
    if (this.company.is_composite) {
      this.items[pos].igst_per = 0;
      this.items[pos].igst_amt = 0;
      this.items[pos].sgst_per = 0;
      this.items[pos].sgst_amt = 0;
      this.items[pos].cgst_per = 0;
      this.items[pos].cgst_amt = 0;
    } else {
      if (this.items[pos].tax_mode === "GST") {
        if (this.form.value.billing_state == this.company.address_state) {
          this.items[pos].sgst_per = this.items[pos].taxslab / 2;
          this.items[pos].sgst_amt = this.items[pos].taxable_amt * (this.items[pos].sgst_per / 100);
          this.items[pos].sgst_amt = Math.round(this.items[pos].sgst_amt * 100) / 100;
          this.items[pos].cgst_per = this.items[pos].taxslab / 2;
          this.items[pos].cgst_amt = this.items[pos].taxable_amt * (this.items[pos].cgst_per / 100);
          this.items[pos].cgst_amt = Math.round(this.items[pos].cgst_amt * 100) / 100;
          this.items[pos].igst_per = 0;
          this.items[pos].igst_amt = 0;
        } else {
          this.items[pos].sgst_per = 0;
          this.items[pos].sgst_amt = 0;
          this.items[pos].cgst_per = 0;
          this.items[pos].cgst_amt = 0;
          this.items[pos].igst_per = this.items[pos].taxslab;
          this.items[pos].igst_amt = this.items[pos].taxable_amt * (this.items[pos].igst_per / 100);
          this.items[pos].igst_amt = Math.round(this.items[pos].igst_amt * 100) / 100;
        }
      } else {
        this.items[pos].sgst_per = 0;
        this.items[pos].sgst_amt = 0;
        this.items[pos].cgst_per = 0;
        this.items[pos].cgst_amt = 0;
        this.items[pos].igst_per = 0;
        this.items[pos].igst_amt = 0;
        this.items[pos].vat_per = this.items[pos].taxslab;
        this.items[pos].vat_amt = this.items[pos].taxable_amt * (this.items[pos].vat_per / 100);
        this.items[pos].vat_amt = Math.round(this.items[pos].vat_amt * 100) / 100;
        console.log("taxesssss--- ", this.items[pos].vat_per, this.items[pos].vat_amt, this.items[pos].taxable_amt)
        // this.items[pos].total_tax = this.items[pos].vat_amt;

      }
    }
    this.items[pos].total_tax = this.items[pos].sgst_amt + this.items[pos].cgst_amt + this.items[pos].igst_amt + this.items[pos].vat_amt;
    this.items[pos].total = this.items[pos].taxable_amt + this.items[pos].total_tax;
  }

  onAddQuantity(i) {
    this.items[i].qty = parseInt(this.items[i].qty) + 1;
    this.calculatePOS(i)
    if (this.isTransactionGSTslab) {
      this.calculateAmtWithTransTax()
    } else {
      this.calculateAmt();
    }
  }
  onRemoveQuantity(i) {
    this.items[i].qty = parseInt(this.items[i].qty) - 1;
    this.calculatePOS(i);
    if (this.isTransactionGSTslab) {
      this.calculateAmtWithTransTax()
    } else {
      this.calculateAmt();
    }
  }

  onCashOrCard() {
    var total_advance = this.form.value.cash + this.form.value.card + this.form.value.upi;
    var balance_amt = this.form.value.net_amount - total_advance;
    this.form.get('advance').setValue(total_advance);
    this.form.get('balance').setValue(balance_amt);
  }
  onAddCustomerClick() {
    this.dialog.open(AddEditCustomersMasterComponent, {
      width: '800px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var ledgerData = result.data;
          this.ledger_id = ledgerData.ledger_id;
          this.ledgerControl.setValue(ledgerData);
          this.form.get('ledger_id').setValue(ledgerData.ledger_id);
          this.form.get('billing_address1').setValue(ledgerData.address_address1);
          this.form.get('billing_address2').setValue(ledgerData.address_address2);
          this.form.get('billing_street').setValue(ledgerData.address_street);
          this.form.get('billing_city').setValue(ledgerData.address_city);
          this.form.get('billing_state').setValue(ledgerData.address_state);
          this.form.get('billing_country').setValue(ledgerData.address_country);
          this.form.get('billing_pin').setValue(ledgerData.address_pin);
          this.apiService.getLedgerClosingBalance(this.ledger_id).subscribe(
            (result: any) => {
              if (result.success) {
                this.closing_balance = result.message.closing_balance + ' ' + result.message.cbdrcr;

              }
              else {
                this.notifier.notify('error', result.message);
              }
            },
            (err: any) => {

            }
          )
        } else {
          this.ledgerControl.setValue(this.ledgerObj);
        }
      });
  }
  onSOItemsView() {
    var dataObj = {
      type: "edit-so-invoice",
      invoice_id: this.data.transaction_id
    }
    this.dialog.open(TransactionListComponent, {
      width: '600px',
      data: dataObj,
      autoFocus: false
    });
  }
  onDOItemsView() {
    var dataObj = {
      type: "edit-do-invoice",
      invoice_id: this.data.transaction_id
    }
    this.dialog.open(TransactionListComponent, {
      width: '600px',
      data: dataObj,
      autoFocus: false
    });
  }
  //Invoice Email Send
  emailSendInvoice() {
    this.ledgerObjForEmail = this.ledgerObj;
    this.ledgerObjForEmail.display_trans_no = this.form.value.display_trans_no;
    this.ledgerObjForEmail.transaction_id = this.form.value.transaction_id;
    this.ledgerObjForEmail.trans_type = "Invoice";

    this.dialog.open(SendMailComponent, {
      width: '650px',
      data: this.ledgerObjForEmail
    })
      .afterClosed().subscribe(result => {
        if (result == 'Edited') {
          console.log("ok");
        }
      });

  }

  getCustomerRate() {


    if (this.transaction_details.sale_type == 'credit') {
      this.isCredit = true;
    }

    if (this.isCredit) {
      if (this.ledger_id) {
        this.data.ledger_id = this.ledger_id;
      }
      else {
        this.apiService.getCustomerItemRate(this.data.ledger_id)
          .subscribe((result: any) => {
            // console.log(result)
            if (result.success != false) {

              this.customerRates = result;

            }
          },
            (result: any) => {
            });
      }
    }

  }

}
