<div class="dialog-title" mat-dialog-title>
  <div class="row">
    <div class="col-md-6 col-9">
      <span class="color-h3">Edit Expense</span>
    </div>
    <div class="col-md-6 col-3 right">
      <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
</div>
<mat-dialog-content class="pt-2 pb-2">
  <form class="payment-form" #f="ngForm" name="form">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="contract-full-width">
          <input matInput placeholder="Voucher No.: " [(ngModel)]="model.trans_no" name="trans_no" readonly>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="contract-full-width">
          <input matInput [matDatepicker]="date" placeholder="Date:" [(ngModel)]="model.trans_date" name="trans_date"
            required>
          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="contract-full-width">
          <mat-select placeholder="Dr Ledger:" [(ngModel)]="model.ledger_id" name="ledger_id" required (selectionChange)="onChange1()">
            <mat-option *ngFor="let ledger of ledgerObj" [value]="ledger.ledger_id">
              {{ledger.ledger_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="closing_balance1" class="right small p-0 m-0"
          style="margin-top:-10px!important;font-family: sans-serif;color:red">
          {{closing_balance1}}
        </div>
      </div>

      <div class="col-md-6">
        <mat-form-field class="contract-full-width">
          <mat-select placeholder="Cr Ledger:" [(ngModel)]="model.ledger_id2" name="ledger_id2" required (selectionChange)="onChange2()">
            <mat-option *ngFor="let ledger2 of ledgerObj2" [value]="ledger2.ledger_id">
              {{ledger2.ledger_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="closing_balance2" class="right small p-0 m-0"
          style="margin-top:-10px!important;font-family: sans-serif;color:red">
          {{closing_balance2}}
        </div>
      </div>

      <div class="col-md-12">
        <b>
          <p>Payment Mode </p>
        </b>
        <mat-radio-group [(ngModel)]="model.payment_mode" name="payment_mode">
          <mat-radio-button value="Cash" (click)="disableInp()">Cash</mat-radio-button>
          <mat-radio-button class="ml-2" value="Bank" (click)="enableInp()">Bank</mat-radio-button>
          <mat-radio-button class="ml-2" value="UPI" (click)="enableInp()">UPI</mat-radio-button>
        </mat-radio-group>
      </div>


      <div class="col-md-6">
        <mat-form-field class="contract-full-width">
          <input matInput placeholder="Amount" type="number" [(ngModel)]="model.total_amount" name="total_amount"
            required>
          <span matPrefix><i class="fa fa-{{currency}}"></i> &nbsp; </span>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="contract-full-width">
          <input matInput placeholder="Bill no. : " name="bill_no" [(ngModel)]="model.bill_no">
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <fieldset class="border p-2 ml-3 mr-3 form-fieldset">
          <legend class="w-auto">Bank Details:</legend>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Cheque/DD no. :" [disabled]="disableInput" name="pay_ref_no"
                  [(ngModel)]="model.pay_ref_no">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input matInput [matDatepicker]="cheque_date" [disabled]="disableInput" placeholder="Cheque/DD date:"
                  name="pay_ref_date" [(ngModel)]="model.pay_ref_date">
                <mat-datepicker-toggle matSuffix [for]="cheque_date"></mat-datepicker-toggle>
                <mat-datepicker #cheque_date></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Bank name:" [disabled]="disableInput" name="bank" [(ngModel)]="model.bank">
              </mat-form-field>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-12">
        <mat-form-field class="contract-full-width">
          <textarea matInput placeholder="Notes:" name="notes" [(ngModel)]="model.notes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- <button mat-raised-button class="ml-2 form-margin" color="warn" (click)="cancelInvoice()">Cancel Voucher</button>
      <button mat-raised-button class="ml-2" color="primary" type="submit" (click)="downloadClick()">Print</button>
      <span class="fill-remaining-space"></span>
      <button type="button" class="mat-raised-button" (click)="closeDialog()">Close</button>
      <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Update</button> -->

  <div class="col-md-4">
    <div class="row">
      <div class="col-12 col-md-5 col-xs-12 col-sm-12">
        <button mat-raised-button class="mfullwidth1 form-margin" color="warn" (click)="cancelInvoice()">Cancel
          Voucher</button>
      </div>
      <div class="col-12 col-md-4 col-xs-12 col-sm-12">
        <button mat-raised-button class="mfullwidth1" color="primary" type="submit"
          (click)="downloadClick()">Print</button>

      </div>

    </div>
  </div>

  <div class="col-md-3"></div>

  <div class="col-md-5  text-right">
    <button style="margin-left: 8px;" mat-raised-button type="button" class="mfullwidth1"
      (click)="closeDialog()">Close</button>
    <button mat-raised-button color="primary" type="submit" class="mfullwidth1" (click)="onSubmit()">Update</button>
  </div>
</mat-dialog-actions>