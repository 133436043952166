import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-pending-dlvry',
  templateUrl: './pending-dlvry.component.html',
  styleUrls: ['./pending-dlvry.component.css']
})
export class PendingDlvryComponent implements OnInit {

  constructor(private apiService:DataService) { }

  ngOnInit() {
    this.getPendingDOCount();
  }
  PendingdoCount:any;

  getPendingDOCount(){
  this.apiService.getpendingDelivery().subscribe( (result:any)=>{
 
   
 
    this.PendingdoCount = result[0].count;
    
  })
  }

}
