<h2 mat-dialog-title class="color-h3">SMS Settings</h2>
<div id="smsTab">
  <mat-form-field>
    <mat-label>Select Provider</mat-label>
    <mat-select name="smsSetting" (selectionChange)="onOrderTypeChange($event)">
      <mat-option *ngFor="let option of smsSetting" [value]="option.viewValue">
        {{ option.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="table-details" *ngIf="twilio">
  <mat-dialog-content>
    <form name="twilioForm" #twilioForm="ngForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="TWILIO ACCOUNT SID:"
              [(ngModel)]="modelTwilio.twilio_sid"
              name="twilio_sid"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="TWILIO AUTHTOKEN:"
              [(ngModel)]="modelTwilio.twilio_token"
              name="twilio_token"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="TWILIO PHONE NUMBER:"
              [(ngModel)]="modelTwilio.twilio_phone_no"
              name="twilio_phone_no"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <p>
            BALANCE :
            <span
              [ngClass]="[
                twilioBalance > 15 ? 'alert-success' : 'alert-danger'
              ]"
            >
              <i class="fa fa-{{ twilioCurrency }}"></i>

              {{ twilioBalance | number : "1.2-2" }}</span
            >
          </p>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">
      Close
    </button>
    <button
      type="submit"
      class="btn-primary mat-raised-button"
      (click)="onSubmit(twilioForm)"
      [disabled]="twilioForm.invalid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>

<div class="table-details" *ngIf="local">
  <mat-dialog-content>
    <form name="localForm" #localForm="ngForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="API KEY:"
              [(ngModel)]="modelLocal.local_api"
              name="local_api"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="SENDER ID:"
              [(ngModel)]="modelLocal.local_apicontent"
              name="local_apicontent"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="contract-full-width">
            <input
              matInput
              placeholder="BALANCE API:"
              [(ngModel)]="modelLocal.local_balancespi"
              name="local_balancespi"
              required
            />
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <p>BALANCE ALERT COUNT:</p>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">
      Close
    </button>
    <button
      type="submit"
      class="btn-primary mat-raised-button"
      (click)="onSubmit(localForm)"
      [disabled]="localForm.invalid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
