import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AddItemComponent } from '../../items/add-item/add-item.component';
@Component({
  selector: 'app-edit-stock-adjustment',
  templateUrl: './edit-stock-adjustment.component.html',
  styleUrls: ['./edit-stock-adjustment.component.css']
})
export class EditStockAdjustmentComponent implements OnInit {

  constructor(private apiService: DataService,
    private dialogRef: MatDialogRef<EditStockAdjustmentComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  @ViewChild('f') f;
  model: any;
  itemControl = new FormControl();
  options: any;
  filteredOptions: Observable<string[]>;
  itemObj: any[];
  ledgerObj: any = {};
  item_data: any;
  company: any;
  locObj:any;
  mapArr:any;
  isLocation:boolean = false;
  ngOnInit() {
    this.load()
  }
  getItems(item_id) {
    this.apiService.getItem(item_id)
      .subscribe((result: any) => {
        this.item_data = result[0];
        this.itemControl.setValue(this.item_data);
      }, (result: any) => {
      });
  }
  load() {
    this.apiService.getAccountMapList()
    .subscribe((result: any) => {
      this.mapArr = result;
      this.findAccSetting();
    }, (result: any) => {
    });

    this.apiService.getLocationList()
    .subscribe((result1: any) => {
      this.locObj = result1;
    }, (result: any) => {
    });

    this.apiService.getStockAdjustment(this.data.transaction_id)
      .subscribe((result: any) => {
        if (result.qty >= 0) {
          result.type = 'In';
        } else {
          result.qty = -(result.qty);
          result.type = 'Out';
        }
        // console.log(this.form.value);
        this.form.patchValue(result);
        // console.log(this.form.value)
        this.getItems(result.item_id);
      }, (result: any) => {
      });
  }
  findAccSetting() {
    var indexOfLoc = this.mapArr.findIndex(i => i.key === "Enable_Location");
    if (this.mapArr[indexOfLoc].checkbox_value)
      this.isLocation = true;
  }
  _filter(value) {
    const filterValue = value ? value.toLowerCase() : value;
    return this.itemObj.filter(item => item.item_code.toLowerCase().includes(filterValue) || item.item_name.toLowerCase().includes(filterValue));
  }
  displayFn(Item) {
    return Item ? Item.item_name : "";
  }

  form: FormGroup = new FormGroup({
    type: new FormControl(null, Validators.required),
    item_id: new FormControl(null, Validators.required),
    location_id: new FormControl(null),
    description: new FormControl(null),
    qty: new FormControl(null, Validators.required),
    trans_date: new FormControl(new Date(), Validators.required),
    trans_no: new FormControl("Auto Generated", Validators.required),
  });

  initializeFormGroup() {
    this.form.setValue({
      type: null,
      item_id: null,
      location_id: null,
      description: null,
      qty: null,
      trans_date: new Date(),
      trans_no: null,
    });
    this.initialiseItem();
  }
  initialiseItem() {
    this.itemControl.setValue(null);
    this.itemObj = [];
    this.filteredOptions = this.itemControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  onClear() {
    this.initializeFormGroup();
  }

  itemSelection(event: MatAutocompleteSelectedEvent) {
    var item_id = event.option.value ? event.option.value.item_id : undefined;
    this.form.get('item_id').setValue(item_id);
  }
//search Item
  search(event) {
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl.value.length > 1) {
        this.apiService.getFilterItemList(this.itemControl.value)
          .subscribe((result: any) => {
            this.itemObj = result;
            this.filteredOptions = this.itemControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    }
  }
  //on focus on Item search
  onFocus(e) {
    this.apiService.getItemList()
      .subscribe((result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }, (result: any) => {
      });
  }

  onSubmit() {
    // console.log(this.form.value)
    this.apiService.editStockAdjustment(this.data.transaction_id, this.form.value)
      .subscribe((result: any) => {
        if (result.success) {
          this.notifier.notify('success', 'Stock Adjustment updated successfully');
          this.dialogRef.close('Edited');
        } else {
          window.scroll(0, 0);
          this.notifier.notify('error', result.message);
        }
      }, (result: any) => {
        this.initializeFormGroup();
        this.notifier.notify('error', 'unable to update Stock Adjustment');
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  cancelStockAd() {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Cancel the Stock Adjustment Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteStockAdjustment(this.data.transaction_id)
            .subscribe((result: any) => {
              this.dialogRef.close('Edited');
              this.notifier.notify('success', 'Stock Adjustment Canceled successfully');
            }, (result: any) => {
              this.notifier.notify('error', 'unable to cancel Stock Adjustment');
            });
        }
      });
  }

   //to add Item
   onAddItemClick() {
    this.dialog.open(AddItemComponent, {
      width: '650px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var itemData = result.data;
          this.itemControl.setValue(itemData);
          this.populateItemData(itemData);
        }
      });
  }

  populateItemData(itemDetails) {
    this.itemControl = itemDetails;
    this.form.get('item_id').setValue(itemDetails.item_id);
  }
}
