import { Component, OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { DataService } from "../../data.service";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { NgxSpinnerService } from "ngx-spinner";
import { ExportService } from "src/app/_export-service/daybook-export.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-import-customer",
  templateUrl: "./import-customer.component.html",
  styleUrls: ["./import-customer.component.css"],
})
export class ImportCustomerComponent implements OnInit {
  customerData: any[] = [];
  displayedColumns: string[] = [
    "select",
    "id",
    "name",
    "contact_no",
    "email_id",
    "date_of_birth",
    "date_of_anniversary",
    "gstin",
    "do_not_send_updates",
    "stage",
    "shipping_address1",
    "shipping_address2",
  ];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  file: any;
  uploaded: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ImportCustomerComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    private exportService: ExportService
  ) {}

  ngOnInit() {}

  csvInputChange(evt) {
    var files = evt.target.files; // FileList object
    this.file = files[0];
    var reader = new FileReader();
    reader.readAsText(this.file);
    reader.onload = () => {
      this.extractData(reader.result);
    };
  }

  extractData(data) {
    let csvData = data;
    let allTextLines = csvData.split(/\r\n|\n/);
    let headers = allTextLines[0].split(",");
    let lines = [];

    let obj: any = {};
    for (let i = 0; i < allTextLines.length; i++) {
      let data = allTextLines[i].split(",");
      if (data.length == headers.length) {
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }
        lines.push(tarr);
      }
    }
    for (var m = 1; m < lines.length; m++) {
      obj = {};
      for (var n = 0; n < lines[m].length; n++) {
        obj[lines[0][n]] = lines[m][n];
      }
      this.customerData.push(obj);
    }
    this.dataSource = new MatTableDataSource(this.customerData);
    this.masterToggle();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => {
          this.selection.select(row);
        });
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  onImportDataClick() {
    if (this.selection.selected.length) {
      this.spinner.show();
      this.apiService.importContact(this.selection.selected).subscribe(
        (result: any) => {
          this.spinner.hide();
          if (result.success) {
            this.uploaded = true;
            this.displayedColumns = [
              "select",
              "id",
              "name",
              "contact_no",
              "email_id",
              "date_of_birth",
              "date_of_anniversary",
              "gstin",
              "do_not_send_updates",
              "stage",
              "shipping_address1",
              "shipping_address2",
              "msg",
            ];
            this.dataSource.data = result.customers;
            this.masterToggle();
            this.notifier.notify(
              "success",
              "Selected Customers imported successfully"
            );
            // this.dialogRef.close('Imported');
          } else {
            this.notifier.notify("error", result.message);
          }
        },
        (result: any) => {
          this.spinner.hide();
          this.notifier.notify("error", "Unable to import Customers");
        }
      );
    } else {
      this.notifier.notify("error", "Select customers to import");
    }
  }

  close() {
    // this.dialogRef.close();
    this.dialogRef.close("Imported");
  }

  downloadTemplate() {
    const link = document.createElement("a");
    link.href = "assets/file/customers.csv";
    link.download = "customers.csv";
    link.click();
  }
}
