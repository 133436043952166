import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from "@angular/material";
import { DataService } from "../data.service";
import { NotifierService } from "angular-notifier";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-takeaway-memo",
  templateUrl: "./takeaway-memo.component.html",
  styleUrls: ["./takeaway-memo.component.css"],
})
export class TakeawayMemoComponent implements OnInit {
  form: FormGroup;
  result: any;
  dataSource: MatTableDataSource<any>;
  selectedCustomer: any;
  customerOrders: any[];
  constructor(
    private fb: FormBuilder,
    private apiService: DataService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TakeawayMemoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      contact_no: [""],
      customer_name: [""],
      email: [""],
      gstin: [""],
    });
  }

  displayedColumns = [
    "contact_no",
    "customer_name",
    "gross_amount",
    "gstin",
    "email",
    "actions",
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.load();
  }

  load() {
    this.apiService.getTakeAwayMemo().subscribe(
      (result: any) => {
        console.log("result----", result);

        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // console.log(result);
      },
      (result: any) => {}
    );
  }
  openTakeAwayCustomer(event, row: any) {
    // console.log(row.transaction_id);
    this.apiService.openTakeAwayCustomer(row.contact_no).subscribe(
      (result: any) => {
        this.selectedCustomer = result.customer;
        this.form.patchValue({
          contact_no: this.selectedCustomer.contact_no,
          customer_name: this.selectedCustomer.customer_name,
          email: this.selectedCustomer.email || "",
          gstin: this.selectedCustomer.gstin || "",
        });
        // this.cdr.detectChanges();
        this.closeThis(result);
      },
      (result: any) => {}
    );
  }
  closeThis(result) {
    this.dialogRef.close(result);
  }
  add(status) {
    if (status == true) {
      this.closeThis(status);
    }
  }

  deleteMemo(event, row: any) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: "Are you sure to delete selected Memo.?",
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == true) {
          this.apiService
            .deleteMemos(row.transaction_id)
            .subscribe((result: any) => {
              if (result.success) {
                this.notifier.notify("success", result.message);
                this.load();
              } else {
                this.notifier.notify("error", result.message);
              }
            });
        }
      });
  }
}
