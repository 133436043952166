import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Inject,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatAutocompleteSelectedEvent,
  MatSelect,
} from "@angular/material";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from "../../data.service";
import { NotifierService } from "angular-notifier";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ConfirmDialogComponent } from "src/app/confirm-dialog/confirm-dialog.component";
import { ItemsDialogComponent } from "../items-dialog/items-dialog.component";
import { KotCancelDialogComponent } from "./kot-cancel-dialog/kot-cancel-dialog.component";

@Component({
  selector: "app-rst-kot",
  templateUrl: "./rst-kot.component.html",
  styleUrls: ["./rst-kot.component.css"],
})
export class RstKotComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private dialogRef: MatDialogRef<RstKotComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("datakot---", data);
    this.table_id = data.table_id;
    this.currency = environment.currency;
  }
  @Output() onKotAdd: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("s_rate1") s_rate1: ElementRef;
  @ViewChild("qty1") qty1: ElementRef;
  @ViewChild("sitm") sitm: ElementRef;
  @ViewChild("sitm") itemSelect: MatSelect;
  table_id: any;
  itemsArr: any = [];
  model: any = { trans_date: new Date(), net_amount: 0 };
  itemModel: any = {};
  itemControl = new FormControl();
  options: any;
  filteredOptions: Observable<string[]>;
  itemObj: any[];
  isAutoFocus: boolean = false;
  isQtyFocused: boolean = false;
  currency: any;
  allowChangeRate: boolean;
  isTransactionGSTslab: boolean = false;
  isLocal: boolean = false;
  status: any = "add";
  showDelButton: any = false;
  ngOnInit() {
    this.load();
  }
  load() {
    const isTransaction = localStorage.getItem("isTransactionGSTslab");
    this.isTransactionGSTslab = isTransaction == "false" ? false : true;
    const isLoc = localStorage.getItem("isLocal");
    this.isLocal = isLoc == "false" ? false : true;
    this.allowChangeRate = this.data.allowChangeRate;
    // this.sitm.nativeElement.focus();
    if (this.data.kot_id) {
      this.apiService.getSalesOrder(this.data.kot_id).subscribe(
        (result: any) => {
          this.model = result;
          this.itemsArr = result.items_details;
          console.log("this.itemsArr----", this.itemsArr.length);
          console.log("this.itemsArr----", this.itemsArr);
          this.calculateAmt();
          // const itemsActivearr = this.itemsArr.filter((e) => e.active);
          // this.showDelButton = itemsActivearr.length > 1 ? false : true;
        },
        (error: any) => {}
      );
    } else {
      this.apiService.getItemList().subscribe(
        (result: any) => {
          this.itemObj = result;
          this.sitm.nativeElement.focus();
          this.filteredOptions = this.itemControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filter(value))
          );
        },
        (result: any) => {}
      );
    }
  }
  getActiveItemCount() {
    return this.itemsArr.filter((item) => item.active).length;
  }

  _filter(value) {
    const filterValue = typeof value != "object" ? value.toLowerCase() : value;
    return this.itemObj.filter(
      (item) =>
        item.item_code.toLowerCase().includes(filterValue) ||
        item.item_name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(Item) {
    return Item ? Item.item_name : "";
  }
  search(event) {
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl.value.length > 1) {
        const filter = "restaurant_menu_items";
        this.apiService
          .getCustomFilterItemList(
            this.itemControl.value,
            filter,
            this.table_id
          )
          .subscribe(
            (result: any) => {
              this.itemObj = result;
              this.filteredOptions = this.itemControl.valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
              );
            },
            (result: any) => {}
          );
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      }
    }
  }
  itemSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option.value != 0) {
      var item_id = event.option.value ? event.option.value.item_id : undefined;

      var indexofItem = this.itemObj.findIndex((i) => i.item_id === item_id);
      if (event.option.value.options.length > 0) {
        const data = { item: this.itemObj[indexofItem], status: "add" };

        this.openCustomization(data);
      } else {
        const isCustomised = false;
        this.populateItemData(this.itemObj[indexofItem], isCustomised);
      }
    }
  }
  populateItemData(itemDetails, isCustomised) {
    console.log("------ add items clicked ------- ");
    this.itemModel = itemDetails;
    const qty = itemDetails.qty ? itemDetails.qty : 1;
    this.itemModel.qty = qty;
    const a = this.itemModel.qty * this.itemModel.s_rate;
    const b = this.itemModel.qty * this.optionarray_amount;
    const amount = a + b;
    console.log("itemDetails---", isCustomised, this.itemModel);
    this.itemModel.amount = amount ? amount : itemDetails.s_rate * qty;
    // this.optionarray_amount = this.itemModel.optionAmount;
    // this.options_array = this.itemModel.optionsData;
    // this.checkedVariantAmount = this.itemModel.checkedVariantAmount;
    // this.checkedVariant = this.itemModel.checkedVariant;
    // this.itemModel.amount =
    //   this.itemModel.checkedVariantAmount > 0
    //     ? (this.itemModel.checkedVariantAmount + this.itemModel.optionAmount) *
    //     (this.itemModel.qty > 0 ? this.itemModel.qty : 1)
    //     : this.itemModel.optionAmount > 0
    //       ? this.itemModel.optionAmount + this.itemModel.s_rate * this.itemModel.qty
    //       : this.itemModel.qty > 0
    //         ? this.itemModel.s_rate * this.itemModel.qty
    //         : this.itemModel.s_rate;
    // if (this.isTransactionGSTslab === false) {
    //   // console.log("this.isTransactionGSTslab--", this.isTransactionGSTslab)
    //   if (this.itemModel.tax_mode === "GST") {
    //     if (this.isLocal) {
    //       this.itemModel.sgst_per = this.itemModel.taxslab / 2;
    //       this.itemModel.sgst_amt = this.itemModel.amount * (this.itemModel.sgst_per / 100) || 0;
    //       this.itemModel.sgst_amt = Math.round(this.itemModel.sgst_amt * 100) / 100;
    //       this.itemModel.cgst_per = this.itemModel.taxslab / 2;
    //       this.itemModel.cgst_amt = this.itemModel.amount * (this.itemModel.cgst_per / 100) || 0;
    //       this.itemModel.cgst_amt = Math.round(this.itemModel.cgst_amt * 100) / 100;
    //     } else {
    //       this.itemModel.igst_per = this.itemModel.taxslab;
    //       this.itemModel.igst_amt = this.itemModel.amount * (this.itemModel.igst_per / 100) || 0;
    //       this.itemModel.igst_amt = Math.round(this.itemModel.igst_amt * 100) / 100;
    //     }

    //     this.itemModel.total_tax =
    //       this.itemModel.sgst_amt || 0 + this.itemModel.cgst_amt || 0 + this.itemModel.igst_amt || 0;
    //   } else {
    //     this.itemModel.vat_per = this.itemModel.taxslab;
    //     this.itemModel.vat_amt = this.itemModel.amount * (this.itemModel.vat_per / 100) || 0;
    //     this.itemModel.vat_amt = Math.round(this.itemModel.vat_amt * 100) / 100;
    //     this.itemModel.total_tax = this.itemModel.vat_amt || 0;
    //   }
    //   this.itemModel.total = this.itemModel.amount + this.itemModel.total_tax;
    //   // item.instruction = this.form.value.instruction
    // }

    if (this.data.isAutoFocus) {
      this.qty1.nativeElement.focus();
    }

    if (isCustomised) {
      this.itemModel = itemDetails.item;
      const qty = itemDetails.qty ? itemDetails.qty : 1;
      this.itemModel.qty = qty;
      const a = this.itemModel.qty * this.itemModel.s_rate;
      const b = this.itemModel.qty * this.optionarray_amount;
      const amount = a + b;
      console.log("itemDetails---", isCustomised, this.itemModel);
      this.itemModel.amount = amount ? amount : itemDetails.s_rate * qty;
      this.onAddItem();
    }
  }

  onQtyFocus(e) {
    if (e) {
      this.isQtyFocused = true;
    } else {
      this.isQtyFocused = false;
    }
  }
  calculateItemAmt(event) {
    var qty = this.itemModel.qty;
    var s_rate = this.itemModel.s_rate;
    const a = (qty ? qty : 0) * (s_rate ? s_rate : 0);
    const b = (qty ? qty : 0) * this.optionarray_amount;
    var amount = a + b;
    this.itemModel.amount = amount;

    if (event.key == "Enter" && !this.isQtyFocused) {
      this.onAddItem();
      this.sitm.nativeElement.focus();
    }
    if (event.key == "Enter" && this.isQtyFocused) {
      this.s_rate1.nativeElement.focus();
    }
  }
  onAddItem() {
    console.log("this.itemModel--", this.itemModel);
    if (this.itemModel.qty) {
      this.itemModel.amount =
        this.itemModel.checkedVariantAmount > 0
          ? (this.itemModel.checkedVariantAmount +
              this.itemModel.optionAmount) *
            (this.itemModel.qty > 0 ? this.itemModel.qty : 1)
          : this.itemModel.optionAmount > 0
          ? this.itemModel.optionAmount +
            this.itemModel.s_rate * this.itemModel.qty
          : this.itemModel.qty > 0
          ? this.itemModel.s_rate * this.itemModel.qty
          : this.itemModel.s_rate;
      if (this.isTransactionGSTslab === false) {
        // console.log("this.isTransactionGSTslab--", this.isTransactionGSTslab)
        if (this.itemModel.tax_mode === "GST") {
          if (this.isLocal) {
            this.itemModel.sgst_per = this.itemModel.taxslab / 2;
            this.itemModel.sgst_amt =
              this.itemModel.amount * (this.itemModel.sgst_per / 100) || 0;
            this.itemModel.sgst_amt =
              Math.round(this.itemModel.sgst_amt * 100) / 100;
            this.itemModel.cgst_per = this.itemModel.taxslab / 2;
            this.itemModel.cgst_amt =
              this.itemModel.amount * (this.itemModel.cgst_per / 100) || 0;
            this.itemModel.cgst_amt =
              Math.round(this.itemModel.cgst_amt * 100) / 100;
          } else {
            this.itemModel.igst_per = this.itemModel.taxslab;
            this.itemModel.igst_amt =
              this.itemModel.amount * (this.itemModel.igst_per / 100) || 0;
            this.itemModel.igst_amt =
              Math.round(this.itemModel.igst_amt * 100) / 100;
          }

          this.itemModel.total_tax =
            this.itemModel.sgst_amt ||
            0 + this.itemModel.cgst_amt ||
            0 + this.itemModel.igst_amt ||
            0;
        } else {
          this.itemModel.vat_per = this.itemModel.taxslab;
          this.itemModel.vat_amt =
            this.itemModel.amount * (this.itemModel.vat_per / 100) || 0;
          this.itemModel.vat_amt =
            Math.round(this.itemModel.vat_amt * 100) / 100;
          this.itemModel.total_tax = this.itemModel.vat_amt || 0;
        }
        this.itemModel.total = this.itemModel.amount + this.itemModel.total_tax;
      }
      var pos = this.itemsArr.findIndex(
        (e) => e.item_id === this.itemModel.item_id
      );
      if (pos == -1) {
        this.itemsArr.push(this.itemModel);
      } else {
        this.itemsArr[0].optionsData = this.options_array;
        this.itemsArr[pos].qty =
          parseFloat(this.itemsArr[pos].qty) + parseFloat(this.itemModel.qty);
        const a =
          parseFloat(this.itemsArr[pos].qty) *
          parseFloat(this.itemsArr[pos].s_rate);
        const b = parseFloat(this.itemsArr[pos].qty) * this.optionarray_amount;
        this.itemsArr[pos].amount = a + b;
      }
      this.calculateAmt();
      this.itemModel = {};
      this.initialiseItem();
    }
  }
  optionarray_amount: number = 0;
  options_array: any[] = [];
  checkedVariantAmount: number = 0;
  checkedVariant: any[] = [];

  openCustomization(e: any) {
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(
      ItemsDialogComponent,
      {
        data: e,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.optionarray_amount = result.optionAmount
        // this.options_array = result.optionsData
        // const isCustomised = true
        // this.populateItemData(result, isCustomised)
        const item = result.item;
        if (result.status === "add") {
          this.optionarray_amount = item.optionAmount;
          this.options_array = item.optionsData;
          this.checkedVariantAmount = item.checkedVariantAmount;
          this.checkedVariant = item.checkedVariant;
          const isCustomised = true;
          this.populateItemData(result, isCustomised);
        } else {
          this.updateOrder(item);
        }
      }
    });
  }

  updateOrder(item) {
    this.optionarray_amount = item.optionAmount;
    this.options_array = item.optionsData;
    this.checkedVariantAmount = item.checkedVariantAmount;
    this.checkedVariant = item.checkedVariant;
    item.amount =
      item.checkedVariantAmount > 0
        ? (item.checkedVariantAmount + item.optionAmount) *
          (item.qty > 0 ? item.qty : 1)
        : item.optionAmount > 0
        ? item.optionAmount + item.s_rate * item.qty
        : item.qty > 0
        ? item.s_rate * item.qty
        : item.s_rate;
    if (this.isTransactionGSTslab === false) {
      // console.log("this.isTransactionGSTslab--", this.isTransactionGSTslab)
      if (item.tax_mode === "GST") {
        if (this.isLocal) {
          item.sgst_per = item.taxslab / 2;
          item.sgst_amt = item.amount * (item.sgst_per / 100) || 0;
          item.sgst_amt = Math.round(item.sgst_amt * 100) / 100;
          item.cgst_per = item.taxslab / 2;
          item.cgst_amt = item.amount * (item.cgst_per / 100) || 0;
          item.cgst_amt = Math.round(item.cgst_amt * 100) / 100;
        } else {
          item.igst_per = item.taxslab;
          item.igst_amt = item.amount * (item.igst_per / 100) || 0;
          item.igst_amt = Math.round(item.igst_amt * 100) / 100;
        }

        item.total_tax =
          item.sgst_amt || 0 + item.cgst_amt || 0 + item.igst_amt || 0;
      } else {
        item.vat_per = item.taxslab;
        item.vat_amt = item.amount * (item.vat_per / 100) || 0;
        item.vat_amt = Math.round(item.vat_amt * 100) / 100;
        item.total_tax = item.vat_amt || 0;
      }
      item.total = item.amount + item.total_tax;
      // item.instruction = this.form.value.instruction
    }
    this.updateOrderItem(item);
  }

  updateOrderItem(item) {
    const index = this.itemsArr.findIndex(
      (itm) => itm.item_id === item.item_id
    );
    if (index !== -1) {
      this.itemsArr[index] = item;
    }
    this.calculateAmt();
    // console.log("KOT_data---", this.itemsArr)
  }

  onDeleteItem(i: number) {
    this.itemsArr[i].active = false;
    this.itemsArr[i].qty = 0;
    this.itemsArr[i].s_rate = 0;
    this.itemsArr[i].amount = 0;
    this.itemsArr[i].cgst_amt = 0;
    this.itemsArr[i].cgst_per = 0;
    this.itemsArr[i].gross_amount = 0;
    this.itemsArr[i].sgst_amt = 0;
    this.itemsArr[i].sgst_per = 0;
    this.itemsArr[i].taxable_amt = 0;
    this.calculateAmt();
    // const itemsActivearr = this.itemsArr.filter((e) => e.active);
    // this.showDelButton = itemsActivearr.length > 1 ? false : true;
  }

  initialiseItem() {
    this.itemControl.setValue(null);
    this.itemObj = [];
    this.filteredOptions = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }
  calculateAmt() {
    this.model.net_amount = 0;
    console.log("this.model---", this.model);
    this.itemsArr.forEach((itm) => {
      if (itm.active) {
        this.model.net_amount = this.model.net_amount + itm.amount;
      }
    });
    this.model.total_amount = this.model.net_amount;
  }
  onFocus(e) {
    const filter = "restaurant_menu_items";
    this.apiService.getCustomItemList(filter).subscribe(
      (result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      },
      (result: any) => {}
    );
  }
  onClose() {
    this.dialogRef.close();
  }
  getTransNo() {
    return this.model.trans_no ? this.model.trans_no : " Auto";
  }
  // onChangeItemQtyOrRate(e, i) {
  //   const a = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
  //   const b = this.itemsArr[i].qty * this.optionarray_amount
  //   this.itemsArr[i].amount = a +b ;
  //   this.calculateAmt()
  // }
  // cancelKOT() {
  //   this.dialog
  //     .open(ConfirmDialogComponent, {
  //       data: "Are you sure to Cancel the KOT Details.?",
  //     })
  //     .afterClosed()
  //     .subscribe((result) => {
  //       console.log("result---", result);
  //       console.log("this.data.kot_id---", this.data.kot_id);

  //       if (result == true) {
  //         console.log("this.data.kot_id---", this.data.kot_id);
  //         const Reason = "";
  //         this.apiService.deleteSalesOrder(this.data.kot_id, Reason).subscribe(
  //           (result: any) => {
  //             this.notifier.notify("success", "KOT Canceled successfully");
  //             this.dialogRef.close("Edited");
  //           },
  //           (result: any) => {
  //             this.notifier.notify("error", "unable to cancel KOT");
  //           }
  //         );
  //       }
  //     });
  // }
  cancelKOT() {
    this.dialog
      .open(KotCancelDialogComponent, {
        width: "400px",
        data: { transaction_id: this.data.kot_id },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.success) {
          this.model.trans_type = "SalesOrder";
          var tr_no = this.model.trans_no;
          this.downloadClick(this.model, tr_no);
          this.dialogRef.close();
        }
      });
  }
  downloadClick(dataObj, transNo) {
    this.apiService.downloadPdf(dataObj).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + transNo + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => {}
    );
  }
  onSubmit() {
    if (this.itemsArr.length) {
      if (this.data.kot_id) {
        this.model.items_details = this.itemsArr;
        this.apiService.editSalesOrder(this.data.kot_id, this.model).subscribe(
          (result: any) => {
            if (result.success) {
              this.model.trans_type = "SalesOrder";
              var tr_no = this.model.trans_no;
              this.downloadClick(this.model, tr_no);
              this.notifier.notify(
                "success",
                "Sales Order Updated successfully"
              );
            } else {
              this.notifier.notify("error", result.message);
            }
            this.dialogRef.close("Edited");
          },
          (result: any) => {
            this.notifier.notify("error", "unable to update Sales Order");
          }
        );
      } else {
        // this.model.items_details = this.itemsArr;
        // this.model.table_id = this.data.table_id;
        // this.model.ledger_id = 0;
        // this.apiService.addSalesOrder(this.model)
        //   .subscribe((result: any) => {
        //     if (result.success) {
        //       this.model.trans_no = result.max_trans_value;
        //       this.model.transaction_id = result.transaction_id;
        //       this.model.trans_type = "SalesOrder";
        //       var tr_no = this.model.trans_no
        //       this.downloadClick(this.model,tr_no);
        //       this.model = {trans_date: new Date(), net_amount: 0};
        //       this.itemModel = {};
        //       this.itemsArr = [];
        //       this.onKotAdd.emit(true);
        //       this.notifier.notify('success', 'New KOT added successfully');
        //     } else {
        //       this.notifier.notify('error', result.message);
        //     }
        //   }, (result: any) => {
        //     this.notifier.notify('error', 'Unable to add new KOT');
        //   });
      }
    } else {
      this.notifier.notify("error", "Add atleast one item to continue");
    }
  }
  convertFloat(val) {
    return parseFloat(val);
  }
  onChangeItemQtyOrRate(event, i) {
    const qty = this.itemsArr[i].qty;
    const rate = this.itemsArr[i].s_rate;
    if (qty < 1) {
      this.itemsArr[i].qty = 1;
    }
    if (rate < 1) {
      this.itemsArr[i].s_rate = rate;
    }
    const a = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    const b = this.itemsArr[i].qty * this.optionarray_amount;
    this.itemsArr[i].amount = a + b;
    this.calculateAmt();
  }

  updateCustomisation(itm) {
    // console.log("update item --", itm)
    const data = { item: itm, status: "update" };
    this.openCustomization(data);
  }
}
