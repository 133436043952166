<div class="my_container">
  <h3 class="content-span  color-h3">Database back up confirmation <span style="float: right;"> <mat-icon id="close-icon"
        (click)="closeDialog()">close</mat-icon></span></h3>
  <div class="table-details">
    <div class="row" style="margin-top:30px">
      <div class="col-md-6 text-center">
        <button class="btn btn-primary  " (click)="google_drive()">
          <mat-icon class="align-top" style="font-size:35px;text-align: center"> cloud_upload </mat-icon>
          <div style="margin-top:10px;font-size: 20px;text-align: center"><span>Google Drive</span></div>
        </button>

      </div>
      <div class="col-md-6 text-center">
        <button class="btn btn-primary " (click)="local_storage()">
          <mat-icon class="align-top" style="font-size:35px;text-align: center"> folder </mat-icon>
          <div style="margin-top:10px;font-size: 20px;text-align: center"><span>Local Disk</span></div>
        </button>
      </div>

    </div>
  </div>
</div>