<h2 *ngIf="!data.value" mat-dialog-title class="color-h3">Add Item</h2>
<h2 *ngIf="data.value" mat-dialog-title class="color-h3">Edit Item</h2>
<div class="table-details">
    <mat-dialog-content>
        <form #transForm="ngForm">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="contract-full-width">
                        <input type="text" placeholder="Item" aria-label="Number" matInput [formControl]="itemControl"
                            [matAutocomplete]="auto" (keyup)="search($event)" (focus)="onFocus($event)" required>
                        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
                            (optionSelected)="itemSelection($event)">
                            <mat-option>
                                <div (click)="onAddItemClick()" class="text-center">
                                    <span>Add New Item</span>
                                </div>
                            </mat-option>
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                <span class="item-font">({{option.item_code}})</span>
                                <span class="item-font"> {{option.item_name}}</span>
                                <br>
                                <small>
                                    <span class="item-subtitle">PRate: </span>
                                    <span *ngIf="option.p_rate" class="item-rate">{{option.p_rate | number :
                                        '1.2-2'}}</span>
                                </small>
                                <small>
                                    <span class="item-rate-font">SRate: </span>
                                    <span *ngIf="option.s_rate" class="item-rate">{{option.s_rate | number :
                                        '1.2-2'}}</span>
                                </small>
                                <small>
                                    <span class="item-rate-font">MRP: </span>
                                    <span *ngIf="option.mrp" class="item-rate"> {{option.mrp | number : '1.2-2'}}</span>
                                </small>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field class="contract-full-width">
                        <textarea matInput placeholder="Description:" [(ngModel)]="model.description"
                            name="description"></textarea>
                    </mat-form-field>
                </div>


                <div class="col-md-12">
                    <div class="row">
                        <div *ngFor="let optional of optionalFields; let i=index"
                            [ngClass]="{'col-md-6': moreField, 'col-md-12': !moreField}">

                            <mat-form-field class="contract-full-width">
                                <input matInput placeholder={{optional.assignedname}} [(ngModel)]="model[optional.code]"
                                    name="{{optional.code}}Input">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="contract-full-width">
                        <span matPrefix># &nbsp;</span>
                        <input type="number" matInput placeholder="Quantity:" [(ngModel)]="model.qty" name="qty"
                            required (keyup)="quantityChange()" (click)="quantityChange()">
                        <mat-error>This field is mandatory.</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="data.type == 'po'" class="col-md-6">
                    <mat-form-field class="contract-full-width">
                        <span matPrefix><i class="fa fa-{{currency}}"></i> &nbsp;</span>
                        <input type="number" matInput placeholder="Purchase Rate:" [(ngModel)]="model.p_rate"
                            name="p_rate" required (keyup)="rateChange()">
                        <mat-error>This field is mandatory.</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="data.type == 'so'" class="col-md-6">
                    <mat-form-field class="contract-full-width">
                        <span matPrefix><i class="fa fa-{{currency}}"></i> &nbsp;</span>
                        <input type="number" matInput placeholder="Sale Rate:" [(ngModel)]="model.s_rate" name="s_rate"
                            required (keyup)="rateChange()">
                        <mat-error>This field is mandatory.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="contract-full-width">
                        <span matPrefix> <i class="fa fa-{{currency}}"></i> &nbsp;</span>
                        <input type="number" matInput placeholder="Amount:" [(ngModel)]="model.amount" name="amount"
                            readonly>
                        <mat-error>This field is mandatory.</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" class="mat-raised-button" (click)="close()">Close</button>
        <button type="submit" class="mat-raised-button mat-primary" (click)="transForm.valid && onSubmit()">Add</button>
    </mat-dialog-actions>
</div>