import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatTabGroup } from "@angular/material";
import { CompanySettingsEmailComponent } from "../company-settings-email/company-settings-email.component";
import { DataService } from "src/app/data.service";
import { NotifierService } from "angular-notifier";
interface sms {
  viewValue: string;
}

@Component({
  selector: "app-sms-info",
  templateUrl: "./sms-info.component.html",
  styleUrls: ["./sms-info.component.css"],
})
export class SmsInfoComponent implements OnInit {
  smsSetting: sms[] = [{ viewValue: "TWILIO" }, { viewValue: "LOCAL" }];
  // @ViewChild('f') f;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  @ViewChild("twilioForm") twilioForm: any;
  @ViewChild("localForm") localForm: any;

  constructor(
    private dialogRef: MatDialogRef<CompanySettingsEmailComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log("data--------", data)
  }
  modelTwilio: any = {};
  modelLocal: any = {};
  twilioSetting: any;
  localSetting: any;
  show: boolean = false;
  typePassword = "password";
  twilioBalance: any;
  companySmsSettings: any = {};
  twilioCurrency: any;
  ngOnInit() {
    // this.f.submitted = true;
    this.modelTwilio = JSON.parse(this.data.sms_settings_data);
    this.modelLocal = JSON.parse(this.data.sms_settings_data);
    this.load();
  }

  load() {
    if (this.modelLocal.twilio_sid)
      this.apiService.getTwilioBalance(this.modelLocal).subscribe(
        (result: any) => {
          // console.log("result for twilio -", result)
          this.twilioBalance = result.balance;

          this.twilioCurrency = result.currency.toLowerCase();
          if (this.twilioBalance < 15) {
            // alert("Your Balance is low")
            this.notifier.notify("error", "Twilio balance is low");

            const data = { company: this.data, sms: result };
            this.apiService
              .smsLowBalanceEmail(data)
              .subscribe((result: any) => {
                // console.log("emailsent", result)
              }),
              (err: any) => {
                console.log("err sending email ", err);
              };
          }
        },
        (err: any) => {
          console.log("err finding twilio message count ", err);
        }
      );
  }
  close() {
    this.dialogRef.close();
  }

  ShowMe() {
    this.show = !this.show;
    if (this.show) {
      this.typePassword = "text";
    } else {
      this.typePassword = "password";
    }
  }

  local: boolean = false;
  twilio: boolean = false;
  onOrderTypeChange(e) {
    // console.log("selectedValue----", e.value)
    if (e.value === "TWILIO") {
      this.twilio = true;
      this.local = false;
      this.modelLocal = {};
    } else if (e.value === "LOCAL") {
      this.local = true;
      this.twilio = false;
      this.modelTwilio = {};
    }
  }

  onSubmit(form: any) {
    if (form.valid) {
      if (form === this.twilioForm) {
        // console.log('Submitting TWILIO data:', this.modelTwilio);
        const twillo = {
          operator: "twillio",
          twilio_sid: this.modelTwilio.twilio_sid,
          twilio_token: this.modelTwilio.twilio_token,
          twilio_phone_no: this.modelTwilio.twilio_phone_no,
          // twilio_balancealertcount : this.modelTwilio.twilio_balancealertcount,
        };

        this.twilioSetting = JSON.stringify(twillo);
        this.apiService
          .editCompanySmsSettingsInfo(this.data.id, this.twilioSetting)
          .subscribe(
            (result: any) => {
              this.dialogRef.close("Edited");
              if (result.success) {
                this.notifier.notify(
                  "success",
                  "Company sms settings updated successfully"
                );
              } else {
                this.notifier.notify("error", "unable to update data");
              }
            },
            (result: any) => {
              this.notifier.notify("error", "unable to update data");
            }
          );
      } else if (form === this.localForm) {
        // console.log('Submitting LOCAL data:', this.modelLocal);
        const local = {
          operator: "local",
          local_api: this.modelLocal.api_key,
          local_apicontent: this.modelLocal.sender_id,
          local_balancespi: this.modelLocal.local_balancespi,
          // local_balancealertcount : this.modelLocal.local_balancealertcount,
        };
        this.localSetting = JSON.stringify(local);
        this.apiService
          .editCompanySmsSettingsInfo(this.data.id, this.localSetting)
          .subscribe(
            (result: any) => {
              this.dialogRef.close("Edited");
              if (result.success) {
                this.notifier.notify(
                  "success",
                  "Company sms settings updated successfully"
                );
              } else {
                this.notifier.notify("error", "unable to update data");
              }
            },
            (result: any) => {
              this.notifier.notify("error", "unable to update data");
            }
          );
      }
    }
  }
}
