import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-call-rider",
  templateUrl: "./call-rider.component.html",
  styleUrls: ["./call-rider.component.css"],
})
export class CallRiderComponent implements OnInit {
  constructor(
    private notifier: NotifierService,
    private apiService: DataService,
    @Inject(MAT_DIALOG_DATA) public transaction_id: any,
    private dialogRef: MatDialogRef<CallRiderComponent>
  ) {
    console.log("data---", transaction_id);
  }
  deliveryAgentDetails: any;
  ngOnInit() {
    this.apiService.getWeraDeliveryAgentDetails(this.transaction_id).subscribe(
      (result: any) => {
        console.log("result--", result.data.details);
        this.deliveryAgentDetails = result.data.details;
      },
      (result: any) => {}
    );
  }

  onClose() {
    this.dialogRef.close(this.transaction_id);
  }
}
