<div class="panelcard">
  <h3 class="color-h3">Discount Report</h3>
  <div class="table-details">
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6"></div>
    </div>

    <!-- <form> -->
    <div class="row">
      <div class="col-md-2">
        <label for="">From*</label>
        <angular2-date-picker
          (onDateSelect)="onDateSelect($event)"
          [(ngModel)]="f_date"
          [settings]="settings"
        >
        </angular2-date-picker>
        <!-- <mat-form-field class="contract-full-width">
                      <input matInput [matDatepicker]="from_date" [disabled]="fromDateFlag" placeholder="From:"
                          [(ngModel)]="model.from_date" name="from_date" required>
                      <mat-datepicker-toggle matSuffix [for]="from_date"></mat-datepicker-toggle>
                      <mat-datepicker #from_date></mat-datepicker>
                  </mat-form-field> -->
      </div>
      <div class="col-md-2">
        <label for="">To*</label>
        <angular2-date-picker
          (onDateSelect)="onDateSelect($event)"
          [(ngModel)]="t_date"
          [settings]="settings"
        >
        </angular2-date-picker>
        <!-- <mat-form-field class="contract-full-width">
                      <input matInput [matDatepicker]="to_date" [disabled]="toDateFlag" placeholder="To:"
                          [(ngModel)]="model.to_date" name="to_date" required>
                      <mat-datepicker-toggle matSuffix [for]="to_date"></mat-datepicker-toggle>
                      <mat-datepicker #to_date></mat-datepicker>
                  </mat-form-field> -->
      </div>
      <!-- <div class="col-md-2">
        <mat-form-field class="contract-full-width">
          <mat-select
            class="form-margin"
            placeholder="Select Filter Name"
            (selectionChange)="searchByFilterKey($event.value)"
            [(ngModel)]="model.duesFilterTypeId"
          >
            <mat-option
              *ngFor="let obj of searchFilterName"
              value="{{ obj.id }}"
            >
              {{ obj.name | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <!-- <div class="col-md-2">
        <mat-form-field class="contract-full-width">
          <mat-select
            class="form-margin"
            placeholder="Select User"
            (selectionChange)="UserFilter($event.value)"
            [(ngModel)]="model.filter_user_id"
            name="filter_user"
          >
            <mat-option value="-1"> All </mat-option>
            <mat-option *ngFor="let obj of userList" value="{{ obj.user_id }}">
              {{ obj.full_name | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <!-- <div class="col-md-1">
        <section class="form-section">
          <mat-checkbox
            class="form-margin"
            [disabled]="duesOnlyFlag"
            [(ngModel)]="model.onlyDue"
            name="onlyDue"
            >Dues Only
          </mat-checkbox>
        </section>
      </div> -->
      <!-- <div class="col-md-1">
        <section class="form-section">
          <mat-checkbox
            class="form-margin"
            [disabled]="ncOnlyFlag"
            [(ngModel)]="model.onlync"
            name="onlync"
            >NC
          </mat-checkbox>
        </section>
      </div> -->
      <div
        class="col-md-2 mb-2"
        style="padding: 0 !important; margin-top: 18px"
      >
        <button mat-raised-button color="primary" (click)="searchClick()">
          Search
        </button>
        <button
          class="btn ml-2 dropdown-toggle"
          style="height: 36px"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="height: 36px"
        >
          Print
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="setPrintOption('Sales Summary')"
            >Sales Summary</a
          >
          <a class="dropdown-item" (click)="setPrintOption('Total Sale Data')"
            >All Sale Data</a
          >
          <a class="dropdown-item" (click)="exportSalesData()">Export</a>
        </div>
      </div>
    </div>
    <!-- </form> -->
    <div class="spinner-container" *ngIf="isLoading">
      <div class="spinner"></div>
    </div>

    <table mat-table [dataSource]="dueReceiptObj">
      <ng-container matColumnDef="trans_date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let row" data-label="Date">
          {{ row.trans_date | date : "dd/MMM/yyyy" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="trans_no">
        <th mat-header-cell *matHeaderCellDef>Voucher No</th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let row"
          data-label="Voucher No"
        >
          {{ row.trans_no }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="display_trans_no">
        <th mat-header-cell *matHeaderCellDef>Invoice No</th>
        <td mat-cell *matCellDef="let row" data-label="Invoice No">
          {{ row.display_trans_no }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="table">
        <th mat-header-cell *matHeaderCellDef>Table</th>
        <td mat-cell *matCellDef="let row" data-label="Table">
          {{ row.table_name }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="customer_name">
        <th mat-header-cell *matHeaderCellDef>Customer Name</th>
        <td mat-cell *matCellDef="let row" data-label="Customer Name">
          {{ row.customer_name }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="full_name">
        <th mat-header-cell *matHeaderCellDef>User Name</th>
        <td mat-cell *matCellDef="let row" data-label="User Name">
          {{ row.full_name }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="discount">
        <th mat-header-cell *matHeaderCellDef>Discount</th>
        <td mat-cell *matCellDef="let row" data-label="Discount">
          {{ row.discount_amt | number : "1.2-2" }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ totalDiscountAmt() | number : "1.2-2" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="net_amount">
        <th class="right" mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let row" data-label="Amount">
          {{ row.net_amount | number : "1.2-2" }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ totalAmt() | number : "1.2-2" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          'table-info': row.non_receivable == true
        }"
      ></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{
                  'table-info': row.non_receivable == true,
                  'swiggy-background': row.order_mode.toLowerCase() === 'swiggy',
                  'zomato-background': row.order_mode.toLowerCase() === 'zomato'
           }">
          </tr> -->
      <tr
        mat-footer-row
        class="right"
        *matFooterRowDef="displayedColumns"
        class="total-row"
      ></tr>
    </table>
  </div>
</div>
