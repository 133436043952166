import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { NotifierService } from 'angular-notifier';

import { DataService } from '../../data.service';
import { RolesComponent } from '../../roles/roles.component';

@Component({
  selector: 'app-change-user-pass',
  templateUrl: './change-user-pass.component.html',
  styleUrls: ['./change-user-pass.component.css']
})
export class ChangeUserPassComponent implements OnInit {
  @ViewChild('f') f;
  constructor(private dialogRef: MatDialogRef<ChangeUserPassComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  user_id: any;
  model: any = { old_password: "", password: "", confirm_password: "" };
  roles: any;
  ngOnInit() {
    this.load()
  }
  load() {
    this.user_id = localStorage.getItem('user_id');
  }

  onSubmit() {
    // console.log(this.data);

    if (this.model.password != this.model.confirm_password) {
      this.notifier.notify('error', 'Confirm password not Matched');
    } else {
      if (this.data == "User") {
        this.apiService.changeUserPass(this.user_id, this.model)
          .subscribe((result: any) => {
            if (result.success) {
              this.dialogRef.close('Edited');
              this.notifier.notify('success', 'Password updated successfully');
            } else {
              this.notifier.notify('error', result.message);
            }

          }, (result: any) => {
            this.notifier.notify('error', result.message);
          });
      }
      if (this.data == "Tenent") {
        this.apiService.changeTenentPass(this.user_id, this.model)
          .subscribe((result: any) => {
            if (result.success) {
              this.dialogRef.close('Edited');
              this.notifier.notify('success', 'Password updated successfully');
            } else {
              this.notifier.notify('error', result.message);
            }

          }, (result: any) => {
            this.notifier.notify('error', result.message);
          });
      }


    }

  }


  close() {
    this.dialogRef.close();
  }

}
