import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from "@angular/material";
import { NotifierService } from 'angular-notifier';

import { DataService } from '../../data.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { startWith, map } from 'rxjs/operators';
@Component({
  selector: 'app-add-ledger',
  templateUrl: './add-ledger.component.html',
  styleUrls: ['./add-ledger.component.css']
})
export class AddLedgerComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AddLedgerComponent>,
    private apiService: DataService,
    private notifier: NotifierService) { }
  @ViewChild('f') f;
  ngOnInit() {
   // this.load();
    this.allstates();
  }
  model: any = {};
  groups: any;
  partyTypeObj: any = [{ value: "Local" }, { value: "InterState" }];
  accntTypeObj: any = [{ value: "Saving" }, { value: "Current" }, { value: "Other" }];

  obdrcr: any = [{ value: "DR" }, { value: "CR" }];

  GState: any;
  stateName: any;
  statelist: any;
  result_data: any = {};
  allstates() {
    this.apiService.getGstState()
      .subscribe((result: any) => {
        this.stateName = result;
        this.load();
        this.searchState();
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }

  stateControl = new FormControl();
  filteredState: Observable<string[]>;

  location_filter(value) {
    const filterValue =value?value.toLowerCase():'';
    return this.stateName.filter(state => state.state_name.toLowerCase().includes(filterValue));
  }

  displayStateFn(state) {
    return state ? state.state_name : "";
  }
  searchState() {
        this.filteredState = this.stateControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.location_filter(value))
          );
  }
  stateSelection(event: MatAutocompleteSelectedEvent) {
    this.model.address_state = event.option.value ? event.option.value.state_gst_id : undefined;
  }


  searchState1(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.stateControl.value.length >= 1) {
        this.apiService.sgetGstState(this.stateControl.value, 'false')
          .subscribe((result: any) => {
            this.stateName = result;
          });
      }else{
        this.filteredState = this.stateControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.location_filter(value))
          );
      }
    }
  }

  StateonFocus(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.stateName = result;
        this.filteredState = this.stateControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this.location_filter(value))
        );
        //this.stateName = this.GState;
        // console.log(this.stateName);
      });
  }


  load() {
    this.f.submitted = true;
    this.apiService.getLedgerGroupList()
      .subscribe((result: any) => {
        this.groups = result;
      }, (result: any) => {
      });
  }

  onSubmit() {
    this.model.customer_item_rate_applicable=false;
    this.model.use_qty_from_sale_history=false;
    this.apiService.addLedger(this.model)
      .subscribe((result: any) => {
        if (result.success) {
          this.result_data = { status: 'Added', data: result.data };
          this.dialogRef.close(this.result_data);
          this.notifier.notify('success', 'New Ledger added successfully');
        } else {
          this.notifier.notify('error', result.message);
        }
      }, (result: any) => {
        this.notifier.notify('error', 'unable to add new Ledger');
      });
  }

  close() {
    this.dialogRef.close(this.result_data);
  }
}
