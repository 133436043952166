import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { NotifierService } from 'angular-notifier';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-tenent-login',
  templateUrl: './tenent-login.component.html',
  styleUrls: ['./tenent-login.component.css']
})
export class TenentLoginComponent implements OnInit {

  constructor(private router: Router,
    private apiService: DataService,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private cookieService: CookieService,
    public dialog: MatDialog,) { }
  model: any = {};
  loading: any = false;
  returnUrl: any;
  subdomain:any;

  ngOnInit() {
    this.getSubdomain();
    
  }
  getSubdomain() {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      this.subdomain = '';
    } else {
      this.subdomain = domain.split('.')[0];
    }
    if (this.subdomain == 'accounts') {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      this.apiService.logout();
    } else {
      this.router.navigate(['/login']);
    }
  }
  login() {
    this.loading = true;
    const body = {
      'username': this.model.username, 'password': this.model.password
    };
    this.apiService.tenentLogin(body)
      .subscribe((result: any) => {
        this.loading = false;
        const parsedresult = (result.body.dataObj);
        if (!result.body.success) {
          this.notifier.notify('error', result.body.message);
        } else {
          this.cookieService.set( 'SESSIONID', parsedresult.token);
          localStorage.setItem('token', parsedresult.token);
          localStorage.setItem('userName', parsedresult.userName);
          localStorage.setItem('emailId', parsedresult.emailId);
          this.router.navigate(['admin/tenent-management']);
        }
      }, (result: any) => {
        this.loading = false;
      });
  }
  forgetPassClick(){
    this.dialog.open(ConfirmDialogComponent, {
      data: "New password will send to owner email_id, want to proceed.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.mailPassToOwner()
          .subscribe((result: any) => {
                if(result.success){
                  this.notifier.notify('success', result.message);
                }else{
                  this.notifier.notify('error', result.message);
                }
          }, (result: any) => {
            this.notifier.notify('error', 'unable to accept your request');
          });
        }
      });
  }
}
