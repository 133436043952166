import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LicenceInfoComponent } from './licence-info/licence-info.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        NgxSpinnerModule
    ],
    exports: [
        CommonModule,
        MaterialModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        NgxSpinnerModule,
        LicenceInfoComponent,
        NgxQRCodeModule
    ],
    declarations: [LicenceInfoComponent],
})
export class SharedModule { }