<div class="dialog-title" mat-dialog-title>
  <h3 class="color-h3">
    <span *ngIf="!data.kot_id">Add KOT</span>
    <span *ngIf="data.kot_id">Edit KOT</span>
    <span
      ><mat-icon
        class="close-icon"
        style="color: black; padding: 5px; float: right"
        (click)="onClose()"
        >close</mat-icon
      ></span
    >
  </h3>
</div>
<div class="table-details">
  <mat-dialog-content>
    <form #transForm="ngForm">
      <div class="row">
        <div class="col-md-12 border mb-1">
          <div class="row">
            <div class="col-md-4 pt-2 customer">
              <div *ngIf="data">
                <label class="font-weight-bold">Table : </label>
                <label class="col-form-label">{{ data.table_name }}</label>
              </div>
            </div>
            <div class="col-md-4 pt-2 customer">
              <label><strong>Trans no. :</strong>{{ getTransNo() }}</label>
            </div>
            <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  [matDatepicker]="trans_date"
                  placeholder="Date:"
                  [(ngModel)]="model.trans_date"
                  name="trans_date"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="trans_date"
                ></mat-datepicker-toggle>
                <mat-datepicker #trans_date></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12 border">
          <div class="row">
            <div class="col-md-5">
              <mat-form-field class="contract-full-width">
                <input
                  type="text"
                  placeholder="Search Item"
                  aria-label="Number"
                  matInput
                  [formControl]="itemControl"
                  [matAutocomplete]="auto"
                  (keyup)="search($event)"
                  (focus)="onFocus($event)"
                  #sitm
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  autoActiveFirstOption
                  [displayWith]="displayFn"
                  (optionSelected)="itemSelection($event)"
                >
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                  >
                    <span class="item-font"> {{ option.item_name }}</span>
                    <span class="item-font">({{ option.item_code }})</span>
                    <br />
                    <small>
                      <span class="item-subtitle">category: </span>
                      <span *ngIf="option.category_name" class="item-rate">{{
                        option.pricelist_name
                      }}</span>
                    </small>
                    <small>
                      <span class="item-rate-font">SRate: </span>
                      <span *ngIf="option.s_rate" class="item-rate">{{
                        option.s_rate | number : "1.2-2"
                      }}</span>
                    </small>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  type="number"
                  placeholder="Qty:"
                  [(ngModel)]="itemModel.qty"
                  name="qty"
                  (focus)="onQtyFocus($event)"
                  (blur)="onQtyFocus(null)"
                  (change)="calculateItemAmt($event)"
                  #qty1
                  min="0"
                />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  type="number"
                  placeholder="Rate:"
                  [(ngModel)]="itemModel.s_rate"
                  name="s_rate"
                  [readonly]="!allowChangeRate"
                  (keyup)="calculateItemAmt($event)"
                  #s_rate1
                />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  type="number"
                  placeholder="Amount:"
                  [(ngModel)]="itemModel.amount"
                  name="amount"
                  readonly
                />
              </mat-form-field>
            </div>
            <div class="col-md-1 pt-3">
              <button
                type="button"
                class="btn btn-sm del-btn"
                (click)="onAddItem()"
              >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
            </div>
          </div>
          <!-- <table class="table item-tbl">
            <tr>
              <th>Sl</th>
              <th class="itm-name">Item Name</th>
              <th class="text-center">Qty</th>
              <th class="text-center">Rate</th>
              <th class="right">Amount</th>
              <th></th>
            </tr>

            <ng-container *ngFor="let item of itemsArr; let i = index">
              <tr>
                <td data-title="Sl">{{ i + 1 }}</td>
                <td data-title="Name" class="table-font-arial itm-name">
                  {{ item.item_name }}
                </td>
                <td data-title="Qty" class="table-font-arial">
                  <input type="number" class="form-control form-control-xs" [(ngModel)]="item.qty" name="qty{{ i }}"
                    (keyup)="onChangeItemQtyOrRate($event, i)" (change)="onChangeItemQtyOrRate($event, i)" />
                </td>
                <td data-title="Rate" class="table-font-arial right">
                  <input type="number" class="form-control form-control-xs" [(ngModel)]="item.s_rate"
                    name="s_rate{{ i }}" [readonly]="!allowChangeRate" (keyup)="onChangeItemQtyOrRate($event, i)" />
                </td>
                <td data-title="Amount" class="table-font-arial right">
                  {{ item.amount | number : "1.2-2" }}
                </td>
                <td data-title="Actions" class="action-column">
                  <button type="button" class="btn btn-sm del-btn" (click)="onDeleteItem(i)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </td>
              </tr>
              <tr *ngIf="
                  (item.checkedVariant && item.checkedVariant.length > 0) ||
                  (item.optionsData && item.optionsData.length > 0)
                ">
                <td>
                  <a (click)="updateCustomisation(item)" style="text-decoration: none; color: blue">Customisable</a>
                </td>
              </tr>
              <tr>
                <td colspan="7" class="table-font-arial">
                  <mat-form-field class="contract-full-width">
                    <textarea matInput placeholder="Write your cooking instruction here" [(ngModel)]="item.instruction"
                      name="instruction{{ i }}"></textarea>
                  </mat-form-field>
                </td>
              </tr>
            </ng-container> -->

          <!-- <tr *ngFor="let item of itemsArr;let i=index">
              <td data-title='Sl'>{{i+1}}</td>
              <td data-title='Name' class="table-font-arial itm-name">{{item.item_name}}</td>
              <td data-title='Qty' class="table-font-arial">
                <input type="number" class="form-control form-control-xs" [(ngModel)]="item.qty" name="qty{{i}}"
                  (keyup)="onChangeItemQtyOrRate($event,i)">
              </td>
              <td data-title='Rate' class="table-font-arial right">
                <input type="number" class="form-control form-control-xs" [(ngModel)]="item.s_rate" name="s_rate{{i}}"
                  [readonly]="!allowChangeRate" (keyup)="onChangeItemQtyOrRate($event,i)">
              </td>
              <td data-title='Amount' class="table-font-arial right">{{item.amount | number : '1.2-2'}}</td>
              <td data-title='Actions' class="action-column">
                <button type="button" class="btn btn-sm del-btn" (click)="onDeleteItem(i)">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
              <div>
                <fieldset class="border pl-4 pr-4 form-fieldset">
                  <legend class="w-auto">Add cooking instruction</legend>
                  <mat-form-field class="contract-full-width">
                    <textarea matInput placeholder="Write your cooking instruction here" [(ngModel)]="model.notes" name="notes"></textarea>
                  </mat-form-field>
                </fieldset>
              </div>
            </tr> -->
          <!-- </table> -->

          <table class="table item-tbl">
            <tr>
              <th>Sl</th>
              <th class="itm-name">Item Name</th>
              <th class="text-center">Qty</th>
              <th class="text-center">Rate</th>
              <th class="right">Amount</th>
              <th></th>
            </tr>

            <ng-container *ngFor="let item of itemsArr; let i = index">
              <tr>
                <td data-title="Sl">{{ i + 1 }}</td>
                <td data-title="Name" class="table-font-arial itm-name">
                  <span *ngIf="!item.active" class="cancelled-text"
                    >Cancelled</span
                  >
                  {{ item.item_name }}
                </td>
                <td data-title="Qty" class="table-font-arial">
                  <input
                    type="number"
                    class="form-control form-control-xs"
                    [(ngModel)]="item.qty"
                    name="qty{{ i }}"
                    (keyup)="onChangeItemQtyOrRate($event, i)"
                    (change)="onChangeItemQtyOrRate($event, i)"
                    [disabled]="!item.active"
                  />
                </td>
                <td data-title="Rate" class="table-font-arial right">
                  <input
                    type="number"
                    class="form-control form-control-xs"
                    [(ngModel)]="item.s_rate"
                    name="s_rate{{ i }}"
                    [readonly]="!allowChangeRate"
                    (keyup)="onChangeItemQtyOrRate($event, i)"
                    [disabled]="!item.active"
                  />
                </td>
                <td data-title="Amount" class="table-font-arial right">
                  {{ item.amount | number : "1.2-2" }}
                </td>
                <td data-title="Actions" class="action-column">
                  <button
                    *ngIf="item.active && getActiveItemCount() > 1"
                    type="button"
                    class="btn btn-sm del-btn"
                    (click)="onDeleteItem(i)"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </td>
              </tr>
              <tr
                *ngIf="
                  ((item.checkedVariant && item.checkedVariant.length > 0) ||
                    (item.optionsData && item.optionsData.length > 0)) &&
                  item.active
                "
              >
                <td></td>
                <td colspan="5">
                  <a
                    (click)="updateCustomisation(item)"
                    style="text-decoration: none; color: blue"
                    >Customisable</a
                  >
                </td>
              </tr>
              <tr *ngIf="item.active">
                <td></td>
                <td colspan="5">
                  <mat-form-field class="contract-full-width">
                    <textarea
                      matInput
                      placeholder="Write your cooking instruction here"
                      [(ngModel)]="item.instruction"
                      name="instruction{{ i }}"
                    ></textarea>
                  </mat-form-field>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 col-8 right">
          <label class="form-bold-label big">Total</label>
        </div>
        <div class="col-md-2 col-4 right">
          <label class="form-bold-label big"
            ><i class="fa fa-{{ currency }}"></i>
            {{ model.net_amount | number : "1.2-2" }}</label
          >
        </div>
      </div>
      <!-- <div>
        <fieldset class="border pl-4 pr-4 form-fieldset">
          <legend class="w-auto">Add cooking instruction</legend>
          <mat-form-field class="contract-full-width">
            <textarea matInput placeholder="Write your cooking instruction here" [(ngModel)]="model.notes" name="notes"></textarea>
          </mat-form-field>
        </fieldset>
      </div> -->
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      *ngIf="data.kot_id"
      mat-raised-button
      color="warn"
      type="button"
      (click)="cancelKOT()"
    >
      Cancel
    </button>
    <button mat-raised-button class="btn" type="button" (click)="onClose()">
      Close
    </button>
    <button
      mat-raised-button
      class="btn-primary"
      type="button"
      (click)="transForm.valid && onSubmit()"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
<!-- *ngIf="item.active && itemsArr.length > 1 && !showDelButton" -->
