import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatTabGroup } from "@angular/material";
import { CompanySettingsEmailComponent } from "../company-settings-email/company-settings-email.component";
import { DataService } from "src/app/data.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-payment-settings",
  templateUrl: "./payment-settings.component.html",
  styleUrls: ["./payment-settings.component.css"],
})
export class PaymentSettingsComponent implements OnInit {
  paymentSetting: any = [{ viewValue: "Razorpay" }];
  selectedPaymentSetting: string;
  // @ViewChild('f') f;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  @ViewChild("PayForm") PayForm: any;
  @ViewChild("localForm") localForm: any;

  constructor(
    private dialogRef: MatDialogRef<PaymentSettingsComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("data--------", data);
  }
  modelPayment: any = {};

  paySetting: any;
  localSetting: any;
  show: boolean = false;
  typePassword = "password";

  ngOnInit() {
    // this.f.submitted = true;
    this.modelPayment = this.data;

    this.load();
    if (this.paymentSetting.length === 1) {
      this.selectedPaymentSetting = this.paymentSetting[0].viewValue;
      this.razorPay = true;
    }
  }

  load() {}
  close() {
    this.dialogRef.close();
  }

  ShowMe() {
    this.show = !this.show;
    if (this.show) {
      this.typePassword = "text";
    } else {
      this.typePassword = "password";
    }
  }

  local: boolean = false;
  razorPay: boolean = false;
  onOrderTypeChange(e) {
    // console.log("selectedValue----", e.value)
    if (e.value === "Razorpay") {
      this.razorPay = true;
    }
  }

  onSubmit(form: any) {
    console.log("consoling");
    if (form.valid) {
      if (form.valid) {
        if (form === this.PayForm) {
          console.log("Submitting TWILIO data:", this.modelPayment);
          // const twillo = {
          //   operator: "twillio",
          //   keyid: this.modelTwilio.keyid,
          //   secret_key: this.modelTwilio.secret_key,
          //
          // };
          const payment = {
            operator: "pay",
            keyid: this.modelPayment.keyid,
            secret_key: this.modelPayment.secret_key,
          };
          console.log("payment", payment);
          //   this.twilioSetting = JSON.stringify(twillo);
          //   this.apiService
          //     .editCompanySmsSettingsInfo(this.data.id, this.twilioSetting)
          //     .subscribe(
          //       (result: any) => {
          //         this.dialogRef.close("Edited");
          //         if (result.success) {
          //           this.notifier.notify(
          //             "success",
          //             "Company sms settings updated successfully"
          //           );
          //         } else {
          //           this.notifier.notify("error", "unable to update data");
          //         }
          //       },
          //       (result: any) => {
          //         this.notifier.notify("error", "unable to update data");
          //       }
          //     );
          // } else if (form === this.localForm) {
          //   // console.log('Submitting LOCAL data:', this.modelLocal);
          //   const local = {
          //     operator: "local",
          //     local_api: this.modelLocal.api_key,
          //     local_apicontent: this.modelLocal.sender_id,
          //     local_balancespi: this.modelLocal.local_balancespi,
          // local_balancealertcount : this.modelLocal.local_balancealertcount,
          //----------------------------------------------------------------
          this.paySetting = JSON.stringify(payment);
          console.log("thispaySetting", this.paySetting);
          console.log("this.data.id", this.data.id);
          this.apiService
            .editPaymantSetting(this.data.id, this.paySetting)
            .subscribe(
              (result: any) => {
                this.dialogRef.close("Edited");
                if (result.success) {
                  this.notifier.notify(
                    "success",
                    "Company payment settings updated successfully"
                  );
                } else {
                  this.notifier.notify("error", "unable to update data");
                }
              },
              (result: any) => {
                this.notifier.notify("error", "unable to update data");
              }
            );
        }
      }
      // this.localSetting = JSON.stringify(local);
      // this.apiService
      //   .editRazerpaySettingsInfo(this.data.id, this.localSetting)
      //   .subscribe(
      //     (result: any) => {
      //       this.dialogRef.close("Edited");
      //       if (result.success) {
      //         this.notifier.notify(
      //           "success",
      //           "Company sms settings updated successfully"
      //         );
      //       } else {
      //         this.notifier.notify("error", "unable to update data");
      //       }
      //     },
      //     (result: any) => {
      //       this.notifier.notify("error", "unable to update data");
      //     }
      //   );
      // }
    }
  }
}
