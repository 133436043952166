<h3 mat-dialog-title class="color-h3">Featured Items <span><button mat-icon-button class="close-button"
      [mat-dialog-close]="true">
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button></span></h3>
<div class="table-details">
  <mat-dialog-content class="mat-typography" #ftable>
    <div style="height: 480px;">
      <table mat-table [dataSource]="featuredItems">
        <ng-container matColumnDef="item_id">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">Image</th>
          <td mat-cell *matCellDef="let row" data-label="Image" [ngClass]="'customWidthClass imageclass'">
            <a [routerLink]="">
              <img *ngIf="row.imageurl" class="item-image" [src]="row.imageurl" alt="image">
              <img *ngIf="!row.imageurl" class="item-image" src="../../assets/images/no_image.png" alt="no image">
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="item_name">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">Name</th>
          <td mat-cell *matCellDef="let row" data-label="itemname" [ngClass]="'customWidthClass'">{{row.item_name}}</td>
        </ng-container>
        <ng-container matColumnDef="mrp/s_rate">
          <th mat-header-cell *matHeaderCellDef>MRP/Sell Rate</th>
          <td mat-cell *matCellDef="let row" data-label="mrpsellrate">{{row.mrp}}/{{row.s_rate}}</td>
        </ng-container>
        <!-- <ng-container matColumnDef="seq_no">
        <th mat-header-cell *matHeaderCellDef>Seq No</th>
        <td mat-cell *matCellDef="let row" data-label="seqno">
          <input matInput placeholder="Seq No" [(ngModel)]="row.seq_no" name="seqno" required>
        </td>
      </ng-container> -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: end;">Actions</th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="actions">
            <button mat-icon-button color="primary" (click)="shiftrow('up', i)"
              [disabled]="i === 0"><mat-icon>arrow_upward</mat-icon></button>
            <button mat-icon-button color="primary" (click)="shiftrow('down', i)"
              [disabled]="i === featuredItems.data.length - 1"><mat-icon>arrow_downward</mat-icon></button>
            <button mat-icon-button color="warn" (click)="deleteClick(row)"><mat-icon>delete_outline</mat-icon></button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn mat-raised-button" (click)="addItems()">Add Items</button>
    <button class="btn-primary mat-raised-button" (click)="save()">Save</button>
  </mat-dialog-actions>
</div>