<div class="dialog-title" mat-dialog-title>
  <h3 mat-dialog-title class="color-h3">
    <span> Item Variant List</span>
  </h3>
</div>
<div class="table-details">
  <div class="table-items" style="overflow-y: scroll">
    <table mat-table [dataSource]="itemVarArr" matSort>
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="'customWidthClass'"
          mat-sort-header
        >
          ID
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          data-label="id"
          [ngClass]="'customWidthClass imageclass'"
        >
          {{ row.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="item_id">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="'customWidthClass'"
          mat-sort-header
        >
          ITEM_ID
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          data-label="item_id"
          [ngClass]="'customWidthClass'"
        >
          {{ row.item_id }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="sub_cat_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Category Name</th>
            <td mat-cell *matCellDef="let row" data-label="Name">{{row.sub_cat_name}}
              <span *ngIf="!row.active" class="badge badge-danger">inactive</span>
            </td>
          </ng-container> -->
      <ng-container matColumnDef="parent_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PARENT_ID</th>
        <td mat-cell *matCellDef="let row" data-label="parent_id">
          {{ row.parent_id }}
          <!-- <span *ngIf="!row.active" class="badge badge-danger"
                >inactive</span
              > -->
        </td>
      </ng-container>

      <ng-container matColumnDef="default_rate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DEFAULT RATE</th>
        <td mat-cell *matCellDef="let row" data-label="default_rate">
          {{ row.price }}
        </td>
      </ng-container>
      <ng-container matColumnDef="s_rate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S_Rate</th>
        <td mat-cell *matCellDef="let row" data-label="Sell Rate">
          {{ row.s_rate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ACTION</th>
        <td mat-cell *matCellDef="let row" data-label="actions">
          <mat-slide-toggle
            [ngModel]="row.wera_active"
            color="primary"
            (ngModelChange)="updateItemWeraActive($event, row)"
            *ngIf="checkEnableWeraFlag"
          >
          </mat-slide-toggle>
          <button mat-icon-button (click)="editClick(row)">
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="DeleteItemPriceListId(row)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'table-danger': row.active == false }"
      ></tr>
    </table>
  </div>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="btn" type="button" (click)="onClose()">
      Close
    </button>

    <button
      mat-raised-button
      class="btn-primary"
      type="button"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </mat-dialog-actions>
</div>
