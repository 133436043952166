import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-reorder-stock',
  templateUrl: './reorder-stock.component.html',
  styleUrls: ['./reorder-stock.component.css']
})
export class ReorderStockComponent implements OnInit {

  constructor(private apiService:DataService) { }

  ngOnInit() {
    this.getReorderCount();
  }
  ReorderdoCount:any;

  getReorderCount(){   

    this.apiService.getReorderList().subscribe((res) =>{
    
      this.ReorderdoCount = res;
     
       
    });
  }
}
