import { Injectable } from "@angular/core";
//import { Workbook } from 'exceljs';

import * as fs from "file-saver";
// const Workbook =require('exceljs');
// import * as ExcelJS from 'exceljs'
const ExcelJS = require("exceljs");

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor() {}
  generateExcel(
    file_name,
    title,
    header,
    subheader,
    data,
    columnWidth,
    numFmt
  ) {
    // console.log("export start")
    //Excel Title, Header, Data

    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();

    // let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Day Book");
    let topRow = worksheet.addRow([]);
    topRow.font = {
      name: "Arial",
      family: 4,
      size: 12,
      underline: false,
      bold: false,
    };

    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    worksheet.mergeCells("A2:F2");
    titleRow.font = {
      name: "Comic Sans MS",
      family: 4,
      size: 16,
      underline: "double",
      bold: true,
    };
    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    let subTitleRow = worksheet.addRow([subheader]);
    worksheet.mergeCells("A3:F3");
    subTitleRow.font = {
      name: "Comic Sans MS",
      family: 4,
      size: 12,
      underline: false,
      bold: false,
    };
    worksheet.getCell("A3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: "Arial Black",
      family: 4,
      size: 12,
      underline: false,
      bold: true,
    };

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    // Add Data and Conditional Formatting
    data.forEach((d) => {
      let row = worksheet.addRow(d);
      row.font = {
        name: "Arial",
        family: 4,
        size: 12,
        underline: false,
        bold: false,
      };
    });
    columnWidth.forEach((col) => {
      worksheet.getColumn(col.column).width = col.width;
    });
    numFmt.forEach((nf) => {
      worksheet.getColumn(nf.column).numFmt = nf.numFmt;
    });

    // worksheet.getColumn(5).numFmt = '$#,##0.00';
    worksheet.addRow([]);
    // console.log("export complete")
    //Generate Excel File with given name
    workbook.xlsx
      .writeBuffer()
      .then((data) => {
        // console.log("downloading start");
        // console.log(data);
        // let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // fs.saveAs(blob, file_name+'.xlsx');

        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name + new Date().toLocaleDateString() + ".xlsx";
        link.click();
        window.URL.revokeObjectURL(link.href);
        // console.log("downloading completed");
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log("export downloaded")
  }

  generateCSV(file_name, header, data) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add header row
    csvContent += header.join(",") + "\n";

    // Add data rows
    data.forEach((rowData) => {
      csvContent += rowData.join(",") + "\n";
    });

    // Create CSV file
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", file_name + ".csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  }

  generateItemsCSV(file_name, header, data) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add header row
    csvContent += header.join(",") + "\n";

    // Add data rows
    data.forEach((rowData) => {
      csvContent += rowData.join(",") + "\n";
    });

    // Create CSV file
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", file_name + ".csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  }

  generateCSVar(file_name, header, data) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add header row
    csvContent += header.join(",") + "\n";

    // Add data rows
    data.forEach((rowData) => {
      csvContent += rowData.join(",") + "\n";
    });

    // Create CSV file
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", file_name + ".csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  }
}
