import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatAutocompleteSelectedEvent,
  MatTabChangeEvent,
  MatDialog,
} from "@angular/material";
import { NotifierService } from "angular-notifier";
import { map, startWith, switchMap } from "rxjs/operators";
import { DataService } from "../../data.service";
import { Observable } from "rxjs/internal/Observable";
import { FormControl } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { MatTabsModule } from "@angular/material/tabs";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AddCategoryComponent } from "../../category/add-category/add-category.component";
import { AddUnitComponent } from "../../units/add-unit/add-unit.component";
import { ConsumableModel, OptionModel, VariantModel } from "../../data.modal";
import { take } from "rxjs/operators";
import { of } from "rxjs";
import { AddEditSubCategoryComponent } from "src/app/sub-category/add-edit-sub-category/add-edit-sub-category.component";
import { AddKitchenComponent } from "src/app/kitchen/add-kitchen/add-kitchen.component";

@Component({
  selector: "app-add-item",
  templateUrl: "./add-item.component.html",
  styleUrls: ["./add-item.component.css"],
})
export class AddItemComponent implements OnInit {
  formLocation: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddItemComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currency = environment.currency;
  }
  @Output() onItemAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMatDialogCustomTabClicked: EventEmitter<any> =
    new EventEmitter<any>();

  @ViewChild("f") f;
  @ViewChild("itemTabGroup") itemTabGroup;
  checkEnableWeraFlag: boolean;

  ngOnInit() {
    this.load();
  }
  selectedIndex: number = 0;
  model: any = {
    item_type: "p",
    tax_type: "e",
    locationob: [],
    locationServiceFlag: false,
    tax_mode: "GST",
  };
  modelLoc: any = {};
  currency: any;
  catObj: any;
  unitObj: any;
  kitchenObj: any;
  result_data: any = {};
  locObj: any;
  locObjob: any;
  mapArr: any;
  TotalOb: any = 0;
  isLocation: boolean = false;
  locationServiceFlag: boolean = false;
  locationControl = new FormControl();
  category = new FormControl();
  unit = new FormControl();
  kitchen = new FormControl();
  categories: any;
  units: any;
  kitchens: any;
  consumeitem: any[] = [];
  Units: any[] = [];
  filteredItems: Observable<string[]>;
  alertMessage: string = "";
  showPopup = false;
  filteredOptions: Observable<string[]>;
  consumableControl = new FormControl();
  optionArray: OptionModel[] = [];
  variantArray: VariantModel[] = [];
  consumableItem: ConsumableModel[] = [];
  resultData: any;
  subcatObj: any;
  subcategories: any;
  selectedCategory: any;
  filterCategory: any;
  subcategory = new FormControl();
  showConsumableinVariant: boolean = false;
  consumableData: any = [];
  checkedIndices: boolean[] = [];
  checkedIndices1: boolean[] = [];
  filterItem1: any = "restaurant_menu_items";

  itemTypes: any = [
    { name: "Product", value: "p" },
    { name: "Service", value: "s" },
  ];
  taxTypes: any = [
    { name: "Exclusive", value: "e" },
    { name: "Inclusive", value: "i" },
  ];

  checkEnableResturantFlag: boolean = false;

  location_filter(value) {
    const filterValue = value.toLowerCase();
    return this.locObj.filter((location) =>
      location.location_name.toLowerCase().includes(filterValue)
    );
  }

  displayLocationFn(location) {
    return location ? location.location_name : "";
  }
  searchLocation() {
    this.filteredOptions = this.locationControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.location_filter(value))
    );
  }

  locationSelection(event: MatAutocompleteSelectedEvent) {
    this.model.location_id = event.option.value
      ? event.option.value.location_id
      : undefined;
  }
  load() {
    this.apiService.getUnitList().subscribe((result: any) => {
      this.Units = result;
    });

    setTimeout(() => {
      this.apiService
        .getResultData()
        .pipe(take(1))
        .subscribe((data: any) => {
          if (data) {
            this.consumableItem = data.map((item: any) => ({
              ...item,
              consumableControl: this.fb.control(item.consumable_item_id),
            }));
          }
        });
    }, 0);
    this.apiService.getConsumableList().subscribe((result: any) => {
      if (result) {
        result.map((item: any) => {
          console.log("this.consumeitem---", item);
          this.consumeitem.push(item);
          this.filterItem();
        });
      }
    });
    this.f.submitted = true;
    this.apiService.getLocationSeviceGet().subscribe(
      (result: any) => {
        this.locationServiceFlag = result;
      },
      (result: any) => {
        this.notifier.notify("error", "unable to load Locations list");
      }
    );
    this.apiService.getUnitList().subscribe(
      (result: any) => {
        this.unitObj = result;
      },
      (result: any) => {
        this.notifier.notify("error", "unable to load Unit list");
      }
    );
    // Get kitchen list
    this.apiService.getKitchenList().subscribe(
      (result: any) => {
        this.kitchenObj = result;
      },
      (result: any) => {
        this.notifier.notify("error", "unable to load Kitchen list");
      }
    );
    this.apiService.getCategoryList().subscribe(
      (result: any) => {
        this.catObj = result;
        console.log("this.catObj", this.catObj);
      },
      (result: any) => {
        this.notifier.notify("error", "unable to load Category list");
      }
    );

    this.apiService.getLocationList().subscribe(
      (result: any) => {
        this.locObj = result;
        if (this.data && this.data != "fromTransaction") {
          this.apiService.getLocationByObList(this.data.item_id).subscribe(
            (result: any) => {
              this.locObjob = result;
              console.log("this.locObjon", this.locObjob);

              if (this.locObjob.length > 0) {
                this.locObj.forEach((location) => {
                  location.ob = 0;
                  this.locObjob.forEach((obValue) => {
                    if (
                      parseInt(location.id) == parseInt(obValue.location_id)
                    ) {
                      location.ob = obValue.ob;
                    }
                  });
                });

                this.locObjob.forEach((loclocation) => {
                  this.TotalOb =
                    parseInt(this.TotalOb) + parseInt(loclocation.ob);
                });
                // console.log(this.TotalOb);
                this.model.ob = this.TotalOb;
              } else {
                this.locObj.forEach((location) => {
                  location.ob = 0;
                  this.TotalOb = 0;
                });
              }
            },
            (result: any) => {
              this.notifier.notify("error", "unable to load Locations list");
            }
          );
        } else {
          this.locObj.forEach((location) => {
            location.ob = 0;
            this.TotalOb = 0;
          });
        }
        this.apiService.getSubCategorylistofList().subscribe(
          (result: any) => {
            this.subcatObj = result;
          },
          (result: any) => {
            this.notifier.notify("error", "unable to load Category list");
            console.log("under Load Category");
          }
        );

        this.searchLocation();
        if (this.data && this.data != "fromTransaction") {
          this.apiService.getItem(this.data.item_id).subscribe(
            (result: any) => {
              console.log("getitemsresult---------", result);
              this.model = result;
              console.log("this.mode--", this.model);
              this.optionArray = result.options;
              this.variantArray = result.variant_groups;
              // console.log("this.variantArray---", this.variantArray);

              var indexofLocation = this.locObj.findIndex(
                (i) => i.id === result.location_id
              );
              this.locationControl.setValue(this.locObj[indexofLocation]);

              var indexofCat = this.catObj.findIndex(
                (i) => i.category_id === result.category_id
              );
              this.category.setValue(this.catObj[indexofCat]);

              var indexofSubCat = this.subcatObj.findIndex(
                (i) => i.sub_category_id === result.sub_category_id
              );
              this.subcategory.setValue(this.subcatObj[indexofSubCat]);

              var indexofUnit = this.unitObj.findIndex(
                (i) => i.unit_id === result.unit_id
              );
              this.unit.setValue(this.unitObj[indexofUnit]);

              //set kitchen
              var indexofKitchen = this.kitchenObj.findIndex(
                (i) => i.kitchen_id === result.kitchen_id
              );
              console.log(
                "this.kitchenObj[indexofKitchen]--",
                this.kitchenObj[indexofKitchen]
              );
              this.kitchen.setValue(this.kitchenObj[indexofKitchen]);
              this.model.ob = this.TotalOb;
            },
            (result: any) => {
              this.notifier.notify("error", "unable to load data");
            }
          );
        } else {
          this.model.ob = this.TotalOb;
        }
      },
      (result: any) => {
        this.notifier.notify("error", "unable to load Locations list");
      }
    );

    this.apiService.getAccountMapList().subscribe(
      (result: any) => {
        this.mapArr = result;
        this.findAccSetting();
      },
      (result: any) => {}
    );

    /* Getting restaurant pos setting */
    this.apiService.getEnableResturantPos().subscribe(
      (result: any) => {
        this.checkEnableResturantFlag = result;
        // console.log(this.checkEnableResturantFlag);
      },
      (result: any) => {}
    );

    this.apiService.getWeraEnableStatus().subscribe(
      (result: any) => {
        this.checkEnableWeraFlag = result;
        // console.log("checkEnableWeraFlag--", this.checkEnableWeraFlag);
      },
      (result: any) => {}
    );
  }

  findAccSetting() {
    var indexOfLoc = this.mapArr.findIndex((i) => i.key === "Enable_Location");
    if (this.mapArr[indexOfLoc].checkbox_value) this.isLocation = true;
  }
  async onSubmit() {
    console.log("this.model---", this.model);
    if (!this.consumableItem || this.consumableItem.length === 0) {
      console.log("Consumable items are missing or empty.");
    }
    // console.log("this.model--------------", this.model);
    // console.log("this.variant--------------", this.variantArray);
    const modelDataToSend = { ...this.model };
    modelDataToSend.consumable = this.consumableItem.map((consumableItem) => {
      const { consumableControl, ...consumableData } = consumableItem;
      return consumableData;
    });

    this.model.locationServiceFlag = this.locationServiceFlag;
    this.model.locationob = this.locObj;
    modelDataToSend.locationServiceFlag = this.locationServiceFlag;
    modelDataToSend.locationob = this.locObj;

    // const modelDataToSendCopy = cloneDeep(modelDataToSend);
    const modelDataToSendCopy = JSON.parse(JSON.stringify(modelDataToSend));
    modelDataToSendCopy.consumable.forEach((consumableItem) => {
      if (typeof consumableItem.consumable_item_id === "string") {
        const selectedCategory = this.consumeitem.find(
          (item) => item.item_name === consumableItem.consumable_item_id
        );
        if (selectedCategory) {
          consumableItem.consumable_item_id = selectedCategory.item_id;
        }
      }
    });

    this.model.locationServiceFlag = this.locationServiceFlag;
    this.model.locationob = this.locObj;
    this.model.consumable = this.consumableItem;
    modelDataToSendCopy.variant_groups = this.variantArray;
    this.model.variant_groups = this.variantArray;

    if (this.data && this.data != "fromTransaction") {
      if (modelDataToSendCopy.consumable.length) {
        this.alertMessage = "";
        for (let field of modelDataToSendCopy.consumable) {
          if (!field.consumable_item_id) {
            this.alertMessage = "Please Fill the valid Consumable Item";
            break;
          }
          if (!field.qty) {
            this.alertMessage = "Please Fill the valid Quantity";
            break;
          }
          if (!field.unit_id) {
            this.alertMessage = "Please Fill the valid Unit";
            break;
          }
        }
      }
      if (this.alertMessage) {
        this.notifier.notify("error", this.alertMessage);
        this.alertMessage = "";
      } else {
        (await modelDataToSendCopy.variant_groups.length) > 0 &&
          modelDataToSendCopy.variant_groups[0].variants.map((e) => {
            if (e.consumableItems.length > 0) {
              e.consumableItems.map((f) => {
                f.variant_id = e.id;
                f.is_variant = true;
                modelDataToSendCopy.consumable.push(f);
              });
            }
          });
        console.log("finalmodelDataToSendCopy---", modelDataToSendCopy);

        this.apiService
          .editItem(this.model.item_id, modelDataToSendCopy)
          .subscribe(
            (result: any) => {
              if (result.success) {
                this.result_data = { status: "Edited", data: result.data };
                this.dialogRef.close(this.result_data);
                this.notifier.notify("success", "Item updated successfully");
              } else {
                this.notifier.notify("error", result.message);
              }
            },
            (result: any) => {
              this.notifier.notify("error", "unable to update Item details");
            }
          );
      }
    } else {
      /* let valid = true;
      this.optionArray.forEach(option => {
        if (option.childOptions && option.childOptions.length > 0) {
          option.childOptions.forEach(childOptionModel => {
            if (childOptionModel.title === '') {
              this.notifier.notify('error', 'Title can\'t be empty.');
              valid = false;
            }
          })
        } else {
          if (option.title === '') {
            this.notifier.notify('error', 'Title can\'t be empty.');
            valid = false;
          } 
        }
      }); */

      if (!this.validateOptionArray(this.optionArray)) {
        this.notifier.notify("error", "Title can't be empty");
        return;
      }
      if (!this.validateVariantArray(this.variantArray)) {
        this.notifier.notify("error", "Title can't be empty");
        return;
      }
      // console.log("this.variantArray-------", this.variantArray);
      modelDataToSend.options = this.optionArray;
      this.model.options = this.optionArray;
      modelDataToSend.variant_groups = this.variantArray;
      this.model.variant_groups = this.variantArray;
      this.model.consumable = this.consumableItem;

      if (modelDataToSendCopy.consumable.length) {
        this.alertMessage = "";
        for (let field of modelDataToSendCopy.consumable) {
          if (!field.consumable_item_id) {
            this.alertMessage = "Please select the valid Consumable Item";
            break;
          }
          if (!field.qty) {
            this.alertMessage = "Please add the Quantity";
            break;
          }
          if (!field.unit_id) {
            this.alertMessage = "Please select the Unit";
            break;
          }
        }
      }
      if (this.alertMessage) {
        this.notifier.notify("error", this.alertMessage);
      } else {
        this.apiService.addItem(modelDataToSendCopy).subscribe(
          (result: any) => {
            if (result.success) {
              this.result_data = { status: "Added", data: result.data };
              if (this.data == "fromTransaction") {
                this.dialogRef.close(this.result_data);
              } else {
                this.onItemAdd.emit(this.result_data);
                this.model = { item_type: "p", tax_type: "e" };
                this.locObj.forEach((location) => {
                  location.ob = 0;
                });
                this.selectedIndex = 0;
                this.optionArray = [];
                this.variantArray = [];
              }

              this.notifier.notify("success", "New Item added successfully");
            } else {
              this.notifier.notify("error", result.message);
            }
          },
          (result: any) => {
            this.notifier.notify("error", "unable to add new Item");
          }
        );
      }
    }
  }

  validateOptionArray(list) {
    let valid = true;
    for (let item of list) {
      valid = valid && item.title;
      if (item.childOptions && item.childOptions.length > 0) {
        valid =
          valid && item.title && this.validateOptionArray(item.childOptions);
      }
    }
    return valid;
  }
  validateVariantArray(list) {
    let valid = true;
    for (let item of list) {
      valid = valid && item.title;
      if (item.variants && item.variants.length > 0) {
        valid = valid && item.title && this.validateVariantArray(item.variants);
      }
    }
    return valid;
  }

  close() {
    this.dialogRef.close(this.result_data);
  }
  totalSumOb() {
    this.TotalOb = 0;
    this.locObj.forEach((location) => {
      location.ob = location.ob ? location.ob : 0;
      this.TotalOb = parseInt(this.TotalOb) + parseInt(location.ob);
    });
    this.model.ob = this.TotalOb;
  }

  categoryonfocus(e) {
    this.categories = this.catObj;
  }
  unitonfocus(e) {
    this.units = this.unitObj;
  }

  // categoryselection(e) {
  //   if (e.option.value != 0) {
  //     this.model.category_id = e.option.value.category_id;
  //   }

  // }
  unitselection(e) {
    if (e.option.value != 0) {
      this.model.unit_id = e.option.value.unit_id;
    }
  }

  // displaycategory(category) {
  //   return category ? category.category_name : '';
  // }
  displayunit(unit) {
    return unit ? unit.unit_name : "";
  }
  searchcategory(event) {
    if (
      event.key != "ArrowDown" &&
      event.key != "ArrowUp" &&
      event.key != "Enter"
    ) {
      if (this.category.value.length >= 1) {
        this.categories = this.catObj.filter((category) =>
          category.category_name.toLowerCase().includes(this.category.value)
        );
      } else {
        this.categories = this.catObj;
      }
    }
  }
  searchunit(event) {
    if (
      event.key != "ArrowDown" &&
      event.key != "ArrowUp" &&
      event.key != "Enter"
    ) {
      if (this.unit.value.length >= 1) {
        this.units = this.unitObj.filter((unit) =>
          unit.unit_name.toLowerCase().includes(this.unit.value)
        );
      } else {
        this.units = this.unitObj;
      }
    }
  }

  onAddCategoryClick() {
    this.dialog
      .open(AddCategoryComponent, {
        width: "500px",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.status == "Added") {
          this.apiService.getCategoryList().subscribe(
            (result: any) => {
              this.catObj = result;
              var categoryIndex = this.catObj.findIndex(
                (category) => category.category_code == res.category_code
              );
              this.category.setValue(this.catObj[categoryIndex]);
              this.model.category_id = this.catObj[categoryIndex].category_id;
            },
            (result: any) => {
              this.notifier.notify("error", "unable to load Category list");
            }
          );
        }
      });
  }
  onAddUnitClick() {
    this.dialog
      .open(AddUnitComponent, {
        width: "500px",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.status == "Added") {
          this.apiService.getUnitList().subscribe(
            (result: any) => {
              this.unitObj = result;
              var unitIndex = this.unitObj.findIndex(
                (unit) => unit.unit_code == res.unit_code
              );
              this.unit.setValue(this.unitObj[unitIndex]);
              this.model.unit_id = this.unitObj[unitIndex].unit_id;
            },
            (result: any) => {
              this.notifier.notify("error", "unable to load unit list");
            }
          );
        }
      });
  }

  onTabChanged(tabIndexObject) {
    this.onMatDialogCustomTabClicked.emit(tabIndexObject.tab.textLabel);
  }

  addOptionModelDataToArray(parentObjectIndex = null) {
    console.log("parentObjectIndex", parentObjectIndex);
    let option: OptionModel = {
      title: "",
      notes: "",
      s_input_type: 1,
      qty_selection_req: false,
      is_veg: false,
      in_stock: false,
      is_default: false,
      default_value: 1,
      amount: 0,
      taxslab: 0,
      childOptions: [],
    };
    if (parentObjectIndex !== null) {
      this.optionArray[parentObjectIndex].childOptions.push(option);
    } else {
      this.optionArray.push(option);
    }
  }

  deleteOptionClick(parentObjectIndex, childObjecIndex = null) {
    if (parentObjectIndex !== null && childObjecIndex !== null) {
      this.optionArray[parentObjectIndex].childOptions.splice(
        childObjecIndex,
        1
      );
    }
    if (parentObjectIndex !== null && childObjecIndex === null) {
      this.optionArray.splice(parentObjectIndex, 1);
    }
  }

  addVariantModelDataToArray(parentObjectIndex = null) {
    // console.log(" this.variantArray-----", this.variantArray);
    let option: ConsumableModel = {
      item_id: this.model.item_id,
      consumable_item_id: "",
      qty: 0,
      unit_id: "",
      consumableControl: new FormControl(),
    };
    let variant: VariantModel = {
      title: "",
      notes: "",
      s_input_type: 1,
      qty_selection_req: false,
      is_veg: false,
      in_stock: false,
      is_default: false,
      default_value: 1,
      price: 0,
      taxslab: 0,
      consumableItems: [],
      variants: [],
    };
    if (parentObjectIndex !== null) {
      this.variantArray[parentObjectIndex].variants.push(variant);
      // console.log("parentObjectIndex !== null", variant);
    } else {
      // console.log("parentObjectIndex else---", variant);
      this.variantArray.push(variant);
    }
  }

  deleteVariantClick(parentObjectIndex, childObjecIndex = null) {
    if (parentObjectIndex !== null && childObjecIndex !== null) {
      this.variantArray[parentObjectIndex].variants.splice(childObjecIndex, 1);
    }
    if (parentObjectIndex !== null && childObjecIndex === null) {
      this.variantArray.splice(parentObjectIndex, 1);
    }
  }

  addConsumableData() {
    let option: ConsumableModel = {
      item_id: this.model.item_id,
      consumable_item_id: "",
      qty: 0,
      unit_id: "",
      consumableControl: new FormControl(),
    };
    this.consumableItem.push(option);
  }
  removeConsumableItem(index: number) {
    this.consumableItem.splice(index, 1);
  }

  filterConsumableItems(value: string): Observable<string[]> {
    if (value && value.trim() !== "") {
      const filterValue = value.toLowerCase();
      return of(
        this.consumeitem
          .filter((item) => item.item_name.toLowerCase().includes(filterValue))
          .map((item) => item.item_name)
      );
    } else {
      return of(this.consumeitem.map((item) => item.item_name));
    }
  }

  displayConsumable(item: string): string {
    return item ? item : "";
  }

  consumableSelection(event: MatAutocompleteSelectedEvent, index: number) {
    const selectedCategoryName = event.option.value;
    const selectedCategory = this.consumeitem.find(
      (item) => item.item_name === selectedCategoryName
    );

    if (selectedCategory) {
      this.consumableItem[index].consumableControl.setValue(
        selectedCategoryName
      ); // Set category_name for display
      this.consumableItem[index].consumable_item_id = selectedCategory.item_id;
    }
  }

  filterItem() {
    this.filteredItems = this.consumableControl.valueChanges.pipe(
      startWith(""),
      switchMap((value) => this.filterConsumableItems(value))
    );
  }

  categoryselection(e: MatAutocompleteSelectedEvent): void {
    const selectedCategory = e.option.value;
    if (e.option.value != 0) {
      this.model.category_id = e.option.value.category_id;
      if (selectedCategory && selectedCategory.category_id) {
        this.selectedCategory = selectedCategory;
        this.subcategory.reset(); // Reset the subcategory control
        this.filterSubcategoriesByCategory(selectedCategory.category_id);
      }
    }
  }
  displaycategory(category) {
    if (category && category.category_id) {
      this.filterCategory = category.category_id;
    }
    return category ? category.category_name : "";
  }
  searchsubcategory(event) {
    if (
      event.key != "ArrowDown" &&
      event.key != "ArrowUp" &&
      event.key != "Enter"
    ) {
      if (this.subcategory.value.length >= 1) {
        this.subcategories = this.subcatObj.filter((subcategory) =>
          subcategory.name.toLowerCase().includes(this.subcategory.value)
        );
        console.log("searchsubcategory", this.categories);
      } else {
        this.subcategories = this.subcatObj;
      }
    }
  }
  displaysubcategory(subcategory) {
    console.log("subcategory", subcategory);
    return subcategory ? subcategory.name : "";
  }
  filterSubcategoriesByCategory(categoryId) {
    if (categoryId) {
      this.subcategories = this.subcatObj.filter(
        (subcat) => subcat.cat_id === categoryId
      );
    } else if (this.data.category_id) {
      this.subcategories = this.subcatObj.filter(
        (subcat) => subcat.cat_id === this.data.category_id
      );
    } else {
      this.subcategories = [];
    }
  }
  subcategoryonfocus(e) {
    this.filterSubcategoriesByCategory(
      this.selectedCategory ? this.selectedCategory.category_id : null
    );
  }
  subcategoryselection(e) {
    if (e.option.value !== 0) {
      this.model.sub_category_id = e.option.value.sub_category_id;
    }
  }
  onAddSubCategoryClick() {
    const dialogRef = this.dialog.open(AddEditSubCategoryComponent, {
      width: "500px",
      data: { category_id: this.model.category_id }, // Pass the sub_category_id to the dialog
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.status === "Added") {
        this.apiService.getSubCategoryList(res.id).subscribe(
          (result: any) => {
            this.subcatObj = result;
          },
          (error: any) => {
            this.notifier.notify("error", "Unable to load Subcategory list");
          }
        );
      }
    });
  }

  updateOptionWeraActive($event: any, data: any) {
    // console.log("data----", $event, data);
    const addOns = {
      id: data.id,
      wera_active: $event,
    };
    this.apiService.weraAddOnOnOFF(addOns).subscribe(
      (result: any) => {
        if (result.success) {
          this.notifier.notify("success", "Add-Ons updated successfully");
        } else {
          this.notifier.notify("error", result.message);
        }
      },
      (result: any) => {
        this.notifier.notify("error", result);
      }
    );
  }

  updateVeriantsWeraActive($event: any, data: any) {
    console.log("data----", data);
    // console.log("data----", $event, data);

    const addOns = {
      item_id: data.item_id,
      wera_active: $event,
    };
    this.apiService.weraSizeOnOff(addOns).subscribe(
      (result: any) => {
        if (result.success) {
          this.notifier.notify("success", "Variant updated successfully");
        } else {
          this.notifier.notify("error", result.message);
        }
      },
      (result: any) => {
        this.notifier.notify("error", result);
      }
    );
  }

  showConsumable(index: number) {
    this.checkedIndices[index] = !this.checkedIndices[index];
    this.checkedIndices1[index] = !this.checkedIndices1[index];
    // console.log(" this.variantArray--", this.variantArray);

    // this.consumableData = this.consumableItem;
  }
  itemObj: any[];
  itemControl = new FormControl();
  @ViewChild("sitm") sitm: ElementRef;
  //search items
  _filter(value) {
    const filterValue = typeof value != "object" ? value.toLowerCase() : value;
    return this.itemObj.filter(
      (item) =>
        item.item_code.toLowerCase().includes(filterValue) ||
        item.item_name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(Item) {
    // console.log("displayFn-------------------------")
    return Item ? Item.item_name : "";
  }

  search(event) {
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl.value.length > 1) {
        this.apiService
          .getCustomFilterItemList(
            this.itemControl.value,
            this.filterItem1,
            null
          )
          .subscribe(
            (result: any) => {
              this.itemObj = result;
              this.filteredOptions = this.itemControl.valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
              );
            },
            (result: any) => {}
          );
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      }
    }
  }
  itemSelection(
    event: MatAutocompleteSelectedEvent,
    parent_index,
    child_index
  ) {
    // console.log("itemSelection-----------------------------")
    // console.log("event.option.value--", event.option.value);
    // console.log("optionArray----", this.optionArray, parent_index, child_index);

    if (parent_index >= 0 && parent_index < this.optionArray.length) {
      const parentOption = this.optionArray[parent_index];
      if (child_index >= 0 && child_index < parentOption.childOptions.length) {
        parentOption.childOptions[child_index].title =
          event.option.value.item_name;
        parentOption.childOptions[child_index].notes = event.option.value.notes;
        parentOption.childOptions[child_index].s_input_type = 1;
        parentOption.childOptions[child_index].is_veg =
          event.option.value.is_veg;
        parentOption.childOptions[child_index].amount =
          event.option.value.s_rate;
        parentOption.childOptions[child_index].in_stock =
          event.option.value.in_stock;
        parentOption.childOptions[child_index].is_veg =
          event.option.value.is_veg;
        parentOption.childOptions[child_index].taxslab =
          event.option.value.taxslab;
        // console.log(
        //   "parentOption.childOptions[child_index]--",
        //   parentOption.childOptions[child_index]
        // );
        this.itemObj = [];
        this.itemControl.reset();
      } else {
        console.error("Child index out of bounds");
      }
    } else {
      console.error("Parent index out of bounds");
    }
  }

  onFocus(e) {
    this.apiService.getCustomItemList(this.filterItem1).subscribe(
      (result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      },
      (result: any) => {}
    );
  }

  onEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    if (event.target === this.sitm.nativeElement) {
      this.filteredOptions.subscribe((options) => {
        if (options.length === 1) {
          this.itemControl.setValue(options[0]);
          // this.onAddItem();
        }
      });
    }
    // this.onAddItem();
  }
  focusSetting() {
    // if (this.isAutoFocus) {
    //   this.qty1.nativeElement.focus();
    // }
  }

  // On focus on kitchen input
  kitchenonfocus(e) {
    this.kitchens = this.kitchenObj;
  }

  //kitchen tab
  searchkitchen(event) {
    if (
      event.key != "ArrowDown" &&
      event.key != "ArrowUp" &&
      event.key != "Enter"
    ) {
      if (this.kitchen.value.length >= 1) {
        this.kitchens = this.kitchenObj.filter((kitchen) =>
          kitchen.kitchen_name.toLowerCase().includes(this.kitchen.value)
        );
      } else {
        this.kitchens = this.kitchenObj;
      }
    }
  }

  //when select any kitchen from dropdown
  kitchenselection(e) {
    if (e.option.value != 0) {
      console.log(
        "this.model.kitchen_id = e.option.value.kitchen_id--",
        this.model.kitchen_id,
        e.option.value.kitchen_id
      );
      this.model.kitchen_id = e.option.value.kitchen_id;
    }
  }

  // after selection it will show in the tab
  displaykitchen(kitchen) {
    return kitchen ? kitchen.kitchen_name : "";
  }

  //add new kitchen
  onAddKitchenClick() {
    this.dialog
      .open(AddKitchenComponent, {
        width: "500px",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.status == "Added") {
          this.apiService.getKitchenList().subscribe(
            (result: any) => {
              this.kitchenObj = result;
              var kitchenIndex = this.kitchenObj.findIndex(
                (kitchen) => kitchen.kitchen_code == res.kitchen_code
              );
              this.kitchen.setValue(this.kitchenObj[kitchenIndex]);
              this.model.kitchen_id = this.kitchenObj[kitchenIndex].kitchen_id;
            },
            (result: any) => {
              this.notifier.notify("error", "unable to load kitchen list");
            }
          );
        }
      });
  }
}
