<h2 mat-dialog-title class="color-h3">Edit Map Settings</h2>
<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field color="accent" class="contract-full-width">
            <input matInput placeholder="latitude,longitude" [(ngModel)]="model.coordinates" name="coordinates"
              required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field color="accent" class="contract-full-width">
            <input matInput placeholder="delivery distance in km " [(ngModel)]="model.delivery_distance"
              name="delivery_distance" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">Close</button>
    <button type="submit" class="btn-primary mat-raised-button" (click)="onSubmit()"
      [disabled]="f.invalid">Save</button>
  </mat-dialog-actions>
</div>