<h2 *ngIf="!data" mat-dialog-title class="color-h3">Add New Tenent</h2>
<h2 *ngIf="data" mat-dialog-title class="color-h3">Edit Tenent</h2>
<div class="table-details">
<mat-dialog-content >
  <form name="form" #f="ngForm">
    <div class="row">
        <div class="col-md-4">
            <mat-form-field class="contract-full-width">
                <input matInput placeholder="Tenent Code:" [(ngModel)]="model.tenent_code" name="tenent_code" required>
                <mat-error>required</mat-error>
              </mat-form-field>
          </div>
          <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                  <input matInput placeholder="Database Name: same as tenent_code" [(ngModel)]="model.db_name" name="db_name" required>
                  <mat-error>required</mat-error>
                </mat-form-field>
          </div>
          <div class="col-md-4">
            <section class="form-section">
              <mat-checkbox class="form-margin" [(ngModel)]="model.is_composite" name="is_composite">Composite</mat-checkbox>
          </section>
          </div>
          <div *ngIf="!data" class="col-md-12">
              <mat-form-field class="contract-full-width">
                  <input matInput placeholder="Tenent Name:" [(ngModel)]="model.tenent_name" name="tenent_name" required>
                  <mat-error>required</mat-error>
                </mat-form-field>
          </div>
          <div *ngIf="data" class="col-md-6">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Tenent Name:" [(ngModel)]="model.tenent_name" name="tenent_name" required>
              <mat-error>required</mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="data" class="col-md-6">
            <section class="form-section">
              <mat-checkbox class="form-margin" [(ngModel)]="model.active" name="active">Active</mat-checkbox>
          </section>
          </div>
        <div class="col-md-4">
            <mat-form-field color="accent" class="contract-full-width">
              <input matInput placeholder="Address1:" [(ngModel)]="model.address_address1" name="address_address1" required>
              <mat-error>required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Address2:" [(ngModel)]="model.address_address2" name="address_address2" required>
              <mat-error>required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="City:" [(ngModel)]="model.address_city" name="address_city"required>
              <mat-error>required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <!-- <mat-form-field class="contract-full-width">
                <mat-select placeholder="State" name="address_state"  [(ngModel)]="model.address_state" >
                  <mat-option *ngFor="let state of statelist" [value]="state.value">
                    {{state.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

              <mat-form-field class="contract-full-width">
                <input type="text" placeholder="Select State" aria-label="address_state" matInput [formControl]="StateControl" [matAutocomplete]="auto"required>
                <mat-error>required</mat-error>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayState" autoActiveFirstOption
                (optionSelected)="StateSelection($event)">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>



          </div>
          <div class="col-md-4">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Country:" [(ngModel)]="model.address_country" name="address_country"required>
              <mat-error>required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Pin:" [(ngModel)]="model.address_pin" name="address_pin" required>
              <mat-error>required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
              <mat-form-field class="contract-full-width">
                <input matInput placeholder="Contact No:" [(ngModel)]="model.contact_no" name="contact_no">
              </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="contract-full-width">
                  <input matInput placeholder="Email Id:" [(ngModel)]="model.email_id" name="email_id">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                  <mat-form-field class="contract-full-width">
                    <input matInput placeholder="GSTIN:" [(ngModel)]="model.gstin" name="gstin">
                  </mat-form-field>
                </div>
    </div>




 </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button"(click)="close()">Close</button>
    <button type="submit" class="mat-raised-button btn-primary"(click)="onSubmit()" [disabled]="f.invalid">Save</button>
</mat-dialog-actions>
</div>
