<h2 *ngIf="!data" class="color-h3" mat-dialog-title>Add Customer</h2>
<h2 *ngIf="data" class="color-h3" mat-dialog-title>Edit Customer</h2>
<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Code:" [(ngModel)]="model.ledger_code" name="ledger_code" required>
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Name:" [(ngModel)]="model.ledger_name" name="ledger_name" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Company Name:" [(ngModel)]="model.company_name" name="company_name">
          </mat-form-field>

        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Contact Person:" [(ngModel)]="model.contact_person" name="contact_person">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Contact No.:" [(ngModel)]="model.contact_no" name="contact_no">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Email Id:" [(ngModel)]="model.email_id" name="email_id">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Opening Balance:" [(ngModel)]="model.ob" name="ob">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-select placeholder="OBDRCR:" [(ngModel)]="model.obdrcr" name="obdrcr">
              <mat-option *ngFor="let ob of obdrcr" [value]="ob.value">
                {{ob.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <mat-select placeholder="Customer Type:" [(ngModel)]="model.party_type" name="party_type" required>
              <mat-option *ngFor="let partyType of partyTypeObj" [value]="partyType.value">
                {{partyType.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Pan No.:" [(ngModel)]="model.pan_no" name="pan_no">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="GSTIN:" [(ngModel)]="model.gstin" name="gstin">
          </mat-form-field>
        </div>
      </div>
      <fieldset class="border p-2 form-fieldset">
        <legend class="w-auto">Address:</legend>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Address1:" [(ngModel)]="model.address_address1" name="address_address_1">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Address2:" [(ngModel)]="model.address_address2" name="address_address_2">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Street:" [(ngModel)]="model.address_street" name="address_street">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="City:" [(ngModel)]="model.address_city" name="address_city">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="contract-full-width">
              <input type="text" (keyup)="searchState1($event)" (focus)="StateonFocus($event)" placeholder="State"
                matInput [formControl]="stateControl" [matAutocomplete]="autoState" required>
              <mat-autocomplete #autoState="matAutocomplete" autoActiveFirstOption [displayWith]="displayStateFn"
                (optionSelected)="stateSelection($event)">
                <mat-option *ngFor="let option of filteredState | async" [value]="option">
                  {{option.state_name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </div>
          <div class="col-md-4">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Country:" [(ngModel)]="model.address_country" name="address_country">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="contract-full-width">
              <input matInput placeholder="Pin:" [(ngModel)]="model.address_pin" name="address_pin">
            </mat-form-field>
          </div>
        </div>
      </fieldset>
      <div class="row">
        <div class="col-md-2" *ngIf="data">
          <mat-checkbox class="form-margin" [(ngModel)]="model.active" name="active">Active</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-checkbox [(ngModel)]="model.customer_item_rate_applicable" name="customer_item_rate_applicable">Customer
            Specific Sale Rate</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-checkbox [(ngModel)]="model.use_qty_from_sale_history" name="use_qty_from_sale_history">Use Quantity From
            Sale History</mat-checkbox>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn mat-raised-button" (click)="close()">Close</button>
    <button class="btn-primary mat-raised-button" (click)="onSubmit()" [disabled]="f.invalid">Save</button>
  </mat-dialog-actions>
</div>