import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef,MatAutocompleteSelectedEvent } from "@angular/material";
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-add-edit-company',
  templateUrl: './add-edit-company.component.html',
  styleUrls: ['./add-edit-company.component.css']
})
export class AddEditCompanyComponent implements OnInit {
  @ViewChild('f') f;
  StateControl = new FormControl();
  constructor(private dialogRef: MatDialogRef<AddEditCompanyComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,

    @Inject(MAT_DIALOG_DATA) public data: any) { }
    filteredOptions: Observable<any[]>;
    tenent_id:any;
    model: any = {};
    statelist: any = [
      { value: 1, name: "Jammu & Kashmir" },
      { value: 2, name: "Himachal Pradesh" },
      { value: 3, name: "Punjab" },
      { value: 4, name: "Chandigarh" },
      { value: 5, name: "Uttarakhand" },
      { value: 6, name: "Haryana" },
      { value: 7, name: "Delhi" },
      { value: 8, name: "Rajasthan" },
      { value: 9, name: "Uttar Pradesh" },
      { value: 10, name: "Bihar" },
      { value: 11, name: "Sikkim" },
      { value: 12, name: "Arunachal Pradesh" },
      { value: 13, name: "Nagaland" },
      { value: 14, name: "Manipur" },
      { value: 15, name: "Mizoram" },
      { value: 16, name: "Tripura" },
      { value: 17, name: "Meghalaya" },
      { value: 18, name: "Assam" },
      { value: 19, name: "West Bengal" },
      { value: 20, name: "Jharkhand" },
      { value: 21, name: "Orissa" },
      { value: 22, name: "Chhattisgarh" },
      { value: 23, name: "Madhya Pradesh" },
      { value: 24, name: "Gujarat" },
      { value: 25, name: "Daman & Diu" },
      { value: 26, name: "Dadra & Nagar Haveli" },
      { value: 27, name: "Maharashtra" },
      { value: 28, name: "Andhra Pradesh" },
      { value: 29, name: "Karnataka" },
      { value: 30, name: "Goa" },
      { value: 31, name: "Lakshadweep" },
      { value: 32, name: "Kerala" },
      { value: 33, name: "Tamil Nadu" },
      { value: 34, name: "Puducherry" },
      { value: 35, name: "Andaman & Nicobar Islands" },
      { value: 36, name: "Telengana" },
      { value: 37, name: "Andrapradesh(New)" }
    ];

  ngOnInit() {
    this.tenent_id = localStorage.getItem('tenent_id');
    // console.log(this.tenent_id)
    this.filteredOptions = this.StateControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.statelist.slice())
    );

    this.f.submitted = true;
    this.load();
  }

  displayState(state?: any): string | undefined {
    return state ? state.name : undefined;
  }

  private _filter(name: string): any {
    const filterValue = name.toLowerCase();

    return this.statelist.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  StateSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
  this.model.address_state = event.option.value ? event.option.value.value : undefined;

    }
  }
  load() {
    this.statelist.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    })

    if (this.data) {
      this.apiService.getCompany(this.data.company_id)
        .subscribe((result: any) => {
          // console.log(result)
          this.model = result[0];
        }, (result: any) => {
          this.notifier.notify('error', 'unable to load data');
        });
    }
  }


  onSubmit() {
    if (this.data) {
      this.spinner.show();
      this.apiService.editCompany(this.model.company_id, this.model)
        .subscribe((result: any) => {
          this.spinner.hide();
          if(result.success){
            this.dialogRef.close('Edited');
          this.notifier.notify('success', 'Company updated successfully');
          }else{
            this.notifier.notify('error', result.message);
          }
        }, (result: any) => {
          this.spinner.hide();
          this.notifier.notify('error', 'unable to update data');
        });
    } else {
      this.spinner.show();
      this.apiService.addCompany(this.model, this.tenent_id)
        .subscribe((result: any) => {
          this.spinner.hide();
          if(result.success){
            this.dialogRef.close('Added');
          this.notifier.notify('success', 'Company created successfully');
          }else{
            this.notifier.notify('error', result.message);
          }
        }, (result: any) => {
          this.spinner.hide();
          this.notifier.notify('error', 'unable to add new Company');
        });
    }

  }

  close() {
    this.dialogRef.close();
  }
}

