
//import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private _router: Router, private apiService: DataService) {
  }

  menuObj: any;
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
    return this.apiService.getMenuPermissionList()
    .map((result: any) => {
      return true;
      // this.menuObj = result;
      // for (var i = 0; i < this.menuObj.length; i++) {
      //   if (state.url == "/" + this.menuObj[i].route) {
      //     return true;
      //   }
      // }
      // this._router.navigate(['/access-denied']);
      // return false
    }, (result: any) => {
      // this._router.navigate(['/access-denied']);
      // return false
    });
  }

}
