import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';

import { DataService } from '../data.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AddEditTenentComponent } from './add-edit-tenent/add-edit-tenent.component';
@Component({
  selector: 'app-tenent-management',
  templateUrl: './tenent-management.component.html',
  styleUrls: ['./tenent-management.component.css']
})
export class TenentManagementComponent implements OnInit {
  tenentObj: MatTableDataSource<any>;
  constructor(private apiService: DataService, public dialog: MatDialog, private notifier: NotifierService) {

  }
  displayedColumns = ['tenent_code', 'tenent_name', 'db_name', 'active', 'is_trial', 'allow_multicompany', 'actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit() {
    this.load();
  }

  load() {
    this.apiService.getTenentList()
      .subscribe((result: any) => {
        this.tenentObj = new MatTableDataSource(result);
        this.tenentObj.sort = this.sort;
        this.tenentObj.paginator = this.paginator;
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }

  deleteClick(row) {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Delete Selected Tenent Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteTenent(row.tenent_id)
            .subscribe((result: any) => {
              if (!result.success)
                this.notifier.notify('error', result.message);
              else
                this.notifier.notify('warning', 'tenent deleted successfully');
              this.apiService.getTenentList()
                .subscribe((result: any) => {
                  this.tenentObj = new MatTableDataSource(result);
                  this.tenentObj.sort = this.sort;
                  this.tenentObj.paginator = this.paginator;
                }, (result: any) => {
                });
            }, (result: any) => {
              this.notifier.notify('error', 'unable to delete data');
            });
        }
      });
  }
  addClick() {
    this.dialog.open(AddEditTenentComponent, {
      width: '650px',
    })
      .afterClosed().subscribe(result => {
        if (result == 'Added') {
          this.apiService.getTenentList()
            .subscribe((result: any) => {
              this.tenentObj = new MatTableDataSource(result);
              this.tenentObj.sort = this.sort;
              this.tenentObj.paginator = this.paginator;
            }, (result: any) => {
            });
        }
      });

  }
  editClick(row) {
    this.dialog.open(AddEditTenentComponent, {
      width: '650px',
      data: row
    })
      .afterClosed().subscribe(result => {
        if (result == 'Edited') {
          this.apiService.getTenentList()
            .subscribe((result: any) => {
              this.tenentObj = new MatTableDataSource(result);
              this.tenentObj.sort = this.sort;
              this.tenentObj.paginator = this.paginator;
            }, (result: any) => {
            });
        }
      });
  }

  updateIsTrial(event, data) {
    data.is_trial = event
    this.apiService.editTenent(data.tenent_id, data)
      .subscribe((result: any) => {
        this.notifier.notify('success', 'Tenent Updated Successfully');
      }, (result: any) => {
        this.notifier.notify('error', 'Unable To Update Data');
      });
  }
  updateAllowMultiCompany(event, data) {
    data.allow_multicompany = event
    this.apiService.editTenent(data.tenent_id, data)
      .subscribe((result: any) => {
        this.notifier.notify('success', 'Tenent Updated Successfully');
      }, (result: any) => {
        this.notifier.notify('error', 'Unable To Update Data');
      });

  }

}

