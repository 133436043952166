import { Component } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataService } from "../data.service";
import { isoStringToDate } from "@angular/common/src/i18n/format_date";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatAutocompleteSelectedEvent,
  MatCheckboxChange,
} from "@angular/material";
import { ChangeUserPassComponent } from "./change-user-pass/change-user-pass.component";
import * as FileSaver from "file-saver";
import { environment } from "../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { DBbackupComponent } from "../db-backup/db-backup.component";
import { NotifierService } from "angular-notifier";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { CompanyManagementComponent } from "../company-management/company-management.component";
import { SocketServiceService } from "../socket-service.service";
import { Howl, Howler } from "howler";
import {
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSidenavModule } from "@angular/material/sidenav";
import { CacheServiceService } from "../cache-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BooktableComponent } from "../rst-dashboard/booktable/booktable.component";
import { OrderConfirmationComponent } from "./order-confirmation/order-confirmation.component";
import { AutoAcceptDialogeComponent } from "./auto-accept-dialoge/auto-accept-dialoge.component";
@Component({
  selector: "app-app-nav",
  templateUrl: "./app-nav.component.html",
  styleUrls: ["./app-nav.component.css"],
})
export class AppNavComponent {
  websiteData:any;
  isApprovedChecked = false;
  isRejectedChecked = false;
  permanentMute = false;
  permanentApproved = false;
  permanentRejected = false;
  sound: Howl;
  isMuted: boolean = false;
  showDetails: boolean = false;
  helpDeskUrl: any;
  rightMenuOpen = false;
  isMenuOpen = true;
  contentMargin = 250;
  userName: any;
  width: any = window.innerWidth;
  is_trial: any;
  allow_multicompany: any;
  params1: any;
  params2: any;
  connection: any;
  is_connected: boolean = false;
  isDarkTheme: boolean = false;
  checkEnableResturantFlag: boolean = false;
  websiteInvoiceData: any;
  websiteInvoicecount: any;
  checkEnablewebsiteFlag: boolean = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  selectedSearchFieldValue: any;
  UnApprovedReservedTablesArr: any = [];
  unApprovedRequestCount: any = 0;
  requestCount: any = 0;
  currentUrl: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private apiService: DataService,
    private socketService: SocketServiceService,
    public dialog: MatDialog,
    private router: Router,
    private cacheService: CacheServiceService,
    private spinner: NgxSpinnerService
  ) {
    this.currency = environment.currency;
  }

  // toggleDetails(data, event: Event) {
  //   event.stopPropagation();
  //   data.show_details = !data.show_details;
  // }

  localset() {
    this.apiService.getInitialSetting().subscribe((result: any) => {
      if (result.Currency) {
        localStorage.setItem("currency", result.Currency);
      }
      if ("Website" in result) {
        localStorage.setItem("Website", result.Website);
      }
      if ("POS" in result) {
        localStorage.setItem("POSEnable", result.POS);
      }
      if ("company" in result) {
        localStorage.setItem("CompanyInfo", JSON.stringify(result));
      }
      if (result.menu.length) {
        localStorage.setItem("menuData", JSON.stringify(result.menu));
      } else {
        this.apiService.getMenuGroupList().subscribe((result: any) => {
          localStorage.setItem("menuData", JSON.stringify(result));
        });
      }
      if (result.menuItem.length) {
        localStorage.setItem("menuItemData", JSON.stringify(result.menuItem));
      } else {
        this.apiService.getMenuPermissionList().subscribe((result: any) => {
          localStorage.setItem("menuItemData", JSON.stringify(result));
        });
      }
      localStorage.setItem("isTransactionGSTslab", result.isTransactionGSTslab);
      localStorage.setItem("isEBillingEnable", result.isEBillingEnable);

      // if (result.websiteinvoce) {
      //   localStorage.setItem('websiteinvoce', JSON.stringify(result.websiteinvoce))
      // } else {
      //   const orderMode = "website"
      //   this.apiService.getWebsiteInvoice(orderMode).subscribe(
      //     (result: any) => {
      //       localStorage.setItem('websiteinvoce', JSON.stringify(result))

      //     },
      //   );
      // }
      setTimeout(() => {
        this.load();
        this.generate_token();
      }, 500);
    });
  }

  ngOnInit() {
    const groups = ["Entry", "Report", "Master", "Setting", "Transaction"];
    this.currentUrl = this.router.url;
    const parts = this.currentUrl.split("/"); // Split the path by '/'
    const entry = parts[1]; // Access the first element of the array
    groups.forEach((e) => {
      if (e === entry) {
        this.groupname = entry == "Entry" ? "Transaction" : entry;
      }
    });
    this.is_trial = localStorage.getItem("is_trial");
    this.allow_multicompany = localStorage.getItem("allow_multicompany");
    // console.log("is_trial---", this.is_trial)
    this.isMenuOpen = false;

    this.getNotApprovedKot().then((result: any) => {
      this.playNotificationSound();

      if (this.kotCount == 0) {
        this.rightMenuOpen = false;
      } else {
        this.rightMenuOpen = true;
      }
    });
    this.connectSocket();
    this.localset();

    /* Getting restaurant pos setting */
  }
  ngOnDestroy() {
    if (this.play_sound) clearInterval(this.play_sound);
    this.play_sound = undefined;

    this.connection.unsubscribe();
  }

  connectSocket() {
    this.socketService.emit("join", {
      company_id: localStorage.getItem("company_id"),
    });
    this.socketService.on("reconnect").subscribe((result: any) => {
      // console.log("socket reconnect to notifiaction");
      this.is_connected = true;
      this.getNotApprovedKot().then((result: any) => {
        this.playNotificationSound();
      });
    });
    this.socketService.on("connect").subscribe((result: any) => {
      this.is_connected = true;
    });
    this.socketService.on("disconnect").subscribe((result: any) => {
      this.is_connected = false;
    });
    this.connection = this.socketService
      .on("refresh")
      .subscribe((result: any) => {
        // console.log("socket called on notification");
        this.websiteInvoice();

        this.getNotApprovedKot().then((result: any) => {
          this.playNotificationSound();
        });
      });

    // Call socket connection for auto acccept order
    this.connection = this.socketService
      .on("autoAccept")
      .subscribe((result: any) => {
        // call api to accept order
        this.onAutoAccept(result);
        // call functions for popup
      });

    this.connection = this.socketService
      .on("websiteInvoice")
      .subscribe((result: any) => {
        console.log("socket called on notification websiteInvoice", result);
        // this.websiteInvoice(result)
        this.websiteInvoice()
          .then((result: any) => {
            this.playWebsiteNotificationSound();
          })
          .catch((error: any) => {
            // Handle any errors that occur during the Promise execution.
            console.error(error);
          });
      });
    this.connection = this.socketService
      .on("tableReservation")
      .subscribe((result: any) => {
        this.getResetvedTableDetails();
      });
    this.connection = this.socketService
      .on("updateAccountMap")
      .subscribe((result: any) => {
        // console.log("socket called on notification updateAccountMap");
        // this.websiteInvoice(result)
        // console.log("updateAccountMapresult---", result)
        this.websiteEnablestatus();
        // this.websiteInvoice();
      });
    if (this.socketService.checkConnectionStatus()) {
      this.is_connected = true;
    }
  }
  groupname: any = "Home";
  show: boolean = true;
  sliderdata_name: [];
  ischecked: boolean = false;
  themes: any = "light";
  playWebsiteNotificationSoundstatus: boolean = true;
  play_sound: any;
  currency: any;
  kotCount: any;
  kotArr: any;
  permissionArr: any;
  menuGroupObj: any;
  menuItemObj: any = [];
  company_name: any;
  userFullName: any;
  role_id: any;
  user_id: any;
  itemArr: any = [];
  version: any;
  windowReference: any;
  userRole: any;
  isrst: any = false;
  load() {
    const e_pos: any = localStorage.getItem("POSEnable");

    // if (e_pos !== null && e_pos !== undefined) {
    //   this.checkEnableResturantFlag = JSON.parse(e_pos);
    //   // if (this.checkEnableResturantFlag == true) {
    //   //   this.router.navigate(["/rst-dashboard"]);
    //   // } else {
    //   //   this.router.navigate(["/dashboard"]);
    //   // }
    // } else {
    //   this.apiService.getEnableResturantPos().subscribe(
    //     (result: any) => {
    //       this.checkEnableResturantFlag = result;
    //       if (this.checkEnableResturantFlag == true) {
    //         this.router.navigate(["/rst-dashboard"]);
    //       } else {
    //         this.router.navigate(["/dashboard"]);
    //       }
    //     },
    //     (result: any) => { }
    //   );
    // }

    this.websiteInvoice();
    this.websiteEnablestatus();
    //Getting enable website setting

    this.userName = localStorage.getItem("userName");
    this.isrst = localStorage.getItem("isrst");
    this.userFullName = localStorage.getItem("userFullName");

    this.role_id = localStorage.getItem("role_id");
    this.user_id = localStorage.getItem("user_id");
    this.userRole = localStorage.getItem("userRole");
    if (!this.userName) {
      this.userName = "Admin";
    }
    const Comp = localStorage.getItem("CompanyInfo");
    const parsed = JSON.parse(Comp);
    this.company_name = parsed.company[0].company_name;

    const cachedData = localStorage.getItem("menuData");
    const cachedItemData = localStorage.getItem("menuItemData");

    if (cachedData && cachedItemData) {
      const parsedData = JSON.parse(cachedData);
      const parsedItemData = JSON.parse(cachedItemData);
      this.menuItemObj = parsedItemData;
      this.menuGroupObj = parsedData;
      let menuGroupId = 0;

      this.menuGroupObj.filter((e) => {
        const displayGrp =
          this.groupname === "Setting" ? "Setting" : `${this.groupname}s`;
        // console.log(" this.groupname", e.menu_group_name === `${this.groupname}s`, e.menu_group_name, displayGrp)
        if (e.menu_group_name === displayGrp) {
          menuGroupId = e.menu_group_id;
        }
      });
      let initMenuItem = this.menuItemObj.filter(
        (e) => e.menu_group_id === menuGroupId
      );
      this.sliderdata_name = initMenuItem;
      for (var i = 0; i < this.menuGroupObj.length; i++) {
        this.itemArr = [];
        for (var j = 0; j < this.menuItemObj.length; j++) {
          if (
            this.menuGroupObj[i].menu_group_id ==
            this.menuItemObj[j].menu_group_id
          ) {
            this.itemArr.push(this.menuItemObj[j]);
          }
        }
        this.menuGroupObj[i].menuItem = this.itemArr;
      }
    }
    this.helpDeskUrl = localStorage.getItem("help_Desk_Url");

    //this.spinner.hide();
    this.getResetvedTableDetails();
  }

  websiteEnablestatus() {
    const cachedData: any = localStorage.getItem("Website");
    if (cachedData !== null && cachedData !== undefined) {
      this.checkEnablewebsiteFlag = JSON.parse(cachedData);
      // console.log(this.checkEnablewebsiteFlag, "this.checkEnablewebsiteFlag")
    }
  }

  onAutoAccept(kot: any) {
    // console.log('auto accept hit', kot)
    const dialogRef = this.dialog.open(AutoAcceptDialogeComponent, {
      width: "300px",
      data: {
        title: "Auto Approve KOT",
        preparationTime: 15,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const reqObj: any = {
        transaction_id: kot.transaction_id,
        merchant_id: 330,
        order_id: kot.order_id,
        prepartion_time: result.preparationTime,
      };
      this.apiService
        .approveWeraKot(kot.transaction_id, reqObj)
        .subscribe((result: any) => {
          if (result.success) {
            this.getNotApprovedKot();
            this.printKot(kot);
          }
        });
    });
  }
  onApproveClick($event: any, kot) {
    console.log("onApproveClick--", kot);
    if (
      kot.order_mode.toLowerCase() === "zomato" ||
      kot.order_mode.toLowerCase() === "swiggy"
    ) {
      const dialogRef = this.dialog.open(OrderConfirmationComponent, {
        width: "400px",
        data: {
          title: "Approve",
          preparationTime: 15,
          order_mode: kot.order_mode,
          trans_no: kot.trans_no,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const reqObj: any = {
            transaction_id: kot.transaction_id,
            merchant_id: 330,
            order_id: kot.order_id,
            prepartion_time: result.preparationTime,
          };
          if (result.approved) {
            this.apiService
              .approveWeraKot(kot.transaction_id, reqObj)
              .subscribe((result: any) => {
                if (result.success) {
                  this.getNotApprovedKot();
                  this.printKot(kot);
                } else {
                  this.notifier.notify("error", "Something went Wrong");
                }
              });
          }
        }
      });
    } else {
      $event.stopPropagation();
      this.apiService.approveKot(kot.transaction_id).subscribe(
        (result: any) => {
          if (result.success) {
            this.getNotApprovedKot();
            this.printKot(kot);
          }
        },
        (result: any) => {}
      );
    }
  }

  onRejectClick($event: any, kot) {
    console.log("onRejectClick--", kot);
    if (
      kot.order_mode.toLowerCase() === "zomato" ||
      kot.order_mode.toLowerCase() === "swiggy"
    ) {
      const dialogRef = this.dialog
        .open(OrderConfirmationComponent, {
          width: "300px",
          data: {
            title: "Reject",
            order_mode: kot.order_mode,
            trans_no: kot.trans_no,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          console.log("result", result);
          if (result) {
            const zReqObj: any = {
              transaction_id: kot.transaction_id,
              order_mode: kot.order_mode,
              order_id: kot.order_id,
              rejection_id: result.rejection_id, //go to docs for rejection id and values
            };
            const sReqObj: any = {
              transaction_id: kot.transaction_id,
              order_mode: kot.order_mode,
              order_id: kot.order_id,
              remark: result.rejection_id, //go to docs for rejection id and values
            };
            const data =
              kot.order_mode.toLowerCase() === "swiggy" ? sReqObj : zReqObj;
            // console.log(reqObj)
            if (result.approved) {
              this.apiService
                .rejectWeraKot(kot.transaction_id, data)
                .subscribe((result: any) => {
                  // console.log("rejectWeraKot--", result)
                  if (result.success) {
                    this.notifier.notify("error", result.message);

                    this.getNotApprovedKot();
                  } else {
                    console.log("error while cancelling");
                    this.notifier.notify("error", "Something went Wrong");
                  }
                });
            }
          }
        });
    } else {
      $event.stopPropagation();
      const reason = "";
      this.apiService.deleteSalesOrder(kot.transaction_id, reason).subscribe(
        (result: any) => {
          if (result.success) {
            this.getNotApprovedKot();
          }
        },
        (result: any) => {}
      );
    }
  }

  orderAutoAccepted($event: any, kot) {
    $event.stopPropagation();

    this.apiService.approveKot(kot.transaction_id).subscribe(
      (result: any) => {
        if (result.success) {
          this.getNotApprovedKot();
          this.printKot(kot);
        }
      },
      (result: any) => {}
    );
  }

  cancelledOrder($event: any, kot) {
    $event.stopPropagation();
    const reason = "";
    this.apiService.deleteSalesOrder(kot.transaction_id, reason).subscribe(
      (result: any) => {
        if (result.success) {
          this.getNotApprovedKot();
        }
      },
      (result: any) => {}
    );
  }
  getNotApprovedKot() {
    return new Promise(
      function (resolve, reject) {
        this.apiService.getSONotApproved().subscribe(
          (result: any) => {
            const data = result.filter(
              (e: any) => e.order_mode.toLowerCase() != "website"
            );
            this.kotArr = data;

            this.kotCount = data.length;
            console.log("getNotApprovedKot--11122345455656", data);
            resolve(true);
          },
          (err: any) => {
            reject(err);
          }
        );
      }.bind(this)
    );
  }
  convertFloat(val) {
    return parseFloat(val);
  }

  // Chnage Password

  changePass() {
    this.dialog.open(ChangeUserPassComponent, {
      height: "auto",
      width: "auto",
      data: "User",
    });
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;

    if (this.width <= 768) {
      if (!this.isMenuOpen) {
        this.contentMargin = 0;
      } else {
        this.contentMargin = 0;
      }
    } else {
      if (!this.isMenuOpen) {
        this.contentMargin = 0;
      } else {
        this.contentMargin = 250;
      }
    }
  }
  onrightToolbarMenuToggle() {
    if (this.kotCount > 0 || this.websiteInvoicecount > 0) {
      this.rightMenuOpen = !this.rightMenuOpen;
    } else {
      this.rightMenuOpen = false;
    }
  }

  onBackupClick() {
    this.dialog
      .open(DBbackupComponent, {
        width: "auto",
        height: "auto",
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.windowReference = window.open(
            result,
            "_blank",
            "toolbar=0,width=400,height=600,left=500,top=50"
          );
        }
      });
  }
  dashboardClick() {
    this.sliderdata_name = [];
    this.groupname = "Home";
    this.isMenuOpen = !this.isMenuOpen;
    this.apiService.setDashboardStatus({ key: false });
  }
  get_routerlink() {
    if (this.isrst == "true") {
      return "/rst-dashboard";
    } else {
      return "/dashboard";
    }
  }
  mobilemenuCTRL() {
    if (this.width <= 768) {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }

  redirectToCompanyInfo() {
    this.router.navigate(["/Setting/company-info"]);
  }
  onCompanyManage() {
    this.dialog.open(CompanyManagementComponent, {
      width: "800px",
    });
  }
  generate_token() {
    this.route.queryParams.subscribe((params) => {
      this.params1 = params["code"];
      if (this.params1)
        this.apiService.generate_token(this.params1).subscribe(
          (result: any) => {
            if (result.success) {
              window.close();
            }
          },
          (result: any) => {
            this.notifier.notify("error", "Something went Wrong");
          }
        );
    });
  }

  playNotificationSound() {
    // Check the kotCount condition first
    if (this.kotCount > 0) {
      // Check if the array is not empty
      if (this.websiteInvoiceData.length > 0) {
        // Iterate through each invoice in the array
        for (let data of this.websiteInvoiceData) {
          // Check the is_muted property
          if (data.is_muted === false) {
            if (!this.play_sound) {
              console.log("playNotificationSound--------1");

              this.sound = new Howl({
                src: ["./assets/ringtone.mp3"],
              });

              this.sound.play();
              this.rightMenuOpen = true;
              this.play_sound = setInterval(
                function () {
                  if (this.kotCount == 0) {
                    clearInterval(this.play_sound);
                    this.onrightToolbarMenuToggle();
                  } else {
                    this.sound.play();
                  }
                }.bind(this),
                7000
              );
            }
            break; // Exit the loop once a sound is played
          }
        }
      }
    } else {
      // Handle the case when kotCount is 0
      console.log("playNotificationSound--------2");
      if (this.websiteInvoiceData && this.websiteInvoiceData.length > 0) {
        for (let data of this.websiteInvoiceData) {
          // Check the is_muted property
          if (data.is_muted === false) {
            if (this.play_sound) clearInterval(this.play_sound);
            this.play_sound = undefined;
          }
        }
      }
    }
  }

  playWebsiteNotificationSound() {
    console.log("1");

    // Initialize the sound
    this.sound = new Howl({
      src: ["./assets/ringtone.mp3"],
    });

    console.log("2");

    // Check if there are any non-muted invoices
    const hasActiveNotifications =
      this.websiteInvoiceData &&
      this.websiteInvoiceData.some((notification) => !notification.is_muted);

    if (
      this.websiteInvoicecount > 0 &&
      this.playWebsiteNotificationSoundstatus &&
      hasActiveNotifications
    ) {
      if (!this.play_sound) {
        console.log("3");
        this.sound.play();

        if (this.websiteInvoiceData.length > 0) {
          // Iterate through each invoice in the array
          this.websiteInvoiceData.forEach((data) => {
            const requestBody = {
              display_trans_no: data.display_trans_no,
              is_muted: true, // Set to true for muting
            };

            console.log("data.trans_no---", data.display_trans_no, requestBody);

            // Make the API call to update the mute status
            this.apiService
              .updateMuteBtn(data.display_trans_no, requestBody)
              .subscribe((result: any) => {
                if (result.success) {
                  // Update the is_muted value from the response
                  data.is_muted = result.is_muted;

                  // Set the permanentMute flag to true for this specific notification
                  data.permanentMute = true;

                  // Check if all notifications are muted
                  const allMuted = this.websiteInvoiceData.every(
                    (notification) => notification.is_muted
                  );

                  if (allMuted && this.play_sound) {
                    clearInterval(this.play_sound);
                    if (this.sound) {
                      this.sound.stop(); // Stop the sound immediately
                    }
                    this.play_sound = undefined;
                  }
                }
              });
          });
        }

        this.play_sound = setInterval(() => {
          if (this.websiteInvoicecount === 0) {
            console.log("4");
            clearInterval(this.play_sound);
            this.play_sound = undefined; // Ensure play_sound is cleared
          } else {
            console.log("5");
            if (this.sound) {
              this.sound.play(); // Ensure sound is played periodically
            }
          }
        }, 7000);
      }
    } else {
      console.log("6");
      if (this.play_sound) {
        clearInterval(this.play_sound);
        this.play_sound = undefined; // Ensure play_sound is cleared
      }
    }
  }

  printKot(kot) {
    this.apiService.downloadPdfByTransId(kot.transaction_id).subscribe(
      (result: any) => {
        // console.log(result);
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + kot.trans_no + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => {}
    );
  }

  theme(e: any) {
    // console.log(e)

    this.ischecked = !this.ischecked;
    if (this.ischecked == true) {
      this.apiService.settheme(this.ischecked);
      this.themes = "dark";
      localStorage.setItem("theme", "true");
    } else {
      // console.log(this.ischecked)
      localStorage.setItem("theme", "false");
      this.apiService.settheme(this.ischecked);
    }
  }

  sliderdata(data: any) {
    this.show = false;
    this.sliderdata_name = data.menuItem;
    console.log("data.menuItem--", data.menuItem);
    this.groupname = data.menu_group_name;
    this.isMenuOpen = !this.isMenuOpen;
    // console.log("data.................................",data)
  }

  searchitems: any;
  newresultedvalue: any = "";
  // search -----------------------------------
  itemControl = new FormControl();

  applyFilter(event: any) {
    let filtename: string = this.itemControl.value;
    if (filtename != "") {
      // filtename = filtename.toLowerCase()
      if (event.key != "ArrowDown" && event.key != "ArrowUp") {
        // if (this.itemControl.value.length > 1) {
        let p = [];
        this.menuGroupObj.map((k: any) => {
          k.menuItem.map((j: any) => {
            p.push({ menu_name: j.menu_name, route: j.route });
          });
        });
        p = p.filter((i: any) => {
          let name: string = i.menu_name;
          name = name.toLowerCase();
          if (name.startsWith(filtename)) {
            return i;
          }
        });
        if (p.length <= 0) {
          this.searchitems = [{ menu_name: "Not Found" }];
        } else {
          this.searchitems = p;
        }
        // }
      }
    } else {
      this.searchitems = [];
    }
    if (event.key === "Enter") {
      event.preventDefault();
      this.newresultedvalue = null;
      // this.router.navigate(["/{{r}}"])
    }
    // console.log("serach------------",this.searchitems)
  }
  routingdata: any;
  focus(n: any) {
    this.newresultedvalue = null;

    // this.searchitems = []
  }

  result_items: any;
  routing(i: any) {
    this.routingdata = "/" + i.route;
    // console.log(this.routingdata)
    this.searchitems = [];

    // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiiiii",i)
    // console.log("this.menuGroupObj----------------",this.menuGroupObj)
    var result_items = this.menuGroupObj.filter((search_item) => {
      // console.log("search_item------------------------",search_item);
      return search_item.menuItem.some(
        (subItem) => subItem.menu_name === i.menu_name
      );
    });
    // console.log("result_items----------",result_items)
    this.groupname = result_items[0].menu_group_name;
    // console.log("this.groupname-----",this.groupname)
    this.sliderdata_name = result_items[0].menuItem;
    this.selectedSearchFieldValue = "";
  }

  routingByEnterKey(i: any) {
    this.selectedSearchFieldValue = "";
    this.searchitems = [];
    var result_items = this.menuGroupObj.filter((search_item) => {
      return search_item.menuItem.some(
        (subItem) => subItem.menu_name === i.menu_name
      );
    });
    this.groupname = result_items[0].menu_group_name;
    this.sliderdata_name = result_items[0].menuItem;
    this.router.navigate(["/" + i.route]);
  }

  // --------------websiteNotification----------------
  // websiteInvoice() {
  //   const websiteInvoice = localStorage.getItem('websiteinvoce');
  //   var parsedData = JSON.parse(websiteInvoice);
  //   const result = parsedData
  //   return new Promise(
  //     function (resolve, reject) {
  //       {
  //         this.websiteInvoiceData = result.session;
  //         this.websiteInvoicecount = result.session.length;
  //         this.playWebsiteNotificationSound();
  //         resolve(true);
  //       }
  //       (err: any) => {
  //         reject(err);
  //       }

  //     }.bind(this)
  //   );
  // }
  websiteInvoice() {
    const orderMode = "website";
    return new Promise(
      function (resolve, reject) {
        this.apiService.getWebsiteInvoice(orderMode).subscribe(
          (result: any) => {
            // this.kotArr = result.session;
            // this.kotCount = result.session.length;
            this.websiteInvoiceData = result.session;
            this.websiteInvoicecount =
              result.session && result.session.length
                ? result.session.length
                : null;
            // console.log(
            //   "websiteInvoicegetWebsiteInvoice11---",
            //   this.websiteInvoiceData,
            //   "count=",
            //   this.websiteInvoicecount
            // );
            this.playWebsiteNotificationSound();

            resolve(true);
          },
          (err: any) => {
            reject(err);
          }
        );
      }.bind(this)
    );
  }

  // muteAlertRingtone(data: any, event: Event) {
  //   event.stopPropagation(); // Stop event propagation

  //   // Check if already muted
  //   if (data.is_muted) {
  //     console.log("Notification is already muted.");

  //     return; // Exit early if already muted
  //   }

  //   const requestBody = {
  //     display_trans_no: data.display_trans_no,
  //     is_muted: true, // Set to true for muting
  //   };

  //   console.log("data.trans_no---", data.display_trans_no, requestBody);

  //   this.apiService.updateMuteBtn(data.display_trans_no, requestBody).subscribe(
  //     (result: any) => {
  //       if (result.success) {
  //         // Update the is_muted value from the response
  //         data.is_muted = result.is_muted;

  //         // Set the permanentMute flag to true for this specific notification
  //         data.permanentMute = true;

  //         // Check if all notifications are muted
  //         );

  //         if (allMuted && this.play_sound) {
  //           clearInterval(this.play_sound);
  //           if (this.sound) {
  //             this.sound.stop(); // Stop the sound immediately
  //           }
  //           this.play_sound = undefined;
  //         }
  //       }
  //     },
  //     (error) => {
  //       console.error("Error muting alert:", error);
  //     }
  //   );
  // }

  NotificationSeen(data: any, event: MatCheckboxChange) {
    // event.stopPropagation();
    event.source.checked = true; // Ensure the checkbox is checked after click

    // Check if already muted
    if (data.show_details) {
      console.log("Notification is already Seen.");

      return; // Exit early if already muted
    }

    const requestBody = {
      display_trans_no: data.display_trans_no,
      show_details: true, // Set to true for muting
    };

    console.log("data.trans_no---", data.display_trans_no, requestBody);

    this.apiService
      .NotificationSeen(data.display_trans_no, requestBody)
      .subscribe(
        (result: any) => {
          if (result.success) {
            // Update the is_muted value from the response
            data.show_details = result.show_details;

            // Set the permanentMute flag to true for this specific notification
            data.permanentMute = true;

            // Check if all notifications are muted
            // const allMuted = this.websiteInvoiceData.every(
            //   (notification) => notification.is_muted
            // );

            // if (allMuted && this.play_sound) {
            //   clearInterval(this.play_sound);
            //   if (this.sound) {
            //     this.sound.stop(); // Stop the sound immediately
            //   }
            //   this.play_sound = undefined;
            // }
          }
        },
        (error) => {
          console.error("Error muting alert:", error);
        }
      );
  }

  getResetvedTableDetails() {
    this.apiService.getUnApprovedReservedTables().subscribe(
      (result: any) => {
        this.UnApprovedReservedTablesArr = result.tblReservationData;
        this.requestCount = this.UnApprovedReservedTablesArr.length;
        // console.log("tableReservation--------", this.UnApprovedReservedTablesArr)
        const count = this.UnApprovedReservedTablesArr.filter((e) => {
          if (e.approved != "Confirmed") {
            return e;
          }
        });
        if (count.length > 0) {
          this.unApprovedRequestCount = count.length;
          // console.log("this.requestCount---", this.unApprovedRequestCount)
        }
        // this.startTimer();
        // this.spinner.hide();
      },
      (err: any) => {
        // this.spinner.hide();
        console.log("err--", err);
      }
    );
  }
  tableReservationDashboard() {
    this.dialog
      .open(BooktableComponent, {
        width: "800px",
      })
      .afterClosed()
      .subscribe((result) => {
        // if (result == 'Edited') {
        //   this.reset();
        // }
      });
  }

  // getOrderImage(order: string): string {
  //   console.log("order------",order)
  //   switch (order) {
  //     case 'swiggy':
  //       return 'assets/images/swiggy.png';
  //     case 'zomato':
  //       return 'assets/images/zomato.png';
  //     default:
  //       return '';
  //   }
  // }

  kot(data: any) {
    console.log("data----KOT", data.kot_transction_id);
    this.apiService.downloadPdfByTransId(data.kot_transction_id).subscribe(
      (result: any) => {
        // console.log(result);
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + data.trans_no + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => {}
    );
  }

  invoice(data: any) {
    console.log("data-----invocce", data.transaction_id);
    this.apiService.downloadPdfByTransId(data.transaction_id).subscribe(
      (result: any) => {
        // console.log(result);
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Invoice" + data.transaction_id + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => {}
    );
  }

  // onCheckboxChange(event: any, type: string, data: any) {
  //   if (event.checked) {
  //     if (type === "approved") {
  //       this.siteOrderApprove(event, data);
  //     } else if (type === "rejected") {
  //       this.siteOrderReject(event, data);
  //     }
  //   } else {
  //     if (type === "approved") {
  //       this.isApprovedChecked = false;
  //     } else if (type === "rejected") {
  //       data.kot_transaction_id.isRejectedChecked = false;
  //     }
  //   }
  // }

  siteOrderApprove(event: MatCheckboxChange, data: any) {
    console.log("data----KOT", data.kot_transction_id, data.kot_approved);

    // Ensure the checkbox is checked after click
    // if (data.is_muted === false) {
    //   // Check if already muted
    //   if (data.is_muted) {
    //     console.log("Notification is already muted.");
    //     if (this.sound) {
    //       this.sound.stop();
    //     }
    //     this.play_sound = undefined;
    //     return; // Exit early if already muted
    //   }

    //   const requestBody = {
    //     display_trans_no: data.display_trans_no,
    //     is_muted: true, // Set to true for muting
    //   };

    //   console.log("data.trans_no---", data.display_trans_no, requestBody);

    //   this.apiService
    //     .updateMuteBtn(data.display_trans_no, requestBody)
    //     .subscribe((result: any) => {
    //       if (result.success) {
    //         // Update the is_muted value from the response
    //         data.is_muted = result.is_muted;

    //         // Set the permanentMute flag to true for this specific notification
    //         data.permanentMute = true;

    //         // Check if all notifications are muted
    //         const allMuted = this.websiteInvoiceData.every(
    //           (notification) => notification.is_muted
    //         );

    //         if (allMuted && this.play_sound) {
    //           clearInterval(this.play_sound);
    //           if (this.sound) {
    //             this.sound.stop(); // Stop the sound immediately
    //           }
    //           this.play_sound = undefined;
    //         }
    //       }
    //     });
    // }

    const requestBodyy = {
      kot_transction_id: data.kot_transction_id,
      kot_approved: true, // Set to true for muting
      color: true, // Set to true for muting
      disable: true, // Set to true for muting
    };
    console.log("requestBody-------------A", requestBodyy);

    this.apiService
      .kotApproved(data.kot_transction_id, requestBodyy)
      .subscribe((result: any) => {
        // Update the is_muted value from the response
        data.kot_approved = result.kot_approved;

        // Set the permanentMute flag to true for this specific notification
        data.permanentApproved = true;
        if (result.success) {
          this.apiService
            .downloadPdfByTransId(data.kot_transction_id)
            .subscribe(
              (result: any) => {
                // console.log(result);
                let blob = new Blob([result], {
                  type: "application/pdf",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "kot" + data.trans_no + ".pdf";
                link.click();
                window.URL.revokeObjectURL(link.href);
              },
              (result: any) => {}
            );
        }
      });
  }

  siteOrderReject(event: MatCheckboxChange, data: any) {
    // Ensure the checkbox is checked after click

    // Check if already muted
    if (data.is_muted === false) {
      if (data.is_muted) {
        console.log("Notification is already muted.");
        if (this.sound) {
          this.sound.stop();
        }
        this.play_sound = undefined;
        return; // Exit early if already muted
      }

      const requestBody = {
        display_trans_no: data.display_trans_no,
        is_muted: true, // Set to true for muting
      };

      console.log("data.trans_no---", data.display_trans_no, requestBody);

      this.apiService
        .updateMuteBtn(data.display_trans_no, requestBody)
        .subscribe((result: any) => {
          if (result.success) {
            // Update the is_muted value from the response
            data.is_muted = result.is_muted;

            // Set the permanentMute flag to true for this specific notification
            data.permanentMute = true;

            // Check if all notifications are muted
            const allMuted = this.websiteInvoiceData.every(
              (notification) => notification.is_muted
            );

            if (allMuted && this.play_sound) {
              clearInterval(this.play_sound);
              if (this.sound) {
                this.sound.stop(); // Stop the sound immediately
              }
              this.play_sound = undefined;
            }
          }
        });
    }
    const requestBodyy = {
      kot_transction_id: data.kot_transction_id,
      inv_transaction_id: data.transaction_id,
      kot_rejected: true, // Set to true for muting
      active: false,
      color: true, // Set to true for muting
      disable: true, // Set to true for muting
    };
    console.log("requestBody------------R", requestBodyy);

    this.apiService.OrderRejected(requestBodyy).subscribe((result: any) => {
      // Update the is_muted value from the response
      data.kot_rejected = result.kot_rejected;

      // Set the permanentMute flag to true for this specific notification
      data.permanentRejected = true;
      if (result.success) {
        console.log("data.kot_transaction_id----", data.kot_transction_id);
      }
    });
  }

  riderInvoice(id: any,trans_no:any) {
    if (!trans_no && id) {
        console.error("Invoice data is not provided or empty");
        return;
    }

    // Assuming you want to use the first item from the array
   

    console.log("Selected Invoice Data", this.websiteInvoiceData);
    console.log("transaction_id", id);
    console.log("display_trans_no",trans_no);

    // Ensure websiteData is initialized
    this.websiteData = this.websiteData || {};

    // Assign values from selectedInvoice to websiteData
    this.websiteData.transaction_id = id;
    this.websiteData.display_trans_no = trans_no;
    this.websiteData.trans_type = "Invoice";

    // Call the API to download PDF
    this.apiService.downloadPdf(this.websiteData).subscribe(
        (result: any) => {
            let blob = new Blob([result], {
                type: "application/pdf",
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "invoice_" + this.websiteData.display_trans_no + ".pdf";
            link.click();
            window.URL.revokeObjectURL(link.href);
        },
        (error: any) => {
            console.error("Error downloading PDF:", error);
        }
    );
}


  
}
