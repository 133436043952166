import { Component, OnInit, ViewChild, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent, MatTabChangeEvent, MatDialog, MatTableDataSource, MatSort } from "@angular/material";
import { NotifierService } from 'angular-notifier';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../../data.service';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { AddFeaturedItemComponent } from './add-featured-item/add-featured-item.component';

@Component({
  selector: 'app-featured-item',
  templateUrl: './featured-item.component.html',
  styleUrls: ['./featured-item.component.css']
})
export class FeaturedItemComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<FeaturedItemComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  displayedColumns = ['item_id', 'item_name', 'mrp/s_rate', 'actions'];
  featuredItems: MatTableDataSource<any>;

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.apiService.getFeturedItem()
    .subscribe((result: any) => {
      this.featuredItems = new MatTableDataSource(result);
      this.featuredItems.data.forEach(item => {
        this.apiService.getItemImage(item.item_id)
          .subscribe((resultObj: any) => {
            this.createImageFromBlob(resultObj, (url) => {
              item.imageurl = url;
            });
          }, (err: any) => {
            console.log('unable to load image');
          });
      });
    },(result: any) => {
      this.notifier.notify('error', 'unable to load Item data');
    });
  }

  createImageFromBlob(image: Blob, fn) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      var reader_result = reader.result;
      fn(reader_result);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  deleteClick(item) {
    let data = this.featuredItems.data.filter(_item => _item.item_id !== item.item_id);
    this.featuredItems = new MatTableDataSource(data);
  }

  save() {
    let data = this.featuredItems.data;
    let item_ids = data.map((item) =>  {
      return item.item_id;
    });
    this.apiService.updateFeaturedItem({item_ids})
    .subscribe((result: any) => {
      this.notifier.notify('success', 'Items updated successfully');
      this.close();
      // this.load()
    },(result: any) => {
      this.notifier.notify('error', result.message);
    })
  }

  shiftrow(action, index) {
    let data = this.featuredItems.data;
    if (action === 'down') {
      if (index !== data.length - 1) {
        let temp = data[index + 1];
        data[index + 1] = data[index];
        data[index] = temp;
      }
    }
    if (action === 'up') {
      if (index !== 0) {
        let temp = data[index - 1];
        data[index - 1] = data[index];
        data[index] = temp;
      }
    }
    this.featuredItems = new MatTableDataSource(data);
  }

  close() {
    this.dialogRef.close();
  }

  addItems() {
    let itemref = this.dialog.open(AddFeaturedItemComponent, {
      width: '750px',
    });
    itemref.componentInstance.onItemAdd.subscribe((result) => {
      // console.log('Result', result);
      this.load();
    });
  }
}
