import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { environment } from '../../../environments/environment';
import {MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter  } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../date.adapter';

import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-edit-cash-bank',
  templateUrl: './edit-cash-bank.component.html',
  styleUrls: ['./edit-cash-bank.component.css'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
  
})
export class EditCashBankComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private dialogRef: MatDialogRef<EditCashBankComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currency = environment.currency;
  }

  @ViewChild('f') f;
  currency: any;
  disableInput: any = true;
  ngOnInit() {
    this.load();
  }
  model: any = {};
  ledgerObj: any;
  ledgerObj2: any;
  l_id1: any;
  closing_balance1: any;
  ledger_id1: any;
  l_id2: any;
  closing_balance2: any;
  ledger_id2: any;
  load() {
    if (this.data.payment_mode == "Bank")
      this.disableInput = false;
    this.f.submitted = true;
    this.apiService.getCashBank(this.data.transaction_id)
      .subscribe((result: any) => {
        this.model = result;

        this.apiService.getLedgerClosingBalance(this.model.ledger_id).subscribe(
          (result: any) => {
            if (result.success) {
              this.closing_balance1 = result.message.closing_balance + ' ' + result.message.cbdrcr;

            }
            else {
              this.notifier.notify('error', result.message);
            }
          },
          (err: any) => {

          }
        )


        this.apiService.getLedgerClosingBalance(this.model.ledger_id2).subscribe(
          (result: any) => {
            if (result.success) {
              this.closing_balance2 = result.message.closing_balance + ' ' + result.message.cbdrcr;

            }
            else {
              this.notifier.notify('error', result.message);
            }
          },
          (err: any) => {

          }
        )
      }, (result: any) => {
      });

    this.apiService.getCashBankLedgerList()
      .subscribe((result: any) => {
        this.ledgerObj = result;
        this.ledgerObj2 = result;
      }, (result: any) => {
      });
  }

  enableInp() {
    this.disableInput = false;
  }
  disableInp() {
    this.disableInput = true;
  }

  onSubmit() {
   
    if (this.f.valid) {
      this.apiService.editCashBank(this.model.transaction_id, this.model)
        .subscribe((result: any) => {
          if (result.success) {
            this.downloadClick();
            this.notifier.notify('success', 'Cash/Bank Details Updated successfully');
          } else {
            this.notifier.notify('error', result.message);
          }
          this.dialogRef.close('Edited');
        }, (result: any) => {
          this.notifier.notify('error', 'unable to update Cash/Bank Details');
        });
    }
  }

  downloadClick() {
    this.model.trans_type = "CashBank";
    this.apiService.downloadPdf(this.model)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'cash_bank' + this.model.trans_no + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
  cancelInvoice() {
    this.dialog.open(ConfirmDialogComponent, {
      data: "Are you sure to Cancel the Cash/Bank Voucher Details.?"
    })
      .afterClosed().subscribe(result => {
        if (result == true) {
          this.apiService.deleteCashBank(this.data.transaction_id)
            .subscribe((result: any) => {
              this.dialogRef.close('Edited');
              this.notifier.notify('success', 'Cash/Bank Canceled successfully');
            }, (result: any) => {
              this.notifier.notify('error', 'unable to cancel Cash/Bank');
            });
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onChange1(){
    this.apiService.getLedgerClosingBalance(this.model.ledger_id).subscribe(
      (result:any)=>{
        if(result.success){
          this.closing_balance1=result.message.closing_balance+' '+result.message.cbdrcr;
         
        }
        else{
          this.notifier.notify('error', result.message);
        }
      },
      (err:any)=>{

      }
    )
  }

  onChange2(){
    this.apiService.getLedgerClosingBalance(this.model.ledger_id2).subscribe(
      (result:any)=>{
        if(result.success){
          this.closing_balance2=result.message.closing_balance+' '+result.message.cbdrcr;
         
        }
        else{
          this.notifier.notify('error', result.message);
        }
      },
      (err:any)=>{

      }
    )
  }
}

