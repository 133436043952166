import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { DataService } from "../../../../data.service";
import { NotifierService } from "angular-notifier";
import { ItemsVariantComponent } from "../items-variant/items-variant.component";

@Component({
  selector: "app-items-variant-list",
  templateUrl: "./items-variant-list.component.html",
  styleUrls: ["./items-variant-list.component.css"],
})
export class ItemsVariantListComponent implements OnInit {
  model: any = {};
  variant_id: any;
  itemIdVariant: any[] = [];
  itemVarArr: any[] = [];
  displayedColumns: any[] = [
    "id",
    "item_id",
    "parent_id",
    "default_rate",
    "s_rate",
    "actions",
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ItemsVariantListComponent>,
    private apiService: DataService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.load();
  }

  load() {
    this.itemIdVariant = this.data.modelData;
    console.log("thhthe", this.itemIdVariant);
    if (this.itemIdVariant) {
      this.apiService
        .getItemVariant(this.itemIdVariant)
        .subscribe((response: any) => {
          this.itemVarArr = response;
          console.log("thisitemVarArr", this.itemVarArr);
        });
    }
  }

  editClick() {
    this.dialog.open(ItemsVariantComponent, {
      data: {
        modelData: this.itemIdVariant, // Pass this.model data to ItemsVariantListComponent
      },

      width: "400px",
    });
  }

  onClose() {
    this.dialogRef.close();
  } 
  onSubmit() {
    this.dialogRef.close();
  }
}
