<mat-card class="d-card" *ngIf="successFlag">
    <mat-card-content>
        <mat-card-title class="text-center">
          Sales
             </mat-card-title>
        <div>
            <div>
              <div>
                <div style="display: block">
                  <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                    [legend]="barChartLegend" [chartType]="barChartType" (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
                </div>
                 
              </div>
            </div>
          </div>
 </mat-card-content>
</mat-card>