import { Component, Inject, OnInit,inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-add-sub-category-image',
  templateUrl: './add-sub-category-image.component.html',
  styleUrls: ['./add-sub-category-image.component.css']
})
export class AddSubCategoryImageComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AddSubCategoryImageComponent>,
    private apiService:DataService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }
    file: any;
    imageUrl: any;
  ngOnInit() {
    this.load();
  }
  load() {
    this.imageUrl = this.data.imageurl;
  }

  imgInputChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    this.readFile(this.file);
  }

  onSave() {
    const fd = new FormData();
    fd.append('sub_category_id', this.data.sub_category_id);
    fd.append('itemImg', this.file, this.file.name);
    this.apiService.addSubCategoryImage(fd)
      .subscribe((result: any) => {
        if (result.success) {
          this.notifier.notify('success', 'Image updated successfully');
        } else {
          this.notifier.notify('error', result.message);
        }
        this.dialogRef.close("Save");
      }, (result: any) => {
        this.notifier.notify('error', 'unable to update image...try after some time.');
      });
  }

  close(){
    this.dialogRef.close();
  }

  readFile(file: File) {
    var reader = new FileReader();
    reader.onload = () => {
      this.imageUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }
}
