import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaxSummaryService {
  constructor() { }

  obj: any = {};
  tax: any = [];
  totalObj: any = {};
  isTransactionGSTslab: boolean = false;

  calculateTax(items) {
    console.log("items---", items)
    var sgstUnqPer = items.map(item => item.taxslab)
      .filter((value, index, self) => self.indexOf(value) === index);
    console.log("sgstUnqPer---", sgstUnqPer)
    var taxPer = sgstUnqPer.filter(function (per) {
      return per > 0;
    });
    console.log("taxPer---", taxPer)
    this.obj = {};
    this.tax = [];
    this.totalObj = {};
    var totalSGST = 0;
    var totalCGST = 0;
    var totalIGST = 0;
    var totalVAT = 0;
    var totalItemTaxableAmt = 0;
    var totalItemAmt = 0;
    var totalItemGST = 0;
    for (var i = 0; i < taxPer.length; i++) {
      for (var j = 0; j < items.length; j++) {
        if (taxPer[i] == items[j].taxslab) {
          totalSGST = totalSGST + items[j].sgst_amt;
          totalCGST = totalCGST + items[j].cgst_amt;
          totalIGST = totalIGST + items[j].igst_amt;
          totalVAT = totalVAT + items[j].vat_amt || 0;;
          totalItemTaxableAmt = totalItemTaxableAmt + items[j].taxable_amt;
          totalItemAmt = totalItemAmt + items[j].amount;
          totalItemGST = totalSGST + totalCGST + totalIGST + totalVAT
        }
      }
      this.obj.per = taxPer[i] + "%";
      this.obj.sgst_amt = totalSGST;
      this.obj.cgst_amt = totalCGST;
      this.obj.igst_amt = totalIGST;
      this.obj.vat_amt = totalVAT;
      this.obj.taxable_amt = totalItemTaxableAmt;
      this.obj.amount = totalItemAmt;
      this.obj.total_gst_amt = totalItemGST;
      this.tax.push(this.obj);
      totalSGST = 0;
      totalCGST = 0;
      totalIGST = 0;
      totalVAT = 0;
      totalItemTaxableAmt = 0;
      totalItemAmt = 0;
      totalItemGST = 0;
      this.obj = {};
    }
    var total_amt = 0;
    var total_taxable_amt = 0;
    var total_sgst_amt = 0;
    var total_cgst_amt = 0;
    var total_igst_amt = 0;
    var total_vat_amt = 0;
    var total_tax_amt = 0;
    for (var i = 0; i < this.tax.length; i++) {
      total_amt = total_amt + this.tax[i].amount;
      total_taxable_amt = total_taxable_amt + this.tax[i].taxable_amt;
      total_sgst_amt = total_sgst_amt + this.tax[i].sgst_amt;
      total_cgst_amt = total_cgst_amt + this.tax[i].cgst_amt;
      total_igst_amt = total_igst_amt + this.tax[i].igst_amt;
      total_vat_amt = total_igst_amt + this.tax[i].vat_amt;
      total_tax_amt = total_tax_amt + this.tax[i].total_gst_amt;
    }
    this.totalObj.per = "Total"
    this.totalObj.amount = total_amt;
    this.totalObj.taxable_amt = total_taxable_amt;
    this.totalObj.sgst_amt = total_sgst_amt;
    this.totalObj.cgst_amt = total_cgst_amt;
    this.totalObj.igst_amt = total_igst_amt;
    this.totalObj.vat_amt = total_vat_amt;
    this.totalObj.total_gst_amt = total_tax_amt;
    this.tax.push(this.totalObj);
    return this.tax;
  }
}


// calculateTax(items) {
//   // Get the value from localStorage
//   var isTransactionWiseGST = localStorage.getItem("isTransactionGSTslab");

//   // Check if the value is "true" or "false" and assign a boolean accordingly
//   if (isTransactionWiseGST === "true")
//     this.isTransactionGSTslab = true;

//   if (this.isTransactionGSTslab) {
//     var sgstUnqPer = items.map(item => item.taxslab)
//       .filter((value, index, self) => self.indexOf(value) === index);
//     var taxPer = sgstUnqPer.filter(function (per) {
//       return per > 0;
//     });
//     this.obj = {};
//     this.tax = [];
//     this.totalObj = {};
//     var totalSGST = 0;
//     var totalCGST = 0;
//     var totalIGST = 0;
//     var totalItemTaxableAmt = 0;
//     var totalItemAmt = 0;
//     var totalItemGST = 0;
//     var total_vat_amt = 0;
//     for (var j = 0; j < items.length; j++) {
//       total_vat_amt += items[j].vat_amt || 0;
//     }
//     for (var i = 0; i < taxPer.length; i++) {
//       for (var j = 0; j < items.length; j++) {
//         if (taxPer[i] == items[j].taxslab) {
//           totalSGST = totalSGST + items[j].sgst_amt;
//           totalCGST = totalCGST + items[j].cgst_amt;
//           totalIGST = totalIGST + items[j].igst_amt;
//           totalItemTaxableAmt = totalItemTaxableAmt + items[j].taxable_amt;
//           totalItemAmt = totalItemAmt + items[j].amount;
//           totalItemGST = totalSGST + totalCGST + totalIGST
//         }
//       }
//       this.obj.per = taxPer[i] + "%";
//       this.obj.sgst_amt = totalSGST;
//       this.obj.cgst_amt = totalCGST;
//       this.obj.igst_amt = totalIGST;
//       this.obj.taxable_amt = totalItemTaxableAmt;
//       this.obj.amount = totalItemAmt;
//       this.obj.total_gst_amt = totalItemGST;
//       this.tax.push(this.obj);
//       totalSGST = 0;
//       totalCGST = 0;
//       totalIGST = 0;
//       totalItemTaxableAmt = 0;
//       totalItemAmt = 0;
//       totalItemGST = 0;
//       this.obj = {};
//     }
//     var total_amt = 0;
//     var total_taxable_amt = 0;
//     var total_sgst_amt = 0;
//     var total_cgst_amt = 0;
//     var total_igst_amt = 0;
//     var total_tax_amt = 0;
//     for (var i = 0; i < this.tax.length; i++) {
//       total_amt = total_amt + this.tax[i].amount;
//       total_taxable_amt = total_taxable_amt + this.tax[i].taxable_amt;
//       total_sgst_amt = total_sgst_amt + this.tax[i].sgst_amt;
//       total_cgst_amt = total_cgst_amt + this.tax[i].cgst_amt;
//       total_igst_amt = total_igst_amt + this.tax[i].igst_amt;
//       total_tax_amt = total_tax_amt + this.tax[i].total_gst_amt;
//     }
//     this.totalObj.per = "Total"
//     this.totalObj.amount = total_amt;
//     this.totalObj.taxable_amt = total_taxable_amt;
//     this.totalObj.sgst_amt = total_sgst_amt;
//     this.totalObj.cgst_amt = total_cgst_amt;
//     this.totalObj.igst_amt = total_igst_amt;
//     this.totalObj.vat_amt = total_vat_amt;
//     this.totalObj.total_gst_amt = total_tax_amt + total_vat_amt;
//     this.tax.push(this.totalObj);
//     return this.tax;
//   } else {
//     // var sgstUnqPer = items.map(item => item.taxslab)
//     //   .filter((value, index, self) => self.indexOf(value) === index);
//     // var taxPer = sgstUnqPer.filter(function (per) {
//     //   return per > 0;
//     // });
//     this.obj = {};
//     this.tax = [];
//     this.totalObj = {};
//     var totalSGST = 0;
//     var totalCGST = 0;
//     var totalIGST = 0;
//     var totalItemTaxableAmt = 0;
//     var totalItemAmt = 0;
//     var totalItemGST = 0;


//     var total_amt = 0;
//     var total_taxable_amt = 0;
//     var total_sgst_amt = 0;
//     var total_cgst_amt = 0;
//     var total_igst_amt = 0;
//     var total_vat_amt = 0;
//     var total_tax_amt = 0;
//     for (var j = 0; j < items.length; j++) {
//       total_sgst_amt += items[j].sgst_amt || 0;
//       total_cgst_amt += items[j].cgst_amt || 0;
//       total_igst_amt += items[j].igst_amt || 0;
//       total_vat_amt += items[j].vat_amt || 0;
//       total_taxable_amt += items[j].taxable_amt;
//       total_amt += items[j].amount;

//       // total_sgst_amt = total_sgst_amt + this.tax[i].sgst_amt;
//       // total_cgst_amt = total_cgst_amt + this.tax[i].cgst_amt;
//       // total_igst_amt = total_igst_amt + this.tax[i].igst_amt;
//       total_tax_amt = total_tax_amt + total_sgst_amt +
//         total_cgst_amt +
//         total_igst_amt;
//     }
//     // this.obj.per = taxPer[i] + "%";
//     // this.obj.sgst_amt = totalSGST;
//     // this.obj.cgst_amt = totalCGST;
//     // this.obj.igst_amt = totalIGST;
//     // this.obj.vat_amt = totalVAT;
//     // this.obj.taxable_amt = totalItemTaxableAmt;
//     // this.obj.amount = totalItemAmt;
//     // this.obj.total_gst_amt = totalItemGST;
//     // this.tax.push(this.obj);
//     // totalSGST = 0;
//     // totalCGST = 0;
//     // totalIGST = 0;
//     // totalVAT = 0;
//     // totalItemTaxableAmt = 0;
//     // totalItemAmt = 0;
//     // totalItemGST = 0;
//     // this.obj = {};
//     // }

//     // for (var i = 0; i < this.tax.length; i++) {

//     // }
//     this.totalObj.per = "Total"
//     this.totalObj.amount = total_amt;
//     this.totalObj.taxable_amt = total_taxable_amt;
//     this.totalObj.sgst_amt = total_sgst_amt;
//     this.totalObj.cgst_amt = total_cgst_amt;
//     this.totalObj.igst_amt = total_igst_amt;
//     this.totalObj.vat_amt = total_vat_amt;
//     this.totalObj.total_gst_amt = total_tax_amt + total_vat_amt;
//     // this.totalObj.total_vat_amt = total_vat_amt;
//     this.tax.push(this.totalObj);
//     return this.tax;
//   }
// }

