<h2 class="color-h3" mat-dialog-title>Import Customers</h2>
<div class="table-details">
  <mat-dialog-content>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input #csvInput hidden="true" type="file" (change)="csvInputChange($event)" accept=".csv">
            <button mat-flat-button class="btn m-1" (click)="csvInput.click()">Choose csv file</button>
            <span *ngIf="file">{{file.name}}</span>
            <span *ngIf="!file">No File Chosen</span>
          </div>
        </div>
        <div class="col-md-6 text-right">
          <button mat-button color="primary" (click)="downloadTemplate()" style="width: 250px; max-width: 250px;">Download import template here</button>
        </div>
      </div>
    </div>
    <table *ngIf="customerData.length" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="contact_no">
        <th mat-header-cell *matHeaderCellDef> Contact No </th>
        <td mat-cell *matCellDef="let element"> {{element.contact_no}} </td>
      </ng-container>
      <ng-container matColumnDef="email_id">
        <th mat-header-cell *matHeaderCellDef> Email ID </th>
        <td mat-cell *matCellDef="let element"> {{element.email_id}} </td>
      </ng-container>
      <ng-container matColumnDef="date_of_birth">
        <th mat-header-cell *matHeaderCellDef> Date of Birth </th>
        <td mat-cell *matCellDef="let element"> {{element.date_of_birth | date}} </td>
      </ng-container>
      <ng-container matColumnDef="date_of_anniversary">
        <th mat-header-cell *matHeaderCellDef> Date of Anniversary </th>
        <td mat-cell *matCellDef="let element"> {{element.date_of_anniversary | date}} </td>
      </ng-container>
      <ng-container matColumnDef="gstin">
        <th mat-header-cell *matHeaderCellDef> GSTIN </th>
        <td mat-cell *matCellDef="let element"> {{element.gstin}} </td>
      </ng-container>
      <ng-container matColumnDef="do_not_send_updates">
        <th mat-header-cell *matHeaderCellDef> Do Not Send Updates </th>
        <td mat-cell *matCellDef="let element"> {{element.do_not_send_updates}} </td>
      </ng-container>
      <ng-container matColumnDef="stage">
        <th mat-header-cell *matHeaderCellDef> Stage </th>
        <td mat-cell *matCellDef="let element"> {{element.stage}} </td>
      </ng-container>
      <ng-container matColumnDef="shipping_address1">
        <th mat-header-cell *matHeaderCellDef> Shipping Address 1 </th>
        <td mat-cell *matCellDef="let element"> {{element.shipping_address1}} </td>
      </ng-container>
      <ng-container matColumnDef="shipping_address2">
        <th mat-header-cell *matHeaderCellDef> Shipping Address 2 </th>
        <td mat-cell *matCellDef="let element"> {{element.shipping_address2}} </td>
      </ng-container>
      <ng-container matColumnDef="msg">
        <th class="text-center" mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.success" class="badge badge-success">{{element.msg}}</span>
          <span *ngIf="!element.success" class="badge badge-danger">{{element.msg}}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn mat-raised-button" (click)="close()">Close</button>
    <button class="btn mat-raised-button" (click)="onImportDataClick()">Import Data</button>
  </mat-dialog-actions>
</div>
