import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-licence-info',
  templateUrl: './licence-info.component.html',
  styleUrls: ['./licence-info.component.css']
})
export class LicenceInfoComponent implements OnInit {
  constructor(private apiService: DataService,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private router: Router) { }
  @Input() public isLoggedIn: boolean;
  licenceObj: any;
  isLicence: boolean;
  file: any;
  licenceUrl: any;
  client_id: any;
  status: any;
  expiry:any;
  ngOnInit() {
    this.load();
  }
  quotation_terms_conditions: any;
  load() {
    this.apiService.getLicenceDetails()
      .subscribe((result: any) => {
        if (result.success) {
          if (result.licence.SUCCESS) {
            this.isLicence = true;
            this.status = "Active";
            this.expiry = result.licence.LICENSEDATA.EXPIRYDATE?result.licence.LICENSEDATA.EXPIRYDATE:"Never"
            this.licenceObj = result.licence.LICENSEDATA;
          } else {
            this.isLicence = false;
            if (result.licence.LICENSEDATA) {
              this.status = result.licence.ERROR_MSG;
            } else {
              this.status = "Trial mode";
            }
          }
          this.client_id = result.licence.CLIENTID ? result.licence.CLIENTID : "Client Id not found";
        }
      }, (result: any) => {
      });

  }
  licenceChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    this.readFile(this.file);
  }
  readFile(file: File) {
    var reader = new FileReader();
    reader.onload = () => {
      this.licenceUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }

  licenceUpdate() {
    const fd = new FormData();
    fd.append('licenceFile', this.file, "licence.lic");

    this.apiService.uploadLicence(fd)
      .subscribe((result: any) => {
        if (result.success) {
          this.apiService.getLicenceDetails()
            .subscribe((result: any) => {
              if (result.success) {
                if (result.licence.SUCCESS) {
                  this.isLicence = true;
                  this.status = "Active";
                  this.expiry = result.licence.LICENSEDATA.EXPIRYDATE?result.licence.LICENSEDATA.EXPIRYDATE:"Never"
                  this.licenceObj = result.licence.LICENSEDATA;
                } else {
                  this.isLicence = false;
                  if (result.licence.LICENSEDATA) {
                    this.status = result.licence.ERROR_MSG;
                  } else {
                    this.status = "Trial mode";
                  }
                }
                this.client_id = result.licence.CLIENTID ? result.licence.CLIENTID : "Client Id not found";
                if(!result.licence.SUCCESS && result.licence.LICENSEDATA){
                  this.isLoggedIn = false;
                  this.router.navigate(['/update-licence']);
                }
                if (this.isLicence) {
                  localStorage.setItem('licence', 'true');
                } else {
                  localStorage.setItem('licence', 'false');
                }
                if (this.isLoggedIn) {
                  this.router.navigateByUrl('/redirect', { skipLocationChange: true }).then(() =>
                    this.router.navigate(['/Setting/company-info']));
                  this.notifier.notify('success', 'Licence updated successfully');
                } else {
                  if (this.isLicence) {
                    this.router.navigate(['/dashboard']);
                    this.notifier.notify('success', 'Licence updated successfully');
                  } else {
                    this.notifier.notify('error', 'Licence not valid or expired');
                  }
                }  
              }
              
            }, (result: any) => {
            });

        } else {
          this.notifier.notify('error', result.message);
        }
      }, (result: any) => {
        this.notifier.notify('error', 'unable to update licence...try after some time.');
      });
  }

  copyToClipboard() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.client_id));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.notifier.notify('success', 'Client Id copied successfully');
  }

}
