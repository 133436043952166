  <div class="lic-section">
      <div *ngIf="!isLoggedIn" class="text-center">
        <span class="alertt alert alert-danger">Your licence is not valid or Expired. Please update to continue.</span>
      </div>
      <div class="lic-div">
        <img src="../../assets/images/logo.png" alt="" class="img-fluid">
          <h3>Update Licence</h3>
          <app-licence-info isLoggedIn=false></app-licence-info>
      </div>
  </div>
