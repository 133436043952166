import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from "@angular/material";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../data.service';
import { AddItemComponent } from '../items/add-item/add-item.component';
import { environment } from '../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { ItemOptionsCustomComponent } from '../commom-components/item-options-custom/item-options-custom.component';
import * as _ from 'lodash';
import { ItemsDialogComponent } from '../rst-dashboard/items-dialog/items-dialog.component';

@Component({
  selector: 'app-transaction-items',
  templateUrl: './transaction-items.component.html',
  styleUrls: ['./transaction-items.component.css']
})
export class TransactionItemsComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<TransactionItemsComponent>,
    private apiService: DataService,
    public dialog: MatDialog,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.filterItem = data.mode ? data.mode : null
    this.currency = environment.currency;
    // console.log("this.filterItem---", this.filterItem)
    // console.log("this.data.trans_type---", this.data.trans_type)
  }
  @ViewChild('transForm') transForm;
  @ViewChild('p_rate1') p_rate1: ElementRef;
  @ViewChild('s_rate1') s_rate1: ElementRef;
  @ViewChild('qty1') qty1: ElementRef;
  @ViewChild('sub1') sub1: ElementRef;


  @Output() onItemAdd: EventEmitter<any> = new EventEmitter<any>();

  model: any = {};
  isAutoFocus: boolean = false;
  currency: any;
  itemControl = new FormControl();
  options: any;
  filteredOptions: Observable<string[]>;
  itemObj: any[];
  ledgerObj: any = {};
  locObj: any;
  isLocation: boolean = false;
  selected_item_id: any;
  mapArr: any;
  moreField: boolean = false;
  optionalFields: any;
  customerRates: any;
  optionsArray: any = [];
  clonedModel: any = null;
  filterItem: any = null;
  isTransactionGSTslab: boolean = false;

  ngOnInit() {
    this.getEnabledAdditionItemFields();
    this.load();
  }



  _filter(value) {
    const filterValue = value ? value.toLowerCase() : value;
    return this.itemObj.filter(item => item.item_code.toLowerCase().includes(filterValue) || item.item_name.toLowerCase().includes(filterValue));
  }

  displayFn(Item) {
    return Item ? Item.item_name : "";
  }

  getEnabledAdditionItemFields() {
    this.apiService.getEnabledItemAdditionField(this.data.v_type_id)
      .subscribe((result: any) => {
        this.optionalFields = result;
        if (this.optionalFields.length > 0) {
          this.moreField = true;
        }
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load enabled fields');
      });
  }


  company: any;
  addFieldUp = false;
  load() {

    // Get the value from localStorage
    var isTransactionWiseGST = localStorage.getItem("isTransactionGSTslab");

    // Check if the value is "true" or "false" and assign a boolean accordingly
    if (isTransactionWiseGST === "true")
      this.isTransactionGSTslab = true;
    this.transForm.submitted = true;
    this.getCustomerRate();
    if (this.data.item) {
      //this.addFieldUp=true;
      this.model = this.data.item;
      this.apiService.getItem(this.model.item_id)
        .subscribe((result: any) => {
          this.itemObj = result;
          this.selected_item_id = result.item_id;
          if (this.data.trans_type == "Out") {
            this.apiService.getLocationByItem(this.selected_item_id)
              .subscribe((result: any) => {
                this.locObj = result;
              }, (result: any) => {
              });

          }
          this.itemControl.setValue(this.itemObj);
          this.model.total = this.model.taxable_amt + this.model.total_tax;
          if (this.model.options && this.model.options.length > 0) {
            this.optionsArray = this.model.options;
          }
        }, (result: any) => {
        });

    } else {
      this.model = { sgst_amt: 0, cgst_amt: 0, igst_amt: 0, dis_amt: 0, taxable_amt: 0, qty: 1, sgst_per: 0, cgst_per: 0, igst_per: 0 };
    }

    this.model.total_tax = this.model.sgst_amt + this.model.cgst_amt + this.model.igst_amt;

    if (this.data.address_state) {
      this.ledgerObj.address_state = this.data.address_state;
    } else {
      this.apiService.getLedger(this.data.ledger_id)
        .subscribe((result: any) => {
          this.ledgerObj = result[0];
        }, (result: any) => {
        });
    }
    this.apiService.getCompanyInfo()
      .subscribe((result: any) => {
        this.company = result;

      }, (result: any) => {
      });
    if (this.data.trans_type == "In") {
      this.apiService.getLocationList()
        .subscribe((result: any) => {
          this.locObj = result;
        }, (result: any) => {
        });
    }
    this.apiService.getAccountMapList()
      .subscribe((result: any) => {
        this.mapArr = result;
        this.findAccSetting();
      }, (result: any) => {
      });
  }
  findAccSetting() {
    var indexOfLoc = this.mapArr.findIndex(i => i.key === "Enable_Location");
    var indexofautofocus = this.mapArr.findIndex(i => i.key === "Enable_Auto_Focus");
    if (this.mapArr[indexOfLoc].checkbox_value) {
      this.isLocation = true;
    }
    if (this.mapArr[indexofautofocus].checkbox_value) {
      this.isAutoFocus = true;
    }
  }
  search(event) {
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl.value.length > 1) {
        this.apiService.getCustomFilterItemList(this.itemControl.value, this.filterItem, null)
          .subscribe((result: any) => {

            this.itemObj = result;
            this.filteredOptions = this.itemControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.optionsArray = [];
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    }
  }

  itemSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option.value != 0) {

      var item_id = event.option.value ? event.option.value.item_id : undefined;
      var p_rate = event.option.value ? event.option.value.p_rate : undefined;
      this.selected_item_id = item_id;

      this.clonedModel = _.cloneDeep(event.option.value);

      if (event.option.value.options.length > 0) {
        this.optionsArray = event.option.value.options;
        this.model.options = [];
      }

      if (this.data.trans_type == "Out") {
        this.apiService.getLocationByItem(item_id)
          .subscribe((result: any) => {
            this.locObj = result;
          }, (result: any) => {
          });
      }
      var indexofItem = this.itemObj.findIndex(i => i.item_id === item_id && i.p_rate === p_rate);
      if (event.option.value.options.length > 0) {
        const data = { item: this.itemObj[indexofItem], status: 'add' }
        this.openCustomization(data);
      } else {
        this.populateItemData(this.itemObj[indexofItem]);
      }
      this.model.item_id = item_id;
    }

  }

  optionarray_amount: number = 0
  options_array: any[] = []
  checkedVariantAmount: number = 0;
  checkedVariant: any[] = []

  openCustomization(e: any) {
    this.options_array = []
    this.optionarray_amount = 0
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(ItemsDialogComponent, {
      data: e,
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.optionarray_amount = result.optionAmount
        // this.options_array = result.optionsData
        // this.populateItemData(result)
        const item = result.item
        if (result.status === "add") {

          this.optionarray_amount = item.optionAmount;
          this.options_array = item.optionsData;
          this.checkedVariantAmount = item.checkedVariantAmount;
          this.checkedVariant = item.checkedVariant;
          const isCustomised = true;
          this.populateItemData(item);
        } else {
          // this.updateOrder(item)
        }
      }
    });
  }


  populateItemData(itemDetails) {
    console.log("itemDetails--", itemDetails)
    itemDetails.s_rate = itemDetails.checkedVariantAmount && itemDetails.checkedVariantAmount > 0 ? itemDetails.checkedVariantAmount : itemDetails.s_rate;
    this.model.item_name = itemDetails.item_name;
    this.model.item_code = itemDetails.item_code;
    this.model.hsn_code = itemDetails.hsn_code;
    this.model.checkedVariant = itemDetails.checkedVariant;
    this.model.optionsData = itemDetails.optionsData;
    this.model.optionAmount = itemDetails.optionAmount;
    this.model.cb = itemDetails.current_cb;
    this.model.p_rate = itemDetails.p_rate;
    this.model.s_rate = itemDetails.s_rate;
    this.model.tax_mode = itemDetails.tax_mode;
    this.model.cust_rate_id = null;
    if (this.data.isCredit) {
      var indexof_s_rate = this.customerRates.findIndex(i => i.item_id == itemDetails.item_id);
      if (indexof_s_rate >= 0) {
        if (this.data.customer_item_rate_applicable) {
          this.model.s_rate = this.customerRates[indexof_s_rate].sale_rate;
        }
        this.model.cust_rate_id = this.customerRates[indexof_s_rate].cus_item_rates;
        if (this.data.use_qty_from_sale_history) {
          this.model.qty = this.customerRates[indexof_s_rate].qty;
        }

      }
    }
    this.model.mrp = itemDetails.mrp;
    this.model.hsn_code = itemDetails.hsn_code;
    this.model.barcode = itemDetails.barcode;
    this.model.taxslab = itemDetails.taxslab;
    this.model.tax_type = itemDetails.tax_type;
    this.model.item_type = itemDetails.item_type;
    this.model.inclusive_rate = itemDetails.s_rate;
    if (this.model.tax_type == 'i' && this.data.trans_type == 'Out') {
      this.model.s_rate = (parseFloat(((((this.model.inclusive_rate * this.model.qty) - this.model.dis_amt) * 100) / (100 + this.model.taxslab)).toFixed(2)) + this.model.dis_amt) / this.model.qty;
    }

    if (this.data.trans_type == "In") {
      const a = this.model.p_rate * this.model.qty;
      const b = this.model.qty * this.optionarray_amount
      this.model.amount = a + b;
    }
    if (this.data.trans_type == "Out") {
      const a = this.model.s_rate * this.model.qty;
      const b = this.model.qty * this.optionarray_amount
      this.model.amount = a + b;
    }
    if (this.model.tax_type == 'i' && this.data.trans_type == 'Out') {

      this.model.taxable_amt = this.model.amount;
    } else {
      this.model.taxable_amt = this.model.amount - this.model.dis_amt;
    }

    if (this.company.is_composite && this.data.trans_type == "Out") {
      this.model.sgst_per = 0;
      this.model.cgst_per = 0;
      this.model.igst_per = 0;
      this.model.sgst_amt = 0;
      this.model.cgst_amt = 0;
      this.model.igst_amt = 0;
    } else {
      if (itemDetails.tax_mode === "VAT") {
        this.model.vat_per = itemDetails.taxslab;
        this.model.vat_amt = this.model.taxable_amt * (this.model.vat_per / 100);
        this.model.vat_amt = Math.round(this.model.vat_amt * 100) / 100;
        this.model.total_tax = this.model.vat_amt;
        console.log("taxxxx-----", this.model.vat_per, this.model.vat_amt, this.model.taxable_amt)

      } else {
        if (this.ledgerObj.address_state == this.company.address_state) {
          this.model.sgst_per = itemDetails.taxslab / 2;
          this.model.sgst_amt = this.model.taxable_amt * (this.model.sgst_per / 100);
          this.model.sgst_amt = Math.round(this.model.sgst_amt * 100) / 100;
          this.model.cgst_per = itemDetails.taxslab / 2;
          this.model.cgst_amt = this.model.taxable_amt * (this.model.cgst_per / 100);
          this.model.cgst_amt = Math.round(this.model.cgst_amt * 100) / 100;
        } else {
          this.model.igst_per = itemDetails.taxslab;
          this.model.igst_amt = this.model.taxable_amt * (this.model.igst_per / 100);
          this.model.igst_amt = Math.round(this.model.igst_amt * 100) / 100;
        }
        this.model.total_tax = this.model.sgst_amt + this.model.cgst_amt + this.model.igst_amt;
      }


    }
    // console.log("1this.model.sgst_amt + this.model.cgst_amt + this.model.igst_amt", this.model.sgst_amt, this.model.cgst_amt, this.model.igst_amt, "total amt=", this.model.total_tax)
    this.model.total = this.model.taxable_amt + this.model.total_tax;
    console.log("this.model---", this.model)
    this.focusSetting();
  }
  onKeyUp() {
    if (this.model.tax_type == 'i' && this.data.trans_type == 'Out') {
      this.model.s_rate = (parseFloat(((((this.model.inclusive_rate * this.model.qty) - this.model.dis_amt) * 100) / (100 + this.model.taxslab)).toFixed(2)) + this.model.dis_amt) / this.model.qty;
    }
    if (this.data.trans_type == "In") {
      const a = this.model.p_rate * this.model.qty;
      const b = this.model.qty * this.optionarray_amount
      this.model.amount = a + b;
    }
    if (this.data.trans_type == "Out") {
      const a = this.model.s_rate * this.model.qty;
      const b = this.model.qty * this.optionarray_amount
      this.model.amount = a + b;
    }

    this.model.dis_per = this.model.dis_per ? this.model.dis_per : 0;
    if (this.model.dis_per) {
      this.model.dis_amt = this.model.amount * (this.model.dis_per / 100);
      this.model.dis_amt = Math.round(this.model.dis_amt * 100) / 100;
    }
    this.model.taxable_amt = this.model.amount - this.model.dis_amt;

    this.model.sgst_amt = this.model.taxable_amt * (this.model.sgst_per / 100);
    this.model.sgst_amt = Math.round(this.model.sgst_amt * 100) / 100;
    this.model.cgst_amt = this.model.taxable_amt * (this.model.cgst_per / 100);
    this.model.cgst_amt = Math.round(this.model.cgst_amt * 100) / 100;
    this.model.igst_amt = this.model.taxable_amt * (this.model.igst_per / 100);
    this.model.igst_amt = Math.round(this.model.igst_amt * 100) / 100;
    this.model.vat_amt = this.model.taxable_amt * (this.model.vat_per / 100);
    this.model.vat_amt = Math.round(this.model.vat_amt * 100) / 100;
    this.model.total_tax = this.model.sgst_amt + this.model.cgst_amt + this.model.igst_amt + this.model.vat_amt;
    this.model.total = this.model.taxable_amt + this.model.total_tax;
    // this.model.sgst_amt = Math.round(this.model.sgst_amt * 100) / 100;

  }


  onKeyClickSpiner() {
    if (this.model.tax_type == 'i' && this.data.trans_type == 'Out') {
      this.model.s_rate = (parseFloat(((((this.model.inclusive_rate * this.model.qty) - this.model.dis_amt) * 100) / (100 + this.model.taxslab)).toFixed(2)) + this.model.dis_amt) / this.model.qty;
    }
    if (this.data.trans_type == "In") {
      const a = this.model.p_rate * this.model.qty;
      const b = this.model.qty * this.optionarray_amount
      this.model.amount = a + b;
    }
    if (this.data.trans_type == "Out") {
      const a = this.model.s_rate * this.model.qty;
      const b = this.model.qty * this.optionarray_amount
      this.model.amount = a + b;
    }

    this.model.dis_per = this.model.dis_per ? this.model.dis_per : 0;
    if (this.model.dis_per) {
      this.model.dis_amt = this.model.amount * (this.model.dis_per / 100);
      this.model.dis_amt = Math.round(this.model.dis_amt * 100) / 100;
    }
    this.model.taxable_amt = this.model.amount - this.model.dis_amt;

    this.model.sgst_amt = this.model.taxable_amt * (this.model.sgst_per / 100);
    this.model.sgst_amt = Math.round(this.model.sgst_amt * 100) / 100;
    this.model.cgst_amt = this.model.taxable_amt * (this.model.cgst_per / 100);
    this.model.cgst_amt = Math.round(this.model.cgst_amt * 100) / 100;
    this.model.igst_amt = this.model.taxable_amt * (this.model.igst_per / 100);
    this.model.igst_amt = Math.round(this.model.igst_amt * 100) / 100; this.model.vat_amt = this.model.taxable_amt * (this.model.vat_per / 100);
    this.model.vat_amt = Math.round(this.model.vat_amt * 100) / 100;
    this.model.total_tax = (this.model.sgst_amt || 0) + (this.model.cgst_amt || 0) + (this.model.igst_amt || 0) + (this.model.vat_amt || 0);
    // console.log("3this.model.sgst_amt + this.model.cgst_amt + this.model.igst_amt", this.model.sgst_amt, this.model.cgst_amt, this.model.igst_amt, "total amt=", this.model.total_tax)
    this.model.total = this.model.taxable_amt + this.model.total_tax;
    this.model.sgst_amt = Math.round(this.model.sgst_amt * 100) / 100;
  }





  onPerAmtChange() {
    this.model.dis_amt = this.model.amount * (this.model.dis_per / 100);
    this.model.dis_amt = Math.round(this.model.dis_amt * 100) / 100;

    this.onKeyUp();
  }
  onDisAmtChange() {
    this.model.dis_per = 0;
    this.onKeyUp();
  }
  onSubmit() {


    if (this.data.item) {
      // console.log("this.model--", this.model)

      this.optionsArray = [];
      this.dialogRef.close(this.model);
    } else {
      this.optionsArray = [];
      console.log("this.model--", this.model)
      this.onItemAdd.emit(this.model);
      this.notifier.notify('success', 'Item added');
      this.initialiseItem();
      this.model = { sgst_amt: 0, cgst_amt: 0, igst_amt: 0, vat_amt: 0, dis_amt: 0, taxable_amt: 0, qty: 1, sgst_per: 0, cgst_per: 0, igst_per: 0, options: [] };
    }
  }

  close() {
    this.dialogRef.close();
  }
  //Initialise Item Object
  initialiseItem() {
    this.itemControl.setValue(null);
    this.itemObj = [];
    this.filteredOptions = this.itemControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  //to add Item
  onAddItemClick() {
    this.dialog.open(AddItemComponent, {
      width: '650px',
      data: "fromTransaction"
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          this.apiService.getItem(result.data.item_id)
            .subscribe((itemData: any) => {
              this.itemControl.setValue(itemData[0]);
              this.model.cb = itemData[0].cb;
              this.populateItemData(itemData[0]);
            }, (result: any) => {
              this.notifier.notify('error', 'unable to load data');
            });
          this.apiService.getLocationByItem(result.data.item_id)
            .subscribe((result: any) => {
              this.locObj = result;
            }, (result: any) => {
            });
        }
      });
  }
  //on focus on item search
  onFocus(e) {
    // this.filterItem = 'maintain_stock'
    // const filter = 'restaurant_menu_items'
    // console.log("this.filterItem--", this.filterItem, this.data)
    this.apiService.getCustomItemList(this.filterItem)
      .subscribe((result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }, (result: any) => {
      });
  }

  onLocationSelect(loc_id) {
    this.apiService.getLocationByCb(loc_id, this.selected_item_id).
      subscribe((result: any) => {
        this.model.cb = parseInt(result.cb);
      }, (result: any) => {

      });
  }
  getCustomerRate() {
    if (this.data.isCredit) {
      this.apiService.getCustomerItemRate(this.data.ledger_id)
        .subscribe((result: any) => {
          if (result.success != false) {
            this.customerRates = result;
          }
        },
          (result: any) => {
          });
    }
  }
  focusSetting() {
    if (this.isAutoFocus) {
      if (this.data.trans_type == "Out") {

        if (!this.model.s_rate) {

          this.s_rate1.nativeElement.focus();
        }
        if (this.model.s_rate) {

          this.qty1.nativeElement.focus();
        }

      }
      if (this.data.trans_type == "In") {
        if (this.model.p_rate) {
          this.qty1.nativeElement.focus();

        }
        if (!this.model.p_rate) {
          this.p_rate1.nativeElement.focus();
        }
      }
    }
  }
  onEnterFromQuantity() {
    if (this.isAutoFocus) {
      this.sub1.nativeElement.focus();
    }
  }

  openCustomDialog() {
    let dialogRef = this.dialog.open(ItemOptionsCustomComponent, {
      width: '720px',
      data: this.optionsArray
    });
    dialogRef.componentInstance.onItemOptionSelected.subscribe((result) => {
      if (result) {
        this.optionsArray = result.selectedItemOptions;
        if (result.selectedItemOptions.length > 0) {
          this.model.options = result.selectedItemOptions;
          if (this.data.item) {
            let item = this.itemObj.filter(item => item.item_id === this.model.item_id)[0];
            this.model.s_rate = result.rate ? item.s_rate + result.rate : this.model.s_rate;
          } else {
            this.model.s_rate = result.rate ? this.model.s_rate + result.rate : this.model.s_rate;
          }
          this.model.qty = result.quantity ? result.quantity : this.model.qty;
        } else {
          this.model.options = result.selectedItemOptions;
          this.model.s_rate = this.clonedModel.s_rate;
          this.model.qty = 1;
        }
        this.populateItemData(this.model);
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
