import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

import { DataService } from '../../data.service';
import { EditInvoiceComponent } from '../../invoice/edit-invoice/edit-invoice.component';
import { EditInvoiceReturnComponent } from '../../invoice-return/edit-invoice-return/edit-invoice-return.component';
import { EditDeliveryOrderComponent } from '../../delivery-order/edit-delivery-order/edit-delivery-order.component';
import { EditPurchaseComponent } from '../../purchase/edit-purchase/edit-purchase.component';
import { EditPurchaseReturnComponent } from '../../purchase-return/edit-purchase-return/edit-purchase-return.component';
import { EditGrnComponent } from '../../grn/edit-grn/edit-grn.component';
import { EditPaymentComponent } from '../../payment/edit-payment/edit-payment.component';
import { EditReceiptComponent } from '../../receipt/edit-receipt/edit-receipt.component';
import { EditExpenseComponent } from '../../expense/edit-expense/edit-expense.component';
import { EditJournalComponent } from '../../journal/edit-journal/edit-journal.component';
import { EditCashBankComponent } from '../../cash-bank/edit-cash-bank/edit-cash-bank.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EditSalesOrderComponent } from '../../sales-order/edit-sales-order/edit-sales-order.component';
import { EditPurchaseOrderComponent } from '../../purchase-order/edit-purchase-order/edit-purchase-order.component';
import { EditCreditNoteComponent } from '../../credit-note/edit-credit-note/edit-credit-note.component';
import { EditStockAdjustmentComponent } from '../../stock-adjustment/edit-stock-adjustment/edit-stock-adjustment.component';
import { MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../date.adapter';

@Component({
  selector: 'app-item-stock-details',
  templateUrl: './item-stock-details.component.html',
  styleUrls: ['./item-stock-details.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class ItemStockDetailsComponent implements OnInit {
  accountVoucherObj: MatTableDataSource<any>;
  constructor(private dialogRef: MatDialogRef<ItemStockDetailsComponent>,
    private apiService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    // console.log("data---", data)

  }
  item_name: any = this.data.item_name;
  itemControl = new FormControl();
  options: any;
  filteredOptions: Observable<string[]>;
  itemObj: any[];
  openDialog: any;
  dataObj: any = {};
  catObj: any;
  itemStockData: any;
  ob: any = 0;
  obType: any;
  cbType: any;
  cb: any = 0;
  totalIn: any = 0;
  totalOut: any = 0;
  model: any = { from_date: this.data.from_date, to_date: this.data.to_date, item_id: this.data.item_id };
  displayedColumns = ['trans_date', 'trans_no', 'v_type_name', 'ledger_name', 'in_item', 'out_item',];

  ngOnInit() {
    this.load();
  }
  _filter(value) {
    const filterValue = value.toLowerCase();
    return this.itemObj.filter(item => item.item_code.toLowerCase().includes(filterValue) || item.item_name.toLowerCase().includes(filterValue));
  }

  displayFn(Item) {
    return Item ? Item.item_name : "";
  }
  load() {
    this.model.to_date.setHours(23, 30, 60, 60);
    this.model.from_date.setHours(6, 0, 0, 0);
    this.apiService.getItemStockDetails(this.model.from_date, this.model.to_date, this.model.item_id)
      .subscribe((result: any) => {
        this.itemStockData = result;
        this.calculate();
      }, (result: any) => {
      });

    this.apiService.getItem(this.model.item_id)
      .subscribe((result: any) => {
        this.itemObj = result[0];
        this.itemControl.setValue(this.itemObj);
      }, (result: any) => {
      });
  }
  search(event) {
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl.value.length > 1) {
        this.apiService.getFilterItemList(this.itemControl.value)
          .subscribe((result: any) => {
            this.itemObj = result;
            this.filteredOptions = this.itemControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    }
  }
  itemSelection(event: MatAutocompleteSelectedEvent) {
    this.model.item_id = event.option.value.item_id;
    this.model.item_name = event.option.value.item_name;
  }
  calculate() {
    this.totalIn = 0;
    this.totalOut = 0;
    for (var i = 0; i < this.itemStockData.length; i++) {
      if (this.itemStockData[i].sq == 1) {
        this.ob = parseInt(this.itemStockData[i].in_item) ? parseInt(this.itemStockData[i].in_item) : parseInt(this.itemStockData[i].out_item);
      }
      if (this.itemStockData[i].sq == 3) {
        this.cb = parseInt(this.itemStockData[i].in_item) ? parseInt(this.itemStockData[i].in_item) : parseInt(this.itemStockData[i].out_item);
      }
      if (this.itemStockData[i].sq == 2) {
        this.totalIn = this.totalIn + parseInt(this.itemStockData[i].in_item);
        this.totalOut = this.totalOut + parseInt(this.itemStockData[i].out_item);
      }
    }
  }
  serachClick() {
    this.item_name = this.model.item_name;
    this.load();
  }

  printClick() {
    this.model.to_date.setHours(23, 30, 60, 60);
    this.model.from_date.setHours(6, 0, 0, 0);
    this.model.trans_type = "ItemDetails";
    this.model.voucherList = this.itemStockData;
    // console.log(this.model);

    this.apiService.downloadPdf(this.model)
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'item_detail' + new Date().toLocaleDateString() + '.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);
      }, (result: any) => {
      });
  }
  close() {
    this.dialogRef.close();
  }
  edittransaction(row) {
    if (row.v_type_name == "Invoice")
      this.openDialog = EditInvoiceComponent;
    if (row.v_type_name == "InvoiceReturn")
      this.openDialog = EditInvoiceReturnComponent;
    if (row.v_type_name == "DeliveryOrder")
      this.openDialog = EditDeliveryOrderComponent;
    if (row.v_type_name == "Purchase")
      this.openDialog = EditPurchaseComponent;
    if (row.v_type_name == "PurchaseReturn")
      this.openDialog = EditPurchaseReturnComponent;
    if (row.v_type_name == "GRN")
      this.openDialog = EditGrnComponent;
    if (row.v_type_name == "Payment")
      this.openDialog = EditPaymentComponent;
    if (row.v_type_name == "Receipt")
      this.openDialog = EditReceiptComponent;
    if (row.v_type_name == "Expense")
      this.openDialog = EditExpenseComponent;
    if (row.v_type_name == "Journal")
      this.openDialog = EditJournalComponent;
    if (row.v_type_name == "CashBank")
      this.openDialog = EditCashBankComponent;
    if (row.v_type_name == "SalesOrder")
      this.openDialog = EditSalesOrderComponent;
    if (row.v_type_name == "PurchaseOrder")
      this.openDialog = EditPurchaseOrderComponent;
    if (row.v_type_name == "CreditNote")
      this.openDialog = EditCreditNoteComponent;
    if (row.v_type_name == "StockAdjustment")
      this.openDialog = EditStockAdjustmentComponent;

    this.dialog.open(this.openDialog, {
      width: '1000px',
      panelClass: 'dialog-class',
      data: row,
      autoFocus: false
    })
      .afterClosed().subscribe(result => {
        if (result == 'Edited') {
          this.load();
        }
      });
  }
}
