<h2 *ngIf="!data" mat-dialog-title class="color-h3">Add New Unit</h2>
<h2 *ngIf="data" mat-dialog-title class="color-h3">Edit Unit</h2>
<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <mat-form-field class="contract-full-width">
        <input matInput placeholder="Unit Code:" [(ngModel)]="model.unit_code" name="unit_code" required>
        <mat-error>required</mat-error>
      </mat-form-field>
      <mat-form-field class="contract-full-width">
        <input matInput placeholder="Unit Name:" [(ngModel)]="model.unit_name" name="unit_name" required>
        <mat-error>required</mat-error>
      </mat-form-field>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" class="btn mat-raised-button" (click)="close()">Close</button>
    <button type="submit" class="btn-primary mat-raised-button" (click)="onSubmit()" [disabled]="f.invalid">Save</button>
  </mat-dialog-actions>
</div>