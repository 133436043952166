<h2 *ngIf="!data" mat-dialog-title class="color-h3">Add New Category</h2>
<h2 *ngIf="data" mat-dialog-title class="color-h3">Edit Category</h2>
<div class="table-details">
  <mat-dialog-content>
    <form name="form" #f="ngForm">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Category Code:" [(ngModel)]="model.category_code" name="category_code"
              required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Sequence:" [(ngModel)]="model.seq_no" name="seq_no" required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="contract-full-width">
            <input matInput placeholder="Category Name:" [(ngModel)]="model.category_name" name="category_name"
              required>
            <mat-error>required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="isRestaurantCategory">
          <mat-form-field class="contract-full-width">
            <input
              type="text"
              placeholder="Kitchen"
              aria-label="Number"
              matInput
              (focus)="kitchenonfocus($event)"
              (keyup)="searchkitchen($event)"
              [formControl]="kitchen"
              [matAutocomplete]="autoKitchen"
              required
            />
            <mat-autocomplete
              autoActiveFirstOption
              #autoKitchen="matAutocomplete"
              [displayWith]="displaykitchen"
              (optionSelected)="kitchenselection($event)"
            >
              <mat-option [value]="0">
                <div (click)="onAddKitchenClick()" class="text-center">
                  <span>Add New Kitchen</span>
                </div>
              </mat-option>
              <mat-option *ngFor="let kitchen of kitchens" [value]="kitchen">
                {{ kitchen.kitchen_name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <section class="form-section">
          <mat-checkbox (change)="checkStatus($event)" [(ngModel)]="model.maintain_stock" name="maintain_stock">Show In
            Stock Register</mat-checkbox>
          <mat-checkbox *ngIf="isRestaurantCategory" class="ml-3" (change)="restaurantmenu($event)"
            [(ngModel)]="model.restaurant_menu_items" name="restaurant_menu_items">Restaurant Menu Items</mat-checkbox>
        </section>
      </div>
      <section class="form-section section1">
        <mat-checkbox class="ml-3" name="consumable" [ngModel]="condition"
          (change)="consumableStatus($event)">Consumable</mat-checkbox>
        <mat-checkbox class="ml-3" name="disposal" [ngModel]="disposalcondition"
          (change)="disposiableStatus($event)">Packaging Material</mat-checkbox>
      </section>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button *ngIf="data" type="button" class="mat-raised-button btn" (click)="subCategoryClick()"
      style="margin-right: 140px;">Sub Category</button>
    <span class="fill-space"></span>
    <button type="button" class="btn mat-raised-button" (click)="close()">Close</button>
    <button type="submit" class="btn-primary mat-raised-button" (click)="onSubmit()"
      [disabled]="f.invalid">Save</button>
  </mat-dialog-actions>
</div>