  <div class="lic-section">
      <div class="row lic-div">
        <div class="col-md-12">
          <table class="table table-borderless licenceInfo-tbl">
            <tr *ngIf="client_id">
              <th class="clientid-th">Client Id</th>
              <td>:</td>
              <td>{{client_id}}
                  <button mat-icon-button (click)="copyToClipboard()" title="Copy to Clipboard" ><i class="fa fa-clipboard" aria-hidden="true"></i></button>
              </td>
            </tr>
            <tr *ngIf="status">
              <th>Status</th>
              <td>:</td>
              <td>
                  <span *ngIf="status == 'Active'" class="badge badge-success">{{status}}</span>
                  <span *ngIf="status != 'Active' && status != 'Trial mode'" class="badge badge-danger">{{status}}</span>
                  <span *ngIf="status == 'Trial mode'" class="badge badge-warning">{{status}}</span>

              </td>
            </tr>
            <tr *ngIf="isLicence && licenceObj">
              <th>Registerd Clients</th>
              <td>:</td>
              <td>{{licenceObj.CLIENTID}}</td>
            </tr>
            <tr *ngIf="isLicence && licenceObj">
              <th>Client Name</th>
              <td>:</td>
              <td>{{licenceObj.CLIENTNAME}}</td>
            </tr>
            <tr *ngIf="isLicence && licenceObj">
              <th>Expiry Date</th>
              <td>:</td>
              <td>{{expiry}}</td>
            </tr>
            <tr *ngIf="isLicence && licenceObj">
              <th>Installation Date</th>
              <td>:</td>
              <td>{{licenceObj.INSTALLATIONDATE}}</td>
            </tr>
            <tr *ngIf="isLicence && licenceObj">
              <th>Product Version</th>
              <td>:</td>
              <td>{{licenceObj.PRODUCTVERSION}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <input #licenceInput hidden="true" type="file" (change)="licenceChange($event)" accept=".lic">
            <button mat-flat-button class="btn mr-1" (click)="licenceInput.click()">Upload Licence</button>
            <span *ngIf="file">{{file.name}}</span>
            <span *ngIf="!file">No File Choosen</span>
          </div>
        </div>
        <div class="col-md-4 text-right">
          <button mat-flat-button class="mr-1 btn" (click)="licenceUpdate()">Update</button>
        </div>
      </div>
  </div>
