import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../data.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import {MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter  } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../date.adapter';
@Component({
  selector: 'app-due-receipt',
  templateUrl: './due-receipt.component.html',
  styleUrls: ['./due-receipt.component.css'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class DueReceiptComponent implements OnInit {
  constructor(
    private apiService: DataService,
    private dialogRef: MatDialogRef<DueReceiptComponent>,
    public dialog: MatDialog,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    dueReceiptObj:any;
    displayedColumns = ['check','trans_date', 'trans_no','display_trans_no','discount_amt', 'net_amount','advance','paid','due'];
    isPOS:boolean=false;
    isRstPOS:boolean=false;
    mapArr:any;
    invoicePaymentObj = [];
  ngOnInit() {
    this.load();
  }

  load() {
    this.apiService.getAccountMapList()
    .subscribe((result: any) => {
      this.mapArr = result;
      this.findAccSetting();
    }, (result: any) => {
    });
    this.apiService.getDueReceiptList(this.data)
      .subscribe((result: any) => {
        this.dueReceiptObj = result;
        this.dueReceiptObj.forEach(element => {
               element.isChecked = false;
               element.bill_amt = element.due; 
          });

      }, (result: any) => {
      });
  }

  findAccSetting() {
    var indexofPOS = this.mapArr.findIndex(i => i.key === "Enable_POS");
    var indexofRstPOS = this.mapArr.findIndex(i => i.key === "Enable_Restaurant_POS");

    if (this.mapArr[indexofPOS].checkbox_value && !this.mapArr[indexofRstPOS].checkbox_value) {
      this.isPOS = true;
      this.displayedColumns = ['check','trans_date', 'trans_no','display_trans_no','customer_name','discount_amt','net_amount','advance','paid','due'];
    }
   
    if (this.mapArr[indexofRstPOS].checkbox_value && !this.mapArr[indexofPOS].checkbox_value) {
      this.isRstPOS = true;
      this.displayedColumns = ['check','trans_date', 'trans_no','display_trans_no','table_name','discount_amt','net_amount','advance','paid','due'];
    }
    if (this.mapArr[indexofRstPOS].checkbox_value && this.mapArr[indexofPOS].checkbox_value) {
      this.isRstPOS = true;
      this.isPOS = true;
      this.displayedColumns = ['check','trans_date', 'trans_no','display_trans_no','table_name','customer_name','discount_amt','net_amount','advance','paid','due'];
    }
  }
  onSelection(data) {
      if(data.isChecked){
        this.invoicePaymentObj.push(data);
      }else{
        const index = this.invoicePaymentObj.indexOf(data.transaction_id);
        if (index)
        this.invoicePaymentObj.splice(index, 1);
      }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  cancelPaymentPopup(){
    this.dialogRef.close();
  }

  addAllTransactionData(){
    this.dialogRef.close(this.invoicePaymentObj);
  }
}
 