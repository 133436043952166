import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
// import { Label } from 'ng2-charts';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-sales-invoice',
  templateUrl: './sales-invoice.component.html',
  styleUrls: ['./sales-invoice.component.css']
})
export class SalesInvoiceComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };
  successFlag: Boolean = false;
  graphData: any;
  monthArr: any;
  countArr: any;
  amountArr: any;
  constructor(private apiService: DataService) { }
  model: any = { from_date: new Date(), to_date: new Date };

  ngOnInit() {
    this.getInvoiceCountAmt();

  }

  getInvoiceCountAmt() {
    //********* Get Current Year **********/
    var today = new Date();
    var curMonth = today.getMonth();
    if (curMonth >= 3) { //
      this.model.from_date = new Date(today.getFullYear(), 3, 1, 0, 0, 0, 0);
      this.model.to_date = new Date(today.getFullYear() + 1, 2, 31, 23, 59, 59);

    } else {
      this.model.from_date = new Date(today.getFullYear() - 1, 3, 1, 0, 0, 0, 0);
      this.model.to_date = new Date(today.getFullYear(), 2, 31, 0, 23, 59, 59);

    }

    // this.model.from_date.setHours(1, 0, 0, 0); 
    // this.model.to_date.setHours(23, 30, 60, 60);



    this.apiService.getInvoiceCountTrans(this.model.from_date, this.model.to_date)
      .subscribe((result: any) => {

        this.graphData = result;
        this.monthArr = this.graphData.map(item => item.month_name);
        this.countArr = this.graphData.map(item => item.count);
        this.amountArr = this.graphData.map(item => item.amount);
        this.successFlag = true;

        this.barChartLabels = this.monthArr;
        this.barChartData = [
          { data: this.countArr, label: 'Count' },
          { data: this.amountArr, label: 'Amount' }];



      }, (result: any) => {

      });
  }


  public barChartLabels: string[] = this.monthArr;
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[] = [];

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public randomize(): void {
    this.barChartType = this.barChartType === 'bar' ? 'line' : 'bar';
  }
}
