<div class="dialog-title" mat-dialog-title>
  <div class="row">
    <div class="col-md-6 col-9">
      <span class="color-h3">Due Payment :</span>
    </div>
    <div class="col-md-6 col-3 right">
      <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
</div>
<table mat-table [dataSource]="duePaymentObj" matSort>


  <ng-container matColumnDef="check">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Check</th>
    <td mat-cell *matCellDef="let row" data-label="Check">
      <mat-checkbox [(ngModel)]="row.isChecked"   (change)="onSelection(row)" >
   </mat-checkbox>
    </td>
    <td mat-footer-cell *matFooterCellDef> 
      <button mat-raised-button color="primary" [disabled]="invoicePaymentObj.length == 0" (click)="addAllTransactionData()">Save</button>  
    </td>
    
  </ng-container> 

  <ng-container matColumnDef="trans_date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let row" data-label="Date">{{row.trans_date | date: 'dd/MMM/yyyy'}}</td>
    <td mat-footer-cell *matFooterCellDef>
      <button mat-raised-button (click)="cancelPaymentPopup()">Cancel</button>  
    </td>
  </ng-container>
  <ng-container matColumnDef="trans_no">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Voucher No</th>
    <td mat-cell *matCellDef="let row" data-label="Voucher No">{{row.trans_no}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="bill_no">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Bill No</th>
    <td mat-cell *matCellDef="let row" data-label="Bill No">{{row.bill_no}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="net_amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
    <td mat-cell *matCellDef="let row" data-label="Amount">
      <a [routerLink]="" (click)="onSelection(row)">{{row.net_amount | number : '1.2-2'}} </a>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="advance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Advance</th>
    <td mat-cell *matCellDef="let row" data-label="Advance">{{row.advance | number : '1.2-2'}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="paid">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
    <td mat-cell *matCellDef="let row" data-label="Paid">{{row.paid | number : '1.2-2'}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="due">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Due</th>
    <td mat-cell *matCellDef="let row" data-label="Due">{{row.due | number : '1.2-2'}}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>