<div style="background-color: white">
  <div *ngIf="is_trial == 'true'" class="row trial" [ngClass]="themes">
    <div class="col-md-12 text-center alert alert-danger p-0">
      <!-- <span>You are in <strong>Trial mode </strong> , <a [routerLink]="" (click)="redirectToCompanyInfo()"
          class="alert-link">Activate for more features</a>.</span>
    </div>  -->

      <span>You are in <strong>Trial mode </strong> </span>
    </div>
  </div>
  <mat-toolbar
    [ngClass]="{
      'fixed-header': is_trial == 'false',
      'trial-header': is_trial == 'true'
    }"
  >
    <div class="row top-section mt-2" style="margin-top: 14px !important">
      <div class="col-md-2 col-3 mt-1" style="display: flex">
        <button
          type="button"
          style="margin-top: 5px"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="onToolbarMenuToggle()"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <!-- <span *ngIf="width > 768">IntelliBooks </span><small> {{company_name}}</small> -->
        <!-- <span> <img src="../../assets/images/logoNew.png" alt="" class="logodb"> </span> -->
        <span>
          <p style="margin-top: 5px; font-size: 18px">{{ groupname }}</p>
        </span>
      </div>
      <!-- searchbaar----------------------------------------------------- -->
      <div class="col-md-6 col-0 mt-1 p-0 search-button">
        <!-- <mat-form-field > -->
        <input
          type="text"
          placeholder="Search..."
          aria-label="Search"
          class="form-control me-2"
          [(ngModel)]="selectedSearchFieldValue"
          [formControl]="itemControl"
          [matAutocomplete]="auto"
          (keyup)="applyFilter($event)"
          (focus)="focus($event)"
          (keyup.enter)="routingByEnterKey(selectedSearchFieldValue)"
        />
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
          <mat-option
            *ngFor="let i of searchitems"
            (click)="routing(i)"
            routerLink="/{{ i.route }}"
            [value]="i"
          >
            <span class="item-font">{{ i.menu_name }}</span>
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-md-4 col-9 right user-sec">
        <!-- <a class="right-menu-icon mr-4" mat-icon-button>
          <mat-icon>language</mat-icon>
        </a> -->

        <!-- <mat-menu #notification="matMenu">
          <mat-card>
            <mat-card-title> Table Reservation </mat-card-title>
            <mat-card-content>
              <div class="w-100 not-head">
                <p>Total Request</p>
                <span>Total : {{ requestCount }} </span><br />
                <span>UnApproved : {{ unApprovedRequestCount }}</span
                ><br />
              </div>
              <mat-list role="list"> </mat-list>
            </mat-card-content>
            <mat-card-actions>
              <button
                type="button"
                class="btn btn-primary btn-sm not-btn"
                (click)="tableReservationDashboard()"
              >
                Dashboard
              </button> -->
        <!-- <button type="button" class="btn btn-danger btn-sm not-btn"
                (click)="onRejectClick($event, kot.transaction_id)">Reject</button> -->
        <!-- </mat-card-actions>
          </mat-card>
          <div *ngFor="let data of websiteInvoiceData" class="p-2 not-sec">
            <mat-card style="width: 210px"> -->
        <!-- <mat-card-content>
                <div class="w-100 not-head">
                  <div
                    class="row"
                    style="color: rgb(233, 31, 58); margin-top: 16px"
                  >
                    <div class="col-md-5 col-sm-6">
                      <button
                        style="
                          font-size: small;
                          color: rgb(47, 165, 155);
                          
                        "
                        mat-raised-button
                        (mouseenter)="data.show_details = true"
                        (mouseleave)="data.show_details = false"
                      >
                        Customer
                      </button>
                    </div>

                    <button
                      mat-raised-button
                      (click)="toggleDetails(data, $event)"
                    >
                      Customer
                    </button>

                    <div
                      class="col-md-5 col-sm-6 d-flex"
                      style="margin-top: 1px"
                    >
                      <mat-icon
                        style="font-size: 16px; float: left; cursor: pointer"
                        (click)="kot(data)"
                        >save</mat-icon
                      >
                      <mat-icon
                        style="font-size: 16px; cursor: pointer"
                        (click)="invoice(data)"
                        >print</mat-icon
                      >
                    </div>
                    <br />
                    <div *ngIf="data.show_details" style="margin-left: 15px">
                      <span>
                        Customer Name:
                        <span style="color: rgb(26, 21, 22); margin-top: 5px"
                          >{{ data.customer_name }}.</span
                        >
                      </span>
                      <br />
                      <span>
                        Contact:
                        <span style="color: rgb(26, 21, 22)"
                          >{{ data.contact_no }}.</span
                        >
                      </span>
                      <br />
                      <span>
                        Address:
                        <span style="color: rgb(26, 21, 22)">{{
                          data.shipping_address1
                        }}</span>
                      </span>
                      <hr />
                    </div>
                  </div>

                  <p style="margin-top: 10px">
                    #Invoice : {{ data.display_trans_no }}
                  </p>

                  <span>#KOT : {{ data.trans_no }}</span
                  ><br />
                  <span>Date: {{ data.trans_date | date : "short" }}</span
                  ><br />

                  <span>
                    Trans Amount: <i class="fa fa-{{ currency }}"></i
                    >{{ data.net_amount | number : "1.0-0" }}
                  </span>
                </div>

                <mat-list role="list"> -->
        <!-- <mat-list-item role="listitem" class="ml-3">
                    <div class="w-100">
                      <span>#KOT : {{data.trans_no}}</span>
                      <span>Date: {{data.trans_date | date :'short'}}</span>
                      <span>Trans Amount: <i class="fa fa-{{currency}}"></i>{{data.net_amount | number :
                        '1.0-0'}}</span>
                    </div>
                  </mat-list-item> -->
        <!-- <mat-list-item *ngFor="let item of kot.items_details" role="listitem" class="ml-3">
                    <div class="w-100">
                        <span>{{convertFloat(item.qty)}} x {{item.item_name}}</span>
                        <span class="pull-right pr-3"><i class="fa fa-{{currency}}"></i>{{item.amount | number : '1.0-0'}}</span>
                    </div>
                  </mat-list-item> -->
        <!-- </mat-list>
              </mat-card-content> -->
        <!-- <mat-card-content>
                <div class="w-100 not-head">
                  <div
                    class="row"
                    style="color: rgb(233, 31, 58); margin-top: 10px"
                  >
                    <div class="col-md-6 col-sm-6">
                      <button
                        class="btn-primary btn-sm"
                        mat-raised-button
                        (click)="toggleDetails(data, $event)"
                      >
                        Customer
                      </button>
                    </div>
                    <div
                      class="col-md-4 col-sm-6 d-flex"
                      style="margin-top: 1px; margin-left: 10px"
                    >
                      <mat-icon
                        class="text-primary"
                        style="font-size: 20px; float: left; cursor: pointer"
                        (click)="kot(data)"
                        >save</mat-icon
                      >
                      <mat-icon
                        class="text-primary"
                        style="
                          font-size: 20px;
                          cursor: pointer;
                          margin-left: 15px;
                        "
                        (click)="invoice(data)"
                        >print</mat-icon
                      >
                    </div>
                    <br />
                    <div *ngIf="data.show_details" style="margin-left: 15px">
                      <span>
                        Customer Name:
                        <span style="color: rgb(26, 21, 22); margin-top: 5px"
                          >{{ data.customer_name }}.</span
                        >
                      </span>
                      <br />
                      <span>
                        Contact:
                        <span style="color: rgb(26, 21, 22)"
                          >{{ data.contact_no }}.</span
                        >
                      </span>
                      <br />
                      <span>
                        Address:
                        <span style="color: rgb(26, 21, 22)">{{
                          data.shipping_address1
                        }}</span>
                      </span>
                      <hr />
                    </div>
                  </div>

                  <p style="margin-top: 10px">
                    #Invoice : {{ data.display_trans_no }}
                  </p>

                  <span>#KOT : {{ data.trans_no }}</span
                  ><br />
                  <span>Date: {{ data.trans_date | date : "short" }}</span
                  ><br />

                  <span>
                    Trans Amount: <i class="fa fa-{{ currency }}"></i
                    >{{ data.net_amount | number : "1.0-0" }}
                  </span>
                </div>
              </mat-card-content>
              <mat-card-actions> -->
        <!-- <p
                  *ngIf="data.advance === 0"
                  style="color: crimson; margin-top: 16px"
                >
                  Pay At Restaurant
                  <mat-icon
                    [ngClass]="{ muted: data.is_muted }"
                    style="
                      cursor: pointer;
                      float: right;
                      margin-bottom: 10px;
                      color: rgb(173, 13, 13);
                    "
                    (click)="muteAlertRingtone(data, $event)"
                    >volume_off</mat-icon
                  >
                </p> -->

        <!-- <p
                  *ngIf="data.advance === 0"
                  style="color: crimson; margin-top: 16px"
                >
                  Pay At Restaurant
                  <mat-icon
                    [ngClass]="{ muted: data.is_muted }"
                    style="
                      cursor: pointer;
                      float: right;
                      margin-bottom: 10px;
                      color: rgb(173, 13, 13);
                    "
                    (click)="muteAlertRingtone(data, $event)"
                  >
                    {{ data.is_muted ? "volume_off" : "volume_up" }}
                  </mat-icon>
                </p> -->

        <!-- <p
                  *ngIf="data.advance === 0"
                  style="color: crimson; margin-top: 18px"
                >
                  Pay At Restaurant
                  <mat-checkbox
                    [checked]="data.is_muted"
                    (change)="muteAlertRingtone(data, $event)"
                    style="
                      cursor: pointer;
                      float: right;
                      margin-bottom: 12px;
                      color: rgb(173, 13, 13);
                    "
                  >
                    {{ data.is_muted ? "Seen" : "Unseen" }}
                  </mat-checkbox>
                </p>

                <p
                  *ngIf="data.advance === data.net_amount"
                  style="color: green; margin-top: 16px"
                >
                  Paid
                  <mat-icon
                    [ngClass]="{ muted: data.is_muted }"
                    style="
                      cursor: pointer;
                      float: right;
                      margin-bottom: 10px;
                      color: rgb(173, 13, 13);
                    "
                    (click)="muteAlertRingtone(data, $event)"
                    >volume_off</mat-icon
                  >
                </p>
              </mat-card-actions>
            </mat-card> -->
        <!-- </div>
        </mat-menu> -->
        <span *ngIf="width > 768" class="f15"></span>
        <a
          class="right-menu-icon"
          [matMenuTriggerFor]="menu"
          style="
            color: black;
            padding: 15px 2px;
            font-size: 14px;
            border-radius: 10px;
          "
          >{{ company_name | uppercase }}
          <a
            _ngcontent-c8=""
            aria-haspopup="true"
            class="right-menu-icon mat-icon-button"
            mat-icon-button=""
            tabindex="0"
            aria-disabled="false"
            ><span class="mat-button-wrapper">
              <mat-icon
                _ngcontent-c8=""
                style="margin: 0% !important"
                class="mat-icon notranslate material-icons mat-icon-no-color"
                role="img"
                aria-hidden="true"
                >account_circle</mat-icon
              ></span
            >
            <div
              class="mat-button-ripple mat-ripple mat-button-ripple-round"
              matripple=""
            ></div>
            <div class="mat-button-focus-overlay"></div>
            <span
              ><i class="conn"
                ><mat-icon
                  [ngClass]="{
                    'color-success': is_connected,
                    'color-danger': !is_connected
                  }"
                  >fiber_manual_record</mat-icon
                ></i
              ></span
            >
          </a>
          <!-- <mat-icon></mat-icon> -->
        </a>
        <mat-menu #menu="matMenu">
          <div
            mat-menu-item
            class="user text-center"
            style="border-bottom: 2px solid"
          >
            <span style="font-weight: 600; font-size: 16px"
              >{{ userFullName }}({{ userRole }})</span
            >
          </div>
          <a
            *ngIf="role_id == 1 || allow_multicompany == 'true'"
            mat-menu-item
            (click)="onCompanyManage()"
          >
            <mat-icon>home</mat-icon>
            <span>Company Management</span>
          </a>
          <a mat-menu-item (click)="changePass()">
            <mat-icon>edit</mat-icon>
            <span>Change Password</span>
          </a>
          <a mat-menu-item routerLink="/logout">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </a>
          <a mat-menu-item (click)="onBackupClick()">
            <mat-icon>backup</mat-icon>
            <span>Backup DB</span>
          </a>
        </mat-menu>
        <!-- <a
          class="right-menu-icon btn ml-3"
          mat-icon-button
          (click)="onrightToolbarMenuToggle()"
        >
          <mat-icon>notifications</mat-icon
          ><i *ngIf="kotCount > 0" class="badge badge-danger not">{{
            kotCount
          }}</i>
        </a> -->

        <a
          class="right-menu-icon btn ml-3"
          mat-icon-button
          (click)="onrightToolbarMenuToggle()"
        >
          <mat-icon>notifications</mat-icon>
          <i *ngIf="kotCount > 0" class="badge badge-danger not">{{
            kotCount
          }}</i>
          <i *ngIf="websiteInvoicecount > 0" class="badge badge-danger not">{{
            websiteInvoicecount
          }}</i>
        </a>

        <!-- <a
          *ngIf="checkEnablewebsiteFlag"
          class="right-menu-icon mr-4"
          mat-icon-button
          [matMenuTriggerFor]="notification"
        >
          <mat-icon>language</mat-icon
          ><i *ngIf="websiteInvoicecount > 0" class="badge badge-danger not">{{
            websiteInvoicecount
          }}</i>
        </a> -->
        <!-- <mat-menu #notification="matMenu">
          <div *ngFor="let kot of kotArr" class="p-2 not-sec">
            <mat-card>
              <mat-card-content>
                <div class="w-100 not-head">
                  <span>#KOT: {{kot.trans_no}}</span>
                  <span class="pull-right">Table: {{kot.name}}</span>
                </div>
                <mat-list role="list">
                  <mat-list-item *ngFor="let item of kot.items_details" role="listitem" class="ml-3">
                    <div class="w-100">
                      <span>{{convertFloat(item.qty)}} x {{item.item_name}}</span>
                      <span class="pull-right pr-3"><i class="fa fa-{{currency}}"></i>{{item.amount | number :
                        '1.0-0'}}</span>
                    </div>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
              <mat-card-actions>
                <button type="button" class="btn btn-primary btn-sm not-btn"
                  (click)="onApproveClick($event,kot)">Approve</button>
                <button type="button" class="btn btn-danger btn-sm not-btn"
                  (click)="onRejectClick($event, kot.transaction_id)">Reject</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </mat-menu> -->
      </div>
    </div>
  </mat-toolbar>
  <!-- Sidebar -->
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      fixedInViewport="true"
      mode="side"
      #sidenav
      id="sidenav"
      [class.menu-open]="isMenuOpen"
      [class.menu-close]="!isMenuOpen"
      opened
    >
      <mat-nav-list>
        <hr />
        <div
          routerLinkActive="active"
          routerLink="{{ get_routerlink() }}"
          (click)="dashboardClick()"
        >
          <div role="button" style="padding: 5px 10px">
            <span class="mat-content">
              <mat-panel-title>
                <mat-icon role="img" aria-hidden="true"> home</mat-icon>
                Dashboard
              </mat-panel-title>
            </span>
          </div>
        </div>
        <div
          class="big-screen-view"
          *ngFor="let group of menuGroupObj"
          style="padding: 5px 10px; cursor: pointer"
        >
          <mat-panel-title
            (click)="sliderdata(group)"
            *ngIf="group.menuItem && group.menuItem[0]"
            routerLink="/{{ group.menuItem[0].route }}"
          >
            <mat-icon>{{ group.menu_group_icon }}</mat-icon>
            {{ group.menu_group_name }}
          </mat-panel-title>
        </div>
        <div class="small-screen-view" *ngFor="let group of menuGroupObj">
          <mat-expansion-panel
            *ngIf="group.menuItem && group.menuItem.length"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>{{ group.menu_group_icon }}</mat-icon>
                {{ group.menu_group_name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a
              *ngFor="let item of group.menuItem"
              mat-list-item
              routerLink="/{{ item.route }}"
              routerLinkActive="active-list-item"
              (click)="mobilemenuCTRL()"
            >
              <mat-icon>{{ item.icon }}</mat-icon
              >{{ item.menu_name }}
            </a>
          </mat-expansion-panel>
        </div>
        <hr />
        <div>
          <div role="button" style="padding: 5px 10px">
            <span class="mat-content">
              <mat-panel-title>
                <mat-icon role="img" aria-hidden="true"> help</mat-icon
                ><a
                  target="_blank"
                  href="{{ helpDeskUrl }}"
                  class="ticket-button"
                >
                  Help Desk</a
                >
              </mat-panel-title>
            </span>
            <span class="mat-content">
              Provides quick access to user assistance and support resources.
            </span>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentMargin }">
    </mat-sidenav-content>
  </mat-sidenav-container>
  <!-- ------------------------------------------------------------------- -->
  <div class="row-fullpage d-flex">
    <div class="panelcard inner-side-left content-scroll">
      <div
        *ngFor="let i of sliderdata_name"
        style="width: 15%; min-width: 250px"
      >
        <button
          mat-button
          routerLinkActive="active-list-item"
          routerLink="/{{ i.route }}"
          (click)="mobilemenuCTRL()"
        >
          {{ i.menu_name }}
        </button>
      </div>
    </div>
    <div
      class="col-md-right content-scroll"
      [ngClass]="{ bodyContent: is_trial == 'true' }"
      style="width: 100%; background: white; height: 100vh"
    >
      <router-outlet></router-outlet>
      <notifier-container></notifier-container>
    </div>
  </div>
  <!-- <mat-drawer-container class="example-container" [hasBackdrop]="hasBackdrop.value">
    <mat-drawer #drawer [mode]="mode.value" position="end">I'm a drawer</mat-drawer>
    <mat-drawer-content>
      <mat-form-field>
        <mat-label>Sidenav mode</mat-label>
        <mat-select #mode value="side">
          <mat-option value="side">Side</mat-option>
          <mat-option value="over">Over</mat-option>
          <mat-option value="push">Push</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Has backdrop</mat-label>
        <mat-select #hasBackdrop>
          <mat-option>Unset</mat-option>
          <mat-option [value]="true">True</mat-option>
          <mat-option [value]="false">False</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button>
    </mat-drawer-content>
  </mat-drawer-container> -->
  <mat-sidenav-container class="sidenav-container" [hasBackdrop]="true">
    <mat-sidenav
      fixedInViewport="true"
      mode="side"
      position="end"
      #sidenav
      id="sidenav"
      [class.menu-open]="rightMenuOpen"
      [class.menu-close]="!rightMenuOpen"
      opened
      style="padding-top: 30px"
    >
      <div class="w-100 not-head">
        <p class="text-center mt-2" style="font-size: medium">Total Request</p>
        <span class="mx-5">Total : {{ requestCount }} </span><br />
        <span class="mx-5">UnApproved : {{ unApprovedRequestCount }}</span
        ><br />
      </div>
      <mat-list role="list"> </mat-list>
      <!-- </mat-card-content>
          <mat-card-actions> -->
      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-primary btn-sm not-btn"
          (click)="tableReservationDashboard()"
        >
          Dashboard
        </button>
      </div>
      <hr />
      <!-- <mat-nav-list>
        <hr>
        <div  style="padding: 5px 10px;cursor: pointer;">
          <mat-panel-title>
          </mat-panel-title>
        </div>
      </mat-nav-list> -->
      <!-- <mat-menu #notification="matMenu"> -->

      <!-- <mat-menu #notification="matMenu">
        <mat-card>
          <mat-card-title> Table Reservation </mat-card-title>
          <mat-card-content> -->

      <!-- <button type="button" class="btn btn-danger btn-sm not-btn"
                (click)="onRejectClick($event, kot.transaction_id)">Reject</button> -->
      <!-- </mat-card-actions>
        </mat-card> -->

      <!------------------------------------------website codes--------------------------------------------------->
      <!-- <div
        *ngFor="let data of websiteInvoiceData"
        class="p-2 not-sec"
        style="width: auto"
      >
      
        <mat-card class="w-100 border border-2">
        
          <mat-card-content>
            <div class="w-100 not-head">
              <div
                class="row"
                style="color: rgb(233, 31, 58); margin-top: 10px"
              >
                <div class="d-flex justify-content-between mx-5">
                  <div class="col-md-6 col-sm-6">
                    <button
                      class="btn-primary btn-sm"
                      style="margin-left: -20px"
                      mat-raised-button
                      (click)="toggleDetails(data, $event)"
                    >
                      Customer
                    </button>
                  </div>
                  <div
                    class="col-md-4 col-sm-4 d-flex justify-content-end"
                    style="margin-top: 1px; margin-left: 10px"
                  >
                    <mat-icon
                      class="text-primary"
                      style="font-size: 20px; float: left; cursor: pointer"
                      (click)="kot(data)"
                      >save</mat-icon
                    >
                    <mat-icon
                      class="text-primary"
                      style="
                        font-size: 20px;
                        cursor: pointer;
                        margin-left: 15px;
                      "
                      (click)="invoice(data)"
                      >print</mat-icon
                    >
                  </div>
                </div>
                <br />
                <div
                  *ngIf="data.show_details"
                  style="margin-left: 15px"
                  class="mx-5"
                >
                  <span>
                    Customer Name:
                    <span style="color: rgb(26, 21, 22); margin-top: 5px"
                      >{{ data.customer_name }}.</span
                    >
                  </span>
                  <br />
                  <span>
                    Contact:
                    <span style="color: rgb(26, 21, 22)"
                      >{{ data.contact_no }}.</span
                    >
                  </span>
                  <br />
                  <span>
                    Address:
                    <span style="color: rgb(26, 21, 22)">{{
                      data.shipping_address1
                    }}</span>
                  </span>
                  <hr />
                </div>
              </div>

              <p style="margin-top: 10px" class="mx-5">
                #Invoice : {{ data.display_trans_no }}
              </p>

              <span class="mx-5">#KOT : {{ data.trans_no }}</span
              ><br />
              <span class="mx-5"
                >Date: {{ data.trans_date | date : "short" }}</span
              ><br />

              <span class="mx-5">
                Trans Amount: <i class="fa fa-{{ currency }}"></i
                >{{ data.net_amount | number : "1.0-0" }}
              </span>
            </div>
          </mat-card-content>
          <mat-card-actions>
        
            <p
              *ngIf="data.advance === 0"
              style="color: crimson; margin-top: 18px"
              class="mx-5"
            >
              Pay At Restaurant
              <mat-checkbox
                [checked]="data.is_muted"
                (change)="muteAlertRingtone(data, $event)"
                style="
                  cursor: pointer;
                  float: right;
                  margin-bottom: 12px;
                  color: rgb(173, 13, 13);
                "
              >
                {{ data.is_muted ? "Seen" : "Unseen" }}
              </mat-checkbox>
            </p>

            <p
              *ngIf="data.advance === data.net_amount"
              style="color: green; margin-top: 16px"
              class="mx-5"
            >
              Paid
              <mat-icon
                [ngClass]="{ muted: data.is_muted }"
                style="
                  cursor: pointer;
                  float: right;
                  margin-bottom: 10px;
                  color: rgb(173, 13, 13);
                "
                (click)="muteAlertRingtone(data, $event)"
                >volume_off</mat-icon
              >
            </p>
          </mat-card-actions>
        </mat-card>
      
      </div> -->

      <div
        *ngFor="let data of websiteInvoiceData"
        class="p-2 not-sec"
        style="width: auto"
      >
        <div class="d-flex justify-content-center">
          <mat-card class="border border-2" style="width: 300px">
            <mat-card-content>
              <div class="w-100 not-head">
                <div class="row">
                  <!-- <div class="d-flex justify-content-between"> -->
                  <div class="col-md-12 col-sm-12">
                    <div style="display: flex; align-items: center">
                      <!-- <mat-checkbox
                          class="text-primary"
                          (change)="onCheckboxChange($event, 'approved', data)"
                          [checked]="data.kot_approved"
                          style="cursor: pointer"
                        >
                          {{ data.kot_approved ? "Approved" : "Approve" }}
                        </mat-checkbox> -->

                      <button
                        type="button"
                        class="btn btn-sm not-btn"
                        [ngClass]="{
                          'btn-success': data.kot_approved,
                          'btn-primary': !data.kot_approved
                        }"
                        [disabled]="data.kot_rejected"
                        (click)="siteOrderApprove($event, data)"
                      >
                        {{ data.kot_approved ? "Approved" : "Approve" }}
                      </button>

                      <!-- <mat-checkbox
                          class="text-primary"
                          (change)="onCheckboxChange($event, 'rejected', data)"
                          [checked]="data.kot_rejected"
                          style="margin-left: 10px; cursor: pointer"
                        >
                          {{ data.kot_rejected ? "rejected" : "reject" }}
                        </mat-checkbox> -->

                      <button
                        type="button"
                        class="btn btn-sm not-btn"
                        [ngClass]="{
                          'btn-danger': data.kot_rejected,
                          'btn-primary': !data.kot_rejected
                        }"
                        [disabled]="data.kot_approved"
                        (click)="siteOrderReject($event, data)"
                      >
                        {{ data.kot_rejected ? "Rejected" : "Reject" }}
                      </button>
                    </div>
                  </div>
                  <!-- <div
                      class="col-md-4 col-sm-4 d-flex justify-content-end"
                      style="margin-top: 1px; margin-left: 20px"
                    >
                      <mat-icon
                        class="text-primary"
                        style="font-size: 20px; cursor: pointer"
                        (click)="kot(data)"
                        >save</mat-icon
                      >
                      <mat-icon
                        class="text-primary"
                        style="
                          font-size: 20px;
                          cursor: pointer;
                          margin-left: 10px;
                        "
                        (click)="invoice(data)"
                        >print</mat-icon
                      >
                    </div> -->
                  <!-- </div> -->
                </div>

                <div
                  style="
                    margin-left: 0px;
                    color: rgb(233, 31, 58);
                    margin-top: 10px;
                  "
                >
                  <span>
                    Customer Name:
                    <span style="color: rgb(26, 21, 22); margin-top: 5px"
                      >{{ data.customer_name }}.</span
                    >
                  </span>
                  <br />
                  <span>
                    Contact:
                    <span style="color: rgb(26, 21, 22)"
                      >{{ data.contact_no }}.</span
                    >
                  </span>
                  <br />
                  <span>
                    Address:
                    <span style="color: rgb(26, 21, 22)">{{
                      data.shipping_address1
                    }},{{
                      data.shipping_address2
                    }}</span>
                   
                  </span>
                  <hr />
                </div>

                <p style="margin-top: 10px">
                  #Invoice : {{ data.display_trans_no }}
                </p>

                <span>#KOT : {{ data.trans_no }}</span
                ><br />
                <span>Date: {{ data.trans_date | date : "short" }}</span
                ><br />

                <span>
                  Trans Amount: <i class="fa fa-{{ currency }}"></i
                  >{{ data.net_amount | number : "1.0-0" }}
                </span>
              </div>
            </mat-card-content>
            <hr />
            <div class="d-flex justify-content-between">
              <span style="color: blueviolet; font-weight: 700;">Rider Invoice</span>

              <mat-icon
              class="text-primary"
              style="
                font-size: 25px;
                cursor: pointer;
              "
              (click)="riderInvoice(data.transaction_id,data.display_trans_no)"
              >print</mat-icon
            >
            </div>
            
            <mat-card-actions>
              <p
                *ngIf="data.advance === 0"
                style="color: crimson; margin-top: 18px"
              >
                Paid by COD
                <mat-checkbox
                  [checked]="data.show_details"
                  (change)="NotificationSeen(data, $event)"
                  style="
                    cursor: pointer;
                    float: right;
                    margin-bottom: 12px;
                    color: rgb(173, 13, 13);
                  "
                >
                  {{ data.show_details ? "Seen" : "Unseen" }}
                </mat-checkbox>
              </p>

              <p
                *ngIf="data.advance === data.net_amount"
                style="color: green; margin-top: 16px"
              >
                Paid
                <mat-icon
                  [ngClass]="{ muted: data.is_muted }"
                  style="
                    cursor: pointer;
                    float: right;
                    margin-bottom: 10px;
                    color: rgb(173, 13, 13);
                  "
                  (click)="muteAlertRingtone(data, $event)"
                  >volume_off</mat-icon
                >
              </p>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

      <!-- </mat-menu> -->

      <!--------------------------------web app codes------------------------------------------------------>
      <div *ngIf="kotArr && kotArr.length > 0">
        <div *ngFor="let kot of kotArr" class="p-2 not-sec">
          <mat-card *ngIf="kot.order_mode.toLowerCase() != 'website'">
            <mat-card-content>
              <div class="w-100 not-head border border-1">
                <img
                  style="max-width: 52px; border-radius: 12px"
                  *ngIf="
                    kot.order_mode.toLowerCase() === 'swiggy' ||
                    kot.order_mode.toLowerCase() === 'zomato'
                  "
                  src="assets/images/{{ kot.order_mode }}.png"
                  alt=""
                  class="pull-right"
                />
                <span>#KOT: {{ kot.trans_no }}</span>
                <span
                  class="pull-right"
                  *ngIf="
                    kot.order_mode.toLowerCase() !== 'swiggy' &&
                    kot.order_mode.toLowerCase() !== 'zomato'
                  "
                  >Table: {{ kot.name }}</span
                >
              </div>
              <mat-list role="list">
                <mat-list-item
                  *ngFor="let item of kot.items_details"
                  role="listitem"
                  class="ml-3"
                >
                  <div class="w-100">
                    <span
                      >{{ convertFloat(item.qty) }} x {{ item.item_name }}</span
                    >
                    <span class="pull-right pr-3"
                      ><i class="fa fa-{{ currency }}"></i
                      >{{ item.amount | number : "1.0-0" }}</span
                    >
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
            <mat-card-actions>
              <button
                type="button"
                class="btn btn-primary btn-sm not-btn"
                (click)="onApproveClick($event, kot)"
                *ngIf="!kot.food_cancel && !kot.auto_accept"
              >
                Approve
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm not-btn"
                (click)="onRejectClick($event, kot)"
                *ngIf="!kot.food_cancel && !kot.auto_accept"
              >
                Reject
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="cancelledOrder($event, kot)"
                *ngIf="kot.food_cancel && !kot.auto_accept"
              >
                Order Cancelled
              </button>
              <button
                type="button"
                class="btn btn-success"
                (click)="orderAutoAccepted($event, kot)"
                *ngIf="kot.auto_accept"
              >
                Auto Accepted
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
      <!-- </mat-menu> -->
    </mat-sidenav>
    <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentMargin }">
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
