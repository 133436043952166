import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-items-dialog',
  templateUrl: './items-dialog.component.html',
  styleUrls: ['./items-dialog.component.css']
})
export class ItemsDialogComponent implements OnInit {

  checkedOprionAmount: number = 0;
  checkedOprionArr: any = [];
  checkedVariantAmount: number = 0;
  checkedVariant: any;
  itmData: any = [];
  optionsArr: any = [];
  variantsArr: any = [];
  sRate: number = 0;
  defaultVariantSize: any;
  totalAmt: number = 0;
  currency: any;
  isTransactionGSTslab: boolean = false;
  isLocal: boolean = false;
  status: any = "add"
  constructor(private dialogRef: MatDialogRef<ItemsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any = []) {
    this.currency = environment.currency;
    // console.log("data---", data)
    this.status = data.status ? data.status : "add"
    if (this.status === "add") {
      this.itmData = data.item
      this.initialized()
    } else {
      this.itmData = data.item
      this.updatedRowData(this.itmData)
    }

  }

  ngOnInit() {
    this.load()
  }

  load() {
    // console.log("this.itmData.qty-", this.itmData.qty)
    const isTransaction = localStorage.getItem("isTransactionGSTslab");
    this.isTransactionGSTslab = isTransaction == "false" ? false : true;
    const isLoc = localStorage.getItem("isLocal");
    this.isLocal = isLoc == "false" ? false : true;
    // if (this.data.status && this.data.status !== "update") {
    if (this.itmData.variant_groups.length)
      this.onChangeChildVariant();
    if (this.itmData.options.length)
      this.extractCheckedOptions();

    this.calculateSOAmount();
    // }
  }


  initialized() {
    console.log("------initialize------",this.itmData.s_rate)
    this.defaultVariantSize = this.itmData.variant_groups.length ? this.itmData.variant_groups[0].variants.filter((variant: any) => variant.is_default)[0] : null
    this.itmData.variant_groups.length ? this.itmData.variant_groups[0].variants
      .filter(e => e.is_default && e.in_stock)
      .forEach(e => {
        this.sRate += parseFloat(e.price);
      }) : [];
    this.sRate = this.sRate > 0 ? this.sRate : this.itmData.s_rate;
    // this.itmData.options[0].childOptions
    //   .filter(e => {
    //     if (e.is_default && e.in_stock) {
    //       this.sRate = this.sRate + parseFloat(e.amount);
    //     }
    //   });
    this.optionsArr = this.itmData.options;
    this.variantsArr = this.itmData.variant_groups;
    this.optionsArr = this.optionsArr.map(option => ({
      ...option,
      childOptions: option.childOptions.map(child => ({
        ...child,
        ischecked: child.in_stock === true ? child.is_default : false,
      })),
    }));
  }

  async calculateSOAmount() {
    const optionAmount = await this.calculateOptionAmount()
    this.checkedVariantAmount = this.defaultVariantSize ? parseFloat(this.defaultVariantSize.price) : 0
    // this.totalAmt = this.checkedOprionAmount || 0 + this.checkedVariantAmount || 0
    // console.log("this.itmData.s_rate--", this.itmData.s_rate, this.defaultVariantSize, this.checkedOprionAmount)

    const salesRate = this.defaultVariantSize && this.defaultVariantSize.price > 0 ? parseFloat(this.defaultVariantSize.price) : this.itmData.s_rate;
    this.totalAmt = this.checkedOprionAmount + salesRate
  }
  onChangeChildVariant() {

    this.checkedVariant = this.variantsArr.map(e => {
      return { ...e, variants: [this.defaultVariantSize] };
    });
    this.checkedVariantAmount = this.defaultVariantSize ? parseFloat(this.defaultVariantSize.price) : 0
    this.calculateSOAmount()
  }


  onChangeAddons($event, checkedId: any) {
    this.optionsArr.forEach(p => {
      p.childOptions.map(e => {
        if (e.id == checkedId) {
          e.ischecked = $event
        }
      })
    })
    this.calculateSOAmount()
    this.extractCheckedOptions()
  }


  calculateOptionAmount() {
    // console.log("this.isTransactionGSTslab--", this.isTransactionGSTslab)
    let oprionAmount = 0
    // if (this.isTransactionGSTslab) {
    this.optionsArr.forEach(p => {
      p.childOptions.forEach(e => {
        if (e.ischecked == true && e.amount > 0 && e.in_stock)
          oprionAmount += parseFloat(e.amount)
      })
    })
    // } else {
    //   this.optionsArr.forEach(p => {
    //     p.childOptions.forEach(e => {
    //       console.log("eeeeeeeeeeeeeeeeeeeee-", e)
    //       if (e.ischecked == true && e.amount > 0 && e.in_stock) {

    //         if (this.isLocal) {
    //           e.sgst_per = e.taxslab / 2;
    //           e.sgst_amt = e.amount * (e.sgst_per / 100) || 0;
    //           e.sgst_amt = Math.round(e.sgst_amt * 100) / 100;
    //           e.cgst_per = e.taxslab / 2;
    //           e.cgst_amt = e.amount * (e.cgst_per / 100) || 0;
    //           e.cgst_amt = Math.round(e.cgst_amt * 100) / 100;
    //         } else {
    //           e.igst_per = e.taxslab;
    //           e.igst_amt = e.amount * (e.igst_per / 100) || 0;
    //           e.igst_amt = Math.round(e.igst_amt * 100) / 100;
    //         }

    //         console.log("e.sgst_amt || 0 + e.cgst_amt || 0 + e.igst_amt || 0;--", e.taxslab, e.sgst_amt, e.cgst_amt, e.igst_amt)
    //         e.total_tax = (e.sgst_amt || 0) + (e.cgst_amt || 0) + (e.igst_amt || 0);
    //         e.total = parseFloat(e.amount) + e.total_tax;
    //         oprionAmount += (parseFloat(e.amount) + e.total_tax)
    //         console.log("e.total = e.amount + e.total_tax;--", e.total_tax, parseFloat(e.amount), oprionAmount)
    //       }
    //     })
    //   })
    // }
    this.checkedOprionAmount = oprionAmount
    return oprionAmount
  }

  addItems() {
    this.extractCheckedOptions();
    this.calculateSOAmount();
    this.itmData.optionAmount = this.checkedOprionAmount;
    this.itmData.optionsData = this.checkedOprionArr;
    this.itmData.checkedVariantAmount = this.checkedVariantAmount;
    this.itmData.checkedVariant = this.checkedVariant;
    this.itmData.qty = this.status == "update" ? (this.itmData.qty ? this.itmData.qty : 1) : 1;
    this.itmData.s_rate = this.checkedVariantAmount && this.checkedVariantAmount > 0 ? this.checkedVariantAmount : this.itmData.s_rate
    this.dialogRef.close({ item: this.itmData, status: this.status });
    this.load()
  }


  extractCheckedOptions() {
    this.checkedOprionArr = this.optionsArr.map(option => ({
      ...option,
      childOptions: option.childOptions.filter(childOption => childOption.ischecked == true && childOption.in_stock == true)
    }));

    // console.log("this.checkedOprionArr--", this.checkedOprionArr)
  }
  // -------------------------update customisable -------------


  updatedRowData(itmdata) {
    this.sRate = itmdata.s_rate;
    this.optionsArr = this.extractCheckedOptionsData(itmdata);
    this.variantsArr = this.extractCheckedVariantsData(itmdata);
    this.defaultVariantSize = this.variantsArr && this.variantsArr.length ? this.variantsArr[0].variants.filter((variant: any) => variant.ischecked)[0] : null
    // console.log("sRate------", this.sRate, this.optionsArr, this.variantsArr)
  }






  extractCheckedOptionsData(item) {
    const updatedArray = item.options && item.options.length
      ? item.options.map(option => {
        return {
          ...option,
          childOptions: option.childOptions.map(child => ({
            ...child,
            ischecked: item.optionsData && item.optionsData.length
              ? item.optionsData
                .find(addedOption => addedOption.id === option.id)
                .childOptions.some(addedChild => addedChild.id === child.id)
              : false,
          })),
        };
      })
      : [];
    // console.log("updatedArray--", updatedArray)
    return updatedArray;
  }
  extractCheckedVariantsData(item) {
    const updatedArray = item.variant_groups && item.variant_groups.length
      ? item.variant_groups.map(option => {
        return {
          ...option,
          variants: option.variants.map(child => ({
            ...child,
            ischecked: item.checkedVariant && item.checkedVariant.length
              ? item.checkedVariant
                .find(addedVariants => addedVariants.id === option.id)
                .variants.some(addedChild => addedChild.id === child.id ? true : false)
              : false,
          })),
        };
      })
      : [];
    // console.log("updatedArray--", updatedArray)
    return updatedArray;
  }


}
