import { Component, OnInit, Inject } from '@angular/core';
import * as FileSaver from 'file-saver';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { DataService } from '../data.service';


import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'db-backup',
  templateUrl: './db-backup.component.html',
  styleUrls: ['db-backup.component.css'],

})
export class DBbackupComponent implements OnInit {

  result: any;

  constructor(private apiService: DataService, private notifier: NotifierService,

    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DBbackupComponent>) {

  }
  ngOnInit() {

  }
  google_drive() {

    this.apiService.create_google_drive_DBBackup().subscribe(
      (result: any) => {
        if (result.success) {
          // console.log(result);
          this.dialogRef.close(result.url);

        }
        else {
          this.notifier.notify('error', 'something went wrong');
        }
      },
      (result: any) => {

      });


  }

  local_storage() {
    this.apiService.createDBBackup()
      .subscribe((result: any) => {
        let blob = new Blob([result], {
          type: 'application/tar'
        });
        var dt = new Date();
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'IB' + dt.toJSON().slice(0, 23).replace(/[-T]/g, '').replace(/[:]/g, '').replace(/[.]/g, '').replace(/[-]/g, '') + '.tar';
        link.click();
        window.URL.revokeObjectURL(link.href);
        this.dialogRef.close(null);

      }, (result: any) => {
      });
  }
  closeDialog() {
    this.dialogRef.close(null);
  }

}



