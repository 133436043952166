import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { NotifierService } from 'angular-notifier';

import {DataService} from '../../data.service';
@Component({
  selector: 'app-add-unit',
  templateUrl: './add-unit.component.html',
  styleUrls: ['./add-unit.component.css']
})
export class AddUnitComponent implements OnInit {
  @ViewChild('f') f;
  constructor(private dialogRef: MatDialogRef<AddUnitComponent>,
    private notifier: NotifierService,
    private apiService: DataService, 
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.f.submitted = true;
    this.load();
  }
  model: any = {};
  load(){
    if(this.data){
      this.apiService.getUnit(this.data.unit_id)
      .subscribe((result: any) => {
        this.model=result[0];
      }, (result: any) => {
        this.notifier.notify( 'error', 'unable to load data' );
      });
    }
  }
  onSubmit() {
    if(this.data){
      this.apiService.editUnit(this.model.unit_id,this.model)
      .subscribe((result: any) => {
      this.dialogRef.close('Edited');
      }, (result: any) => {
        this.notifier.notify( 'error', 'unable to update unit details' );
      });
    }else{
      this.apiService.addUnit(this.model)
      .subscribe((result: any) => {
        if(result.success)
        this.dialogRef.close({status:'Added',unit_code:this.model.unit_code});
      else
      this.notifier.notify( 'error', result.message );      

      }, (result: any) => {
        this.notifier.notify( 'error', 'unable to add new unit' );
      });
    }
    
  }
  
  close(){
    this.dialogRef.close();
  }
}