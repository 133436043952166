import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from "@angular/material";
import { NotifierService } from 'angular-notifier';

import { DataService } from '../../data.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { startWith, map } from 'rxjs/operators';
@Component({
  selector: 'app-add-edit-suppliers-master',
  templateUrl: './add-edit-suppliers-master.component.html',
  styleUrls: ['./add-edit-suppliers-master.component.css']
})
export class AddEditSuppliersMasterComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AddEditSuppliersMasterComponent>,
    private apiService: DataService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  @ViewChild('f') f;
  ngOnInit() {
    this.Allstates();
   // this.load();
  }
  result_data = {};
  model: any = {};
  partyTypeObj: any = [{ value: "Local" }, { value: "InterState" }];
  obdrcr: any = [{ value: "DR" }, { value: "CR" }];
  accntTypeObj: any = [{ value: "Saving" }, { value: "Current" }, { value: "Other" }];
  GState: any;
  stateName: any;
  statelist: any;
  Allstates() {
    this.apiService.getGstState()
      .subscribe((result: any) => {
        this.stateName = result;
       // this.searchState();
       this.load();
        // console.log(this.stateName);
      }, (result: any) => {
        this.notifier.notify('error', 'unable to load data');
      });
  }
  stateControl = new FormControl();
  filteredState: Observable<string[]>;

  location_filter(value) {
    const filterValue = value ? value.toLowerCase() : '';
    return this.stateName.filter(state => state.state_name.toLowerCase().includes(filterValue));
  }

  displayStateFn(state) {
    return state ? state.state_name : "";
  }
  // searchState() {
  //   this.filteredState = this.stateControl.valueChanges
  //     .pipe(
  //       startWith(''),
  //       map(value => this.location_filter(value))
  //     );

  // }
  stateSelection(event: MatAutocompleteSelectedEvent) {
    this.model.address_state = event.option.value ? event.option.value.state_gst_id : undefined;
  }
  load() {
    this.f.submitted = true;
    if (this.data) {
      this.apiService.getLedger(this.data.ledger_id)
        .subscribe((result: any) => {
          this.model = result[0];
          var indexOfState = this.stateName.findIndex(i => i.state_gst_id === this.model.address_state);
          this.stateControl.setValue(this.stateName[indexOfState]);
          //this.searchState();
        }, (result: any) => {
          this.notifier.notify('error', 'unable to load data');
        });
    }
  }


  StateonFocus(e) {
    this.apiService.sgetGstState('a', 'false')
      .subscribe((result: any) => {
        this.stateName = result;
        this.filteredState = this.stateControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this.location_filter(value))
        );
        //this.stateName = this.GState;
        // console.log(this.stateName);
      });
  }


  searchState1(event) {
    if (event.key != 'ArrowDown' && event.key != 'ArrowUp' && event.key != 'Enter') {
      if (this.stateControl.value.length >= 1) {
        this.apiService.sgetGstState(this.stateControl.value, 'false')
          .subscribe((result: any) => {
            this.stateName = result;
          });
      }else{
        this.filteredState = this.stateControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.location_filter(value))
          );
      }
    }
  }


  onSubmit() {
    if (this.data) {
      this.apiService.editLedger(this.model.ledger_id, this.model)
        .subscribe((result: any) => {
          if (result.success) {
            this.dialogRef.close('Edited');
            this.notifier.notify('success', 'Supplier updated successfully');
          } else {
            this.notifier.notify('error', result.message);
          }
        }, (result: any) => {
          this.notifier.notify('error', 'unable to update data');
        });
    } else {
      this.model.group_id = 3;
      this.model.customer_item_rate_applicable=false;
      this.model.use_qty_from_sale_history=false;
      this.apiService.addLedger(this.model)
        .subscribe((result: any) => {
          if (result.success) {
            this.result_data = { status: 'Added', data: result.data };
            this.dialogRef.close(this.result_data);
            this.notifier.notify('success', 'New Supplier added successfully');
          } else {
            this.notifier.notify('error', result.message);
          }
        }, (result: any) => {
          this.notifier.notify('error', 'unable to add new Supplier');
        });
    }
  }

  close() {
    this.dialogRef.close(this.result_data);
  }
}

