import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-pending-ordrs',
  templateUrl: './pending-ordrs.component.html',
  styleUrls: ['./pending-ordrs.component.css']
})
export class PendingOrdrsComponent implements OnInit {

  constructor(private apiService:DataService) { }

  ngOnInit() {
    this.getPendingOrders();
  }
  PendingdoCount:any;

  getPendingOrders(){
  this.apiService.getPendingOrders().subscribe( (result:any)=>{
 
   
 
    this.PendingdoCount = result[0].count;
    
  })
  }
}
