import { Component, OnInit, Inject,Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from "@angular/material";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../../data.service';
import { AddItemComponent } from '../../items/add-item/add-item.component';
import { environment} from '../../../environments/environment';
@Component({
  selector: 'app-purchase-order-items',
  templateUrl: './purchase-order-items.component.html',
  styleUrls: ['./purchase-order-items.component.css']
})
export class PurchaseOrderItemsComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<PurchaseOrderItemsComponent>, private apiService: DataService,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.currency=environment.currency;
     }
     @Output() onItemAdd = new EventEmitter<string>();

  model: any={};
  moreField: boolean = false;
  optionalFields: any
  ngOnInit() {
    this.getEnabledAdditionItemFields();
    this.load();
    
  }
  itemControl = new FormControl();
  currency:any;
  options: any;
  filteredOptions: Observable<string[]>;
  itemObj: any;
  unitObj: any;
  load() {
    if (this.data.value) {
      this.model = this.data.value;
      this.itemControl.setValue(this.data.value);
    } else {
      this.model = {};
      this.model.qty = 0;
      this.model.p_rate = 0;
    }
    this.apiService.getItemList()
      .subscribe((result: any) => {
        this.itemObj = result;
      }, (result: any) => {
      });
  }

  getEnabledAdditionItemFields() {
    this.apiService.getEnabledItemAdditionField(this.data.v_type_id)
      .subscribe((result: any) => {
        this.optionalFields = result;
        if (this.optionalFields.length > 0) {
          this.moreField = true;
        }
      }, (result: any) => {
        //this.notifier.notify('error', 'unable to load enabled fields');
      });
  }

  _filter(value) {
    const filterValue = value ? value.toLowerCase() : value;
    return this.itemObj.filter(item => item.item_code.toLowerCase().includes(filterValue) || item.item_name.toLowerCase().includes(filterValue));
  }

  displayFn(Item) {
    return Item ? Item.item_name : "";
  }

  search(event) {
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl.value.length > 1) {
        this.apiService.getFilterItemList(this.itemControl.value)
          .subscribe((result: any) => {
            this.itemObj = result;
            this.filteredOptions = this.itemControl.valueChanges
              .pipe(
                startWith(''),
                map(value => this._filter(value))
              );
          }, (result: any) => {
          });
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    }
  }

  itemSelection(event: MatAutocompleteSelectedEvent) {
    var item_id = event.option.value ? event.option.value.item_id : undefined;
    if (item_id) {
      var indexofItem = this.itemObj.findIndex(i => i.item_id === item_id);
      this.populateItemData(this.itemObj[indexofItem]);
    }
  }
  populateItemData(itemDetails) {
    this.model.item_id = itemDetails.item_id;
    this.model.item_name = itemDetails.item_name;
    this.model.item_code = itemDetails.item_code;
    this.model.qty = 1;
    this.model.p_rate = itemDetails.p_rate;
    this.model.s_rate = itemDetails.s_rate;
    this.model.taxslab = itemDetails.taxslab;
    this.calculate();
  }
  isValidate() {
    if (this.model.item_id && this.model.qty) {
      return true;
    } else {
      return false
    }
  }

  onSubmit() {
    if (this.isValidate()) {
      //this.dialogRef.close(this.model);
      this.model.item_id = this.itemControl.value.item_id;
      if (this.data.value) {
        this.dialogRef.close(this.model);
       
      }else{
        this.onItemAdd.emit(this.model);
        this.itemControl.setValue(null);
        this.model = {};
        this.model.qty = 0;
        this.model.p_rate = 0;
      }
     
    }
  }

  close() {
    this.dialogRef.close();
  }
  quantityChange() {
    this.calculate();
  }
  rateChange() {
    this.calculate();
  }
  calculate() {
    if(this.data.type == 'In')
    this.model.amount = this.model.qty * this.model.p_rate;
    if(this.data.type == 'Out')
    this.model.amount = this.model.qty * this.model.s_rate;
  }
  //to add Item
  onAddItemClick() {
    this.dialog.open(AddItemComponent, {
      width: '650px',
    })
      .afterClosed().subscribe(result => {
        if (result.status == 'Added') {
          var itemData = result.data;
          this.itemControl.setValue(itemData);
          this.populateItemData(itemData);
        }
      });
  }
  //on focus on ledger search
  onFocus(e) {
    this.apiService.getItemList()
      .subscribe((result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }, (result: any) => {
      });
  }
}
